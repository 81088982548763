import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'
import axios from 'axios'

const routes = {
  inviteCodes: 'invite-codes',
  tryInviteCode: 'invite-code',
  requestInviteCodes: 'request-invite-codes',
} as const

export type CreatorInviteCode = {
  code: string
  isActive: boolean
  usedAt: Date
  userAddress: string | null
}

export type CreatorUsedInviteCode = CreatorInviteCode & {
  userPoints: number
}

export type InviteCodes = {
  activeInviteCodes: CreatorInviteCode[]
  usedInviteCodes: CreatorUsedInviteCode[]
}

export interface TryInviteCodeResp {
  isSuccessful: boolean
  remainingAttempts?: number
  hoursLeft?: number
  reason?: string
}

export interface RequestInviteCodesResp {
  message: string
}

const isLocal = import.meta.env.VITE_DEPLOYMENT_ENV === 'local'

export const inviteCodesApi = {
  async getInviteCodes() {
    const httpUrl = useAppChainConfigStore.getState().appChainConfig.httpUrl

    const { data } = await axios({
      method: 'GET',
      withCredentials: true,
      url: `${httpUrl}/${routes.inviteCodes}`,
    })

    return data as InviteCodes
  },

  async tryInviteCode(inviteCode: string, getAccessToken?: any) {
    const httpUrl = useAppChainConfigStore.getState().appChainConfig.httpUrl

    const data: any = {
      inviteCode,
    }

    if (isLocal && getAccessToken) {
      data.privyBodyIdToken = await getAccessToken()
    }

    const { data: resData } = await axios({
      method: 'POST',
      withCredentials: true,
      data,
      url: `${httpUrl}/${routes.tryInviteCode}`,
    })

    return resData as TryInviteCodeResp
  },

  async requestInviteCodes() {
    const httpUrl = useAppChainConfigStore.getState().appChainConfig.httpUrl

    try {
      const { data } = await axios({
        method: 'POST',
        withCredentials: true,
        url: `${httpUrl}/${routes.requestInviteCodes}`,
      })

      return data as RequestInviteCodesResp
    } catch (err) {
      throw new Error('Already request codes')
    }
  },
}
