import useUserDataStore from '@/store/useUserDataStore'
import { type UserFState, initialUserFState } from '../state'
import type { UserSocket } from '../types'
import { FSpaceBase, type IFSpaceBaseOpts } from './FSpace'
import { skulls } from '@/assets/svg/deathmatch-skulls'
import { addAppNoti } from '@/store/useNotiStore'
import { sendGameStoreUpdateByAddress } from '@/store/useGameStateStore'
import { type AppGameName } from '@/chains/types'
import { formatUnits } from 'viem'
import { useBombsGameStore } from '@/store/useBombsGameStore'
import { getAppDecimals } from '@/hooks/useAppChainConfig'
import { useCrashGameStore } from '@/store/useCrashGameStore'
type UserSpaceOpts = Omit<IFSpaceBaseOpts<UserFState>, 'name' | 'status' | 'state'>

export class UserSpace extends FSpaceBase<UserSocket, UserFState> {
  constructor({ fsocketOpts, authToken }: UserSpaceOpts) {
    super({
      name: 'user',
      state: initialUserFState,
      fsocketOpts,
      authToken,
    })

    this.mountListeners()
  }

  onReconnectSocket(): void {
    this.state.bankrollBalance = '0'
  }

  mountListeners() {
    if (this.hasMountedListeners) return
    this.hasMountedListeners = true

    this.io.on('initial_state', initialUserState => {
      this.state.bankrollBalance = initialUserState.bankrollBalance
    })

    // TODO: Ensure that the same chat message isn't added more than once
    this.io.on('bankroll_balance_updated', newBankrollBalance => {
      this.state.bankrollBalance = newBankrollBalance
    })

    this.io.on('user_level_up', levelUpPayload => {
      const { levelId, levelMultiplier } = levelUpPayload
      useUserDataStore.getState().setLevelMultiplier(levelMultiplier)
      // TODO: We have the new level id in state.liveEntry.inProgressLiveEntry.levelId. Whenever we identify a level up with levelMultiplier change, render a notification toast as well
      addAppNoti({
        msg: `Congratulations: LEVEL ${levelId}`,
        type: 'success',
        image: (skulls as any)[`skull${levelId}Complete`],
      })
    })

    this.io.on('su_entry_resolved', suEntryResolvedPayload => {
      // @NOTE: We used to run the below commented out code to set some state values, not sure if we should do it now or not
      console.log('BOMBS: calling resolveEntry of bombs with payload: ', suEntryResolvedPayload)

      // @TODO: Other games are expecting `sendGameStoreUpdateByAddress*() but bombs, do not work like that, so handle bombs explicitly`
      if (suEntryResolvedPayload.gameName === 'bombs') {
        useBombsGameStore.getState().resolveEntry({
          resultSides: suEntryResolvedPayload.resultSides,
          playedCount: suEntryResolvedPayload.playedCount,
          deltaAmounts: suEntryResolvedPayload.deltaAmounts.map(r =>
            Number(formatUnits(BigInt(r), getAppDecimals()))
          ),
          totalDeltaAmount: Number(suEntryResolvedPayload.totalDeltaAmount),
        })
      } else if (suEntryResolvedPayload.gameName === 'crash') {
        console.log('CRASH::: calling resolveEntry of crash with payload: ', suEntryResolvedPayload)
        useCrashGameStore.getState().resolveEntry({
          resultSides: suEntryResolvedPayload.resultSides,
          playedCount: suEntryResolvedPayload.playedCount,
          deltaAmounts: suEntryResolvedPayload.deltaAmounts.map(r =>
            Number(formatUnits(BigInt(r), getAppDecimals()))
          ),
          totalDeltaAmount: Number(suEntryResolvedPayload.totalDeltaAmount),
        })
      }

      sendGameStoreUpdateByAddress(suEntryResolvedPayload.gameName as unknown as AppGameName)?.({
        type: 'RESOLVE',
        payload: {
          resultSides: suEntryResolvedPayload.resultSides,
          playedCount: suEntryResolvedPayload.playedCount,
          deltaAmounts: suEntryResolvedPayload.deltaAmounts.map(r =>
            Number(formatUnits(BigInt(r), getAppDecimals()))
          ),
          totalDeltaAmount: Number(suEntryResolvedPayload.totalDeltaAmount),
        },
      })
    })
  }
}
