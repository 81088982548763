import { HeaderWrapper, MainHeader, HeaderStrip } from './style'
import { CenterHeader } from './CenterHeader'
import { RightHeaderContent } from './RightHeaderContent'
import { LeftHeaderContent } from './LeftHeaderContent'

export const Header = () => {
  return (
    <HeaderWrapper>
      <MainHeader>
        <LeftHeaderContent />
        <CenterHeader />
        <RightHeaderContent />
      </MainHeader>
      <HeaderStrip />
    </HeaderWrapper>
  )
}
