import { utils } from 'ethers'
import axios from 'axios'
import type { Web3Provider } from '@ethersproject/providers'

import { windowEth } from '../crypto'
// import { CHAIN_URLS, DEFAULT_CHAIN_ID } from '@/constants/web3'
// import { AUTH_SIGNING_MSG, HTTP_URL } from '@/constants/http'
import { LOCAL_STORAGE } from '@/constants/storage'
import useUserDataStore, { IUserData } from '@/store/useUserDataStore'
import { EthersStoreUtil } from '@web3modal/scaffold-utils/ethers'
import posthog from 'posthog-js'

const authRoute = (route: string) => `NEED TO REPLACE/auth/${route}`

export const getAuthToken = (): string | null => localStorage.getItem('auth-token')

export const faxios = axios.create()
faxios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const status = error.response ? error.response.status : null
    if (status === 401) {
      window.location.reload()
    }
    return Promise.reject(error)
  }
)

// @TODO: Not sure if it is the best idea to have this here
export const linkTwitter = async (publicAddress: string) => {
  const token = JSON.parse((window.localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN) as string) || '')[
    publicAddress
  ]

  const {
    data: { message },
  } = await faxios({
    headers: { token },
    withCredentials: true,
    data: {},
    method: 'POST',
    url: authRoute('link-twitter'),
  })
  console.log('message received from backend: ', message)
  return message
}

export const setUserData = async ({
  username,
  colorTheme,
  publicAddress,
}: {
  username?: string
  colorTheme?: string
  publicAddress: string
}) => {
  // const token = (window.localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN) as string) || ''
  // const publicAddress = utils.getAddress(EthersStoreUtil.state.address || "")
  const token = JSON.parse((window.localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN) as string) || '')[
    publicAddress
  ]

  const {
    data: { message },
  } = await faxios({
    headers: { token },
    data: { username, colorTheme },
    method: 'POST',
    url: authRoute('set-user-data'),
  })
  return message
}

/** If error is thrown user does not have a wallet installed or enabled. */
export const createAuthApi = (provider: Web3Provider | undefined) => {
  const signMessage = async (msg: string) => {
    if (!provider) return console.error('no provider!')
    const signer = provider.getSigner()
    const signedMessage = await signer.signMessage(msg)
    if (!signedMessage) throw new Error('signMessage undefined.')

    return signedMessage
  }

  async function addAndSwitchNetwork(switchChainId = 0) {
    const switchChainIdHex = '0x' + switchChainId.toString(16)
    const switchChainUrl = ''
    let provider = window.ethereum
    try {
      if (!window.ethereum) throw new Error('Wallet extension is not installed')
      // edge case if MM and CBW are both installed
      if (window.ethereum.providers?.length) {
        window.ethereum.providers.forEach(async (p: any) => {
          if (p.isMetaMask) provider = p
        })
      }
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: switchChainIdHex }], // Hexadecimal version of 80001, prefixed with 0x
      })
    } catch (error: any) {
      console.error(error)
      if (error.code === 4902) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: switchChainIdHex, // Hexadecimal version of 80001, prefixed with 0x
                chainName: 'Fare Protocol Testnet',
                nativeCurrency: {
                  name: 'Ethereum',
                  symbol: 'ETH',
                  decimals: 18,
                },
                rpcUrls: [switchChainUrl],
                blockExplorerUrls: null,
                iconUrls: [''],
              },
            ],
          })

          await provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: switchChainIdHex }], // Hexadecimal version of 80001, prefixed with 0x
          })
        } catch (addError: any) {
          console.error(addError)
          throw new Error('User canceled network add/swtich.')
        }
      } else {
        throw new Error(error)
      }
    }
  }

  const verifyAuthToken = async (authToken?: string) => {
    const authenticatedPublicKey = EthersStoreUtil.state.address || ''

    let token = ''
    if (authToken) {
      token = authToken
    } else {
      token =
        JSON.parse(window.localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN) as string)[
          authenticatedPublicKey || ''
        ] || ''
    }

    if (!token) return
    const { data } = await axios({
      headers: { token },
      method: 'POST',
      url: authRoute('verify-token'),
    })

    return {
      isValid: (data.publicAddress || '').toLowerCase() === authenticatedPublicKey?.toLowerCase(),
      authenticatedPublicKey,
      username: data.username as string,
      hasEnteredInviteCode: data.hasEnteredInviteCode,
      inviteCodesRequested: data.inviteCodesRequested,
      authToken,
    }
  }

  const authenticate = async (publicAddress?: string) => {
    publicAddress = publicAddress || utils.getAddress(EthersStoreUtil.state.address || '')
    const {
      data: { nonce },
    } = await faxios({
      data: { publicAddress },
      method: 'POST',
      url: authRoute('generate-nonce'),
    })
    const signature = await signMessage(`${nonce}`)

    const rpcVerifyResp = await faxios({
      data: { publicAddress, signature },
      method: 'POST',
      url: authRoute('verify-signature'),
    })
    const authToken: string = rpcVerifyResp.data.token
    const username: string = rpcVerifyResp.data.username
    const hasEnteredInviteCode = rpcVerifyResp.data.hasEnteredInviteCode
    const inviteCodesRequested = rpcVerifyResp.data.inviteCodesRequested

    return { authToken, publicAddress, username, hasEnteredInviteCode, inviteCodesRequested }
  }

  const setUserData = async ({
    username,
    colorTheme,
  }: {
    username?: string
    colorTheme?: string
  }) => {
    // const token = (window.localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN) as string) || ''
    const publicAddress = utils.getAddress(EthersStoreUtil.state.address || '')
    const token = JSON.parse(
      (window.localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN) as string) || ''
    )[publicAddress]

    const {
      data: { message },
    } = await faxios({
      headers: { token },
      data: { username, colorTheme },
      method: 'POST',
      url: authRoute('set-user-data'),
    })
    return message
  }

  const logout = async () => {
    // const token = (window.localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN) as string) || ''
    const publicAddress = utils.getAddress(EthersStoreUtil.state.address || '')
    const token = JSON.parse(
      (window.localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN) as string) || ''
    )[publicAddress]
    await faxios({
      headers: { token },
      method: 'POST',
      url: authRoute('logout'),
    })

    // window.localStorage.removeItem(LOCAL_STORAGE.AUTH_TOKEN)
  }

  // @NOTE: Ask Tim, is this `auth` related to the auth flow or basically any req that requires user to be authed
  const rerollAvatarSeed = async () => {
    const publicAddress = utils.getAddress(EthersStoreUtil.state.address || '')
    const token = JSON.parse(
      (window.localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN) as string) || ''
    )[publicAddress]

    const { data } = await faxios({
      headers: { token },
      method: 'POST',
      url: authRoute('reroll-avatar-seed'),
    })
    return data
  }

  const getEmail = async () => {
    const publicAddress = utils.getAddress(EthersStoreUtil.state.address || '')
    const token = JSON.parse(
      (window.localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN) as string) || ''
    )[publicAddress]

    const { data } = await faxios({
      headers: { token },
      method: 'GET',
      url: authRoute('email'),
    })
    return data
  }

  const setEmail = async (email: string) => {
    const publicAddress = utils.getAddress(EthersStoreUtil.state.address || '')
    const token = JSON.parse(
      (window.localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN) as string) || ''
    )[publicAddress]

    const { data } = await faxios({
      headers: { token },
      method: 'POST',
      url: authRoute('email'),
      data: { email },
    })
    return data
  }

  const setUsername = async (username: string) => {
    const publicAddress = utils.getAddress(EthersStoreUtil.state.address || '')
    const token = JSON.parse(
      (window.localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN) as string) || ''
    )[publicAddress]

    const { data } = await faxios({
      headers: { token },
      method: 'POST',
      url: authRoute('username'),
      data: { username },
    })
    return data
  }

  const setIsUsingTwitterToDisplay = async (isUsingTwitterToDisplay: boolean) => {
    const publicAddress = utils.getAddress(EthersStoreUtil.state.address || '')
    const token = JSON.parse(
      (window.localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN) as string) || ''
    )[publicAddress]

    const { data } = await faxios({
      headers: { token },
      method: 'POST',
      url: authRoute('is-using-twitter-to-display'),
      data: { isUsingTwitterToDisplay },
    })
    return data
  }

  // @TODO: Not sure if it is the best idea to have this here
  const linkTwitter = async () => {
    const publicAddress = utils.getAddress(EthersStoreUtil.state.address || '')
    const token = JSON.parse(
      (window.localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN) as string) || ''
    )[publicAddress]

    const { data } = await faxios({
      headers: { token },
      withCredentials: true,
      method: 'POST',
      url: authRoute('link-twitter'),
    })
    // console.log('data received from backend: ', data)
    return data
  }

  const linkDiscord = async (discordToken: string) => {
    // console.log('DISCORD: discordToken received by auth function: ', discordToken)
    const publicAddress = utils.getAddress(EthersStoreUtil.state.address || '')
    const token = JSON.parse(
      (window.localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN) as string) || ''
    )[publicAddress]

    const { data } = await faxios({
      headers: { token },
      method: 'POST',
      url: authRoute(`link-discord?discordToken=${discordToken}`),
    })
    // console.log('data received from backend: ', data)
    return data
  }

  const getTwitterInfo = async () => {
    const publicAddress = utils.getAddress(EthersStoreUtil.state.address || '')
    const token = JSON.parse(
      (window.localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN) as string) || ''
    )[publicAddress]

    const { data } = await faxios({
      headers: { token },
      withCredentials: true,
      method: 'GET',
      url: authRoute('twitter'),
    })
    console.log('message received from backend: ', data)
    return data
  }

  const getUserData = async () => {
    const publicAddress = utils.getAddress(EthersStoreUtil.state.address || '')
    const token = JSON.parse(
      (window.localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN) as string) || ''
    )[publicAddress]

    const { data } = await axios({
      headers: { token },
      withCredentials: true,
      method: 'GET',
      url: authRoute('user-data'),
    })
    console.log('message received from backend: ', data)
    return data as { userData: IUserData }
  }

  const refetchTwitterInfo = async () => {
    const publicAddress = utils.getAddress(EthersStoreUtil.state.address || '')
    const token = JSON.parse(
      (window.localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN) as string) || ''
    )[publicAddress]

    const { data } = await faxios({
      headers: { token },
      method: 'POST',
      url: authRoute('refetch-twitter'),
    })
    return data
  }

  const disconnectTwitter = async () => {
    const publicAddress = utils.getAddress(EthersStoreUtil.state.address || '')
    const token = JSON.parse(
      (window.localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN) as string) || ''
    )[publicAddress]

    const { data } = await faxios({
      headers: { token },
      method: 'POST',
      url: authRoute('disconnect-twitter'),
    })
    return data
  }

  // @TODO: Consider adding some type to this
  const setGameConfig = async (gameConfig: any) => {
    // @TODO: Do this optimization for gameConfig
    // const currentGameName = useUserDataStore.getState().currentGameName
    // if (gameName === currentGameName) {
    //   return posthog.capture('user_submitted_consecutive_game', {
    //     currentGameName,
    //     previousGameName: gameName,
    //   })
    // }
    const publicAddress = utils.getAddress(EthersStoreUtil.state.address || '')
    const token = JSON.parse(
      (window.localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN) as string) || ''
    )[publicAddress]

    const { data } = await faxios({
      headers: { token },
      method: 'POST',
      url: authRoute('set-game-config'),
      data: {
        gameConfig,
      },
    })
    // posthog.capture('user_submitted_different_game_from_previous', {
    //   currentGameName: gameName,
    //   previousGameName: currentGameName,
    // })
    // useUserDataStore.setState({ currentGameName: gameName })
    return data
  }

  return {
    noWallet: !windowEth,
    signMessage,
    verifyAuthToken,
    authenticate,
    logout,
    setUserData,
    addAndSwitchNetwork,
    provider,
    rerollAvatarSeed,
    getEmail,
    setEmail,
    setUsername,
    setIsUsingTwitterToDisplay,
    linkTwitter,
    linkDiscord,
    getTwitterInfo,
    getUserData,
    refetchTwitterInfo,
    disconnectTwitter,
    // setGameName,
    setGameConfig,
  }
}
