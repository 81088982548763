import { AnimatePresence } from 'framer-motion'
import { NotiPill } from '../NotiPill'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import useInviteCodeStore from '@/store/useInviteCodeStore'
import { useAppFeatures } from '@/hooks/useAppFeatures'

const SCenterHeader = styled.div<{ $isMobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  ${({ $isMobile }) => ($isMobile ? 'flex: 1' : 'width: 100px')};

  @media (max-width: 1000px) {
    display: none;
  }
`

export const CenterHeader = () => {
  const isMobileScreen = useIsBreakpoint('sm')
  const inviteCodeCount = useInviteCodeStore(state => (state.activeInviteCodes || []).length)
  const appFeatures = useAppFeatures()

  if (!appFeatures.enableFaucet) return null

  return (
    <SCenterHeader $isMobile={isMobileScreen || inviteCodeCount > 0}>
      <AnimatePresence>{isMobileScreen ? null : <NotiPill />}</AnimatePresence>
    </SCenterHeader>
  )
}
