import * as identicon from '@dicebear/identicon'
import { createAvatar } from '@dicebear/core'

import { generateUserColor } from '@/utils'
import { CSSProperties } from 'styled-components'

interface ITwitterAvatar {
  imageUrl: string
  avatarSeedAddress: string
  style: CSSProperties
}

export const TwitterAvatar = ({ imageUrl, avatarSeedAddress, ...props }: ITwitterAvatar) => {
  const [avatar, setAvatar] = useState(imageUrl)

  const handleImgError = useCallback(() => {
    async function generateAvatar() {
      const userColor = generateUserColor(avatarSeedAddress)
      const avatarDataUri = await createAvatar(identicon, {
        seed: avatarSeedAddress,
        rowColor: [userColor as string],
      }).toDataUri()
      setAvatar(avatarDataUri)
    }
    generateAvatar()
  }, [imageUrl, avatarSeedAddress])

  return <img src={avatar} onError={handleImgError} alt='pfp' {...props} />
}
