import React, { useRef, useEffect, useMemo } from 'react'
import { Text } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import * as THREE from 'three'
import { type ShipConfig } from './GameConfig'
import { Group } from 'three'

interface AnimatedTextProps {
  position: [number, number, number]
  text: string
  onAnimationComplete: () => void
  gameOutcome: 'WIN' | 'LOSS'
  config: ShipConfig
}

const AnimatedText: React.FC<AnimatedTextProps> = ({
  position,
  text,
  onAnimationComplete: onAnimationComplete,
  gameOutcome,
  config,
}) => {
  const textRef = useRef<THREE.Mesh>(null!)
  const currentFontSize = useRef(config.hud.fontSize)
  const maxFontSize = 1.8
  const duration = 1.75
  const startTime = useRef<number>(performance.now())
  const hasCompleted = useRef(false)
  const initialPositionRef = useRef(new THREE.Vector3())
  const animationCompleted = useRef(false)
  const newPositionRef = useRef(new THREE.Vector3())
  const charactersRef = useRef<THREE.Mesh[]>([])
  const bounceStartTimes = useRef<number[]>([])
  const bounceInterval = 2500

  // Memoize the font size and xPosition on start
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fontSize = useMemo(() => config.hud.fontSize, [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const xPosition = useMemo(() => position[0], [])

  // Set initial position on mount
  useEffect(() => {
    initialPositionRef.current.set(
      position[0],
      position[1] + config.hud.verticalOffset,
      position[2]
    )
    if (textRef.current) {
      textRef.current.position.copy(initialPositionRef.current)
    }
  }, [position, config.hud.verticalOffset])

  // Define target position
  // const targetPosition = new THREE.Vector3(position[0], Math.min(position[1] + 4, 7), position[2])
  const targetPosition = new THREE.Vector3(xPosition, 8, 0)

  // Initialize bounce animation parameters
  useEffect(() => {
    // Initial bounce times with character delays
    const resetBounceStartTimes = () => {
      bounceStartTimes.current = text.split('').map((_, i) => performance.now() + i * 100)
    }

    // Set initial bounce times
    resetBounceStartTimes()

    // Set up interval to reset bounce times periodically
    const intervalId = setInterval(() => {
      resetBounceStartTimes()
    }, bounceInterval)

    // Cleanup interval on unmount
    return () => clearInterval(intervalId)
  }, [text])

  useFrame(() => {
    const currentTime = performance.now()
    const elapsed = (currentTime - startTime.current) / 1000

    if (elapsed < duration) {
      // Ease-in font size instead of scaling
      currentFontSize.current = fontSize + (maxFontSize - fontSize) * (elapsed / duration) ** 2

      // Calculate position interpolation
      const progress = elapsed / duration
      newPositionRef.current.lerpVectors(initialPositionRef.current, targetPosition, progress)

      if (textRef.current) {
        textRef.current.position.copy(newPositionRef.current)
      }
    } else {
      // Mark animation as complete and set final position/font size
      if (!animationCompleted.current) {
        animationCompleted.current = true
      }

      if (textRef.current) {
        textRef.current.position.copy(newPositionRef.current)
        currentFontSize.current = maxFontSize
      }
    }

    // Modified bounce animation for each character
    charactersRef.current.forEach((char, index) => {
      if (!char) return

      const bounceElapsed = (currentTime - bounceStartTimes.current[index]) / 1000
      const bounceDuration = 0.3 // Duration of each bounce

      if (bounceElapsed > 0 && bounceElapsed < bounceDuration) {
        const bounceOffset = Math.sin((bounceElapsed * Math.PI) / bounceDuration) * 0.2
        char.position.y = bounceOffset
      } else {
        char.position.y = 0
      }
    })
  })

  useEffect(() => {
    return () => {
      if (!hasCompleted.current) {
        hasCompleted.current = true
        onAnimationComplete()
      }
    }
  }, [onAnimationComplete])

  return (
    <group ref={textRef as unknown as React.RefObject<Group>}>
      {text.split('').map((char, index) => (
        <Text
          key={index}
          ref={el => (charactersRef.current[index] = el as THREE.Mesh)}
          fontSize={currentFontSize.current}
          position={[
            index * currentFontSize.current * 0.6 -
              (text.length - 1) * currentFontSize.current * 0.3,
            0,
            0,
          ]}
          color={gameOutcome === 'LOSS' ? 'red' : 'yellow'}
          anchorX='center'
          anchorY='middle'
          outlineWidth={0.05}
          outlineColor='black'
          font='/fonts/Gohu/gohu-small-uni-14.ttf'
          sdfGlyphSize={64}
          letterSpacing={0.1}
          lineHeight={1}
          characters='0123456789.x'
        >
          {char}
          <meshStandardMaterial
            attach='material'
            emissive={gameOutcome === 'WIN' ? '#00ff00' : '#ff0000'}
            emissiveIntensity={gameOutcome === 'LOSS' ? 3 : 2}
            precision='highp'
            toneMapped={false}
          />
        </Text>
      ))}
    </group>
  )
}

export default AnimatedText
