import { FARE_COLORS } from '@/design'

export const PointsKillsBorder = css`
  /* background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="137" height="65" viewBox="0 0 137 65" fill="none"><path d="M136.5 1H14L1 17V64" stroke="%2327FF83" stroke-linecap="round"/></svg>'); */
  /* background-repeat: no-repeat; */
  /* width: 124px; */
  border-top: 1px solid ${FARE_COLORS.aqua};
  border-left: 1px solid ${FARE_COLORS.aqua};
  border-top-left-radius: 6px;
  flex: 1;
`
export const MobilePointsKillsBorder = css`
  /* background-image: url('data:image/svg+xml,<svg width="153" height="26" viewBox="0 0 153 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M152.5 1H9.7138L1 11.7247V25.5" stroke="%2327FF83" stroke-width="0.670294" stroke-linecap="round"/></svg>'); */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  border-top: 1px solid ${FARE_COLORS.aqua};
  border-left: 1px solid ${FARE_COLORS.aqua};
  border-top-left-radius: 6px;
  flex: 1;
`

export const PointsDeathsBorder = css`
  /* background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="137" height="65" viewBox="0 0 137 65" fill="none"><path d="M136.5 1H14L1 17V64" stroke="%23EC2473" stroke-linecap="round"/></svg>'); */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  /* width: 124px; */

  border-top: 1px solid ${FARE_COLORS.salmon};
  border-left: 1px solid ${FARE_COLORS.salmon};
  border-top-left-radius: 6px;
  flex: 1;
`

export const MobilePointsDeathsBorder = css`
  /* background-image: url('data:image/svg+xml,<svg width="153" height="26" viewBox="0 0 153 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M152.5 1H9.7138L1 11.7247V25.5" stroke="%23EC2473" stroke-width="0.670294" stroke-linecap="round"/></svg>'); */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */

  /* border-top: 1px solid #ec2473; */
  /* border-left: 1px solid #ec2473; */
  /* border-top-left-radius: 6px; */
  border-top: 1px solid ${FARE_COLORS.salmon};
  border-left: 1px solid ${FARE_COLORS.salmon};
  border-top-left-radius: 6px;
  flex: 1;
`

export const PointsAssistBorder = css`
  /* background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="137" height="65" viewBox="0 0 137 65" fill="none"><path d="M136.5 1H14L1 17V64" stroke="%23FCAD01" stroke-linecap="round"/></svg>'); */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */

  /* border-top: 1px solid #fcad01; */
  /* border-left: 1px solid #fcad01; */
  /* border-top-left-radius: 6px; */
  /* width: 124px; */
  /* flex: 1; */

  border-top: 1px solid ${FARE_COLORS.peach};
  border-left: 1px solid ${FARE_COLORS.peach};
  border-top-left-radius: 6px;
  flex: 1;
`

export const MobilePointsAssistsBorder = css`
  /* background-image: url('data:image/svg+xml,<svg width="153" height="26" viewBox="0 0 153 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M152.5 1H9.7138L1 11.7247V25.5" stroke="%23FCAD01" stroke-width="0.670294" stroke-linecap="round"/></svg>'); */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */

  border-top: 1px solid ${FARE_COLORS.peach};
  border-left: 1px solid ${FARE_COLORS.peach};
  border-top-left-radius: 6px;
  flex: 1;
`

export const PointsMultiplierBorder = css`
  /* background-image: url('data:image/svg+xml,<svg width="153" height="26" viewBox="0 0 153 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M152.5 1H9.7138L1 11.7247V25.5" stroke="%23BBCBD3" stroke-width="0.670294" stroke-linecap="round"/></svg>'); */
  border-top: 1px solid white;
  border-left: 1px solid white;
  border-top-left-radius: 6px;
`

export const MobilePointsMultiplierBorder = css`
  background-image: url('data:image/svg+xml,<svg width="153" height="26" viewBox="0 0 153 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M152.5 1H9.7138L1 11.7247V25.5" stroke="%23BBCBD3" stroke-width="0.670294" stroke-linecap="round"/></svg>');
  background-repeat: no-repeat;
  background-size: cover;
`
