import * as THREE from 'three'
import React, { useRef, useEffect, useState } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { type GLTF } from 'three-stdlib'
import { ExplosionEffect } from './BombExplosion'
import { useFrame } from '@react-three/fiber'
import { useSound } from '@/components/shared/SoundSystem/SoundContext'

type GLTFResult = GLTF & {
  nodes: {
    ['bomb-geom']: THREE.Mesh
    ['bomb-geom_1']: THREE.Mesh
    ['bomb-geom_2']: THREE.Mesh
    ['bomb-geom_3']: THREE.Mesh
    Plane: THREE.Mesh
    wick: THREE.SkinnedMesh
    Bone: THREE.Bone
  }
  materials: {
    BombMat1: THREE.MeshStandardMaterial
    BombMat2: THREE.MeshStandardMaterial
    BombMat3: THREE.MeshStandardMaterial
    BombMat4: THREE.MeshStandardMaterial
    Flame: THREE.MeshStandardMaterial
    Wick: THREE.MeshStandardMaterial
  }
}

export const BombModel = ({
  isRevealed = false,
  // onExplosionComplete,
  explosionTiming,
  speedMultiplier = 1,
  showFlame = true,
}: {
  isRevealed: boolean
  // onExplosionComplete?: () => void
  explosionTiming: number
  speedMultiplier: number
  showFlame: boolean
}) => {
  const bombGroup = useRef<THREE.Group>(null)
  const explosionGroup = useRef<THREE.Group>(null)
  const { nodes, materials, animations } = useGLTF('/glb/Bomb_V1.glb') as GLTFResult
  const { actions } = useAnimations(animations, bombGroup)
  const [isExploding, setIsExploding] = useState(false)
  const explosionClock = useRef(new THREE.Clock())
  const [showExplosion, setShowExplosion] = useState(false)

  const explosionDuration = 0.7 // 700ms
  const maxScale = 1.1

  // SFX
  const soundContext = useSound()

  useEffect(() => {
    soundContext.loadSound('BombExplosion', '/src/assets/audio/Explosion49.wav')
  }, [soundContext, soundContext.loadSound])

  useEffect(() => {
    if (isRevealed) {
      const wickBurnAction = actions['WickBurn']
      const wickArmatureAction = actions['Wick_ArmatureAction']

      console.log('DEBUG Wick actions:', { wickBurnAction, wickArmatureAction })

      if (wickBurnAction && wickArmatureAction) {
        const timeScale = (2000 / explosionTiming) * speedMultiplier
        console.log('DEBUG Starting wick burn animation', { explosionTiming, timeScale })

        wickBurnAction.reset().setEffectiveTimeScale(timeScale).setLoop(THREE.LoopOnce, 1).play()
        wickArmatureAction
          .reset()
          .setEffectiveTimeScale(timeScale)
          .setLoop(THREE.LoopOnce, 1)
          .play()

        console.log('DEBUG Wick animations started')
      } else {
        console.warn('WickBurn or Wick_ArmatureAction animation not found')
      }

      // Set a timer to trigger the explosion after explosionDelay
      const explosionTimer = setTimeout(() => {
        console.log('Starting explosion animation')
        setIsExploding(true)
        explosionClock.current.start()
      }, explosionTiming / speedMultiplier)

      return () => {
        clearTimeout(explosionTimer)
        Object.values(actions).forEach(action => action?.stop())
      }
    }
  }, [isRevealed, actions, explosionTiming, speedMultiplier])

  useFrame(() => {
    if (isExploding && bombGroup.current) {
      const elapsedTime = explosionClock.current.getElapsedTime() * speedMultiplier
      const progress = Math.min(elapsedTime / explosionDuration, 1)

      let scale
      if (progress < 0.5) {
        // Scale up
        scale = 1 + (maxScale - 1) * (progress * 2)
      } else {
        // Scale down
        scale = maxScale - maxScale * ((progress - 0.5) * 2)
      }

      bombGroup.current.scale.set(scale, scale, scale)

      if (progress >= 0.5 && !showExplosion) {
        setShowExplosion(true)
        soundContext.playSound('BombExplosion', 0.3, 1)
      }

      if (progress >= 1) {
        setIsExploding(false)
        bombGroup.current.scale.set(0, 0, 0)
        // onExplosionComplete?.()
      }
    }
  })

  return (
    <group dispose={null}>
      <group ref={bombGroup}>
        <group name='Scene'>
          <group name='bomb'>
            <mesh
              name='bomb-geom'
              castShadow
              receiveShadow
              geometry={nodes['bomb-geom'].geometry}
              material={materials.BombMat1}
            />
            <mesh
              name='bomb-geom_1'
              castShadow
              receiveShadow
              geometry={nodes['bomb-geom_1'].geometry}
              material={materials.BombMat2}
            />
            <mesh
              name='bomb-geom_2'
              castShadow
              receiveShadow
              geometry={nodes['bomb-geom_2'].geometry}
              material={materials.BombMat3}
            />
            <mesh
              name='bomb-geom_3'
              castShadow
              receiveShadow
              geometry={nodes['bomb-geom_3'].geometry}
              material={materials.BombMat4}
            />
            {showFlame && (
              <mesh
                name='Plane'
                castShadow
                receiveShadow
                geometry={nodes.Plane.geometry}
                material={materials.Flame}
                position={[1.114, 1.814, 0.108]}
                rotation={[Math.PI / 2, -1.393, 0]}
                scale={0.432}
              />
            )}
            {showFlame && (
              <group name='Wick_Armature' position={[0.954, 1.806, 0]}>
                <skinnedMesh
                  name='wick'
                  geometry={nodes.wick.geometry}
                  material={materials.Wick}
                  skeleton={nodes.wick.skeleton}
                  visible={true}
                />
                <primitive object={nodes.Bone} />
              </group>
            )}
          </group>
        </group>
      </group>
      <group ref={explosionGroup}>
        {showExplosion && (
          <ExplosionEffect
            position={[0, 1, 0]}
            color='#a71b03'
            count={50}
            size={10}
            spread={4}
            duration={700}
          />
        )}
      </group>
    </group>
  )
}

useGLTF.preload('/glb/Bomb_V1.glb')
