import { useEffect, useState, useCallback } from 'react'
import { useCrashGameStore } from '@/store/useCrashGameStore'
import { useGameTime } from './GameTimeContext'
import intensityData from './gameIntensity.json'
import {
  type AsteroidConfig,
  type ShipConfig,
  type GamePacingConfig,
  defaultShipConfig,
} from './GameConfig'
import { useCrashSound } from './CrashSoundInterface'

const GameIntensityManager: React.FC = () => {
  const {
    gameState,
    multiplier,
    setGameIntensity,
    updateAsteroidConfig,
    updateKillerConfig,
    updateShipConfig,
    updateGamePacingConfig,
  } = useCrashGameStore()
  const { roundTime } = useGameTime()
  const { playGameSound } = useCrashSound()
  const [currentIntensityIndex, setCurrentIntensityIndex] = useState(0)

  const updateConfigs = useCallback(
    (currentIntensity: {
      intensity: number
      time?: number
      asteroidConfig?: AsteroidConfig
      killerConfig?: AsteroidConfig
      shipConfig: ShipConfig
      gamePacingConfig: GamePacingConfig
    }) => {
      setGameIntensity(currentIntensity.intensity)
      if (currentIntensity.asteroidConfig) updateAsteroidConfig(currentIntensity.asteroidConfig)
      if (currentIntensity.killerConfig) updateKillerConfig(currentIntensity.killerConfig)
      updateShipConfig({
        ...currentIntensity.shipConfig,
        flyOut: defaultShipConfig.flyOut,
        intensityScaling: defaultShipConfig.intensityScaling,
      })
      updateGamePacingConfig(currentIntensity.gamePacingConfig)
    },
    [
      setGameIntensity,
      updateAsteroidConfig,
      updateKillerConfig,
      updateShipConfig,
      updateGamePacingConfig,
    ]
  )

  useEffect(() => {
    // While Playing
    if (gameState === 'PLAYING') {
      // If we're just starting, immediately set to intensity 1
      if (currentIntensityIndex === 0) {
        const intensity1 = intensityData.intensities.find(i => i.intensity === 1)
        if (intensity1) {
          setCurrentIntensityIndex(1)
          updateConfigs(intensity1)
        }
      }

      const currentIntensity = intensityData.intensities[currentIntensityIndex]
      const nextIntensity = intensityData.intensities[currentIntensityIndex + 1]

      if (nextIntensity && roundTime >= nextIntensity.time) {
        setCurrentIntensityIndex(currentIntensityIndex + 1)
        playGameSound(
          'beep',
          0.5 + currentIntensity.intensity * 0.01,
          0.15 + currentIntensity.intensity * 0.08
        )
        // log intensity level, multiplier step size/rate, and the game time
        // console.log('DEBUG: Setting intensity:', nextIntensity)
        // console.log(
        //   'DEBUG: Multiplier step size:',
        //   nextIntensity.gamePacingConfig.multiplierStepSize
        // )
        // console.log('DEBUG: Multiplier rate:', nextIntensity.gamePacingConfig.multiplierStepRate)
        // console.log('DEBUG: Game time:', roundTime)
        // console.log('DEBUG: Current multiplier:', multiplier)
      }

      if (currentIntensity) {
        updateConfigs(currentIntensity)
      }
    }
  }, [roundTime, gameState, currentIntensityIndex, updateConfigs])

  useEffect(() => {
    // When game ends
    if (gameState === 'IDLE') {
      setCurrentIntensityIndex(0)
      setGameIntensity(0)
      updateConfigs(intensityData.intensities[0])
    }
  }, [gameState, setGameIntensity])

  return null
}

export default GameIntensityManager
