// A dictionary to store render counts
const renderCounts: Record<string, number> = {}

export function useDidIRender(renderName: string) {
  if (!renderCounts[renderName]) {
    renderCounts[renderName] = 1
  } else {
    renderCounts[renderName]++
  }

  // Log the render count when the component unmounts
  return () => {
    if (renderCounts[renderName]) {
      renderCounts[renderName]--
    }
  }
}

;(window as any).didIRender = () => {
  const renderData = Object.entries(renderCounts).map(([renderName, count]) => ({
    Component: renderName,
    RenderCount: count,
  }))

  console.table(renderData)
}
;(window as any).didIRenderReset = () => {
  for (const renderName in renderCounts) {
    renderCounts[renderName] = 0
  }
}
