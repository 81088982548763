import { useEffect } from 'react'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'

interface UseMobileVisibilityProps {
  onHide?: () => void
}

export const useMobileVisibility = ({ onHide }: UseMobileVisibilityProps) => {
  const isMobileScreen = useIsBreakpoint('sm')

  useEffect(() => {
    if (!isMobileScreen) {
      onHide?.()
    }
  }, [isMobileScreen, onHide])

  return isMobileScreen
}
