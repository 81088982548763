import { gameStoreMapByGameName } from '@/store/useGameStateStore'
import { AnimatePresence } from 'framer-motion'
import { COLORS } from '@/design'
import numeral from 'numeral'
import { SVGUSDC } from '../Canvases/CoinFlipCanvas/SVGUSDC'
import { EntryEventListener, entryEvent } from '@/events/entryEvent'
import { v4 as uuid } from 'uuid'
import CountUp from 'react-countup'
import { usePrevious } from 'react-use'
import { AppGameName } from '@/chains/types'
import {
  SAmountNumber,
  SAmountNumbers,
  SDeltaNumber,
  amountNumbersVariant,
  deltaNumberVariant,
} from './styled'

interface IDeltaNumbersDisplay {
  gameName: AppGameName
}

type NumberDisplay = {
  id: string
  delta: number
  timestamp: number
  delay: number
}

const DEFAULT_DELAY = 0

export const DeltaNumbersDisplay = ({ gameName }: IDeltaNumbersDisplay) => {
  const [mainDelta, setMainDelta] = useState(0)
  const prevMainDelta = usePrevious(mainDelta)
  const [numberQueue, setNumberQueue] = useState<NumberDisplay[]>([])
  const previousTimestamp = useRef<number>(0)
  const useGameStore = useMemo(
    () =>
      gameStoreMapByGameName[gameName] ?
        gameStoreMapByGameName[gameName]
      : gameStoreMapByGameName[AppGameName.CoinFlip],
    [gameName]
  )
  const gameState = useGameStore(state => state.type)

  const entryEventListener: EntryEventListener<'entryFinished'> = useCallback(
    data => {
      if (data.detail.deltaAmount && typeof data.detail.deltaAmount === 'number') {
        const diffPrevTimestamp = (Date.now() - previousTimestamp.current) / 1000
        const delayDelta = (data.detail.delaySecs || DEFAULT_DELAY) - diffPrevTimestamp
        const delay = delayDelta <= 0 ? 0 : delayDelta
        const timestamp = Date.now()
        previousTimestamp.current = timestamp

        const numberDisplay: NumberDisplay = {
          id: uuid(),
          delta: data.detail.deltaAmount,
          timestamp,
          delay,
        }
        setNumberQueue(state => {
          return [numberDisplay, ...state]
        })
      }
    },
    [numberQueue]
  )

  entryEvent.useSub('entryFinished', entryEventListener)

  const animatedDeltaNumbers = useMemo(
    () =>
      numberQueue.map(data => {
        return (
          <SDeltaNumber
            key={data.id}
            className={data.delta > 0 ? 'is-win' : 'is-lose'}
            variants={deltaNumberVariant(data.delay)}
            initial='initial'
            animate='animate'
            exit='exit'
            onAnimationStart={def => {
              if (def === 'animate') setMainDelta(value => value + Number(data.delta))
            }}
            onAnimationComplete={() => {
              setNumberQueue(prevQueue => prevQueue.filter(prevData => prevData.id !== data.id))
            }}
          >
            {data.delta > 0 && '+'}
            {numeral(data.delta).format('0,0.00')}
          </SDeltaNumber>
        )
      }),
    [numberQueue]
  )

  const showMainDeltaNumber = useMemo(
    () => gameState === 'START' || gameState === 'RESOLVE',
    [gameState]
  )

  useEffect(() => {
    switch (gameState) {
      case 'START':
        setMainDelta(0)
        break
      case 'RESET':
      case 'ERROR':
        setMainDelta(0)
        break
      default:
        break
    }
  }, [gameState])

  return (
    <SAmountNumbers>
      <AnimatePresence mode='popLayout'>
        {showMainDeltaNumber && (
          <SAmountNumber
            variants={amountNumbersVariant}
            initial='initial'
            animate='animate'
            exit='exit'
          >
            <CountUp
              className={Number(mainDelta) >= 0 ? 'is-win' : 'is-lose'}
              prefix={Number(mainDelta) > 0 ? '+' : ''}
              start={prevMainDelta}
              end={mainDelta}
              duration={0.3}
              decimals={2}
            />
            <SVGUSDC fill={Number(mainDelta) >= 0 ? COLORS.success : COLORS.error} />
          </SAmountNumber>
        )}
      </AnimatePresence>
      <AnimatePresence mode='sync'>{animatedDeltaNumbers}</AnimatePresence>
    </SAmountNumbers>
  )
}
