import { PNGS, SVGS } from '@/assets'
import { AppChainNetworkStyle, ChainName } from '../types'

export const appChainNetworkStyleMap: Record<ChainName, AppChainNetworkStyle> = {
  BERA: {
    networkName: 'BERA',
    currencyIcon: PNGS.honeyBeraIcon,
    networkLogo: PNGS.beraIcon,
    networkType: 'TESTNET',
    currencyName: 'PHONEY',
    nativeToken: 'tBera',
    menuColor: {
      one: '#dd7129',
      two: '#4a310ff5',
      three: '#4a310f40',
      four: '#1d1408f5',
    },
    particleColors: '#dd7129',
    decimals: 6,
  },
  ARBITRUM: {
    networkName: 'ARBITRUM',
    currencyIcon: SVGS.usdcIcon,
    networkLogo: PNGS.arbitrumLogo,
    networkType: 'MAINNET',
    currencyName: 'USDC',
    nativeToken: 'ETH',
    menuColor: {
      one: '#0076ff',
      two: '#092f51f5',
      three: '#092f5140',
      four: '#08131df5',
    },
    particleColors: '#0076ff',
    decimals: 6,
  },
  ARBITRUM_SEPOLIA: {
    networkName: 'ARBITRUM_SEPOLIA',
    currencyIcon: SVGS.usdcIcon,
    networkLogo: PNGS.arbitrumLogo,
    networkType: 'TESTNET',
    currencyName: 'USDC',
    nativeToken: 'ETH',
    menuColor: {
      one: '#0076ff',
      two: '#092f51f5',
      three: '#092f5140',
      four: '#08131df5',
    },
    particleColors: '#0076ff',
    decimals: 6,
  },
  BASE: {
    networkName: 'BASE',
    currencyIcon: SVGS.usdcIcon,
    networkLogo: SVGS.baseLogo,
    networkType: 'MAINNET',
    currencyName: 'USDC',
    nativeToken: 'ETH',
    menuColor: {
      one: '#0076ff',
      two: '#092f51f5',
      three: '#092f5140',
      four: '#08131df5',
    },
    particleColors: '#0076ff',
    decimals: 6,
  },
  BASE_SEPOLIA: {
    networkName: 'BASE_SEPOLIA',
    currencyIcon: SVGS.usdcIcon,
    networkLogo: SVGS.baseLogo,
    networkType: 'TESTNET',
    currencyName: 'USDC',
    nativeToken: 'ETH',
    menuColor: {
      one: '#0076ff',
      two: '#092f51f5',
      three: '#092f5140',
      four: '#08131df5',
    },
    particleColors: '#0076ff',
    decimals: 6,
  },
} as const
