import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react'
import { FARE_COLORS } from '@/design'
import { SupportedAppChainId } from './types'
import { appChainIdMap } from '.'

let isWeb3Initialized = false

export type Chain = {
  rpcUrl: string
  explorerUrl: string
  currency: string
  name: string
  chainId: number
}

export const createWeb3 = () => {
  // Guard statement to ensure this is only initialized once
  if (isWeb3Initialized) return console.log('Web3 modal is already initialized')
  isWeb3Initialized = true

  const chains = Object.keys(appChainIdMap).map(chainName => {
    const chain = appChainIdMap[chainName as unknown as SupportedAppChainId]
    return {
      rpcUrl: chain.rpc.http,
      explorerUrl: chain.chainDefinition.blockExplorers?.[0] || '',
      currency: chain.chainDefinition.nativeCurrency.name,
      name: chain.chainDefinition.name,
      chainId: chain.chainDefinition.id,
    } as Chain
  })
  const defaultChain = chains[0]

  // Define Metadata
  const metadata = {
    name: 'FarePlay',
    description:
      'Fareplay is a decentralized crypto casino. On-chain wagers, verifiable randomness, no deposits.',
    url: 'https://fareplay.io', // origin must match your domain & subdomain
    icons: ['https://fareplay.io/images/metaimage.png'],
  }

  // Create modal
  createWeb3Modal({
    ethersConfig: defaultConfig({
      metadata,
      defaultChainId: defaultChain.chainId,
      enableEIP6963: true,
      enableInjected: true,
      // enableCoinbase: true,
    }),
    chains,
    defaultChain,
    projectId: 'ca77197d67b9942f9ef7f4476b23f85f',
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
    themeVariables: {
      '--w3m-border-radius-master': '1px',
      '--w3m-font-family': 'Gohu, monospace',
      '--w3m-accent': FARE_COLORS.aqua,
      '--w3m-color-mix': '#000',
      '--w3m-color-mix-strength': 40,
    },
  })
}
