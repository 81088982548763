import { SAvatar } from '@/components/LeaderboardGrid'
import { type IAvatarWithoutSeed } from '@/components/shared/Avatar'
import { TwitterAvatar } from '@/components/shared/Avatar/TwitterAvatar'
import { FARE_COLORS } from '@/design'
import { type CSSProperties } from 'react'

const STwitterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 22px;
  position: relative;

  > img {
    position: absolute;
    height: 16px;
    width: 16px;
    border-radius: 100%;
  }
`

export const getUserImageToRender = (
  publicAddress: string,
  isUsingTwitterToDisplay?: boolean,
  avatarSeedAddress?: string,
  twitterImage?: string,
  twitterWrapperStyle: CSSProperties = {},
  twitterImageStyle: CSSProperties = {},
  avatarWrapperStyle: CSSProperties = {},
  avatarImageStyle: CSSProperties = {},
  avatarProps?: IAvatarWithoutSeed
) => {
  if (isUsingTwitterToDisplay && twitterImage) {
    return (
      <STwitterWrapper style={twitterWrapperStyle}>
        <TwitterAvatar
          imageUrl={twitterImage}
          avatarSeedAddress={avatarSeedAddress || ''}
          style={twitterImageStyle}
        />
        {/* <img src={twitterImage} style={twitterImageStyle}></img> */}
      </STwitterWrapper>
    )
  }
  if (avatarSeedAddress) {
    return (
      <SAvatar
        size='sm'
        wrapperStyle={avatarWrapperStyle}
        logoStyle={avatarImageStyle}
        {...avatarProps}
        seed={avatarSeedAddress}
      />
    )
  }
  return (
    <SAvatar
      size='sm'
      wrapperStyle={avatarImageStyle}
      logoStyle={avatarImageStyle}
      {...avatarProps}
      seed={publicAddress}
    />
  )
}

export const useUserImageToRender = (
  publicAddress: string,
  isUsingTwitterToDisplay?: boolean,
  avatarSeedAddress?: string,
  twitterImage?: string,
  twitterWrapperStyle: CSSProperties = {},
  twitterImageStyle: CSSProperties = {},
  avatarWrapperStyle: CSSProperties = {},
  avatarImageStyle: CSSProperties = {},
  avatarProps?: IAvatarWithoutSeed
) => {
  return useMemo(
    () =>
      getUserImageToRender(
        publicAddress,
        isUsingTwitterToDisplay,
        avatarSeedAddress,
        twitterImage,
        twitterWrapperStyle,
        twitterImageStyle,
        avatarWrapperStyle,
        avatarImageStyle,
        avatarProps
      ),
    [
      publicAddress,
      isUsingTwitterToDisplay,
      avatarSeedAddress,
      twitterImage,
      twitterWrapperStyle,
      twitterImageStyle,
      avatarWrapperStyle,
      avatarImageStyle,
      avatarProps,
    ]
  )
}

export const getUsernameToRender = (
  publicAddress: string,
  isUsingTwitterToDisplay?: boolean,
  username?: string,
  twitterUsername?: string,
  twitterUsernameStyle: CSSProperties = {},
  usernameStyle: CSSProperties = {},
  publicAddressStyle: CSSProperties = {},
  renderUnnamed = false,
  renderTwitterAsLink = true
) => {
  if (isUsingTwitterToDisplay && twitterUsername) {
    if (renderTwitterAsLink) {
      return (
        <a
          href={`https://twitter.com/${twitterUsername}`}
          target='_blank'
          style={{ color: `${FARE_COLORS.aqua}`, textDecoration: 'none', ...twitterUsernameStyle }}
          rel='noreferrer'
        >
          @{twitterUsername}
        </a>
      )
    }
    return (
      <span style={{ color: `${FARE_COLORS.aqua}`, ...twitterUsernameStyle }}>
        @{twitterUsername}
      </span>
    )
  }
  if (username) {
    return <span style={usernameStyle}>{username}</span>
  }
  if (renderUnnamed) {
    return <span style={publicAddressStyle}>UNNAMED</span>
  }
  return <span style={publicAddressStyle}>{publicAddress.substring(0, 10)}</span>
}

export const useUsernameToRender = (
  publicAddress: string,
  isUsingTwitterToDisplay?: boolean,
  username?: string,
  twitterUsername?: string,
  twitterUsernameStyle: CSSProperties = {},
  usernameStyle: CSSProperties = {},
  publicAddressStyle: CSSProperties = {},
  renderUnnamed = false,
  renderTwitterAsLink = true
) => {
  return useMemo(
    () =>
      getUsernameToRender(
        publicAddress,
        isUsingTwitterToDisplay,
        username,
        twitterUsername,
        twitterUsernameStyle,
        usernameStyle,
        publicAddressStyle,
        renderUnnamed,
        renderTwitterAsLink
      ),
    [
      publicAddress,
      isUsingTwitterToDisplay,
      username,
      twitterUsername,
      twitterUsernameStyle,
      usernameStyle,
      publicAddressStyle,
      renderUnnamed,
      renderTwitterAsLink,
    ]
  )
}
