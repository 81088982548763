import { DEFAULT_MAX_COUNT } from './constants'
import { type GameHelperFunctions } from './types'

export enum RPSSelection {
  Rock = 0,
  Paper = 1,
  Scissors = 2,
  Random = 3,
}

export const isValidRPSSide = (side: number = 0) => {
  return side >= 0 && side <= 2
}

export const getMaxCountForRPS = (side: number = 0) => {
  return DEFAULT_MAX_COUNT
}

export const getRPSMultiplierWithoutPPV = () => {
  return 2
}

export const getRPSMultiplierWithPPV = () => {
  return 1.98
}

// @NOTE: https://en.wikipedia.org/wiki/Kelly_criterion
export const getRPSKellyFraction = () => {
  return 0.01530571818
}

export const getRPSPotentialProfitCoefficient = () => {
  return 0.98
}

export const rpsHelperFunctions: GameHelperFunctions<number> = {
  isValidSide: isValidRPSSide,
  getMaxCount: getMaxCountForRPS,
  getMultiplierWithoutPPV: getRPSMultiplierWithoutPPV,
  getMultiplierWithPPV: getRPSMultiplierWithPPV,
  getKellyFraction: getRPSKellyFraction,
  getPotentialProfitCoefficient: getRPSPotentialProfitCoefficient,
} as const
