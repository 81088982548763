import { BORDER_COLORS, TEXT_COLORS } from '@/design'
import Avatar from '../Avatar'
import { generateUserColor } from '@/utils'
import { type Variants, motion } from 'framer-motion'
import { useUserImageToRender, useUsernameToRender } from '@/utils/userDisplay'
import { IChatMessage } from '@/lib/fsocket/types'

const SChatAvatar = styled(Avatar)`
  height: 12px;
  width: 12px;
  filter: brightness(0.6);
  > img {
    height: 8px;
    width: 8px;
  }
`

const SChatMessage = styled(motion.div)<{ $userColor: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${BORDER_COLORS.one};
  /* background: #101010; */
  box-sizing: border-box;
  padding: 8px 12px;
  border-radius: 6px;
  background: black;

  .chat-msg-header {
    display: flex;
    align-items: center;
    > .chat-msg-user {
      margin-left: 6px;
      font-size: 11px;
      line-height: 1px;
      padding-top: 2px;
      color: ${TEXT_COLORS.two};
    }
  }

  .chat-msg-content {
    margin-top: 8px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
    overflow-wrap: break-word;
    /* color: #dedede; */
    /* color: ${TEXT_COLORS.two}; */
  }
`

type TrollMessageProps = {
  msg: IChatMessage
}

const chatMessageVariant: Variants = {
  initial: { opacity: 0, y: 20, transition: { duration: 0.22 } },
  animate: { opacity: 1, y: 0, transition: { duration: 0.22 } },
  exit: { opacity: 0, y: 20, transition: { duration: 0.22 } },
}

export const TrollMessage = ({ msg }: TrollMessageProps) => {
  const {
    id,
    text,
    createdBy,
    username,
    avatarSeedAddress,
    isUsingTwitterToDisplay,
    twitterUsername,
    twitterImage,
    // timestamp,
    // twitterDisplayName,
  } = msg

  const userColor = useMemo(() => generateUserColor(username), [username])

  const userImageToRender = useUserImageToRender(
    createdBy,
    isUsingTwitterToDisplay,
    avatarSeedAddress,
    twitterImage,
    {
      borderRadius: 100,
      maxHeight: 20,
    },
    {
      borderRadius: 100,
      maxHeight: 20,
    },
    {
      height: '22px',
      width: '22px',
      filter: 'brightness(0.6)',
    },
    {
      height: '16px',
      width: '16px',
    }
  )
  const usernameToRender = useUsernameToRender(
    createdBy,
    isUsingTwitterToDisplay,
    username,
    twitterUsername,
    {
      marginLeft: '6px',
      fontSize: '11px',
      lineHeight: '1px',
      paddingTop: '2px',
      // color: `${TEXT_COLORS.two}`,
    },
    {
      marginLeft: '6px',
      fontSize: '11px',
      lineHeight: '1px',
      paddingTop: '2px',
      color: `${TEXT_COLORS.two}`,
    },
    {
      marginLeft: '6px',
      fontSize: '11px',
      lineHeight: '1px',
      paddingTop: '2px',
      color: `${TEXT_COLORS.two}`,
    }
  )

  return (
    <SChatMessage
      layout
      layoutId={id}
      className='chat-box-msg'
      $userColor={userColor}
      variants={chatMessageVariant}
      initial='initial'
      animate='animate'
      exit='exit'
    >
      <div className='chat-msg-header'>
        {userImageToRender}
        {usernameToRender}
      </div>
      <div className='chat-msg-content'>{text}</div>
    </SChatMessage>
  )
}
