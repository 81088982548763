import { PropsWithChildren } from 'react'
import { PostHogProvider as PHProvider } from 'posthog-js/react'
import { getPosthogConfig } from './config'

export const PostHogProvider = ({ children }: PropsWithChildren) => {
  const isPosthogDisabled = import.meta.env.VITE_DISABLE_POSTHOG === 'true'

  if (isPosthogDisabled) {
    return <>{children}</>
  }

  const posthogConfig = getPosthogConfig()

  return (
    <PHProvider apiKey={posthogConfig.apiKey} options={posthogConfig.options}>
      {children}
    </PHProvider>
  )
}
