import { motion } from 'framer-motion'
import { type CurrencyColors } from '@/chains/types'
import { BORDER_COLORS, FARE_COLORS, FONT_STYLES, SPACING, TEXT_COLORS } from '@/design'
import { deviceBP } from '@/design/breakpoints'

export const SwitchCoin = styled.div`
  position: relative;
  text-wrap: nowrap;
  margin-right: 12px;
  padding-left: 6px;
  padding-right: 6px;
  user-select: none;
  min-width: 150px;

  @media ${deviceBP.lg} {
    margin-right: 0px;
    min-width: auto;
  }
`

export const Dropdown = styled(motion.div)`
  position: absolute;
  text-transform: uppercase;
  top: 48px;
  left: 0;
  background: black;
  border: 1px solid ${BORDER_COLORS.one};
  border-radius: 6px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 100;
  width: 100%;
  padding-top: 10px;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 10;

  @media ${deviceBP.lg} {
    width: fit-content;
    padding-right: 10px;
  }

  @media ${deviceBP.sm} {
    background: transparent;
    border: none;
  }
`

export const SDropdownItem = styled.div<{ $isActive?: boolean; $menuColor: CurrencyColors }>`
  position: relative;
  text-transform: uppercase;
  width: 100%;
  text-align: left;
  padding-left: 8px;
  margin-bottom: 10px;
  box-sizing: border-box;
  transition: all ease-in-out 0.12s;
  height: 20px;
  display: flex;
  align-items: center;
  font-size: 11px;
  line-height: 12px;
  color: #aaaaaa;

  > img {
    margin-right: 12px;
    height: 16px;
    width: 16px;
  }

  > .hover-bar {
    transition: all ease-in-out 0.12s;
    position: absolute;
    right: 6px;
    top: 0px;
    height: 20px;
    width: 1px;

    box-shadow: inset 0px 0px 10px transparent;
    border: 1px solid transparent;
    background: rgba(255, 255, 255, 0);
  }
  &:hover {
    > span {
      color: white;
    }
    > .hover-bar {
      box-shadow: inset 0px 0px 10px ${FARE_COLORS.blue}99;
      border: 1px solid ${FARE_COLORS.blue};
    }
  }

  @media ${deviceBP.xs} {
    margin: ${SPACING.sm}px;
    height: 60px;
    border-left: 3px solid transparent;
    ${FONT_STYLES.lg};
    color: ${({ $isActive }) => ($isActive ? TEXT_COLORS.one : TEXT_COLORS.two)};
    width: calc(100% - ${SPACING.sm * 2}px);
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    ${({ $isActive, $menuColor }) =>
      $isActive ?
        css`
          background: linear-gradient(270deg, ${$menuColor.two}, ${$menuColor.three});
          color: ${TEXT_COLORS.one};
          border-left: 3px solid ${$menuColor.one};
          box-shadow: inset 10px 0 8px -8px ${$menuColor.one};
        `
      : css`
          background: linear-gradient(270deg, ${$menuColor.four}, transparent);
          color: ${TEXT_COLORS.two};
        `}

    > img {
      width: 42px;
      height: 42px;
      padding: ${SPACING.xs}px;
      margin-inline: 20px;
    }
  }
`

export const SAddressAvatarWrapper = styled.div<{ $borderColor?: string; $isOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 42px;
  width: auto;
  padding-left: 8px;
  padding-right: 12px;
  border: 1px solid ${BORDER_COLORS.one};
  color: #aaaaaa;
  border-radius: 6px;
  transition: all ease-in-out 0.08s;
  position: relative;
  gap: 8px;

  &:hover {
    border-color: ${props => props.$borderColor};
  }

  @media ${deviceBP.lg} {
    padding: 0px 12px;
  }

  @media ${deviceBP.sm} {
    border: none;
    padding-inline: 0px;
  }
`
export const MobileDropdownWrapper = styled(motion.div)`
  position: fixed;
  width: 100%;
  top: 250px;
  left: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
`

export const MobileDropdownMenu = styled(motion.div)`
  background: black;
  border: 1px solid ${BORDER_COLORS.one};
  height: 60svh;
  padding-bottom: 400px;
  border-radius: 0;
  padding: 0px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  margin: auto;
`

export const MobileDropDownContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  > img {
    width: 32px;
    height: 32px;
  }
`

export const MobileDropDownHeader = styled.div`
  margin: auto;
  text-transform: uppercase;
  font-size: 16px;
  padding: 0 0 10px 0;
`
