import React from 'react'
import { RefactorLiveEntries } from '../../LiveEntries/RefactorLiveEntries'

interface IMobileLiveEntriesProps {
  isShowLiveEntries: boolean
}

export const MobileLiveEntries = ({ isShowLiveEntries }: IMobileLiveEntriesProps) => {
  return isShowLiveEntries ? <RefactorLiveEntries /> : null
}
