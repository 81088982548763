import { BORDER_COLORS, FARE_COLORS, SPACING } from '@/design'
import { useActiveWallet } from '@/lib/privy/hooks'
import { SVGS } from '@/assets'
import { styled } from 'styled-components'
import { switchWalletState } from '@/lib/privy/hooks/switchWallet'
import { deviceBP } from '@/design/breakpoints'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'

const SWalletOverview = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin-right: 12px;
  padding: 0 ${SPACING.md}px;
  min-height: 42px;
  border: 1px solid ${BORDER_COLORS.one};
  color: #aaaaaa;
  border-radius: 6px;
  user-select: none;
  transition: all ease-in-out 0.08s;
  &:hover {
    span {
      color: white;
    }
    border-color: ${FARE_COLORS.blue};
  }

  img {
    height: 16px;
    margin-right: 8px;
  }

  span {
    text-transform: uppercase;
    color: #aaaaaa;
  }

  @media ${deviceBP.lg} {
    justify-content: flex-start;
    padding: 0px 12px;
    margin-right: 0;

    > img {
      margin-right: 0;
    }
  }
`

export const WalletOverview = () => {
  const { activeWallet } = useActiveWallet()
  const isMobileScreen = useIsBreakpoint('lg')

  const walletIcon = useMemo(() => {
    return activeWallet?.meta.icon ? activeWallet?.meta.icon : SVGS.privyIcon
  }, [activeWallet])

  return (
    <>
      <SWalletOverview
        onClick={() => {
          switchWalletState.isWalletModalOpen = true
        }}
      >
        <img alt={activeWallet?.meta.name || 'wallet'} src={walletIcon} />
        {!isMobileScreen && <span>{activeWallet?.meta.name}</span>}
      </SWalletOverview>
    </>
  )
}
