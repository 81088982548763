import { useShallow } from 'zustand/react/shallow'
import useSUContractStore from '@/store/useSUContractStore'
import { sendGameStoreUpdateByAddress } from '@/store/useGameStateStore'
import { type GameStateReducer } from '@/lib/fare/state'
import { type BigNumber, type Event, utils } from 'ethers'
import { entryEvent } from '@/events/entryEvent'
import useUserDataStore from '@/store/useUserDataStore'
import { useActiveWallet } from '@/lib/privy/hooks'
import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'
import { getAppCurrencyDecimals } from '@/chains/lib'
import { type AppGameName } from '@/chains/types'

export interface Trial {
  gameName: string
  who: string
  submitter: string
  multiplier: BigNumber
  count: number
  q: bigint[]
  k: bigint[]
  filledOriginalQ: bigint[]
  filledOriginalK: bigint[]
  vrfCostInUsdc: BigNumber
  aaCostInUsdc: BigNumber
}

export const useGameContractListener = (gameName: AppGameName) => {
  const { setSubmittedAmount, setInProgressEntry, setIsSubmitting } = useSUContractStore(
    useShallow(state => ({
      setSubmittedAmount: state.setSubmittedAmount,
      setInProgressEntry: state.setInProgressEntry,
      setIsSubmitting: state.setIsSubmitting,
    }))
  )
  const { walletAddress, walletChainId } = useActiveWallet()
  const appContracts = useAppChainConfigStore(state => state.appContracts)

  /* Memos */
  const send = useMemo(() => sendGameStoreUpdateByAddress(gameName), []) as
    | GameStateReducer
    | undefined

  // @TODO: Think about what these state settings are doing, also consider not even listening to this
  useEffect(() => {
    if (!appContracts || !appContracts.ws || !walletAddress) return
    const entrySubmittedFilter = appContracts.ws.vault.filters.TrialRegistered(
      null,
      walletAddress,
      null,
      null,
      null,
      null,
      null,
      null
    )

    const entrySubmittedListener = (
      _trialId: BigNumber,
      who: string,
      submitter: string,
      multiplier: BigNumber,
      q: BigNumber[],
      k: BigNumber[],
      vrfCostInUsdc: BigNumber,
      aaCostInUsdc: BigNumber,
      event: Event
    ) => {
      if (useUserDataStore.getState().currentGameName !== gameName) return
      const formattedTotalEntryAmount = utils.formatUnits(multiplier, getAppCurrencyDecimals())
      setSubmittedAmount(formattedTotalEntryAmount)
      setIsSubmitting(false)
      setInProgressEntry({ requestId: _trialId.toString(), timestamp: Date.now() })
    }

    appContracts.ws.vault.on(entrySubmittedFilter, entrySubmittedListener)

    return () => {
      appContracts.ws.vault.removeListener(entrySubmittedFilter, entrySubmittedListener)
    }
  }, [walletAddress, appContracts, gameName, walletChainId])

  entryEvent.useSub('gameFinished', () => {
    send?.({
      type: 'RESET',
      payload: {},
    })

    // TODO This could could cause a problem because we are checking the state value
    // inProgressEntry which could remount the listener and ignore events during this time
    // if (!inProgressEntry) return
    // if (inProgressEntry.requestId === requestId.toString()) {
    // console.log('resolved at: ', new Date().toLocaleString())
    setInProgressEntry(null)
    setIsSubmitting(false)
    // }
  })
}
