import Toggle, { ToggleProps } from 'react-toggle'
import 'react-toggle/style.css'
import './style.css'
import { BORDER_COLORS, FARE_COLORS } from '@/design'

interface ITogglerProps extends ToggleProps {
  isChecked: boolean
  onChecked: () => void
  toggleType?: 'default' | 'quickplay'
}

const SToggler = styled.div<{ $toggleType?: string }>`
  .react-toggle-track {
    height: 23px;
    width: 46px;
    border-radius: 6px;
  }

  .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track,
  .react-toggle--checked .react-toggle-track {
    background: transparent;
    box-shadow: inset 0px 0px 36px ${FARE_COLORS.blue}99;
    border: 1px solid ${FARE_COLORS.blue};
    ${props =>
      props.$toggleType === 'quickplay' &&
      css`
        box-shadow: inset 0px 0px 36px ${FARE_COLORS.aqua}99;
        border: 1px solid ${FARE_COLORS.aqua};
      `}
  }

  .react-toggle-thumb {
    height: 20px;
    width: 20px;
    border-radius: 6px;
    top: 2px;
    left: 2px;
  }

  .react-toggle--checked .react-toggle-thumb {
    left: 25px;
  }

  /* &:has(.react-toggle--checked) { */
  /*   .react-toggle-track { */
  /*     border: 1px solid ${FARE_COLORS.blue}; */
  /*   } */
  /* } */
  .react-toggle--focus .react-toggle-thumb {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .react-toggle {
    &:not(.react-toggle--checked) {
      &:hover {
        .react-toggle-track {
          box-shadow: inset 0px 0px 36px ${FARE_COLORS.blue}42;
          border: 1px solid ${FARE_COLORS.blue}99;
          ${props =>
            props.$toggleType === 'quickplay' &&
            css`
              box-shadow: inset 0px 0px 36px ${FARE_COLORS.aqua}42;
              border: 1px solid ${FARE_COLORS.aqua}80;
            `}
        }
      }
    }
  }

  .react-toggle {
    &:not(.react-toggle--checked) {
      .react-toggle-track {
        border: 1px solid ${BORDER_COLORS.one};
      }
    }
  }
`

export const Toggler = ({
  isChecked,
  onChecked,
  toggleType = 'default',
  ...props
}: ITogglerProps) => {
  return (
    <SToggler $toggleType={toggleType || 'external'}>
      <Toggle
        className={toggleType === 'quickplay' ? 'quickplay-toggle' : 'external-toggle'}
        icons={false}
        checked={isChecked}
        onChange={onChecked}
        {...props}
      />
    </SToggler>
  )
}
