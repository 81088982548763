// Binomial probability functions
const factorial = (n: number): number => (n <= 1 ? 1 : n * factorial(n - 1))

const binomialCoefficient = (n: number, k: number) => {
  return factorial(n) / (factorial(k) * factorial(n - k))
}

const binomialProbability = (n: number, k: number, p: number) => {
  return binomialCoefficient(n, k) * Math.pow(p, k) * Math.pow(1 - p, n - k)
}

export const cumulativeBinomialProbability = (n: number, k: number, p: number) => {
  let cumulativeProbability = 0
  for (let i = k; i <= n; i++) {
    cumulativeProbability += binomialProbability(n, i, p)
  }
  return cumulativeProbability
}
