// varying vec2 vUv;

// void main() {
// 	vUv = uv;
// 	gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
// }

#include <fog_pars_vertex>
uniform float uTime;
varying vec3 vVertexWorldPosition;

void main() {
  #include <begin_vertex>
  #include <project_vertex>
  #include <fog_vertex>
	vVertexWorldPosition = (modelMatrix * vec4(position, 2.0)).xyz;
	gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 2.0);
}
