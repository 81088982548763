import type { SpaceOpts } from './types'
import { ChatSpace, IFSpaceBase, LiveEntrySpace, SUEntrySpace, UserSpace } from './space'

export type FSocketInstanceOpts = {
  url?: string
  authToken?: string
}

export class FSocketInstance {
  url!: string
  chat!: ChatSpace
  liveEntry!: LiveEntrySpace
  suEntry!: SUEntrySpace
  user!: UserSpace
  #authToken?: string
  #spaces: Set<IFSpaceBase> = new Set()

  public get fsocketOptions(): SpaceOpts {
    return {
      autoConnect: false,
      withCredentials: true,
      auth: {
        authToken: this.#authToken || '',
      },
    }
  }

  private get defaultSocketOpts() {
    return {
      authToken: this.#authToken || '',
      fsocketOpts: this.fsocketOptions,
    }
  }

  set authToken(value: string) {
    this.#authToken = value
  }

  constructor({ url = '', authToken }: FSocketInstanceOpts) {
    this.url = url
    this.#authToken = authToken

    // Create and initialize listeners
    this.chat = new ChatSpace(this.defaultSocketOpts)
    this.#spaces.add(this.chat)

    this.liveEntry = new LiveEntrySpace(this.defaultSocketOpts)
    this.#spaces.add(this.liveEntry)

    // this.suEntry = new SUEntrySpace(this.defaultSocketOpts)
    // this.#spaces.add(this.suEntry)

    this.user = new UserSpace(this.defaultSocketOpts)
    this.#spaces.add(this.user)
  }

  reconnectAll(opts: { wsUrl: string; authToken?: string }) {
    this.#authToken = opts.authToken
    for (const space of this.#spaces) {
      console.log(`Reconnecting space(${space.name})...`)
      space.reconnectSocket(opts)
    }
  }
}
