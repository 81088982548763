import { AnimatePresence, motion } from 'framer-motion'
import CountUp from 'react-countup'
import { BigNumber } from 'ethers'

import { SVGS, PNGS } from '@/assets'
import useCurrencyStore from '@/store/useCurrencyStore'
import {
  BlockHeader,
  CollapsedWrapper,
  BalanceWrapper,
  OverviewLabel,
  OverviewRow,
  CollapsedBalanceWrapper,
  OverviewPanelWrapper,
  StatsOverviewRow,
  OverviewButtonWrapper,
  GameModeButton,
  PanelContainer,
} from './style'
import { GameHistory } from '@/components/GameHistory'
import { ButtonEnum } from '../../Button'
import { QuickPlayButton, QuickPlaySetupButton } from '../../Button/QuickPlayButton'
import { useLocalStorage } from 'usehooks-ts'
import useUserDataStore from '@/store/useUserDataStore'
import { multiplyBigNumberWithFixedPointNumber } from '@/lib/crypto'
import { addAppNoti } from '@/store/useNotiStore'
import { useAA } from '@/lib/aa/hooks/useAA'
import { useActiveWallet } from '@/lib/privy/hooks'

export const OverviewPanel = () => {
  const [isShowPanel, setShowPanel] = useLocalStorage<boolean>('showBalancePanel', true)
  const balances = useCurrencyStore.use.balances()
  const selectedCurrencyAmount = balances.currency
  const { walletAddress, networkStyle } = useActiveWallet()
  const {
    accWinningAmount,
    accLosingAmount,
    winThresholdForPoint,
    loseThresholdForPoint,
    bonusLevelPoints,
    levelMultiplier,
    cachedAssistPoints,
  } = useUserDataStore()
  const { activateAASession, deactivateAASession, isUsingAA, isActivatingAA, hasSetupBefore } =
    useAA()

  const overviewActionBtnElem = useMemo(() => {
    if (hasSetupBefore) {
      return (
        <>
          <QuickPlayButton
            onClick={async () => {
              try {
                if (isActivatingAA || isUsingAA) return
                await activateAASession(walletAddress as `0x${string}`)
                addAppNoti({
                  msg: `Quickplay enabled`,
                  type: 'success',
                })
              } catch (err) {
                addAppNoti({
                  msg: `Failed: enabling quickplay`,
                  type: 'error',
                })
                console.log(err)
                throw new Error(`Error enabling quickplay`)
              }
            }}
            disabled={isActivatingAA}
            $isProcing={isActivatingAA}
            $isActive={isUsingAA}
          >
            <span>QUICKPLAY</span>
          </QuickPlayButton>
          <GameModeButton
            onClick={() => {
              if (!isUsingAA) return
              try {
                deactivateAASession(walletAddress)
                addAppNoti({
                  msg: `Standard enabled`,
                  type: 'success',
                })
              } catch (err) {
                addAppNoti({
                  msg: `Failed: enabling standard`,
                  type: 'error',
                })
                throw new Error(`Error enabling standard`)
              }
            }}
            disabled={false}
            isLoading={false}
            buttonType={ButtonEnum.PRIMARY_1}
            $isActive={!isUsingAA}
          >
            <span>STANDARD</span>
          </GameModeButton>
        </>
      )
    } else {
      return (
        <QuickPlaySetupButton
          onClick={async () => {
            try {
              if (isActivatingAA || isUsingAA) return
              await activateAASession(walletAddress as `0x${string}`)
              addAppNoti({
                msg: `Quickplay enabled`,
                type: 'success',
              })
            } catch (err) {
              addAppNoti({
                msg: `Failed: enabling quickplay`,
                type: 'error',
              })
              throw new Error(`Error enabling quickplay`)
            }
          }}
          disabled={isActivatingAA || !walletAddress}
        >
          <span>{isActivatingAA ? 'SETTING UP QUICKPLAY...' : 'SETUP QUICKPLAY'}</span>
        </QuickPlaySetupButton>
      )
    }
  }, [
    isUsingAA,
    activateAASession,
    deactivateAASession,
    // authenticate,
    // authToken,
    // isActive,
    // isAuthed,
    walletAddress,
    isActivatingAA,
    hasSetupBefore,
  ])

  return (
    <AnimatePresence>
      <PanelContainer>
        <OverviewPanelWrapper className='solid-color'>
          <BlockHeader $isShowPanel={isShowPanel} onClick={() => setShowPanel(!isShowPanel)}>
            <motion.div
              key={String(isShowPanel)}
              transition={{
                duration: 0.25,
                ease: 'easeIn',
              }}
              initial={{
                opacity: 0,
                height: 0,
              }}
              animate={{
                opacity: 1,
                height: 'auto',
              }}
              exit={{
                opacity: 0,
                height: 0,
              }}
            >
              {isShowPanel ?
                <>
                  <img src={SVGS.rewardIcon} alt='wallet-icon' />
                  <p>BALANCE</p>
                </>
              : <CollapsedWrapper>
                  <CollapsedBalanceWrapper>
                    <img
                      src={networkStyle.currencyIcon}
                      alt={networkStyle.currencyName}
                      width={16}
                    />
                    <CountUp
                      end={Number(selectedCurrencyAmount)}
                      decimals={2}
                      duration={2}
                      separator={','}
                      suffix={` ${networkStyle.currencyName}`}
                      preserveValue
                    />
                    {/* <span style={{ color: COLORS.error, marginLeft: SPACING.xs }}>(-2.53%)</span> */}
                  </CollapsedBalanceWrapper>
                </CollapsedWrapper>
              }
            </motion.div>
            <img
              src={PNGS.chevronIcon}
              style={{
                transform: `rotate(${isShowPanel ? 180 : 0}deg)`,
                transition: '.2s ease-in-out',
              }}
            />
          </BlockHeader>
          {isShowPanel ?
            <motion.div
              key={String(isShowPanel)}
              transition={{
                duration: 0.25,
                ease: 'easeIn',
              }}
              initial={{
                opacity: 0,
                height: 0,
              }}
              animate={{
                opacity: 1,
                height: 'auto',
              }}
              exit={{
                opacity: 0,
                height: 0,
              }}
            >
              <BalanceWrapper>
                <>
                  <StatsOverviewRow className='left-border'>
                    <div>
                      <img
                        src={networkStyle.currencyIcon}
                        alt={networkStyle.currencyName}
                        width={16}
                      />
                      <CountUp
                        end={Number(selectedCurrencyAmount)}
                        decimals={2}
                        duration={2}
                        separator={','}
                        suffix={` ${networkStyle.currencyName}`}
                        preserveValue
                      />
                    </div>
                    <div>
                      <img src={SVGS.deathmatchIcon} alt='usdc' />
                      <CountUp
                        end={
                          multiplyBigNumberWithFixedPointNumber(
                            BigNumber.from(accWinningAmount || '0'),
                            String(levelMultiplier || 1)
                          )
                            .div(winThresholdForPoint || 1)
                            .toNumber() +
                            multiplyBigNumberWithFixedPointNumber(
                              BigNumber.from(accLosingAmount || '0'),
                              String(levelMultiplier || 1)
                            )
                              .div(loseThresholdForPoint || 1)
                              .toNumber() +
                            // Add Assists
                            (cachedAssistPoints || 0) +
                            // Add bonus level points
                            (bonusLevelPoints || 0) || 0
                        }
                        decimals={0}
                        duration={2}
                        separator={','}
                        suffix={' POINTS'}
                        preserveValue
                      />
                    </div>
                  </StatsOverviewRow>

                  <OverviewLabel>
                    <img src={SVGS.slotsIcon} alt='history' />
                    <span>HISTORY</span>
                  </OverviewLabel>
                  <OverviewRow className='left-border-large'>
                    <GameHistory />
                  </OverviewRow>
                </>

                <OverviewButtonWrapper>{overviewActionBtnElem}</OverviewButtonWrapper>
              </BalanceWrapper>
            </motion.div>
          : null}
        </OverviewPanelWrapper>
      </PanelContainer>
    </AnimatePresence>
  )
}
