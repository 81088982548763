import {
  type AsteroidConfig,
  type ShipConfig,
  ambientAsteroidConfig,
  defaultShipConfig,
  defaultGamePacingConfig,
  type GamePacingConfig,
  killerAsteroidConfig,
} from '@/components/Games/Crash/GameConfig'
import { create } from 'zustand'
import intensityData from '@/components/Games/Crash/gameIntensity.json'
import { entryEvent } from '@/events/entryEvent'

// THIS FUNCTION HAS NOT BEEN CHECKED FOR ACCURACY
const calculateSpoofedCrashPoint = (houseEdge = 0.01): number => {
  // Generate a random number between 0 and 1
  const randomValue = Math.random()
  const multiplier = 1 / (randomValue * (1 - houseEdge))
  // Round to 2 decimal places and clamp between 1 and 500
  return Math.min(Math.max(Math.round(multiplier * 100) / 100, 0), 500)
}

interface CrashGameState {
  gameState: 'IDLE' | 'PLAYING' | 'CRASHING' | 'EXPLODING'
  multiplier: number
  crashPoint: number
  autoCashoutMultiplier: number
  entryAmount: number
  shipVelocityY: number
  gameIntensity: number
  asteroidConfig: AsteroidConfig
  killerConfig: AsteroidConfig
  shipConfig: ShipConfig
  gamePacingConfig: GamePacingConfig
  forceFieldPosition: number | null
  backendResult: any
}

interface CrashGameActions {
  updateAsteroidConfig: (config: AsteroidConfig) => void
  updateKillerConfig: (config: AsteroidConfig) => void
  updateShipConfig: (config: ShipConfig) => void
  updateGamePacingConfig: (config: GamePacingConfig) => void
  resetConfigs: () => void
  simulateWin: () => void
  simulateLoss: () => void
  incrementMultiplierBy: (rate: number) => void
  startGame: () => void
  endGame: () => void
  spoofCrashPoint: (point?: number) => void
  resetGame: () => void
  setAutoCashoutMultiplier: (value: number) => void
  setEntryAmount: (value: number) => void
  setShipVelocityY: (value: number) => void
  setGameIntensity: (intensity: number) => void
  activateForceField: () => void
  updateForceFieldPosition: (position: number) => void
  resetForceField: () => void
  getIntensityForTime: (time: number) => number
  getConfigAtIntensity: (path: string, intensity: number) => any
  resolveEntry: (payload: {
    resultSides: any[]
    playedCount: number
    deltaAmounts: number[]
    totalDeltaAmount: number
  }) => void
}

// type CrashGameStore = CrashGameState & CrashGameActions
// const send = (payload: any) => useCrashGameState.getState().send?.(payload)

// const gameState = () => useCrashGameState.getState().type

export const useCrashGameStore = create<CrashGameState & CrashGameActions>((set, get) => ({
  gameState: 'IDLE',
  multiplier: 0,
  crashPoint: 0,
  autoCashoutMultiplier: 2,
  entryAmount: 100,
  shipVelocityY: 0,
  gameIntensity: 1,
  asteroidConfig: ambientAsteroidConfig,
  killerConfig: killerAsteroidConfig,
  shipConfig: defaultShipConfig,
  gamePacingConfig: defaultGamePacingConfig,
  forceFieldPosition: null,
  backendResult: null,

  incrementMultiplierBy: (amount: number) =>
    set(state => ({ multiplier: state.multiplier + amount })),

  startGame: () => {
    set({ gameState: 'PLAYING', multiplier: 0 })
    // console.log('DEBUG: Starting game, crash point:', get().crashPoint)
    if (get().crashPoint === 0) get().spoofCrashPoint()
  },

  endGame: () => {
    set({ gameState: 'CRASHING' })
    setTimeout(() => {
      set({ gameState: 'EXPLODING' })
      setTimeout(() => {
        get().resetGame()
        entryEvent.pub('gameFinished')
      }, 2000)
    }, 2000)
  },

  spoofCrashPoint: (point?: number) => {
    const crashPoint = point ?? calculateSpoofedCrashPoint()
    // console.log('DEBUG: Spoofing crash point:', crashPoint)
    set({ crashPoint })
  },

  resetGame: () => {
    // console.log('DEBUG: Resetting gameState')
    set({
      gameState: 'IDLE',
      multiplier: 0,
      crashPoint: 0,
      gameIntensity: 1,
    })
    get().resetForceField() // Reset the force field position
  },

  setAutoCashoutMultiplier: (value: number) => set({ autoCashoutMultiplier: value }),

  setEntryAmount: (value: number) => set({ entryAmount: value }),

  setShipVelocityY: (velocity: number) => set({ shipVelocityY: velocity }),

  setGameIntensity: (intensity: number) => set({ gameIntensity: intensity }),

  updateAsteroidConfig: (config: AsteroidConfig) => set({ asteroidConfig: config }),
  updateKillerConfig: (config: AsteroidConfig) => set({ killerConfig: config }),
  updateShipConfig: (config: ShipConfig) => set({ shipConfig: config }),
  updateGamePacingConfig: (config: GamePacingConfig) => set({ gamePacingConfig: config }),
  resetConfigs: () =>
    set({
      asteroidConfig: ambientAsteroidConfig,
      shipConfig: defaultShipConfig,
    }),

  simulateWin: () => {
    const { autoCashoutMultiplier } = get()
    get().spoofCrashPoint(autoCashoutMultiplier + 1)
    get().startGame()
  },

  simulateLoss: () => {
    const lossMultiplier = 0.51
    get().spoofCrashPoint(lossMultiplier)
    set({ autoCashoutMultiplier: lossMultiplier + 1 })
    get().startGame()
  },

  resolveEntry: (payload: {
    resultSides: any[]
    playedCount: number
    deltaAmounts: number[]
    totalDeltaAmount: number
  }) => {
    // @TODO: Should I send the below data ????
    // set(state => {
    //   send?.({
    //     type: 'RESOLVE',
    //     payload: {
    //       resultSides: payload.resultSides,
    //       playedCount: payload.playedCount,
    //       deltaAmounts: payload.deltaAmounts,
    //       totalDeltaAmount: payload.totalDeltaAmount,
    //     },
    //   } as GameStateAction)
    // @NOTE: For now, there is no multiple entry support for crash so, everything will work for a single crash instance per bet
    set({
      backendResult: {
        resultSides: payload.resultSides,
        playedCount: payload.playedCount,
        deltaAmounts: payload.deltaAmounts,
        totalDeltaAmount: payload.totalDeltaAmount,
      },
    })
    get().spoofCrashPoint(payload.resultSides[0] / 100) // since there is only one result, we get that, and since backend uses 2 extra decimals to represent the precision, we are dividing by 100 to get the frontend expected value
    get().startGame()
  },

  activateForceField: () => {
    const shipX = get().shipConfig.position.x
    const offset = 1
    set({ forceFieldPosition: shipX + offset })
    // console.log('DEBUG: Force field activated at position:', shipX + offset)
  },
  updateForceFieldPosition: (position: number) => set({ forceFieldPosition: position }),
  resetForceField: () => {
    set({ forceFieldPosition: null })
    // console.log('DEBUG: Force field position has been reset to null.')
  },

  getIntensityForTime: (time: number) => {
    const currentIntensity = intensityData.intensities.reduce((prev, current) => {
      if (time >= current.time) {
        return current
      }
      return prev
    }, intensityData.intensities[0])

    return currentIntensity.intensity
  },

  getConfigAtIntensity: (path: string, intensity: number) => {
    const intensityConfig = intensityData.intensities.find(i => i.intensity === intensity)
    if (!intensityConfig) {
      console.warn(`No configuration found for intensity ${intensity}`)
      return null
    }

    // Split the path into parts
    const pathParts = path.split('.')

    // Traverse the object following the path
    let result: any = intensityConfig
    for (const part of pathParts) {
      if (result && typeof result === 'object' && part in result) {
        result = result[part]
      } else {
        console.warn(`Path ${path} not found in intensity ${intensity} configuration`)
        return null
      }
    }

    return result
  },
}))
