import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'
import { appChainIdMap } from '@/chains'

export const getAppDecimals = () =>
  useAppChainConfigStore.getState().appChainConfig.networkStyle.decimals

export const useAppChainConfig = () => {
  const { selectedAppChainId, appChainConfig, appProvider, isWrongNetwork, appContracts } =
    useAppChainConfigStore(state => ({
      selectedAppChainId: state.appChainId,
      appChainConfig: state.appChainConfig,
      appProvider: state.appProvider,
      appContracts: state.appContracts,
      isWrongNetwork: state.isWrongNetwork,
    }))

  return {
    appChainConfigs: Object.values(appChainIdMap),
    selectedAppChainId,
    appChainConfig,
    appAddresses: appChainConfig.addresses,
    networkStyle: appChainConfig.networkStyle,
    appChainDefinition: appChainConfig.chainDefinition,
    appContracts,
    appProvider,
    isWrongNetwork,
  }
}
