import { SVGS } from '@/assets'
import { FARE_COLORS } from '@/design'

const SAppDownPage = styled.div`
  height: 100vh;
  width: 100vw;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 72px;
    margin-bottom: 8px;
  }

  a {
    color: ${FARE_COLORS.aqua};
  }
`
const SContentCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 80%;
  gap: 8px;
`

const SCenterText = styled.div`
  color: white;
  text-align: center;
`

export const AppDownPage = () => {
  const discordURL = (
    <a target='_blank' href='https://discord.com/invite/eUEwY3vS8R'>
      Discord
    </a>
  )
  const twitterURL = (
    <a target='_blank' href='https://www.x.com/fareplayio'>
      X (Twitter)
    </a>
  )

  return (
    <SAppDownPage>
      <SContentCard>
        <img alt='fareplay-logo' src={SVGS.fareIcon} />
        <SCenterText>Fareplay is down for maintenance</SCenterText>
        <SCenterText>
          Follow us on {twitterURL} or join our {discordURL} for updates
        </SCenterText>
      </SContentCard>
    </SAppDownPage>
  )
}