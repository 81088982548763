import useRandomnessType from '@/hooks/useRandomnessType'
import { useWeb3 } from '@/hooks/useWeb3'
import { getRandomnessTypeFromLocalStorage } from '@/store/useRandomnessTypeStore'
import { usePrevious } from 'react-use'
import useMaxValues from '@/hooks/useMaxValues'
import useMaxValuesStore, { getMaxValuesFromLocalStorage } from '@/store/useMaxValuesStore'
import { useActiveWallet } from '@/lib/privy/hooks'

export const HandleLocalStorageWithAccountChange = () => {
  const { walletAddress: account, walletProvider: provider } = useActiveWallet()
  const prevAccount = usePrevious(account)
  const { setRandomnessType } = useRandomnessType()
  const { fetchSCMaxValues } = useMaxValues()
  const { setMaxValues, setIsUsingDefaultBuffers, setCurrentSCValues, ...vals } =
    useMaxValuesStore()

  // @NOTE: Things to handle: randomnessType, isUsingQuickPlay and maybe authToken in future????

  useEffect(() => {
    // @NOTE: Run for both the initial connection and account changes
    if (account && prevAccount !== account) {
      // window.location.reload()
      const accountsRandomnessType = getRandomnessTypeFromLocalStorage(account)
      setRandomnessType(accountsRandomnessType, account)
    }
    // @TODO: Probably do this with maxValues feature (if values do not exist, fetch sc values and have 10% buffer as default) save sc values and buffer applied values into local storage
    if (provider && account) {
      ;(async () => {
        // console.log('account change: acc, prevAcc:', account, prevAccount)
        const scVals = await fetchSCMaxValues()
        const accountsMaxValues = getMaxValuesFromLocalStorage(account, scVals)
        // console.log('account change: getMaxValues:', accountsMaxValues)
        setMaxValues(accountsMaxValues, account)
        setIsUsingDefaultBuffers(accountsMaxValues.isUsingDefaultBuffers === 'true', account)
        if (scVals) {
          setCurrentSCValues({
            ethUsdcPriceCurrentSCValue: scVals.ethUsdcPriceSCValue,
            averageCallbackGasCurrentSCValue: scVals.averageCallbackGasSCValue,
            aaCostMultiplierCurrentSCValue: scVals.aaCostMultiplierSCValue,
          })
        }
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, provider, prevAccount, setRandomnessType, fetchSCMaxValues])

  return null
}
