import { SVGProps } from 'react'

interface ISVGUSDC extends SVGProps<SVGSVGElement> {
  fill: string
}

export const SVGUSDC = ({ fill, ...props }: ISVGUSDC) => (
  <svg xmlns='http://www.w3.org/2000/svg' id='Layer_1' viewBox='0 0 220 220' {...props}>
    <defs>
      <style>{'.cls-1{fill:#fff;stroke-width:0}'}</style>
    </defs>
    <path
      d='M215 110V86.67h-5.83v-17.5H197.5h5.83V57.5h-5.83V45.83h-5.83V40h-5.84v-5.84H180v-5.83h-5.83V22.5H162.5v-5.84h-11.67v-5.83h-17.5V5H86.67v5.83h-17.5v5.83H57.5v5.84H45.83v5.83H40v5.83h-5.83V40h-5.84v5.83H22.5V57.5h-5.83v11.67h5.83-11.67v17.5H5V110h17.5H5v23.33h5.83v17.5H22.5v.01h-5.83v11.66h5.83v11.67h5.83V180h5.84v5.84H40v5.83h5.83v5.83H57.5v5.84h11.67v5.83h17.5V215h46.66v-5.83h17.5v-5.83h11.67v-5.84h11.67v-5.83H180v-5.83h5.83V180h5.84v-5.83h5.83V162.5h5.83v-11.66h-5.83v-.01h11.67v-17.5H215V110h-17.5H215z'
      style={{
        fill: 'transparent',
        strokeWidth: 0,
      }}
    />
    <path
      d='M69.17 51.67v-.01H57.5v5.84h-5.83v5.83h-5.84V75H40v11.67h-5.83v46.66H40V145h5.83v11.66h5.84v5.84h5.83v5.83h11.67v5.83h11.66V162.5H69.17v-5.83h-5.84v-5.84H57.5V145h-5.84v-11.67h-5.83V86.67h5.83V75h5.84v-5.84h5.83v-5.83h5.84V57.5h11.66V45.84H69.17v5.83zM150.83 168.33v.01h11.67v-5.84h5.83v-5.83h5.84V145H180v-11.67h5.83V86.67H180V75h-5.83V63.34h-5.84V57.5h-5.83v-5.83h-11.67v-5.83h-11.66V57.5h11.66v5.83h5.84v5.84h5.83V75h5.84v11.67h5.83V133.33h-5.83V145h-5.84v5.84h-5.83v5.83h-5.84v5.83h-11.66v11.66h11.66v-5.83zM133.33 92.5V80.83H115.84V69.16h-11.67v11.67H92.5v5.83h-5.83v17.5h5.83V110h11.69v5.83h17.48v17.51H98.34v-5.84H86.67v11.67h17.5v11.67h11.67v-11.67h11.66v-5.83h5.83v-17.5h-5.83V110h-5.87v-5.84H98.34v-17.5h23.33v5.84h11.66z'
      className='cls-1'
      style={{
        fill,
      }}
    />
  </svg>
)
