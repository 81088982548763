import { Contract } from 'ethers'
import { AppChainConfig, AppContractName } from '../types'
import { FareAA__factory, USDCMock__factory, UsdcVault__factory } from '@/lib/crypto/typechain'
import { Web3Provider, WebSocketProvider } from '@ethersproject/providers'

export const makeContracts = (appChainConfig: AppChainConfig, provider: Web3Provider) => {
  const bankroll = FareAA__factory.connect(appChainConfig.addresses.aa, provider!.getSigner())

  return {
    bankroll,
    aa: bankroll,
    currency: USDCMock__factory.connect(appChainConfig.addresses.currency, provider!.getSigner()),
    vault: UsdcVault__factory.connect(appChainConfig.addresses.vault, provider!.getSigner()),
  } as const satisfies Record<AppContractName, unknown>
}

export type AppContracts = ReturnType<typeof makeContracts>

export function connectWsProvider<T extends Contract>(
  contract: T,
  wsProvider: WebSocketProvider
): T {
  return contract.connect(wsProvider) as T
}

export function makeWsContracts(appContracts: AppContracts, wsProvider: WebSocketProvider) {
  const appWsContracts = {} as any
  Object.keys(appContracts).forEach(contractName => {
    appWsContracts[contractName] = connectWsProvider(
      (appContracts as any)[contractName],
      wsProvider
    )
  })

  return appWsContracts as AppContracts
}
