import { Environment } from '@react-three/drei'
import { DiceManager } from './DiceManager'
import { DiceGrid } from './DiceGrid'
import { DiceCamera } from './DiceCamera'

export const DiceScene = ({
  playGameSound,
}: {
  playGameSound: (name: string, volume?: number, pitch?: number) => void
}) => {
  return (
    <>
      <DiceCamera />

      <Environment preset='forest' />

      {/* Lights */}
      <ambientLight intensity={0.4} />
      <directionalLight intensity={1} color={'white'} position={[-2, 3.5, -3]} castShadow />

      <DiceManager playGameSound={playGameSound} />

      <DiceGrid />
    </>
  )
}
