import intensityData from './gameIntensity.json'

// Get intensity level 0 configuration
const defaultIntensity = intensityData.intensities.find(intensity => intensity.intensity === 0)

if (!defaultIntensity) {
  throw new Error('Default intensity configuration (level 0) not found')
}

export interface AsteroidConfig {
  minSize: number
  maxSize: number
  density: number
  speed: {
    min: number
    max: number
  }
  rotationIntensity: number
  xSpawnRange: {
    min: number
    max: number
  }
  ySpawnRange: {
    min: number
    max: number
  }
  safetyBuffer: number
  timingOffset: number
}

export interface ShipConfig {
  position: {
    x: number
  }
  scale: number
  sine1: {
    amplitude: number
    frequency: number
  }
  sine2: {
    amplitude: number
    frequency: number
  }
  barrelRoll: {
    frequency: number // Probability per frame (0-1)
    duration: number // seconds
  }
  safeDistance: {
    buffer: number
  }
  flyIn: {
    duration: number
    tiltAngle: number // radians
  }
  flying: {
    movementSpeed: number
    tiltIntensity: number
    rollIntensity: number
    maxRollAngle: number // radians
  }
  hud: {
    fontSize: number
    verticalOffset: number
  }

  // Optional parameters (these don't change with intensity)
  flyOut?: {
    duration: number
    targetPosition: {
      x: number
      y: number
      z: number
    }
  }
  intensityScaling?: {
    amplitudeScale: number | undefined
    frequencyScale: number | undefined
  }
}

export const defaultShipConfig: ShipConfig = {
  ...defaultIntensity.shipConfig,
  flyOut: {
    duration: 5,
    targetPosition: {
      x: 15,
      y: 0,
      z: 0,
    },
  },
  intensityScaling: {
    // amplitudeScale: 0.001,
    // frequencyScale: 0.003,
    amplitudeScale: 0.0,
    frequencyScale: 0.0,
  },
}

export const ambientAsteroidConfig: AsteroidConfig = defaultIntensity.asteroidConfig

export const killerAsteroidConfig: AsteroidConfig = {
  minSize: 0.5,
  maxSize: 1,
  density: 1,
  speed: {
    min: 9,
    max: 12,
  },
  rotationIntensity: 0.4,
  timingOffset: 0.4,
  xSpawnRange: {
    min: 5,
    max: 10,
  },
  ySpawnRange: {
    min: -5,
    max: 5,
  },
  safetyBuffer: 0,
}

export interface GamePacingConfig {
  multiplierStepSize: number
  multiplierStepRate: number
}

export const defaultGamePacingConfig: GamePacingConfig = defaultIntensity.gamePacingConfig
