import { styled } from 'styled-components'
import { useDidIRender } from '@/hooks/common/useDidIRender'
import { BombsCanvas } from '@/components/Canvases/BombsCanvas'
import { BombsForm } from '@/components/Forms/Bombs'
import GamePageComponent from '@/components/shared/GamePage'
import { AppGameName } from '@/chains/types'

export const RightBombsContainer = styled.div`
  display: grid;
  grid-column: 2 / span 2;
  grid-row: 1 / span 2;
  grid-auto-rows: auto;
`

export const BombsPage = () => {
  useDidIRender('BombsPage')
  return (
    <GamePageComponent
      mode={AppGameName.Bombs}
      CanvasComponent={BombsCanvas}
      FormComponent={BombsForm}
    />
  )
}
