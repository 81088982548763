import { createAvatar } from '@dicebear/core'
import * as identicon from '@dicebear/identicon'
import { generateUserColor } from '@/utils'

import { AvatarWrapper } from './style'
import { type CSSProperties } from 'styled-components'

export interface IAvatar {
  seed: string
  wrapperStyle?: CSSProperties
  logoStyle?: CSSProperties
  size?: 'sm' | 'md' | 'lg'
  hasBorder?: boolean
  avatarColor?: string
}

export interface IAvatarWithoutSeed {
  wrapperStyle?: CSSProperties
  logoStyle?: CSSProperties
  size?: 'sm' | 'md' | 'lg'
  hasBorder?: boolean
  avatarColor?: string
}

const Avatar = ({
  avatarColor,
  seed,
  wrapperStyle = {},
  logoStyle = {},
  size,
  hasBorder = false,
  ...props
}: IAvatar) => {
  const [avatar, setAvatar] = useState<string>('')

  const userColor = useMemo(() => generateUserColor(seed), [seed])

  useEffect(() => {
    if (!seed) return
    async function generateAvatar() {
      const avatarDataUri = await createAvatar(identicon, {
        seed,
        rowColor: [avatarColor?.substring(1) || (userColor as string)],
      }).toDataUri()
      setAvatar(avatarDataUri)
    }
    generateAvatar()
  }, [avatarColor, seed, userColor])

  if (!avatar) return null

  return (
    <AvatarWrapper
      $hasBorder={hasBorder}
      $size={size}
      $borderColor={avatarColor?.substring(1) ?? userColor}
      {...props}
      className={`avatar-wrapper ${(props as any).className || ''}`}
      style={wrapperStyle}
    >
      <img className='avatar-img' src={avatar} alt={'avatar'} style={logoStyle} />
    </AvatarWrapper>
  )
}

export default memo(Avatar)
