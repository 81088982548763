import { useRef } from 'react'
import { type ShaderMaterial } from 'three'
import * as THREE from 'three'

interface EdgeShaderMaterialProps {
  emissiveColor?: THREE.Color
  edgeColor?: THREE.Color
  edgeThickness?: number
  glowIntensity?: number
}

const EdgeShaderMaterial: React.FC<EdgeShaderMaterialProps> = ({
  emissiveColor = new THREE.Color(0xff0000),
  edgeColor = new THREE.Color(0xffffff),
  edgeThickness = 1.5,
  glowIntensity = 2.0,
}: EdgeShaderMaterialProps) => {
  const shaderRef = useRef<ShaderMaterial>(null)

  const uniforms = {
    emissiveColor: { value: emissiveColor },
    edgeColor: { value: edgeColor },
    edgeThickness: { value: edgeThickness },
    glowIntensity: { value: glowIntensity },
  }

  const vertexShader = `
    attribute vec3 aBarycentric;
    varying vec3 vNormal;
    varying vec3 vViewPosition;
    varying vec3 vWorldPosition;
    varying vec3 vBarycentricCoord;

    void main() {
      vBarycentricCoord = aBarycentric;

      // Standard vertex transformations
      vNormal = normalize(normalMatrix * normal);
      vec4 worldPosition = modelMatrix * vec4(position, 1.0);
      vWorldPosition = worldPosition.xyz;
      vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
      vViewPosition = -mvPosition.xyz;
      gl_Position = projectionMatrix * mvPosition;
    }
  `

  const fragmentShader = `
    varying vec3 vNormal;
    varying vec3 vViewPosition;
    varying vec3 vWorldPosition;
    varying vec3 vBarycentricCoord;

    uniform vec3 emissiveColor;
    uniform vec3 edgeColor;
    uniform float edgeThickness;
    uniform float glowIntensity;

    float edgeFactor() {
      vec3 d = fwidth(vBarycentricCoord);
      vec3 a3 = smoothstep(vec3(0.0), d * edgeThickness, vBarycentricCoord);
      return min(min(a3.x, a3.y), a3.z);
    }

    void main() {
      // Calculate edge factor
      float edge = 1.0 - edgeFactor();

      // Calculate fresnel for extra glow on edges
      vec3 viewDir = normalize(vViewPosition);
      float fresnel = pow(1.0 - abs(dot(viewDir, vNormal)), 3.0);
      
      // Combine edge detection with fresnel
      float edgeIntensity = max(edge, fresnel * 0.5);

      // Mix colors based on edge intensity
      vec3 finalColor = mix(emissiveColor * 0.2, edgeColor, edgeIntensity);
      
      // Apply glow intensity and set alpha
      float alpha = edgeIntensity * glowIntensity;
      gl_FragColor = vec4(finalColor * glowIntensity, alpha);
    }
  `

  return (
    <shaderMaterial
      ref={shaderRef}
      uniforms={uniforms}
      vertexShader={vertexShader}
      fragmentShader={fragmentShader}
      transparent
      blending={THREE.AdditiveBlending}
      depthWrite={false}
    />
  )
}

export default EdgeShaderMaterial
