import { useRef, useMemo } from 'react'
import { useFrame, extend } from '@react-three/fiber'
import * as THREE from 'three'
import { useCrashGameStore } from '@/store/useCrashGameStore'

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      starsShader: any
    }
  }
}

interface Distribution {
  xSpread: number
  ySpread: number
  zSpread: number
  spawnBuffer: number
}

interface ColorConfig {
  hue: number
  saturationRange: [number, number]
  lightnessRange: [number, number]
}

interface StarsProps {
  count?: number
  depth?: number
  saturation?: number
  baseSpeed?: number
  speedMultiplier?: number
  size?: number
  opacity?: number
  distribution?: Partial<Distribution>
  color?: Partial<ColorConfig>
}

const defaultDistribution: Distribution = {
  xSpread: 100,
  ySpread: 50,
  zSpread: 10,
  spawnBuffer: 20,
}

const defaultColor: ColorConfig = {
  hue: 0.6,
  saturationRange: [0, 0.3],
  lightnessRange: [0.8, 1],
}

function Stars({
  count = 5000,
  depth = 50,
  saturation = 0,
  baseSpeed = 2,
  speedMultiplier = 0.5,
  size = 0.5,
  opacity = 0.8,
  distribution: distributionProps = {},
  color: colorProps = {},
}: StarsProps) {
  const distribution = { ...defaultDistribution, ...distributionProps }
  const color = { ...defaultColor, ...colorProps }

  const pointsRef = useRef<THREE.Points>(null!)
  const { gameIntensity } = useCrashGameStore()
  const currentSpeedRef = useRef(0)

  // Create a ref to store initial positions for resetting
  const initialPositionsRef = useRef<Float32Array>()

  const [positions, colors] = useMemo(() => {
    const positions = new Float32Array(count * 3)
    const colors = new Float32Array(count * 3)

    // Generate initial positions in a more structured way
    for (let i = 0; i < count; i++) {
      const i3 = i * 3

      // Distribute stars across the full width evenly
      positions[i3] = (i / count) * distribution.xSpread - distribution.xSpread / 2
      positions[i3 + 1] = THREE.MathUtils.randFloatSpread(distribution.ySpread)
      positions[i3 + 2] = THREE.MathUtils.randFloatSpread(distribution.zSpread)
    }

    // Store initial positions for resetting
    initialPositionsRef.current = positions.slice()

    for (let i = 0; i < count; i++) {
      const i3 = i * 3

      const starColor = new THREE.Color()
      starColor.setHSL(
        color.hue,
        THREE.MathUtils.randFloat(color.saturationRange[0], color.saturationRange[1]),
        THREE.MathUtils.randFloat(color.lightnessRange[0], color.lightnessRange[1])
      )
      colors[i3] = starColor.r
      colors[i3 + 1] = starColor.g
      colors[i3 + 2] = starColor.b
    }

    return [positions, colors]
  }, [
    count,
    distribution.xSpread,
    distribution.ySpread,
    distribution.zSpread,
    color.hue,
    color.saturationRange,
    color.lightnessRange,
  ])

  useFrame((_, delta) => {
    if (pointsRef.current) {
      const targetSpeed = baseSpeed * (1 + gameIntensity * speedMultiplier)
      currentSpeedRef.current = THREE.MathUtils.lerp(
        currentSpeedRef.current,
        targetSpeed,
        delta * 0.5
      )

      const positionsArray = pointsRef.current.geometry.attributes.position.array as Float32Array

      for (let i = 0; i < count; i++) {
        const i3 = i * 3

        // Move star
        positionsArray[i3] -= currentSpeedRef.current * delta

        // Reset position using initial position data
        if (positionsArray[i3] < -distribution.xSpread / 2) {
          positionsArray[i3] = distribution.xSpread / 2 + distribution.spawnBuffer
          // Keep the original Y and Z positions
          positionsArray[i3 + 1] = initialPositionsRef.current![i3 + 1]
          positionsArray[i3 + 2] = initialPositionsRef.current![i3 + 2]
        }
      }

      pointsRef.current.geometry.attributes.position.needsUpdate = true
    }
  })

  return (
    <points ref={pointsRef}>
      <bufferGeometry>
        <bufferAttribute
          attach='attributes-position'
          count={positions.length / 3}
          array={positions}
          itemSize={3}
        />
        <bufferAttribute
          attach='attributes-color'
          count={colors.length / 3}
          array={colors}
          itemSize={3}
        />
      </bufferGeometry>
      <pointsMaterial
        size={size}
        vertexColors
        transparent
        opacity={opacity}
        sizeAttenuation={true}
        depthWrite={false}
        blending={THREE.AdditiveBlending}
      />
    </points>
  )
}

export default Stars
