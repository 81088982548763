import React, { useMemo, useRef, useEffect } from 'react'
import { useFrame } from '@react-three/fiber'
import * as THREE from 'three'

interface ExplosionEffectProps {
  position: [number, number, number]
  colors?: string[]
  count?: number
  size?: number
  spread?: number
  duration?: number
  gravity?: number
  force?: number
  shape?: 'circle' | 'square' | 'hex'
  intensity?: number
  onComplete: () => void
}

export const ExplosionEffect: React.FC<ExplosionEffectProps> = ({
  position,
  colors = ['#ff3d00'],
  count = 250,
  size = 1.5,
  spread = 2,
  duration = 1500,
  gravity = 0,
  force = 5,
  shape = 'circle',
  intensity = 1.0,
  onComplete,
}) => {
  const mesh = useRef<THREE.Points>(null!)
  const clock = useRef(new THREE.Clock())

  const particleGeometry = useMemo(() => {
    const geometry = new THREE.BufferGeometry()
    const positions = new Float32Array(count * 3)
    const velocities = new Float32Array(count * 3)
    const initialForces = new Float32Array(count * 3)
    const particleColors = new Float32Array(count * 3)
    const rotations = new Float32Array(count)

    for (let i = 0; i < count; i++) {
      const theta = Math.random() * Math.PI * 2
      const phi = Math.acos(Math.random() * 2 - 1)
      const r = Math.cbrt(Math.random()) * spread

      // Initial positions (closer to center)
      positions[i * 3] = r * Math.sin(phi) * Math.cos(theta) * 0.1
      positions[i * 3 + 1] = r * Math.sin(phi) * Math.sin(theta) * 0.1
      positions[i * 3 + 2] = r * Math.cos(phi) * 0.1

      // Directional velocities
      velocities[i * 3] = (Math.random() - 0.5) * 0.2
      velocities[i * 3 + 1] = (Math.random() - 0.5) * 0.2
      velocities[i * 3 + 2] = (Math.random() - 0.5) * 0.2

      // Initial explosive forces
      initialForces[i * 3] = Math.sin(phi) * Math.cos(theta)
      initialForces[i * 3 + 1] = Math.sin(phi) * Math.sin(theta)
      initialForces[i * 3 + 2] = Math.cos(phi)

      // Random color from array
      const randomColor = new THREE.Color(colors[Math.floor(Math.random() * colors.length)])
      particleColors[i * 3] = randomColor.r
      particleColors[i * 3 + 1] = randomColor.g
      particleColors[i * 3 + 2] = randomColor.b

      // Random rotation for each particle (0 to 2π)
      rotations[i] = Math.random() * Math.PI * 2
    }

    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
    geometry.setAttribute('velocity', new THREE.BufferAttribute(velocities, 3))
    geometry.setAttribute('initialForce', new THREE.BufferAttribute(initialForces, 3))
    geometry.setAttribute('color', new THREE.BufferAttribute(particleColors, 3))
    geometry.setAttribute('rotation', new THREE.BufferAttribute(rotations, 1))

    return geometry
  }, [count, spread, colors])

  const particleMaterial = useMemo(() => {
    return new THREE.ShaderMaterial({
      uniforms: {
        time: { value: 0 },
        size: { value: size },
        duration: { value: duration / 1000 },
        gravity: { value: gravity },
        force: { value: force },
        isCircle: { value: shape === 'circle' },
        ishex: { value: shape === 'hex' },
        intensity: { value: intensity },
      },
      vertexShader: `
        attribute vec3 velocity;
        attribute vec3 initialForce;
        attribute vec3 color;
        attribute float rotation;
        uniform float time;
        uniform float size;
        uniform float duration;
        uniform float gravity;
        uniform float force;
        uniform float intensity;
        varying float vAlpha;
        varying vec3 vColor;
        varying float vRotation;

        void main() {
          // Calculate base position
          vec3 pos = position;
          
          // Apply explosive force and random velocity simultaneously
          pos += initialForce * force * time;
          pos += velocity * time * 2.0;
          
          // Apply gravity from the start
          pos.y += gravity * time * time * -0.5;
          
          vRotation = rotation;
          float progress = clamp(time / duration, 0.0, 1.0);
          vAlpha = (1.0 - progress) * intensity;
          vColor = color * intensity;
          
          gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
          gl_PointSize = size * (1.0 - progress * 0.5);
        }
      `,
      fragmentShader: `
        uniform bool isCircle;
        uniform bool ishex;
        varying vec3 vColor;
        varying float vAlpha;
        varying float vRotation;

        void main() {
          vec2 uv = gl_PointCoord;
          
          // Apply rotation for hex
          if (ishex) {
            vec2 center = vec2(0.5, 0.5);
            vec2 p = uv - center;
            float s = sin(vRotation);
            float c = cos(vRotation);
            p = vec2(p.x * c - p.y * s, p.x * s + p.y * c);
            uv = p + center;
          }

          if (isCircle) {
            // Circular particle
            vec2 center = uv - vec2(0.5);
            float dist = length(center);
            if (dist > 0.5) discard;
            float softness = 0.05;
            float alpha = smoothstep(0.5, 0.5 - softness, dist) * vAlpha;
            gl_FragColor = vec4(vColor, alpha);
          } else if (ishex) {
            // Triangular particle
            vec2 p = uv * 2.0 - 1.0;
            float d = max(
              abs(p.y) - 0.866, // sqrt(3)/2
              max(
                abs(p.x * 0.866 - p.y * 0.5) - 0.866,
                abs(p.x * 0.866 + p.y * 0.5) - 0.866
              )
            );
            if (d > 0.0) discard;
            float softness = 0.05;
            float alpha = (1.0 - smoothstep(-softness, 0.0, d)) * vAlpha;
            gl_FragColor = vec4(vColor, alpha);
          } else {
            // Square particle
            float softness = 0.05;
            float edge = min(min(uv.x, 1.0 - uv.x), min(uv.y, 1.0 - uv.y));
            float alpha = smoothstep(0.0, softness, edge) * vAlpha;
            gl_FragColor = vec4(vColor, alpha);
          }
        }
      `,
      transparent: true,
      depthWrite: false,
      blending: THREE.AdditiveBlending,
    })
  }, [size, duration, gravity, force, shape, intensity])

  useFrame(() => {
    if (mesh.current && mesh.current.material instanceof THREE.ShaderMaterial) {
      const elapsedTime = clock.current.getElapsedTime()
      mesh.current.material.uniforms.time.value = elapsedTime

      // Invoke onComplete when the explosion duration is reached
      if (elapsedTime >= duration / 1000) {
        onComplete()
      }
    }
  })

  useEffect(() => {
    const currentClock = clock.current
    currentClock.start()
    return () => {
      currentClock.stop()
    }
  }, [])

  return (
    <points
      ref={mesh}
      position={position}
      geometry={particleGeometry}
      material={particleMaterial}
    />
  )
}
