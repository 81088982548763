export const COMPONENTS = {
  panelTabHeader: 45,
  header: 64,
  footer: {
    main: 50,
  },
  tableRow: 50,
  rightPanel: 300,
  tabs: 40,
  mobileGameView: 280,
} as const
