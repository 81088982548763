import useRandomnessTypeStore from '@/store/useRandomnessTypeStore'
import { utils } from 'ethers'
import { useShallow } from 'zustand/react/shallow'
// import { useToast } from './useToast'

export const randomnessTypeToUint = {
  keccak: 0,
  vrf: 1,
}

export const encodedKeccak = '0x0000000000000000000000000000000000000000000000000000000000000000'
export const encodedVRF = '0x0000000000000000000000000000000000000000000000000000000000000001'

const useRandomnessType = () => {
  const { randomnessType, setRandomnessType } = useRandomnessTypeStore(useShallow(state => state))
  const [encodedRandomnessType, setEncodedRandomnessType] = useState('')

  useEffect(() => {
    setEncodedRandomnessType(
      utils.defaultAbiCoder.encode(['uint256'], [randomnessTypeToUint[randomnessType]])
    )
  }, [randomnessType])

  return {
    encodedRandomnessType,
    setEncodedRandomnessType,
    randomnessType,
    setRandomnessType,
  }
}

export default useRandomnessType
