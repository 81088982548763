import { createAuthApi } from '@/lib/http/auth'
import useAuthStore from '@/store/useAuthStore'
import { usePrevious } from 'react-use'

import { LOCAL_STORAGE } from '@/constants/storage'
import useWalletStore from '@/store/useWalletStore'
import { useWeb3 } from './useWeb3'
import { addAppNoti } from '@/store/useNotiStore'
import useUserDataStore from '@/store/useUserDataStore'

const useAuth = (shouldVerify = true) => {
  const { isActive, provider, account = '', disconnect } = useWeb3()
  const authApi = useMemo(() => createAuthApi(provider), [provider, isActive, account])
  const {
    authPublicAddress,
    setAuthPublicAddress,
    authTokenObj,
    addAuthToken,
    removeAuthToken,
    isAuthing,
    isVerifying,
    setIsAuthing,
    setIsVerifying,
  } = useAuthStore()
  const username = useUserDataStore.use.username?.()
  const setUsername = useUserDataStore.use.setUsername()
  const { setIsShowingWalletModal } = useWalletStore(state => ({
    setIsShowingWalletModal: state.setIsShowingWalletModal,
  }))

  const prevAuthTokenObj = usePrevious(authTokenObj)

  const openWalletModal = useCallback(() => setIsShowingWalletModal(true), [])
  const closeWalletModal = useCallback(() => setIsShowingWalletModal(false), [])

  const authToken = useMemo(
    () => authTokenObj[account],
    [authTokenObj, account, isAuthing, authPublicAddress]
  )

  const authenticate = useCallback(async () => {
    try {
      if (isAuthing || !provider || !account) return
      if (typeof window !== 'undefined') {
        if (typeof window.ethereum === 'undefined') {
          addAppNoti({
            msg: `Wallet Extension not installed`,
            type: 'error',
          })
          throw new Error(`Error no wallet extension`)
        }
      }
      setIsAuthing(true)
      if (provider && account) {
        const { publicAddress, authToken, username, hasEnteredInviteCode, inviteCodesRequested } =
          await authApi.authenticate(account)
        addAuthToken(authToken, account)
        setUsername(username)
        useAuthStore.setState({
          hasEnteredInviteCode,
          inviteCodesRequested,
          authPublicAddress: publicAddress,
        })

        // posthog?.identify(publicAddress, {
        //   username,
        // })
        // posthog?.group('bera-deathmatch', publicAddress)
      } else {
        throw new Error('Provider or account not defined')
      }
    } catch (error) {
      addAppNoti({
        msg: 'Error authenticating.',
        type: 'error',
      })
      console.error(error)
      throw new Error((error as Error).message)
    } finally {
      setIsAuthing(false)
    }
  }, [
    account,
    authApi,
    addAuthToken,
    setAuthPublicAddress,
    setIsAuthing,
    isAuthing,
    setUsername,
    provider,
  ])

  const deactivateWallet = useCallback(async () => {
    if (!isActive) return
    await disconnect()
  }, [isActive, disconnect])

  const logout = useCallback(async () => {
    try {
      await authApi.logout()
    } finally {
      removeAuthToken(account!)
      localStorage.removeItem(LOCAL_STORAGE.HAS_CONNECTED_PREV)
      await deactivateWallet()
      setAuthPublicAddress('')
      // location.reload()
      window.location.href = '/'
    }
  }, [authApi, removeAuthToken, setAuthPublicAddress, deactivateWallet, account])

  const verify = useCallback(async () => {
    try {
      if (!account) return
      const userAuthToken = authTokenObj[account]
      setIsVerifying(true)
      const resp = await authApi.verifyAuthToken(userAuthToken)
      if (resp?.isValid) {
        const { hasEnteredInviteCode, inviteCodesRequested, authenticatedPublicKey, username } =
          resp
        addAuthToken(resp.authToken || '', authenticatedPublicKey)

        setUsername(username)
        useAuthStore.setState({
          hasEnteredInviteCode,
          inviteCodesRequested,
          authPublicAddress: authenticatedPublicKey,
        })

        return resp
      } else {
        setAuthPublicAddress('')
        setUsername('')
      }
    } catch (error) {
      setAuthPublicAddress('')
      removeAuthToken(account!)
      console.warn('auth:', error)
      // return error
    } finally {
      setIsVerifying(false)
    }
  }, [
    authApi,
    removeAuthToken,
    // authToken,
    setAuthPublicAddress,
    setIsVerifying,
    setUsername,
    account,
  ])

  useEffect(() => {
    if (shouldVerify) verify()
  }, [account])

  return useMemo(
    () => ({
      authPublicAddress,
      setAuthPublicAddress,
      account,
      provider,
      authApi,
      verify,
      authenticate,
      logout,
      authTokenObj,
      prevAuthTokenObj,
      addAuthToken,
      removeAuthToken,
      isWeb3Active: isActive,
      isAuthed: Boolean(authTokenObj), // TODO: Need to refactor how we determine someone is authed
      isAuthing,
      isVerifying,
      username,
      setUsername,
      openWalletModal,
      closeWalletModal,
      authToken,
    }),
    [
      authPublicAddress,
      setAuthPublicAddress,
      isActive,
      authApi,
      authTokenObj,
      authenticate,
      logout,
      prevAuthTokenObj,
      addAuthToken,
      removeAuthToken,
      verify,
      provider,
      account,
      isAuthing,
      isVerifying,
      username,
      setUsername,
      openWalletModal,
      closeWalletModal,
      authToken,
    ]
  )
}
export default useAuth
