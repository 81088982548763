import { motion } from 'framer-motion-3d'
import { useGLTF } from '@react-three/drei'
import { type GroupProps } from '@react-three/fiber'
import { type Group } from 'three'
import {
  useAnimationControls,
  type TargetAndTransition,
  type AnimationDefinition,
} from 'framer-motion'
import { delay } from '@/utils'
import { useDebouncedCallback } from 'use-debounce'
import { sendGameStoreUpdate, useCoinFlipGameState } from '@/store/useGameStateStore'
import { GameAction } from '@fare/sdk/state'
import { Coin, type CoinState } from './Coin'
import { entryEvent } from '@/events/entryEvent'
import { useGameOutcomeStore } from '@/store/useGameOutcomeStore'
import { set } from 'immer/dist/internal'
import { gameProxy } from '@/store/proxy/gameProxy'

const gltfFile = '/glb/coin-animated.glb'

interface ICoinModel {
  coinIdx: number
  cols?: number
  gap?: {
    x: number
    y: number
  }
  side: number
  hasHitLimit: boolean
  gameState: GameAction
  resultSide: number | undefined
  resultDeltaAmount: number | undefined
  playedCount: number
  isWinningOverall: boolean
  intensity: number
  playGameSound: (soundName: string, volume?: number, pitch?: number) => void
  setSide: (side: 0 | 1) => void
  totalCoins: number
}

const coinVariants: { [key: string]: TargetAndTransition } = {
  initial: {
    scale: 0,
  },
  animate: {
    scale: 1,
    transition: {
      type: 'spring',
      bounce: 0.25,
    },
  },
  exit: {
    scale: 0,
  },
}

const { PI } = Math
const PI2 = PI * 2

export const RefactorCoinModel = ({
  coinIdx,
  gap = { x: 4.5, y: 4.25 },
  cols = 5,
  gameState,
  side,
  hasHitLimit,
  resultSide,
  resultDeltaAmount = 0,
  playedCount,
  isWinningOverall,
  intensity,
  playGameSound,
  setSide,
  totalCoins,
  ...props
}: GroupProps & ICoinModel) => {
  const isInitialized = useRef(false)
  const coinRef = useRef<Group>(null)
  const controls = useAnimationControls()
  const [isLimitReached, setIsLimitReached] = useState(false)
  const [isLose, setIsLose] = useState(false)
  const [isWin, setIsWin] = useState(false)

  const setBorderActive = useGameOutcomeStore(state => state.setIsShowingOutcome)
  const setPlayerWon = useGameOutcomeStore(state => state.setDidPlayerWin)
  const setWinIntensity = useGameOutcomeStore(state => state.setIntensity)

  const { posX, posY } = useMemo(
    () => ({
      posX: (coinIdx % cols) * gap.x,
      posY: Math.trunc(coinIdx / cols) * -gap.y,
    }),
    [coinIdx, gap, cols]
  )

  const coinState = useMemo(() => {
    let _state: CoinState = 'idle'
    if (isLimitReached) {
      _state = 'isOverLimit'
    } else if (isLose) {
      _state = 'hasLost'
    } else if (isWin) {
      _state = 'hasWon'
    }
    return _state
  }, [isLose, isLimitReached, isWin])

  /* Callbacks */
  const onClickCoin = useCallback(async () => {
    // @ TODO - Add sound effect here
    playGameSound('coinModeSelectClick', 0.0075, 0.3)
    playGameSound(totalCoins > 1 ? 'coinModeSelectMulti' : 'coinModeSelectSingle', 0.015, 1)
    if (gameState !== 'IDLE') return
    setSide(side ? 0 : 1)
  }, [gameState, side, totalCoins, playGameSound])

  const animateCoinIn = useDebouncedCallback(async (coinSide: number) => {
    if (!coinRef.current) return

    // controls.stop()
    const animDef: AnimationDefinition = {
      scale: [0, 1],
      x: posX,
      y: posY,
      transition: {
        x: {
          type: 'tween',
          duration: 0.4,
          ease: 'easeOut',
        },
        y: {
          type: 'tween',
          duration: 0.4,
          ease: 'easeOut',
        },
        z: {
          type: 'tween',
          duration: 0.5,
          ease: 'easeIn',
        },
        scale: {
          type: 'spring',
          bounce: 0.25,
          duration: 0.5,
        },
        rotateY: {
          type: 'spring',
          bounce: 0.5,
          duration: 1,
        },
      },
    }

    if (coinIdx === 0) {
      coinRef.current.rotation.y = coinSide ? 0 : PI
      coinRef.current.scale.set(0, 0, 0)
      animDef.rotateY = coinSide * PI
    } else {
      coinRef.current.rotation.y = coinSide ? PI : 0
      coinRef.current.scale.set(0, 0, 0)
      animDef.z = [-2, 0]
      animDef.rotateY = coinSide * PI
    }

    await controls.start(animDef)

    isInitialized.current = true
  }, 10)

  const animateFlipSide = useCallback(
    async (side: number) => {
      if (!isInitialized.current) return
      controls.stop()
      await controls.start({
        rotateY: side ? PI : 0,
        scale: 1,
        transition: {
          scale: {
            type: 'tween',
            ease: 'linear',
            duration: 0.2,
          },
          rotateY: {
            type: 'spring',
            bounce: 0.25,
            duration: 0.35,
          },
        },
      })
    },
    [controls]
  )

  const resetCoin = useCallback(
    async (resetSide: number) => {
      setIsLimitReached(false)
      setIsLose(false)
      setIsWin(false)

      // Deactivate Border
      setBorderActive(false)
      setWinIntensity(1)

      controls.stop()
      await controls.start({
        rotateY: resetSide ? PI : 0,
        scale: 1,
        transition: {
          rotateY: {
            type: 'tween',
            ease: 'linear',
            duration: 0.35,
          },
          scale: {
            type: 'tween',
            ease: 'linear',
            duration: 0.35,
          },
        },
      })

      if (coinIdx === 0) {
        sendGameStoreUpdate('coin-flip')({ type: 'IDLE', payload: {} })
      }
    },
    [controls]
  )

  const errorCoin = useCallback((errMsg: string) => {
    console.log(errMsg)
    sendGameStoreUpdate('coin-flip')({ type: 'RESET', payload: {} })
  }, [])

  const animateStartCruising = useCallback(async () => {
    if (!coinRef.current) return
    const initRotY = side ? PI : 0

    await controls.start({
      scale: [1, 0.4, 1],
      rotateY: [initRotY, initRotY, initRotY + PI2], // 4 full rotations (2*PI per rotation)
      transition: {
        scale: {
          type: 'tween',
          ease: 'circOut',
          duration: 0.5,
        },
        rotateY: {
          type: 'tween',
          ease: 'easeOut',
          duration: 0.5,
        },
      },
    })

    await controls.start({
      rotateY: [initRotY, initRotY + PI, initRotY + PI2],
      transition: {
        rotateY: {
          // delay: 0.25,
          repeat: Infinity,
          repeatType: 'loop', // Use 'loop' for continuous rotation without reversing
          type: 'tween',
          duration: 0.35,
          ease: 'linear', // Optional, to keep a consistent speed throughout the animation
        },
      },
    })
  }, [controls, side])

  const winAnimation = useCallback(async () => {
    setIsWin(true)
    // play sfx here
    console.log('DEBUG Attempting to play sfx in winAnimation')
    console.log(`DEBUG totalCoins ${totalCoins}`)
    playGameSound('win', 0.3, 0.8)
    setBorderActive(true)
    setPlayerWon(isWinningOverall)
    setWinIntensity(intensity)
  }, [intensity, isWinningOverall, playGameSound])

  const loseAnimation = useCallback(
    async (side: number) => {
      if (totalCoins === 1) {
        console.log(`DEBUG totalCoins ${totalCoins}`)
        playGameSound('lose', 0.2, 1)
      }
      await controls.start({
        scale: 0.7,
        transition: {
          type: 'tween',
          ease: 'linear',
          duration: 0.1,
        },
      })
    },
    [controls, playGameSound, totalCoins]
  )

  const animateResolveSide = useCallback(
    async (
      side: number,
      userSide: number,
      idx: number,
      playedCount: number,
      deltaAmount: number
    ) => {
      if (!coinRef.current) return

      let delayTime = coinIdx * 200
      if (coinIdx + 1 > playedCount) {
        delayTime = playedCount * 200
      }
      await delay(delayTime)

      const rotY = coinRef.current.rotation.y
      const currentRotation = Math.abs(rotY % PI2)
      const fullRotations = Math.floor(currentRotation / PI2) * PI2 // This is the amount of full rotations (in radians) the coin has already made.
      const rotationRemainder = currentRotation % PI2 // This is the amount of rotation after removing full rotations.

      let targetRotation: number

      if (side) {
        // Tail side
        if (rotationRemainder <= PI) {
          targetRotation = rotationRemainder + (PI - rotationRemainder)
        } else {
          targetRotation = rotationRemainder + PI2 + (PI - rotationRemainder)
        }
      } else {
        // Head side
        if (rotationRemainder >= 0 && rotationRemainder < PI) {
          targetRotation = rotationRemainder + PI2 - rotationRemainder
        } else {
          targetRotation = rotationRemainder + (PI2 - rotationRemainder)
        }
      }

      targetRotation += fullRotations // Add back the full rotations to maintain the overall rotation.

      const rotationDiff = Math.abs(targetRotation - currentRotation)
      const duration = (rotationDiff / PI2) * 0.35
      if (coinIdx + 1 > playedCount) {
        // duration *= 0.5
      }

      await controls
        .start({
          ...coinVariants.animate,
          rotateY: [currentRotation, targetRotation],
          transition: {
            type: 'tween',
            ease: 'linear',
            duration,
          },
        })
        .then(() => {
          if (gameProxy.pathGameName !== 'coinFlip') return
          // @NOTE: Handles the delta amount text to render according to the stopLoss stopGain cases
          if (playedCount > idx) {
            entryEvent.pub('entryFinished', { deltaAmount, delaySecs: 0.2 })
            if (playedCount - 1 === idx) {
              entryEvent.pub('updateBalance')
              setTimeout(() => {
                if (gameProxy.pathGameName !== 'coinFlip') return
                entryEvent.pub('gameFinished', { lingerMs: 3_000 })
              }, 2_500)
            }
          }
        })

      if (coinIdx + 1 > playedCount) {
        await controls.start({
          scale: 0.7,
          transition: {
            type: 'tween',
            ease: 'linear',
            duration: 0.1,
          },
        })
        return
      }

      if (side === userSide) {
        winAnimation()
      } else {
        loseAnimation(side)
      }

      return duration
    },
    [controls, winAnimation, loseAnimation]
  )

  useEffect(() => {
    if (!isInitialized.current) {
      animateCoinIn(side)
      return
    }
  }, [animateCoinIn, controls, side])

  useEffect(() => {
    if (isInitialized.current) {
      animateFlipSide(side)
    }
  }, [side])

  useEffect(() => {
    if (!coinRef.current) return

    switch (gameState) {
      case 'IDLE':
        if (!isInitialized.current) {
          return
        } else {
          // animateFlipSide(side)
        }
        break
      case 'START':
        animateStartCruising()
        break
      case 'RESOLVE':
        if (typeof resultSide !== 'number') return
        if (typeof side === 'number' && typeof playedCount === 'number') {
          animateResolveSide(resultSide, side, coinIdx, playedCount, resultDeltaAmount)
        } else {
          console.error('Error inside of RESOLVE')
        }
        break
      case 'RESET':
        resetCoin(side)
        break
      case 'ERROR':
        const errorMsg = useCoinFlipGameState.getState().errMsg
        console.log(errorMsg)
        errorCoin(errorMsg)
        break
      default:
        break
    }
  }, [gameState])

  useEffect(() => {
    if (gameState === 'RESOLVE') {
      if (typeof playedCount !== 'number') return console.warn('playCount is not a number')
      if (coinIdx + 1 > playedCount) {
        setTimeout(() => {
          setIsLimitReached(true)
        }, playedCount * 200)
      } else {
        setTimeout(() => {
          setIsLose(resultSide !== side)
        }, coinIdx * 200)
      }
    }
  }, [gameState])

  return (
    <motion.group
      animate={controls}
      ref={coinRef as any}
      onClick={onClickCoin}
      rotation={[0, 0, 0]}
      scale={0}
      onPointerOver={e => {
        e.stopPropagation()
        document.body.style.cursor = 'pointer'
      }}
      onPointerOut={_e => {
        document.body.style.cursor = 'auto'
      }}
      {...(props as any)}
    >
      <Coin coinState={coinState} />
    </motion.group>
  )
}

useGLTF.preload(gltfFile)
