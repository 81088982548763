#include <fog_pars_fragment>
uniform vec2 uResolution;
uniform float uTime;
uniform float uGridSpacing;
varying vec2 vUv;
varying vec3 vVertexWorldPosition;

vec3 hsv2rgb(vec3 c) {
    vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
    vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
    return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
}

// void main() {
// 	vec2 coord = vVertexWorldPosition.xz;

//     // Modify coord with uTime to create the moving effect
//     // coord.y += uTime * 1.; // Adjust the 0.1 to control the speed of movement

// 	vec2 grid = abs(fract(coord - 0.5) - 0.5) / fwidth(coord);
// 	float line = min(grid.x, grid.y);

// 	float color = 1.0 - min(line, 1.0);
// 	color = pow(color, 1.0 / 2.2);

// 	float alpha = .35;
// 	// if (color <= .15) {
// 	// 	alpha = 0.;
// 	// }

//     float y = floor(coord.y * 10.0) / 10.0;
//   float x = floor(coord.x * 10.0) / 10.0;

//     float n = 0.0;
//     n += sin(x * 3.141592 + uTime * 0.5) * 0.5 + 0.5;
//     n += sin(y * 3.141592 + uTime * 0.5) * 0.5 + 0.5;

//     vec3 newColor = hsv2rgb(vec3(fract(uTime * 0.1), 1.0, n)) * color;

// 	  gl_FragColor = vec4(vec3(newColor), alpha);
//     #include <fog_fragment>
// }

void main() {
	vec2 coord = vVertexWorldPosition.xz;

    // Modify coord with uTime to create the moving effect
    // coord.y += uTime * 1.; // Adjust the 0.1 to control the speed of movement

	vec2 grid = abs(fract(coord - 1.0) - 1.0) / fwidth(coord);
	float line = min(grid.x, grid.y);

	float color = 1.0 - min(line, 1.0);
	color = pow(color, 1.0 / 2.2);

  # // vec4 final_color = vec4(vec3(0.01), 1.);
  vec4 final_color = vec4(vec3(1), 0.0034);
	if(color <= .01) {
    final_color = vec4(0., 0., 0., 0.);    
  }

	// gl_FragColor = vec4(vec3(color), alpha);
	gl_FragColor = final_color;
    #include <fog_fragment>
}

// https://madebyevan.com/shaders/grid/
// Circles
// void main() {
//   // Pick a coordinate to visualize in a grid
// 	float coord = length(vVertexWorldPosition.xz);

//   // Compute anti-aliased world-space grid lines
// 	float line = abs(fract(coord - 0.5) - 0.5) / fwidth(coord);

//   // Just visualize the grid lines directly
// 	float color = 1.0 - min(line, 1.0);

//   // Apply gamma correction
// 	color = pow(color, 1.0 / 2.2);
// 	gl_FragColor = vec4(vec3(color), 1.0);

//     #include <fog_fragment>
// }
