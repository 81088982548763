import React, { useCallback } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { styled } from 'styled-components'
import { BORDER_COLORS } from '@/design'
import { FareNumberInputNew } from '@/components/shared/Input/FareNumberInputNew'
import {
  FormAmountLabel,
  FormFooter,
  FormIndicator,
  FormLabel,
  FormLabelRow,
  FormWrapper,
  SFormSection,
} from '@/components/Forms/style'
import { SVGS } from '@/assets'
import numeral from 'numeral'
import useCurrencyStore from '@/store/useCurrencyStore'
import { useShallow } from 'zustand/react/shallow'
import CountUp from 'react-countup'
import { useCrashGameStore } from '@/store/useCrashGameStore'
import { GameButton } from '@/components/shared/Button/GameButton'
import { FormLayout } from '@/components/Forms/FormComponents/shared/FormLayout'

const SForm = styled.form`
  border: 1px solid ${BORDER_COLORS.one};
  backdrop-filter: blur(2px);
`

interface CrashFormData {
  side: number
  entryAmount: number
  numberOfEntries: number
  stopLoss: number
  stopGain: number
  autoCashoutMultiplier: number
}

const CrashFormComponent: React.FC = () => {
  const { control, handleSubmit, watch } = useForm<CrashFormData>({
    defaultValues: {
      side: 200,
      entryAmount: 0,
      numberOfEntries: 1,
      stopLoss: 0,
      stopGain: 0,
      autoCashoutMultiplier: 2,
    },
  })
  const formData = watch()

  // Watch only necessary fields to minimize re-renders
  const entryAmount = watch('entryAmount')
  const autoCashoutMultiplier = watch('autoCashoutMultiplier')

  const { selectedBalance } = useCurrencyStore(
    useShallow(state => ({
      selectedBalance: state.balances.currency,
    }))
  )

  const { startGame, gameState, setAutoCashoutMultiplier, setEntryAmount } = useCrashGameStore()

  // Stabilize the onSubmit handler
  const onSubmit = useCallback(
    (data: Partial<CrashFormData>) => {
      if (gameState === 'IDLE') {
        setAutoCashoutMultiplier(data.autoCashoutMultiplier!)
        setEntryAmount(data.entryAmount!)
        startGame()
      }
    },
    [gameState, startGame, setAutoCashoutMultiplier, setEntryAmount]
  )

  const handleClick = useCallback(() => {
    const formData = {
      entryAmount,
      autoCashoutMultiplier,
    }
    onSubmit(formData)
  }, [onSubmit, entryAmount, autoCashoutMultiplier])

  return (
    // <SForm onSubmit={handleSubmit(onSubmit)}>
    <FormWrapper>
      <FormLayout>
        {/* <SForm onSubmit={() => {}}> */}
        <SFormSection>
          <FormLabelRow>
            <FormLabel>Amount</FormLabel>
            <FormLabel>
              <FormIndicator $isActive={entryAmount > 0} />
              <FormAmountLabel>
                {Number(selectedBalance) > 0 && (
                  <CountUp
                    end={Number(selectedBalance)}
                    decimals={2}
                    duration={2}
                    separator=','
                    preserveValue
                  />
                )}
              </FormAmountLabel>
            </FormLabel>
          </FormLabelRow>
          <Controller
            name='entryAmount'
            control={control}
            rules={{ required: true, min: 1, max: Number(selectedBalance) }}
            render={({ field }) => (
              <FareNumberInputNew
                {...field}
                onChange={event => field.onChange(numeral(event.target.value).value())}
                allowLeadingZeros={false}
                allowNegative={false}
                thousandSeparator=','
                inputSuffix={<img src={SVGS.usdcIcon} width={20} />}
                hasInputSlider
                inputSliderProps={{
                  value: field.value,
                  onChange: sliderValue => field.onChange(sliderValue),
                  min: 1,
                  max: 1000,
                  step: 1,
                }}
              />
            )}
          />
        </SFormSection>

        <SFormSection>
          <FormLabelRow>
            <FormIndicator
              $isActive={autoCashoutMultiplier > 1.01 && autoCashoutMultiplier <= 500}
            />
            <FormLabel>Cashout Multiplier</FormLabel>
          </FormLabelRow>
          <Controller
            name='autoCashoutMultiplier'
            control={control}
            rules={{ required: true, min: 2, max: 500 }}
            render={({ field }) => (
              <FareNumberInputNew
                {...field}
                onChange={event => {
                  const value = numeral(event.target.value).value() ?? 0
                  field.onChange(value)
                  setAutoCashoutMultiplier(value)
                }}
                allowLeadingZeros={false}
                allowNegative={false}
                decimalScale={2}
                hasInputSlider
                inputSliderProps={{
                  value: field.value,
                  onChange: sliderValue => {
                    field.onChange(sliderValue)
                    setAutoCashoutMultiplier(sliderValue)
                  },
                  min: 1.01,
                  max: 500,
                  step: 0.01,
                }}
              />
            )}
          />
        </SFormSection>
        {/* </SForm> */}
      </FormLayout>
      <FormFooter>
        <GameButton
          formData={{
            ...formData,
            side: Math.round(formData.autoCashoutMultiplier * 100),
          }}
          entryAmountNum={entryAmount}
        />
      </FormFooter>
    </FormWrapper>
  )
}

export const CrashForm = memo(CrashFormComponent)
