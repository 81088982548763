import { FARE_COLORS } from '@/design'

export const AvatarWrapper = styled.div<{
  $borderColor?: string
  $size?: string
  $hasBorder?: boolean
}>`
  display: grid;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  align-items: center;

  border: ${({ $borderColor, $hasBorder }) =>
    $hasBorder && $borderColor ? `2px solid #${$borderColor}80` : undefined};
  margin: auto 0;

  img {
    border-radius: 50%;
  }

  ${({ $size }) => {
    if ($size === 'sm') {
      return css`
        width: 22px;
        height: 22px;

        img {
          height: 16px;
          width: 16px;
        }
      `
    } else if ($size === 'md') {
      return css`
        width: 32px;
        height: 32px;

        img {
          height: 24px;
          width: 24px;
        }
      `
    } else if ($size === 'lg') {
      return css`
        width: 48px;
        height: 48px;

        img {
          height: 36px;
          width: 36px;
        }
      `
    }
  }}
`
