import { useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import { useGameOutcomeStore } from '@/store/useGameOutcomeStore'
import { SBorderAnimator } from '../shared/BorderFlash'
import { BombsGame } from './BombsGame'
import { SVolumeSliderWrapper, VolumeSlider } from '@/components/shared/SoundSystem/VolumeSlider'
import OddsDisplay from './BombsPayoutText'
import { BombsLighting } from './BombsLighting'
import { Bloom, EffectComposer } from '@react-three/postprocessing'
import { GameGrid } from '@/components/shared/GameGrid/GameGrid'
import { AppGameName } from '@/chains/types'
import { DeltaNumbersDisplay } from '@/components/DeltaNumbersDisplay'
import { ResizingWrapper, SCanvas } from '../styles'
import { useCanvasResize } from '@/hooks/useCanvasResize'
import { type ICanvas } from '../shared/mountedInterface'
import { LoadingChip } from '@/components/shared/LoadingChip'

export const BombsCanvas = ({ isMounted, setIsMounted }: ICanvas) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const { canvasSize, isResizing } = useCanvasResize(containerRef, canvasRef)
  const isShowingOutcome = useGameOutcomeStore(state => state.isShowingOutcome)
  const didPlayerWin = useGameOutcomeStore(state => state.didPlayerWin)
  const intensity = useGameOutcomeStore(state => state.intensity)

  return (
    <SCanvas ref={containerRef} style={{ opacity: isMounted ? 1 : 0 }}>
      <SBorderAnimator active={isShowingOutcome} didWin={didPlayerWin} intensity={intensity}>
        <DeltaNumbersDisplay gameName={AppGameName.Bombs} />
        <Canvas
          className='bombs-canvas'
          ref={canvasRef}
          key={`${canvasSize.width}-${canvasSize.height}`}
          shadows
          dpr={[1, 2]}
          gl={{ antialias: true, stencil: false, preserveDrawingBuffer: false, alpha: true }}
          camera={{ position: [0, 8, 5], fov: 45 }}
          onCreated={_state => {
            setTimeout(() => {
              setIsMounted(true)
            }, 600)
          }}
        >
          <fog attach='fog' args={['#000000', 5, 25]} />
          <EffectComposer>
            <Bloom luminanceThreshold={0.15} luminanceSmoothing={3} intensity={0.5} />
          </EffectComposer>
          <BombsLighting />
          <BombsGame />
          <GameGrid
            position={[0, -0.75, 0]}
            size={[50, 50]}
            color='#000000'
            emissive='#201e1e'
            emissiveIntensity={1}
            opacity={0.8}
            gridSpacing={1.5}
          />
          <OddsDisplay />
        </Canvas>
        <SVolumeSliderWrapper>
          <VolumeSlider />
        </SVolumeSliderWrapper>
      </SBorderAnimator>
      {isResizing && (
        <ResizingWrapper>
          <LoadingChip width={56} height={56} />
        </ResizingWrapper>
      )}
    </SCanvas>
  )
}
