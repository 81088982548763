import { USDCContractInterface } from '@/lib/crypto'
import useCurrencyStore from '@/store/useCurrencyStore'
import { addAppNoti } from '@/store/useNotiStore'
import { BigNumberish, utils } from 'ethers'
import { decodeError } from 'ethers-decode-error'
import { useAppChainConfig } from '@/hooks/useAppChainConfig'
import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'
import { useActiveWallet } from '@/lib/privy/hooks'

// @NOTE: useCurrencyContracts currently only fetches eth or usdc balances.
// This will need to be changed when moving to multicurrency or FARE model
type FetchableCurrencyType = 'native' | 'currency'

export const useNetworkStyle = () => useAppChainConfigStore.use.appChainConfig().networkStyle

export const useCurrency = () => {
  const { walletAddress } = useActiveWallet()
  const {
    appAddresses,
    appContracts,
    networkStyle,
    appChainDefinition,
    appProvider: provider,
  } = useAppChainConfig()
  const currencyStore = useCurrencyStore()

  const fetchBalance = async (address: string, currencyType: FetchableCurrencyType) => {
    if (!address || !appContracts || !provider) return

    try {
      switch (currencyType) {
        case 'native':
          const ethBalance = await provider.getBalance(address)
          if (!ethBalance) return console.warn('There was an issue fetching ethBalance')
          return utils.formatUnits(ethBalance, appChainDefinition.nativeCurrency.decimals)
        case 'currency':
          const usdcBalance = await appContracts.ws.currency.balanceOf(address)
          if (!usdcBalance) return console.warn('There was an issue fetching usdcBalance')
          return utils.formatUnits(usdcBalance, networkStyle.decimals)
        default:
          console.warn('Invalid currency type passed to fetchUpdateBalance')
          break
      }
    } catch (err) {
      console.warn('Issue with fetchBalance:', err)
      return '0'
    }
  }

  const fetchAllowance = async (ownerAddress: string, spenderAddress: string) => {
    if (!ownerAddress || !spenderAddress || !appContracts) return '0'

    try {
      const bnAllowance = await appContracts.currency.allowance(ownerAddress, appAddresses.bankroll)
      if (!bnAllowance) {
        console.warn('There was an issue fetching allowance')
        return '0'
      }

      return utils.formatUnits(bnAllowance, networkStyle.decimals)
    } catch (err) {
      console.warn('Issue with fetchBalance:', err)
      return '0'
    }
  }

  const fetchAndSetAllowance = async (...args: Parameters<typeof fetchAllowance>) => {
    try {
      const allowance = await fetchAllowance(...args)
      currencyStore.setAllowance('currency', allowance)
    } catch (err) {
      console.warn(err)
      throw new Error(err as any)
    }
  }

  const approveAllowance = async (spenderAddress: string, amount: BigNumberish) => {
    try {
      if (!appContracts) throw new Error(`ERC20 contracts are not defined`)
      if (!walletAddress) throw new Error('There was an issue getting the public address')

      currencyStore.setIsApprovingAllowance(true)

      const approveTx = await appContracts.currency.approve(
        spenderAddress,
        utils.parseUnits(String(amount) || '1000', networkStyle.decimals)
      )

      const receipt = await approveTx.wait()

      return receipt
    } catch (err) {
      // @TODO: Need to display error to user here
      console.error(err)
      // @NOTE: Package found from the following discussion: https://github.com/ethers-io/ethers.js/discussions/3027
      // @NOTE: Still problematic to decode AA related stuff (as expected, because we receive an error from our POST request rather than an error from the geth node), like it will not give the custom error but give something like: "Buffer is not defined"
      // @NOTE: Maybe it might be a good idea to divide eoa and aa error handling?
      const decodedError = decodeError(err, USDCContractInterface)
      console.log('decoded error: ', decodedError)
      addAppNoti({
        msg: decodedError.error,
        type: 'error',
      })
      throw new Error(`Error approving allowance`)
    } finally {
      currencyStore.setIsApprovingAllowance(false)
    }
  }

  return {
    networkStyle,
    currencyContract: appContracts?.currency,
    currencyContractWs: appContracts?.ws.currency,
    fetchBalance,
    fetchAllowance,
    approveAllowance,
    fetchAndSetAllowance,
    ...currencyStore,
  }
}
