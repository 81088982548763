import { create } from 'zustand'
import { TextureLoader } from 'three'
export const useTextureCacheStore = create((set, getState: any) => ({
  fareGradientTexture: null,
  fareWhiteTexture: null,
  fareFrameGradientTexture: null,
  fareFrameWhiteTexture: null,
  loadTextures: async () => {
    if (getState().fareGradientTexture && getState().fareWhiteTexture) return
    const textureLoader = new TextureLoader()
    const fareGradientTexture = await textureLoader.loadAsync(
      '/glb/textures/fare-dice-gradient.png'
    )
    const fareWhiteTexture = await textureLoader.loadAsync('/glb/textures/fare-dice-white.png')
    fareGradientTexture.flipY = false
    fareWhiteTexture.flipY = false

    set({
      fareGradientTexture,
      fareWhiteTexture,
    })
  },
}))
