import React, {
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useEffect,
  type MutableRefObject,
} from 'react'
import { styled, css } from 'styled-components'
import { NumericFormat, type NumericFormatProps } from 'react-number-format'
import { BACKGROUND_COLORS, BORDER_COLORS, FARE_COLORS, TEXT_COLORS } from '@/design/colors'
import { SPACING } from '@/design/spacing'
import ReactSlider, { type ReactSliderProps } from 'react-slider'
import { useSound } from '../../shared/SoundSystem/SoundContext'

const Suffix = styled.div`
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  align-items: stretch;
  justify-content: center;
  margin-right: ${SPACING.sm}px;
`

const Prefix = styled.div`
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  display: flex;
  align-items: stretch;
  justify-content: center;
  margin-left: ${SPACING.xs}px;
`

const BaseInput = styled.input<{ $hasPrefix?: boolean; $hasSuffix?: boolean }>`
  background: ${BACKGROUND_COLORS.one};
  border: 1px solid ${BORDER_COLORS.one};
  color: ${TEXT_COLORS.one};
  border-radius: 6px;
  padding: ${SPACING.sm}px;
  width: calc(100% - ${SPACING.sm * 2}px); // Corrected interpolation for spacing
  display: block;
  font-size: 14px !important;
  transition:
    border ease-in-out 0.18s,
    background ease-in-out 0.18s;

  ${({ $hasPrefix }) =>
    $hasPrefix &&
    css`
      padding-left: ${SPACING.xl}px;
    `}

  ${({ $hasSuffix }) =>
    $hasSuffix &&
    css`
      padding-right: ${SPACING.sm}px;
    `}

   &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  &:focus {
    outline: none;
    border: 1px solid ${FARE_COLORS.aqua};
    background: #010e0c;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    user-select: none;
  }
`

const InputWrapper = styled.div<{ $hasPrefix?: boolean; $hasSuffix?: boolean }>`
  display: flex;
  position: relative;

  // Modify sibling selector if DOM hierarchy isn't correct
  #input-num-format-new:focus-within ~ #fare-input-slider-wrapper-new {
    .thumb-0 {
      background: #428375;
    }
    .track-0 {
      border-top: 1px solid ${FARE_COLORS.aqua};
    }
    .track-1 {
      border-top: 1px solid #1b1d26;
      border-bottom: 1px solid ${FARE_COLORS.aqua};
    }
  }

  &:has(#fare-input-slider-wrapper-new > .fare-input-slider-new:active) {
    #input-num-format-new {
      outline: none;
      border: 1px solid ${FARE_COLORS.aqua};
      background: #010e0c;
    }
  }
`

const SReactSliderWrapper = styled.div<{ $disabled?: boolean }>`
  height: 18px;
  width: 100%;
  position: absolute;
  bottom: -6px;
  ${props =>
    props.$disabled &&
    css`
      pointer-events: none;
    `}
  .fare-input-slider-new {
    cursor: pointer;
    width: calc(100% - 1.5px);
    height: 18px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .track-0 {
      transition:
        background ease-out 0.16s,
        border-top ease-out 0.16s;
      height: 4px;
      background: ${FARE_COLORS.aqua}50;
      border-top: 1px solid transparent;
      border-bottom-left-radius: 6px;
      border-bottom: 1px solid transparent;
      overflow: hidden;
    }

    .track-1 {
      transition:
        background ease-out 0.16s,
        border-top ease-out 0.16s;
      height: 4px;
      background: #1b1d26;
      border-bottom-right-radius: 6px;
      border-top: 1px solid ${BORDER_COLORS.one};
      border-bottom: 1px solid transparent;
    }

    &:active {
      cursor: grabbing !important;
      .track-0 {
        border-top: 1px solid ${FARE_COLORS.aqua};
        border-bottom: 1px solid ${FARE_COLORS.aqua};
      }
      .track-1 {
        border-bottom: 1px solid ${FARE_COLORS.aqua};
      }
      .thumb-0 {
        background: #428375;
      }
    }
  }
`

const SSliderThumb = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 2px;
  border: 1px solid ${FARE_COLORS.aqua};
  background: ${FARE_COLORS.aqua}50;
  transition: background ease-out 0.16s;
  backdrop-filter: blur(1px);
  &:active {
    background: #428375;
  }
  &:focus {
    outline: none;
  }
`

const Thumb = (props: any, _state: any) => <SSliderThumb {...props} />

interface IFareNumberInputNew extends Omit<NumericFormatProps, 'getInputRef'> {
  inputPrefix?: JSX.Element
  inputSuffix?: JSX.Element
  customRef?: React.MutableRefObject<HTMLInputElement | null>
  hasInputSlider?: boolean
  inputSliderProps?: ReactSliderProps & {
    totalSteps?: number
  }
}

export const FareNumberInputNew = forwardRef<HTMLInputElement, IFareNumberInputNew>(
  (
    { inputPrefix, inputSuffix, customRef, hasInputSlider = false, inputSliderProps, ...props },
    ref
  ) => {
    const inputRef: MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement>(null)
    const { loadSound, playSound } = useSound()
    const lastPlayTime = useRef(0)
    const lastValue = useRef(inputSliderProps?.defaultValue ?? 0)
    const reachedMinRef = useRef(false)
    const reachedMaxRef = useRef(false)

    const hasPrefix = useMemo(() => Boolean(inputPrefix), [inputPrefix])
    const hasSuffix = useMemo(() => Boolean(inputSuffix), [inputSuffix])

    useEffect(() => {
      loadSound('clickSound2', '/src/assets/audio/bombs-mouse-over.wav')
      loadSound('mouseDownSound', '/src/assets/audio/Click 11.wav')
      loadSound('maxSound', '/src/assets/audio/mouse-over-slider.wav')
      loadSound('minSound', '/src/assets/audio/mouse-over-slider.wav')
    }, [loadSound])

    const setInputRef = useCallback(
      (elem: HTMLInputElement | null) => {
        inputRef.current = elem
        if (customRef && typeof customRef !== 'function') {
          customRef.current = elem // Safe to assign since customRef is MutableRefObject
        }
        if (typeof ref === 'function') {
          ref(elem)
        } else if (ref) {
          ;(ref as MutableRefObject<HTMLInputElement | null>).current = elem
        }
      },
      [customRef, ref]
    )

    const onFocus: React.FocusEventHandler<HTMLInputElement> = useCallback(
      event => event.target.select(),
      []
    )

    const onEnterBlur: React.KeyboardEventHandler<HTMLInputElement> = useCallback(event => {
      if (event.key === 'Enter') {
        event.currentTarget.blur()
      }
    }, [])

    const handleSliderChange = useCallback(
      (value: number | readonly number[], index: number) => {
        const now = Date.now()
        const timeSinceLastPlay = now - lastPlayTime.current

        if (timeSinceLastPlay < 50) return // Throttle to max 20 plays per second

        const min = inputSliderProps?.min ?? 0
        const max = inputSliderProps?.max ?? 100

        // Ensure value is a number
        const numericValue = Array.isArray(value) ? value[0] : value

        // Check if we've reached the min or max
        if (numericValue === min && !reachedMinRef.current) {
          playSound('minSound', 0.1, 0.1)
          reachedMinRef.current = true
          reachedMaxRef.current = false
        } else if (numericValue === max && !reachedMaxRef.current) {
          playSound('maxSound', 0.1, 0.1)
          reachedMaxRef.current = true
          reachedMinRef.current = false
        } else if (numericValue !== min && numericValue !== max) {
          const valueChange = Math.abs(numericValue - lastValue.current)
          const speed = valueChange / timeSinceLastPlay

          const minSpeed = 0.001
          const maxSpeed = 5
          const minPitch = 1
          const maxPitch = 1.05
          const minVolume = 0.2
          const maxVolume = 0.3

          const normalizedSpeed = Math.min(
            Math.max((speed - minSpeed) / (maxSpeed - minSpeed), 0),
            1
          )
          const pitch = minPitch + normalizedSpeed * (maxPitch - minPitch)
          const volume = minVolume + normalizedSpeed * (maxVolume - minVolume)

          playSound('clickSound2', volume, pitch)

          reachedMinRef.current = false
          reachedMaxRef.current = false
        }

        lastPlayTime.current = now
        lastValue.current = numericValue
        inputSliderProps?.onChange?.(value as number, index)
      },
      [playSound, inputSliderProps]
    )

    const handleWheel: React.WheelEventHandler<HTMLDivElement> = useCallback(
      ev => {
        if (!inputSliderProps) return
        const { deltaY, shiftKey } = ev
        const { min, max, step, totalSteps, value } = inputSliderProps

        if (typeof value !== 'number' || typeof min !== 'number' || typeof max !== 'number') return

        const actualStep = totalSteps ? (max - min) / totalSteps : step || 1
        const incrementAmount = shiftKey ? 5 * actualStep : actualStep

        let newVal = value
        if (deltaY < 0) {
          newVal = Math.min(value + incrementAmount, max)
        } else if (deltaY > 0) {
          newVal = Math.max(value - incrementAmount, min)
        }

        handleSliderChange(newVal, 0)
      },
      [inputSliderProps, handleSliderChange]
    )

    return (
      <InputWrapper
        $hasPrefix={hasPrefix}
        $hasSuffix={hasSuffix}
        className='fare-number-input-new'
        onWheel={handleWheel}
      >
        {inputPrefix && <Prefix>{inputPrefix}</Prefix>}
        <NumericFormat
          id='input-num-format-new'
          className='input-numeric-format-new'
          customInput={BaseInput as any}
          getInputRef={setInputRef}
          onFocus={onFocus}
          onKeyDown={onEnterBlur}
          {...props}
        />
        {inputSuffix && <Suffix>{inputSuffix}</Suffix>}
        {hasInputSlider && inputSliderProps && (
          <SReactSliderWrapper
            id='fare-input-slider-wrapper-new'
            $disabled={inputSliderProps.disabled}
          >
            <ReactSlider
              className='fare-input-slider-new'
              renderThumb={Thumb}
              {...inputSliderProps}
              onChange={handleSliderChange}
            />
          </SReactSliderWrapper>
        )}
      </InputWrapper>
    )
  }
)

FareNumberInputNew.displayName = 'FareNumberInputNew'
