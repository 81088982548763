import { useGameContractListener } from './useGameContractListener'
import { useOnGameFinsihed } from './useOnGameFinished'
import useSUContractStore from '@/store/useSUContractStore'
import { resetGameStoreByGameName, useDelayedLiveEntry } from '@/store/useGameStateStore'
import { gameProxy } from '@/store/proxy/gameProxy'
import { routeGameTypeMap } from '@/chains/lib'
import { type AppGameName } from '@/chains/types'

export const GameContractListener = () => {
  const { pathname } = useLocation()
  useGameContractListener(routeGameTypeMap[pathname] as AppGameName)
  useOnGameFinsihed(pathname)
  const { setIsSubmitting, setInProgressEntry } = useSUContractStore(state => ({
    setInProgressEntry: state.setInProgressEntry,
    setIsSubmitting: state.setIsSubmitting,
  }))
  const clearDelayedLiveEntry = useDelayedLiveEntry(state => state.clear)

  useEffect(() => {
    gameProxy.pathGameName = routeGameTypeMap[pathname]

    return () => {
      clearDelayedLiveEntry()
      setIsSubmitting(false)
      setInProgressEntry(null)
      resetGameStoreByGameName(routeGameTypeMap[pathname] as AppGameName)
    }
  }, [pathname])

  return null
}
