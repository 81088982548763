import { entryEvent } from '@/events/entryEvent'
import { useDelayedLiveEntry } from '@/store/useGameStateStore'

export const useOnGameFinsihed = (pathname: string) => {
  const delayLiveEntryTimeoutId = useRef<NodeJS.Timeout>()

  entryEvent.useSub('gameFinished', opts => {
    let delay = 0
    if (opts.detail && opts.detail.liveEntryDelay) {
      delay = opts.detail.liveEntryDelay
    }

    delayLiveEntryTimeoutId.current = setTimeout(() => {
      useDelayedLiveEntry.getState().insertLiveEntry()
    }, delay)
  })

  useEffect(() => {
    return () => {
      clearTimeout(delayLiveEntryTimeoutId.current)
      // If the user goes to another game before current is finished, immediately add live entry
      setTimeout(() => {
        useDelayedLiveEntry.getState().insertLiveEntry()
      }, 1_000)
    }
  }, [pathname])
}
