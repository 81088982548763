import React, { useRef, useEffect } from 'react'
import { useFrame, extend } from '@react-three/fiber'
import * as THREE from 'three'
import { useCrashGameStore } from '@/store/useCrashGameStore'
import gsap from 'gsap'
import { shaderMaterial } from '@react-three/drei'
import { useCrashSound } from './CrashSoundInterface'

interface ForceFieldProps {
  speed?: number
}

// Add custom shader material
const ForceFieldMaterial = shaderMaterial(
  {
    uTime: 0,
    uColor: new THREE.Color(0x00ffff),
  },
  // Vertex shader
  `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  // Fragment shader
  `
    uniform float uTime;
    uniform vec3 uColor;
    varying vec2 vUv;

    void main() {
      float pulse = sin(uTime * 3.0) * 0.7 + 1.7;  // Oscillates between 0.4 and 1.0
      vec3 glowColor = uColor * pulse;
      float alpha = 0.8;  // Constant transparency
      
      gl_FragColor = vec4(glowColor, alpha);
    }
  `
)

extend({ ForceFieldMaterial })

const ForceField: React.FC<ForceFieldProps> = ({ speed = 30 }) => {
  const meshRef = useRef<THREE.Mesh>(null!)
  const scaleRef = useRef<{ value: number }>({ value: 0 })
  const isScalingRef = useRef(true)
  const { forceFieldPosition, updateForceFieldPosition, resetForceField } = useCrashGameStore()
  const materialRef = useRef<any>(null!)
  const { playGameSound } = useCrashSound()

  // Reset scaling state when force field is deactivated
  useEffect(() => {
    if (forceFieldPosition === null) {
      isScalingRef.current = true
      scaleRef.current.value = 0
    }
  }, [forceFieldPosition])

  // Initial scaling animation
  useEffect(() => {
    if (forceFieldPosition !== null && isScalingRef.current) {
      playGameSound('forceField', 0.5, 0.4)
      gsap.to(scaleRef.current, {
        value: 25,
        duration: 0.75,
        ease: 'back.in(3)',
        onComplete: () => {
          isScalingRef.current = false
        },
      })
    }
  }, [forceFieldPosition])

  useFrame((_, delta) => {
    if (forceFieldPosition !== null) {
      if (isScalingRef.current) {
        meshRef.current.scale.y = scaleRef.current.value
      } else {
        // Update shader time uniform
        if (materialRef.current) {
          materialRef.current.uTime += delta
        }

        const newPosition = forceFieldPosition + speed * delta
        if (newPosition > 60) {
          resetForceField() // Deactivate force field after completion
        } else {
          updateForceFieldPosition(newPosition)
        }
      }
    }
  })

  if (forceFieldPosition === null) return null

  return (
    <mesh
      ref={meshRef}
      position={[forceFieldPosition, 0, 0]}
      rotation={[0, Math.PI / 2, 0]}
      scale={[1, 0, 1]}
    >
      <planeGeometry args={[0.2, 1]} />
      {/* @ts-ignore */}
      <forceFieldMaterial ref={materialRef} transparent side={THREE.DoubleSide} />
    </mesh>
  )
}

export default ForceField
