import { ManagerOptions, Socket, SocketOptions } from 'socket.io-client'
import { proxy, useSnapshot } from 'valtio'
import { ISocketError, SpaceName, SpaceOpts } from '../types'
import { createBaseSpaceState, initialBaseSpaceState } from '../state'
import { createSpace } from '../helpers'
import { addAppNoti } from '@/store/useNotiStore'

export interface IFSpaceBaseOpts<K extends object> {
  name: SpaceName
  state: K
  fsocketOpts?: Partial<ManagerOptions & SocketOptions>
  authToken?: string
}

export interface IFSpaceBase extends FSpaceBase<Socket, object> {}

export abstract class FSpaceBase<T extends Socket, K extends object> {
  #name: SpaceName
  #status = createBaseSpaceState()
  #authToken?: string
  #fsocketOpts?: Partial<ManagerOptions & SocketOptions>
  protected hasMountedListeners = false
  protected state: K
  protected io: T

  public get fsocketOptions(): SpaceOpts {
    return (
      this.#fsocketOpts || {
        autoConnect: false,
        auth: {
          authToken: this.#authToken,
        },
      }
    )
  }

  public get status() {
    return this.#status
  }

  public get name() {
    return this.#name
  }

  public get proxyState() {
    return this.state
  }

  constructor({ name, state, fsocketOpts, authToken }: IFSpaceBaseOpts<K>) {
    this.#name = name
    this.state = proxy(state)
    this.#authToken = authToken
    this.#fsocketOpts = fsocketOpts
    // Ensure socket instance is created last
    this.io = createSpace(name, this.fsocketOptions)

    // Setup initial connection handlers
    this.setupConnectionListeners()
  }

  private setupConnectionListeners(): void {
    this.io.on('connect', () => {
      this.#status.isConnected = true
      if (this.name !== 'chat') {
        this.#status.isConnecting = false
      }
      console.log(`${this.name} connected`)
    })

    this.io.on('disconnect', () => {
      this.#status.isConnected = false
      console.log(`${this.name} disconnected`)
    })

    this.io.on('reconnect', () => {
      this.#status.isConnecting = true
      console.log(`${this.name} reconnecting`)
    })

    this.io.on('reconnect_attempt', () => {
      this.#status.isConnecting = true
      console.log(`${this.name} attempting to reconnect`)
    })

    this.io.on('reconnect_failed', () => {
      this.#status.isConnected = false
      this.#status.isConnecting = false
      console.error(`${this.name} reconnection failed`)
    })

    this.io.on('error', error => {
      console.error(error)
    })

    this.io.on('connect_error', error => {
      this.#status.isConnecting = false
      this.#status.isConnected = false
      console.error(`${this.name} connection error:`, error)
    })

    this.io.on('fsocket_error', (payload: ISocketError) => {
      const { error } = payload
      addAppNoti({
        type: 'error',
        msg: error.message,
      })
    })

    this.mountListeners()
  }

  abstract mountListeners(): void

  connect(): void {
    this.#status.isConnecting = true
    // if (!this.io.connected) {
    this.io.connect()
    // }
  }

  disconnect(): void {
    // if (this.io.connected) {
    this.io.disconnect()
    // }
  }

  abstract onReconnectSocket(): void

  reconnectSocket({ wsUrl, authToken }: { wsUrl: string; authToken?: string }): void {
    this.hasMountedListeners = false
    this.onReconnectSocket()
    this.removeAllListeners()
    this.disconnect()

    if (authToken) {
      this.#authToken = authToken
      if (this.#fsocketOpts) this.#fsocketOpts.auth = { authToken }
    }

    this.io = createSpace(this.name, this.fsocketOptions, wsUrl)
    this.setupConnectionListeners()

    this.io.connect()
  }

  removeAllListeners(): void {
    this.io.removeAllListeners()
    this.#status.isConnected = initialBaseSpaceState.isConnected
    this.#status.isConnecting = initialBaseSpaceState.isConnecting
    // this.#status = createBaseSpaceState() // Reset status
  }

  // Hooks
  useStatus(): ReturnType<typeof createBaseSpaceState> {
    return useSnapshot(this.#status)
  }

  useState() {
    return useSnapshot(this.state)
  }
}
