import { useState } from 'react'
import { Chain } from 'viem/chains'
import { useWeb3 } from './useWeb3'
import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'

export function transformChainData(input: any, hexMeDaddy = false) {
  return {
    chainId: hexMeDaddy ? '0x' + input.id.toString(16) : input.id,
    chainName: input.name,
    nativeCurrency: {
      name: input.nativeCurrency.name,
      symbol: input.nativeCurrency.symbol,
      decimals: input.nativeCurrency.decimals,
    },
    rpcUrls: input.rpcUrls?.default?.http || [],
    blockExplorerUrls: [input.blockExplorers?.default?.url || ''].filter(url => url),
    iconUrls: [],
  }
}

export const useNetworkSwitch = () => {
  const { provider } = useWeb3()
  const { isWrongNetwork, appChainConfig } = useAppChainConfigStore(state => ({
    isWrongNetwork: state.isWrongNetwork,
    appChainConfig: state.appChainConfig,
  }))
  const [isOpen, setIsOpen] = useState(false)

  const switchChain = async (_chainDefinition = appChainConfig.chainDefinition) => {
    const switchChainIdHex = '0x' + _chainDefinition.id.toString(16)
    let _provider = provider || window.ethereum
    try {
      if (!window.ethereum) throw new Error('Wallet extension is not installed')
      // edge case if MM and CBW are both installed
      if (window.ethereum.providers?.length) {
        window.ethereum.providers.forEach(async (p: any) => {
          if (p.isMetaMask) _provider = p
        })
      }

      if (_provider.request) {
        await _provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: switchChainIdHex }], // Hexadecimal version of 80001, prefixed with 0x
        })
      } else {
        await _provider.send('wallet_switchEthereumChain', [{ chainId: switchChainIdHex }])
      }
    } catch (error: any) {
      if (error.code === 4902) {
        try {
          if (_provider.request) {
            await _provider.request({
              method: 'wallet_addEthereumChain',
              params: [transformChainData(_chainDefinition, true)],
            })
          } else {
            await _provider.send('wallet_addEthereumChain', [
              transformChainData(_chainDefinition, true),
            ])
          }
          if (_provider.request) {
            await _provider.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: switchChainIdHex }], // Hexadecimal version of 80001, prefixed with 0x
            })
          } else {
            await _provider.send('wallet_switchEthereumChain', [{ chainId: switchChainIdHex }])
          }
        } catch (addError: any) {
          console.error(addError)
          throw new Error('User canceled network add/swtich.')
        }
      } else {
        throw new Error(error)
      }
    }
  }

  return { isOpen, setIsOpen, switchChain, isWrongNetwork }
}
