import {
  type MutableRefObject,
  type InputHTMLAttributes,
  type FocusEventHandler,
  type KeyboardEventHandler,
  type WheelEventHandler,
} from 'react'
import { NumericFormat, type NumericFormatProps } from 'react-number-format'

import { BACKGROUND_COLORS, BORDER_COLORS, FARE_COLORS, TEXT_COLORS } from '@/design/colors'
import { FONT_STYLES } from '@/design'
import { SPACING } from '@/design/spacing'
import { InputSlider } from './InputSlider'
import { type ReactSliderProps } from 'react-slider'

const commonStyles = css`
  height: 100%;
  position: absolute;
  top: 0px;
  display: flex;
  align-items: stretch;
  justify-content: center;
`

const Suffix = styled.div`
  ${commonStyles}
  right: 0px;
  margin-right: ${SPACING.sm}px;
`
const Prefix = styled.div`
  ${commonStyles}
  left: 0px;
  margin-left: ${SPACING.xs}px;
`

const BaseInput = styled.input<{ $hasPrefix?: boolean; $hasSuffix?: boolean }>`
  background: ${BACKGROUND_COLORS.one};
  border: 1px solid ${BORDER_COLORS.one};
  color: ${TEXT_COLORS.one};
  border-radius: 6px;
  padding: ${SPACING.sm}px;
  width: calc(100% - ${SPACING.sm * 2}px);
  display: block;
  font-size: 14px !important;
  transition:
    border ease-in-out 0.18s,
    background ease-in-out 0.18s;

  ${({ $hasPrefix }) =>
    $hasPrefix &&
    css`
      padding-left: ${SPACING.xl}px;
    `}

  ${({ $hasSuffix }) =>
    $hasSuffix &&
    css`
      padding-left: ${SPACING.sm}px;
    `}

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  &:focus {
    outline: none;
    border: 1px solid ${FARE_COLORS.aqua};
    background: #010e0c;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    user-select: none;
  }
`

const InputWrapper = styled.div<{ $hasPrefix?: boolean; $hasSuffix?: boolean }>`
  display: flex;
  position: relative;
  touch-action: none;

  #input-num-format:focus-within ~ #fare-input-slider-wrapper {
    .thumb-0 {
      background: #428375;
    }
    .track-0 {
      border-top: 1px solid ${FARE_COLORS.aqua};
    }
    .track-1 {
      border-top: 1px solid #1b1d26;
      border-bottom: 1px solid ${FARE_COLORS.aqua};
    }
  }

  &:has(#fare-input-slider-wrapper > .fare-input-slider:active) {
    #input-num-format {
      outline: none;
      border: 1px solid ${FARE_COLORS.aqua};
      background: #010e0c;
    }
  }

  input {
    ${({ $hasPrefix }) =>
      $hasPrefix &&
      css`
        padding-left: ${SPACING.lg + SPACING.sm}px !important;
      `}

    ${({ $hasSuffix }) =>
      $hasSuffix &&
      css`
        padding-right: ${SPACING.xl + SPACING.xs}px !important;
      `}
  }

  ${Suffix},
  ${Prefix} {
    > * {
      border-radius: 6px;
      text-transform: uppercase;
      color: ${TEXT_COLORS.one};
      ${FONT_STYLES.xs};
      margin: auto;
    }

    button {
      right: 0;
      background: ${BACKGROUND_COLORS.two};
      border: 1px solid ${BORDER_COLORS.one};
      transition: 0.2s ease-in-out;
      all: unset;
      background: ${BACKGROUND_COLORS.two};
      border: 1px solid ${BORDER_COLORS.one};
      color: ${TEXT_COLORS.two};
      ${FONT_STYLES.xs};
      border-radius: 4px;
      min-width: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: auto;
      padding: ${SPACING.xxs}px;
      min-height: 18px;

      &:not(:last-of-type) {
        margin-right: 6px;
      }

      &:hover {
        color: ${TEXT_COLORS.one};
        border: 1px solid ${FARE_COLORS.blue};
      }
    }
  }
`

interface IInput {
  inputPrefix?: JSX.Element
  inputSuffix?: JSX.Element
  customRef?: MutableRefObject<HTMLInputElement | null>
  hasInputSlider?: boolean
  inputSliderProps?: ReactSliderProps
}

export const FareNumberInput = ({
  inputPrefix,
  inputSuffix,
  getInputRef,
  // customRef,
  hasInputSlider,
  inputSliderProps,
  ...props
}: IInput & InputHTMLAttributes<HTMLInputElement> & NumericFormatProps) => {
  const inputRef: MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement>(null)

  const hasPrefix = useMemo(() => Boolean(inputPrefix), [inputPrefix])
  const hasSuffix = useMemo(() => Boolean(inputSuffix), [inputSuffix])

  const setInputRef = useCallback(
    (ref: HTMLInputElement) => {
      inputRef.current = ref
      if (typeof getInputRef === 'function') getInputRef(ref)
    },
    [getInputRef]
  )

  const onFocus: FocusEventHandler<HTMLInputElement> = useCallback(
    event => event.target.select(),
    []
  )

  const onEnterBlur: KeyboardEventHandler<HTMLInputElement> = useCallback(event => {
    if (event.key === 'Enter') {
      event.currentTarget.blur()
    }
  }, [])

  const handleWheel: WheelEventHandler<HTMLDivElement> = useCallback(
    ev => {
      if (!inputSliderProps) return
      const { deltaX, deltaY, shiftKey } = ev
      let incrementAmount = inputSliderProps.step || 1

      if (shiftKey) {
        incrementAmount = props.name === 'numberOfEntries' ? 2 : 5
      }

      if (
        typeof inputSliderProps.value !== 'number' ||
        typeof inputSliderProps.step !== 'number' ||
        typeof inputSliderProps.max !== 'number' ||
        typeof inputSliderProps.min !== 'number'
      )
        return

      if (deltaX < 0 || deltaY < 0) {
        const newVal = inputSliderProps.value + incrementAmount
        if (newVal > inputSliderProps.max) {
          inputSliderProps.onChange?.(inputSliderProps.max, 0)
        } else {
          inputSliderProps.onChange?.(newVal, 0)
        }
      }

      if (deltaX > 0 || deltaY > 0) {
        const newVal = inputSliderProps.value - incrementAmount
        if (newVal < inputSliderProps.min) {
          inputSliderProps.onChange?.(inputSliderProps.min, 0)
        } else {
          inputSliderProps.onChange?.(newVal, 0)
        }
      }
    },
    [inputSliderProps, props.name]
  )

  return (
    <InputWrapper
      $hasPrefix={hasPrefix}
      $hasSuffix={hasSuffix}
      className='fare-number-input'
      onWheel={handleWheel}
    >
      {inputPrefix && <Prefix>{inputPrefix}</Prefix>}
      <NumericFormat
        id='input-num-format'
        className='input-numeric-format'
        customInput={BaseInput}
        $hasPrefix={hasPrefix}
        $hasSuffix={hasSuffix}
        onFocus={onFocus}
        onKeyDown={onEnterBlur}
        {...(props as any)}
        getInputRef={setInputRef}
      />
      {hasInputSlider && <InputSlider {...inputSliderProps} disabled={props.disabled} />}
      {inputSuffix && <Suffix>{inputSuffix}</Suffix>}
    </InputWrapper>
  )
}
