import { useBalanceListener } from './useBalanceListener'
import { AppGameName } from '@/chains/types'
import { routeGameTypeMap } from '@/chains/lib'

export const BalanceListener = () => {
  const { pathname } = useLocation()
  useBalanceListener(routeGameTypeMap[pathname] as AppGameName)

  return null
}
