import { getAppCurrencyDecimals } from '@/chains/lib'
import { type providers, utils, type BigNumberish, type BigNumber } from 'ethers'

export const windowEth: providers.ExternalProvider = window.ethereum
export const formatUsdc = (value: BigNumberish) =>
  utils.formatUnits(value || 0, getAppCurrencyDecimals())

export const parseUsdc = (value: string) => utils.parseUnits(value || '0', getAppCurrencyDecimals())

export const formatEth = (value: BigNumberish) => utils.formatEther(value || 0)

export const parseEth = (value: string) => utils.parseEther(value || '0')

export function multiplyBigNumberWithFixedPointNumber(
  bigNumber: BigNumber,
  fixedPointNumber: string
): BigNumber {
  const dotIndex = fixedPointNumber.indexOf('.')
  if (dotIndex === -1) {
    return bigNumber.mul(fixedPointNumber)
  }
  const digitCountAfterDot = fixedPointNumber.slice(dotIndex + 1).length
  const divisor = 10 ** digitCountAfterDot
  const multiplier = fixedPointNumber.replace('.', '')

  return bigNumber.mul(multiplier).div(divisor)
}

export * from './contracts'
export * from './types'
