import { createWithEqualityFn } from 'zustand/traditional'
import { shallow } from 'zustand/vanilla/shallow'
import { immer } from 'zustand/middleware/immer'
import { createSelectors } from './helpers/createSelectors'

export type FetchableBalanceType = 'native' | 'currency'
// NOTE: Whenever we implement multi currency we will need to make this dynamic
// Single source of truth for all supported erc20 tokens
// example: 'usdc' | 'fare' | 'usdt' | 'link' ...
export type AvailableCurrencyType = 'currency'

export type CurrencyAmountMap = {
  [K in FetchableBalanceType]: string
}

export type CurrencyAllowanceMap = {
  [K in AvailableCurrencyType]: string
}

export type CurrencyBalanceMap = CurrencyAmountMap & { bankroll: string }

export type CurrencyState = {
  isApprovingAllowance: boolean
  selectedCurrency: AvailableCurrencyType
  allowances: CurrencyAllowanceMap
  balances: CurrencyBalanceMap
}

export type CurrencyActions = {
  setSelectedCurrency: (type: AvailableCurrencyType) => void
  setBalance: (type: keyof CurrencyBalanceMap, amount: string) => void
  setAllowance: (type: AvailableCurrencyType, amount: string) => void
  setIsApprovingAllowance: (isApprovingAllowance: boolean) => void
}

// game contract and currency
export const initialCurrencyAllowance: CurrencyAmountMap = {
  currency: '0',
  native: '0',
}

export const initialBalances: CurrencyBalanceMap = {
  currency: '0',
  native: '0',
  bankroll: '0',
}

export const initialCurrencyState: CurrencyState = {
  isApprovingAllowance: false,
  selectedCurrency: 'currency',
  balances: initialBalances,
  allowances: initialCurrencyAllowance,
}

export type CurrencyStore = CurrencyState & CurrencyActions

const useCurrencyStoreBase = createWithEqualityFn<CurrencyStore>()(
  immer(set => ({
    ...initialCurrencyState,
    setSelectedCurrency: selectedCurrency =>
      set(() => ({
        selectedCurrency,
      })),
    setBalance: (type, balance) =>
      set(state => ({
        balances: {
          ...state.balances,
          [type]: balance,
        },
      })),
    setAllowance: (type, amount) =>
      set(state => ({
        allowances: {
          ...state.allowances,
          [type]: amount,
        },
      })),
    setIsApprovingAllowance: isApprovingAllowance =>
      set(() => ({
        isApprovingAllowance,
      })),
  })),
  shallow
)

const useCurrencyStore = createSelectors(useCurrencyStoreBase)

export default useCurrencyStore
