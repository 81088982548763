import { fsocket } from '@/lib/fsocket'
import { AnimatePresence, Variants, motion } from 'framer-motion'
import { useSnapshot } from 'valtio'

const STrollBoxLoadingOverlay = styled(motion.div)`
  position: absolute;
  z-index: 10;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
`
const overlayVariants: Variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
}

export const TrollBoxLoadingOverlay = () => {
  const statusState = useSnapshot(fsocket.chat.status)

  return (
    <AnimatePresence>
      {statusState.isConnecting ?
        <STrollBoxLoadingOverlay
          variants={overlayVariants}
          initial='initial'
          animate='animate'
          exit='exit'
        >
          connecting to chat...
        </STrollBoxLoadingOverlay>
      : null}
    </AnimatePresence>
  )
}
