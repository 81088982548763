import { appChainConfigs } from '@/chains'
import { useActiveWallet } from '@/lib/privy/hooks'
import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'
import { SDropdownItem } from './style'

export const NetworkDropDownItems = () => {
  const { activeWallet, walletChainId } = useActiveWallet()
  const switchWalletChain = useCallback(
    (chainId: number) => {
      if (!activeWallet) return
      activeWallet.switchChain(chainId)
      useAppChainConfigStore.getState().setAppChainId(chainId)
      close()
    },
    [activeWallet]
  )

  return (
    <>
      {appChainConfigs.map(chainConfig => (
        <SDropdownItem
          key={chainConfig.chainDefinition.id}
          $menuColor={chainConfig.networkStyle.menuColor}
          $isActive={walletChainId === chainConfig.chainDefinition.id}
          onClick={e => {
            e.stopPropagation()
            switchWalletChain(chainConfig.chainDefinition.id)
          }}
        >
          <img
            src={chainConfig.networkStyle.networkLogo}
            alt={chainConfig.networkStyle.currencyName}
          />{' '}
          {chainConfig.chainDefinition.name}
        </SDropdownItem>
      ))}
    </>
  )
}
