import { Button, ButtonEnum } from '../Button'
import { usePrivy } from '@privy-io/react-auth'
import { useAuthWallet } from '@/lib/privy/hooks/useAuthWallet'

const ConnectWallet = ({ ...props }) => {
  const { isWalletAuthed, linkOrLoginWallet } = useAuthWallet()
  const { isModalOpen } = usePrivy()
  const disableLogin = isWalletAuthed

  if (disableLogin) return null

  return (
    <>
      <Button
        type='button'
        buttonType={ButtonEnum.CONNECT_WALLET}
        disabled={disableLogin}
        onClick={linkOrLoginWallet}
        isLoading={isModalOpen}
        loadingText={'CONNECTING'}
        {...props}
      >
        CONNECT WALLET
      </Button>
    </>
  )
}

export default ConnectWallet
