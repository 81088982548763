/* eslint-disable @react-three/no-new-in-loop */
import React, { useImperativeHandle, forwardRef, useRef, useEffect, useMemo, useState } from 'react'
import { useFrame } from '@react-three/fiber'
import * as THREE from 'three'
import HUD from './HUD'
import { getShipPosition } from './shipPath'
import { useGameTime } from './GameTimeContext'
import gsap from 'gsap'
import { type ShipConfig } from './GameConfig'
import { ExplosionEffect } from '../../shared/ParticleFX/GenericExplosion'
import { useGLTF } from '@react-three/drei'
import { useCrashSound } from './CrashSoundInterface'
import { useCrashGameStore } from '@/store/useCrashGameStore'

interface ShipProps {
  config: ShipConfig
  isPlaying: boolean
  multiplier: number
  gameOutcome: 'WIN' | 'LOSS' | null
  setExplosionPosition?: (position: [number, number, number]) => void
}

const Ship = forwardRef<THREE.Group, ShipProps>(
  ({ config, isPlaying, multiplier, gameOutcome, setExplosionPosition }, ref) => {
    const { playGameSound } = useCrashSound()

    // Time and State
    const { gameTime, roundTime } = useGameTime()
    const { gameState, gameIntensity } = useCrashGameStore()

    // Local State
    const [isEntering, setIsEntering] = useState(false)
    const [isFlying, setIsFlying] = useState(false)
    const [isVisible, setIsVisible] = useState(false)
    const [showHUD, setShowHUD] = useState(false)

    // Constant Config Variables
    const flyInDuration = config.flyIn.duration // seconds

    // Refs
    const groupRef = useRef<THREE.Group>(null!)
    const hudRef = useRef<THREE.Group>(null!)
    const flyInlerpElapsed = useRef(0)

    // Expose groupRef to parent via ref for text animations
    useImperativeHandle(ref, () => groupRef.current)

    // Handle game state updates
    useEffect(() => {
      switch (gameState) {
        case 'CRASHING':
          setIsFlying(false)

          if (groupRef.current) {
            const { x, y, z } = groupRef.current.position
            explosionRef.current = [x, y, z]
            setExplosionPosition?.([x, y, z])
          }

          if (gameOutcome === 'LOSS') {
            setShowHUD(false)
          }
          break
        case 'EXPLODING':
          // after 1 second, show explosion
          setTimeout(() => {
            setIsVisible(false)
            setShowExplosion(true)
            playGameSound('explosion', 0.4, 1)
          }, 1450)
          break
        case 'IDLE':
          setShowHUD(false)
          setTimeout(() => {
            setShowExplosion(false)
          }, 2000)
          break
      }
    }, [gameState, setExplosionPosition, gameOutcome, playGameSound])

    // Handle local state based on isPlaying
    useEffect(() => {
      if (isPlaying && !isEntering && !isFlying) {
        setShowHUD(true)
        setIsEntering(true)
        setIsFlying(true)
        setIsVisible(true)
        flyInlerpElapsed.current = 0

        // Set initial horizontal position
        if (groupRef.current) {
          const initialY = getShipPosition(gameTime, roundTime, config)
          groupRef.current.position.set(-15, initialY, 0) // Assuming Z = 0
          groupRef.current.quaternion.set(0, 0, 0, 1) // Reset rotation
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPlaying, gameTime, config, isEntering, isFlying])

    // Initialize flying in
    useEffect(() => {
      if (isEntering && groupRef.current) {
        groupRef.current.quaternion.set(0, 0, 0, 1) // Reset rotation
        flyInlerpElapsed.current = 0
      }
    }, [isEntering])

    // Animate the ship
    useFrame((state, delta) => {
      if (!groupRef.current) return

      // Flying In
      if (isEntering) {
        flyInlerpElapsed.current += delta
        const t = Math.min(flyInlerpElapsed.current / flyInDuration, 1)

        // Calculate ship's Y position with increasing amplitude and frequency
        const currentY = getShipPosition(gameTime, roundTime, config)

        // Linear interpolation for X position from -15 to config.position.x
        const currentX = THREE.MathUtils.lerp(-15, config.position.x, t)

        // Update the ship's position
        groupRef.current.position.set(currentX, currentY, 0)

        // Add tilt during fly-in
        const tiltAngle = (config.flyIn.tiltAngle ?? Math.PI / 6) * Math.sin(t * Math.PI)
        groupRef.current.rotation.z = tiltAngle

        if (t >= 1) {
          setIsEntering(false)
        }
      }
      // Flying Steady
      else if (isFlying) {
        const prevY = groupRef.current.position.y
        const targetY = getShipPosition(gameTime, roundTime, config)

        // Add X position lerping
        const currentX = groupRef.current.position.x
        const targetX = config.position.x
        const horizontalSpeed = config.flying.movementSpeed * 0.15 // Reduced to 15% of vertical speed
        const xDelta = (targetX - currentX) * horizontalSpeed * delta
        groupRef.current.position.x += xDelta

        // Existing Y position update
        groupRef.current.position.y +=
          (targetY - groupRef.current.position.y) * config.flying.movementSpeed * delta

        // Calculate vertical velocity with smoothing
        const verticalVelocity = (groupRef.current.position.y - prevY) / delta
        const smoothedVelocity = verticalVelocity * 0.75 // Reduced velocity impact

        // Add slight tilt based on horizontal movement
        const horizontalTilt = -xDelta * 2 // Adjust multiplier to control tilt intensity
        const verticalTilt = smoothedVelocity * 0.1
        const targetTiltZ = verticalTilt + horizontalTilt
        groupRef.current.rotation.z += (targetTiltZ - groupRef.current.rotation.z) * 0.1

        // Only consider barrel rolls when ship is relatively level
        const tiltThreshold = 0.05 // Adjust this value to determine how "level" the ship needs to be
        const isShipLevel = Math.abs(groupRef.current.rotation.z) < tiltThreshold

        // Random barrel roll only when ship is level
        if (
          Math.random() < config.barrelRoll.frequency &&
          !isBarrelRolling.current &&
          isShipLevel
        ) {
          performBarrelRoll()
        }

        // Smoother roll calculations
        if (!isBarrelRolling.current) {
          const maxRollAngle = Math.PI / 12 // Reduced max angle
          const targetRollX = -smoothedVelocity * 0.03 * maxRollAngle // Reduced roll response
          const clampedRollX = Math.max(Math.min(targetRollX, maxRollAngle), -maxRollAngle)
          groupRef.current.rotation.x += (clampedRollX - groupRef.current.rotation.x) * 0.1 // Reduced lerp factor
        }
      }

      // Update HUD position every frame while flying
      if (hudRef.current && isFlying) {
        const targetHudY = getShipPosition(gameTime, roundTime, config) + config.hud.verticalOffset // offset is handled in HUD
        const currentHudY = hudRef.current.position.y

        // Smooth lerp for HUD position
        const lerpFactor = 0.1
        const newHudY = currentHudY + (targetHudY - currentHudY) * lerpFactor

        hudRef.current.position.set(groupRef.current.position.x, newHudY, 0)
      }
    })

    const isBarrelRolling = useRef(false)

    const performBarrelRoll = () => {
      const duration = config.barrelRoll.duration
      isBarrelRolling.current = true

      const obj = { rotation: 0 }
      const direction = Math.random() < 0.5 ? 1 : -1 // Randomly choose direction

      gsap.to(obj, {
        rotation: Math.PI * 2 * direction, // Apply direction to rotation
        duration: duration,
        ease: 'power2.inOut',
        onUpdate: function () {
          groupRef.current.rotation.x = obj.rotation
        },
        onComplete: () => {
          isBarrelRolling.current = false
          groupRef.current.rotation.x = 0
        },
      })
    }

    const [showExplosion, setShowExplosion] = useState(false)
    const explosionRef = useRef<[number, number, number]>([0, 0, 0])

    // Memoized explosion effect
    const memoizedExplosion = useMemo(
      () => {
        console.log('DEBUG: showExplosion:', showExplosion)
        return (
          showExplosion && (
            <ExplosionEffect
              position={explosionRef.current}
              colors={['#ff3d00', '#ff9500', '#ffcb00', '#ff00dd']}
              count={75 * gameIntensity}
              // count={500}
              size={3}
              spread={3}
              duration={1000}
              gravity={0}
              force={2 + gameIntensity * 2}
              // force={2}
              shape='circle'
              intensity={1.1}
              onComplete={() => {}}
            />
          )
        )
      },
      [showExplosion] // Only recreate if showExplosion changes
    )

    const localScale = config.scale

    // Rocket Model (memoized)
    const { scene } = useGLTF('/glb/rocket.glb')
    const model = useMemo(() => scene.clone(), [scene])

    return (
      <>
        <group ref={groupRef} visible={isVisible}>
          <primitive object={model} rotation={[0, 0, -Math.PI / 2]} scale={localScale} />
        </group>
        {/* Separate group for HUD */}
        {showHUD && (
          <group ref={hudRef}>
            <HUD multiplier={multiplier} gameOutcome={gameOutcome} config={config} />
          </group>
        )}
        {/* Memoized Explosion Effect */}
        {memoizedExplosion}
      </>
    )
  }
)

Ship.displayName = 'Ship'

useGLTF.preload('/glb/rocket.glb')

export default Ship
