import gridFrag from './shaders/grid.frag'
import gridVert from './shaders/grid.vert'
import { useFrame, useThree } from '@react-three/fiber'
import { type ShaderMaterial, Vector2, Color } from 'three'

export const RPSGrid = () => {
  const size = useThree(state => state.size)
  const shaderRef = useRef<ShaderMaterial>(null)

  const uniforms = useMemo(
    () => ({
      uTime: { value: 0 },
      uResolution: { value: new Vector2(size.width, size.height) },
      uGridSpacing: { value: 0.22 },
      fogColor: { value: new Color(1.0, 0, 0) },
      fogNear: { value: 10 },
      fogFar: { value: 20 },
    }),
    [size]
  )

  useFrame(({ clock }) => {
    if (shaderRef.current) {
      shaderRef.current.uniforms.uTime.value = clock.getElapsedTime()
    }
  })

  return (
    <>
      {/* Ground */}
      <mesh position={[0, -0.75, 0]} rotation={[-Math.PI / 2, 0, 0]} receiveShadow>
        <planeGeometry args={[100, 100]} />
        <shaderMaterial
          ref={shaderRef}
          fragmentShader={gridFrag}
          vertexShader={gridVert}
          uniforms={uniforms}
          transparent
          fog
        />
      </mesh>
    </>
  )
}
