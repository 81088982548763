export function getAnimationMap(animations: any) {
  const animationMap: any = {}
  animations.forEach((value: any) => {
    let key = value.name.replace('', '')
    key = key.replace('', '')
    animationMap[key] = value.name
  })

  return animationMap
}

export const handAnimations = {
  'delme.003': 'delme.003',
  'Hand.Fist.L': 'Hand.Fist.L',
  'Hand.FistToReady.L': 'Hand.FistToReady.L',
  'Hand.Idle.L': 'Hand.Idle.L',
  'Hand.Paper.L': 'Hand.Paper.L',
  'Hand.PlayPaper.L': 'Hand.PlayPaper.L',
  'Hand.PlayPaperFast.L': 'Hand.PlayPaperFast.L',
  'Hand.PlayRock.L': 'Hand.PlayRock.L',
  'Hand.PlayRockFast.L': 'Hand.PlayRockFast.L',
  'Hand.PlayScissors.L': 'Hand.PlayScissors.L',
  'Hand.PlayScissorsFast.L': 'Hand.PlayScissorsFast.L',
  'Hand.ReadyIdle.L': 'Hand.ReadyIdle.L',
  'Hand.ReadyToPlay.L': 'Hand.ReadyToPlay.L',
  'Hand.RestingFist.L': 'Hand.RestingFist.L',
  'Hand.Scissors.L': 'Hand.Scissors.L',
  Hand_ThumbsUp: 'Hand_ThumbsUp',
  RPS_Sequence_Paper: 'RPS_Sequence_Paper',
  RPS_Sequence_Rock: 'RPS_Sequence_Rock',
  RPS_Sequence_Scissors: 'RPS_Sequence_Scissors',
  RPS_Win_FingerGun: 'RPS_Win_FingerGun',
  'Hand.Fist.R': 'Hand.Fist.R',
  'Hand.FistToReady.R': 'Hand.FistToReady.R',
  'Hand.Idle.Motion.1.R': 'Hand.Idle.Motion.1.R',
  'Hand.PlayPaper.R': 'Hand.PlayPaper.R',
  'Hand.PlayPaperFast.R': 'Hand.PlayPaperFast.R',
  'Hand.PlayRock.R': 'Hand.PlayRock.R',
  'Hand.PlayRockFast.R': 'Hand.PlayRockFast.R',
  'Hand.PlayScissors.R': 'Hand.PlayScissors.R',
  'Hand.PlayScissorsFast.R': 'Hand.PlayScissorsFast.R',
  'Hand.ReadyIdle.R': 'Hand.ReadyIdle.R',
  'Hand.ReadyToPlay.R': 'Hand.ReadyToPlay.R',
  'Hand.RestingFist.R': 'Hand.RestingFist.R',
  'RPS_Sequence_Paper.001': 'RPS_Sequence_Paper.001',
}

export type HandAnimationName = keyof typeof handAnimations

export const getHandAnimName = (animName: HandAnimationName) => handAnimations[animName]
