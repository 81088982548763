import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import type { CreatorInviteCode, CreatorUsedInviteCode, InviteCodes } from '@/lib/http/inviteCodes'
import type {
  IBonusQuestDetail,
  IQuestDetail,
  IFarePointsDetail,
} from '@/pages/PointsPage/QuestDetails/questDetails.types'

export type InviteCodeState = {
  activeInviteCodes: CreatorInviteCode[]
  usedInviteCodes: CreatorUsedInviteCode[]
  pointsDetails: IFarePointsDetail | null
  quests: IQuestDetail[]
  bonusQuests: IBonusQuestDetail[]
  isShowingWelcomeModal: boolean
}

export type InviteCodeActions = {
  setInviteCodes: (inviteCodes: InviteCodes) => void
  setActiveInviteCodes: (activeInviteCodes: CreatorInviteCode[]) => void
  setUsedInviteCodes: (usedInviteCodes: CreatorUsedInviteCode[]) => void
  setPointsDetails: (pointsDetails: IFarePointsDetail) => void
  setQuests: (quests: IQuestDetail[]) => void
  setBonusQuests: (quests: IBonusQuestDetail[]) => void
}

export const initialInviteCodeState: InviteCodeState = {
  activeInviteCodes: [],
  usedInviteCodes: [],
  pointsDetails: null,
  quests: [],
  bonusQuests: [],
  isShowingWelcomeModal: localStorage.getItem('has-shown-welcome-modal') !== 'true',
}

export type InviteCodeStore = InviteCodeState & InviteCodeActions

const useInviteCodeStore = create<InviteCodeStore, [['zustand/immer', InviteCodeStore]]>(
  immer(set => ({
    ...initialInviteCodeState,
    setActiveInviteCodes: activeInviteCodes =>
      set(() => ({
        activeInviteCodes,
      })),
    setUsedInviteCodes: usedInviteCodes =>
      set(() => ({
        usedInviteCodes,
      })),
    setPointsDetails: pointsDetails =>
      set(() => ({
        pointsDetails,
      })),
    setInviteCodes: inviteCodes =>
      set(state => {
        state.activeInviteCodes = inviteCodes.activeInviteCodes
        state.usedInviteCodes = inviteCodes.usedInviteCodes
      }),
    setQuests: quests =>
      set(() => ({
        quests,
      })),
    setBonusQuests: bonusQuests =>
      set(() => ({
        bonusQuests,
      })),
  }))
)

export default useInviteCodeStore
