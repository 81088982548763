import React from 'react'
import { createRoot } from 'react-dom/client'
import { PostHogProvider } from '@/lib/posthog/Provider'
import { enableMapSet } from 'immer'
import { createWeb3 } from '@/chains/createWeb3'
import { PrivyProvider } from '@/lib/privy/PrivyProvider'

import { GlobalStyle } from './style'
import { App } from './App'
import { SoundProvider } from './components/shared/SoundSystem/SoundContext'
import { AppDownPage } from '@/pages/AppDownPage'

// Init
createWeb3()
enableMapSet()

const root = createRoot(document.getElementById('root') as HTMLElement)

const isAppDown = location.pathname === '/down-for-maintenance'

root.render(
  <React.StrictMode>
    <GlobalStyle />
    <PostHogProvider>
      {isAppDown ?
        <AppDownPage />
      : <SoundProvider>
          <PrivyProvider>
            <App />
          </PrivyProvider>
        </SoundProvider>
      }
    </PostHogProvider>
  </React.StrictMode>
)
