import React, { useMemo } from 'react'
import { Button, ButtonEnum } from '../shared/Button'
import { useActiveWallet } from '@/lib/privy/hooks'
import { defaultSupportedChain } from '@/chains'
import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'

interface NetworkSwitchButtonProps {
  className?: string
  isMinified?: boolean
}

const NetworkSwitchButton: React.FC<NetworkSwitchButtonProps> = ({ className, isMinified }) => {
  const { activeWallet } = useActiveWallet()
  const isWrongNetwork = useAppChainConfigStore.use.isWrongNetwork()
  const appChainId = useAppChainConfigStore.use.appChainId()

  const buttonRenderer = useMemo(() => {
    if (isWrongNetwork) {
      return (
        <Button
          className={className || 'wrong-network-btn'}
          isMinified={isMinified}
          onClick={() => activeWallet?.switchChain(appChainId)}
          buttonType={ButtonEnum.WARNING}
          disabled={!isWrongNetwork}
          loadingText={'SWITCHING NETWORK'}
          type='button'
        >
          SWITCH NETWORK
        </Button>
      )
    }

    return null
  }, [isWrongNetwork, activeWallet, appChainId, className, isMinified])

  return buttonRenderer
}

export default NetworkSwitchButton
