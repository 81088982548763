import { solRequire } from '@/utils'
import { type GameHelperFunctions } from './types'
import { formatEther } from 'viem'
import { GameNameToQK, minimizeQK, unit } from '../vault'
import { DEFAULT_MAX_COUNT } from './constants'

export enum PlinkoRiskSelection {
  Low = 0,
  Medium = 1,
  High = 2,
  Random = 3,
}

export interface IPlinkoSide {
  riskLevel: number
  rowCount: number
}

export const isValidPlinkoSide = (side: IPlinkoSide) => {
  if (side.riskLevel < 0 || side.riskLevel > 2) {
    return false
  }
  if (side.rowCount < 8 || side.rowCount > 16) {
    return false
  }
  return true
}

export const getMaxCountForPlinko = (side: IPlinkoSide) => {
  const gameQKs = GameNameToQK.plinko.getQK(side)
  const minimizedQKs = minimizeQK(gameQKs.q, gameQKs.k)
  const qArr = minimizedQKs.q
  let minQ = qArr[0]
  for (let i = 1; i < qArr.length; ++i) {
    if (qArr[i] < minQ) {
      minQ = qArr[i]
    }
  }
  let i = BigInt(DEFAULT_MAX_COUNT)
  for (; i >= 1n; i--) {
    // Check limit related to representing with q and k
    console.log('MAX ENTRY: q: ', minQ)
    if (minQ === undefined) return DEFAULT_MAX_COUNT // Since revealCount can be 0 as of data but there is no q to represent that probably of win for that condition, it would return undefined, in that case, return the default value
    if (minQ ** i / unit ** (i - 1n) > 0n) {
      console.log('MAX ENTRY: breaking from q with maxEntry: ', i)
      break
    }
  }
  // when loop ends or breaks out, the i should be the max valid count for given prob
  return Number(i)
}

export const getPlinkoMultiplierWithoutPPVFromSide = (side: IPlinkoSide) => {
  return 0
}

// @NOTE: Since it has multipple multipliers, it returns the highest one of them
export const getPlinkoMultiplierWithPPVFromSide = (side: IPlinkoSide) => {
  const multiplierInBigInt =
    PlinkoProbabilitiesAndMultipliers.getMultiplierForRiskLevelRowCountAndPosition(
      side.riskLevel,
      side.rowCount,
      0
    )
  return Number(formatEther(multiplierInBigInt))
}

export const getPlinkoKellyFractionFromSide = (side: IPlinkoSide) => {
  return 0
}

export const getPlinkoPotentialProfitCoefficientFromSide = (side: IPlinkoSide) => {
  return getPlinkoMultiplierWithPPVFromSide(side) - 1
}

export const plinkoHelperFunctions: GameHelperFunctions<IPlinkoSide> = {
  isValidSide: isValidPlinkoSide,
  getMaxCount: getMaxCountForPlinko,
  getMultiplierWithoutPPV: getPlinkoMultiplierWithoutPPVFromSide,
  getMultiplierWithPPV: getPlinkoMultiplierWithPPVFromSide,
  getKellyFraction: getPlinkoKellyFractionFromSide,
  getPotentialProfitCoefficient: getPlinkoPotentialProfitCoefficientFromSide,
}

export class PlinkoProbabilitiesAndMultipliers {
  static getProbabilityForRowCountAndPosition = (rowCount: number, position: number) => {
    solRequire(
      rowCount >= 8 && rowCount <= 16,
      'Row count for Plinko has to be between 8 and 16 (inclusive)'
    )
    solRequire(
      position >= 0 && position <= rowCount,
      `Position has to be between 0 and rowCount (0 <= pos <= ${rowCount})`
    )
    return PlinkoProbabilitiesAndMultipliers.probabilities[rowCount - 8][position]
  }
  static getProbabilitesForRowCount = (rowCount: number) => {
    solRequire(
      rowCount >= 8 && rowCount <= 16,
      'Row count for Plinko has to be between 8 and 16 (inclusive)'
    )
    return PlinkoProbabilitiesAndMultipliers.probabilities[rowCount - 8]
  }
  //
  static getMultiplierForRiskLevelRowCountAndPosition = (
    riskLevel: number,
    rowCount: number,
    position: number
  ) => {
    solRequire(riskLevel >= 0 && riskLevel <= 2, 'Risk level has to be between 0 and 2 (inclusive)')
    solRequire(
      rowCount >= 8 && rowCount <= 16,
      'Row count for Plinko has to be between 8 and 16 (inclusive)'
    )
    solRequire(
      position >= 0 && position <= rowCount,
      `Position has to be between 0 and rowCount (0 <= pos <= ${rowCount})`
    )
    return PlinkoProbabilitiesAndMultipliers.multipliers[riskLevel][rowCount - 8][position]
  }
  static getMultipliersForRiskLevelAndRowCount = (riskLevel: number, rowCount: number) => {
    solRequire(riskLevel >= 0 && riskLevel <= 2, 'Risk level has to be between 0 and 2 (inclusive)')
    solRequire(
      rowCount >= 8 && rowCount <= 16,
      'Row count for Plinko has to be between 8 and 16 (inclusive)'
    )
    return PlinkoProbabilitiesAndMultipliers.multipliers[riskLevel][rowCount - 8]
  }
  static getMultipliersForRiskLevel = (riskLevel: number) => {
    solRequire(riskLevel >= 0 && riskLevel <= 2, 'Risk level has to be between 0 and 2 (inclusive)')
    return PlinkoProbabilitiesAndMultipliers.multipliers[riskLevel]
  }
  static probabilities = [
    [
      3906250000000000n,
      31250000000000000n,
      109375000000000000n,
      218750000000000000n,
      273437500000000000n,
      218750000000000000n,
      109375000000000000n,
      31250000000000000n,
      3906250000000000n,
    ],
    [
      1953125000000000n,
      17578125000000000n,
      70312500000000000n,
      164062500000000000n,
      246093750000000000n,
      246093750000000000n,
      164062500000000000n,
      70312500000000000n,
      17578125000000000n,
      1953125000000000n,
    ],
    [
      976562500000000n,
      9765625000000000n,
      43945312500000000n,
      117187500000000000n,
      205078125000000000n,
      246093750000000000n,
      205078125000000000n,
      117187500000000000n,
      43945312500000000n,
      9765625000000000n,
      976562500000000n,
    ],
    [
      488281250000000n,
      5371093750000000n,
      26855468750000000n,
      80566406250000000n,
      161132812500000000n,
      225585937500000000n,
      225585937500000000n,
      161132812500000000n,
      80566406250000000n,
      26855468750000000n,
      5371093750000000n,
      488281250000000n,
    ],
    [
      244140625000000n,
      2929687500000000n,
      16113281250000000n,
      53710937500000000n,
      120849609375000000n,
      193359375000000000n,
      225585937500000000n,
      193359375000000000n,
      120849609375000000n,
      53710937500000000n,
      16113281250000000n,
      2929687500000000n,
      244140625000000n,
    ],
    [
      122070312500000n,
      1586914062500000n,
      9521484375000000n,
      34912109375000000n,
      87280273437500000n,
      157104492187500000n,
      209472656250000000n,
      209472656250000000n,
      157104492187500000n,
      87280273437500000n,
      34912109375000000n,
      9521484375000000n,
      1586914062500000n,
      122070312500000n,
    ],
    [
      61035156250000n,
      854492187500000n,
      5554199218750000n,
      22216796875000000n,
      61096191406250000n,
      122192382812500000n,
      183288574218750000n,
      209472656250000000n,
      183288574218750000n,
      122192382812500000n,
      61096191406250000n,
      22216796875000000n,
      5554199218750000n,
      854492187500000n,
      61035156250000n,
    ],
    [
      30517578125000n,
      457763671875000n,
      3204345703125000n,
      13885498046875000n,
      41656494140625000n,
      91644287109375000n,
      152740478515625000n,
      196380615234375000n,
      196380615234375000n,
      152740478515625000n,
      91644287109375000n,
      41656494140625000n,
      13885498046875000n,
      3204345703125000n,
      457763671875000n,
      30517578125000n,
    ],
    [
      15258789062500n,
      244140625000000n,
      1831054687500000n,
      8544921875000000n,
      27770996093750000n,
      66650390625000000n,
      122192382812500000n,
      174560546875000000n,
      196380615234375000n,
      174560546875000000n,
      122192382812500000n,
      66650390625000000n,
      27770996093750000n,
      8544921875000000n,
      1831054687500000n,
      244140625000000n,
      15258789062500n,
    ],
  ]
  // static multipliers = [
  //   [
  //     [
  //       5600000000000000000n,
  //       2100000000000000000n,
  //       1100000000000000000n,
  //       1000000000000000000n,
  //       500000000000000000n,
  //       1000000000000000000n,
  //       1100000000000000000n,
  //       2100000000000000000n,
  //       5600000000000000000n,
  //     ],
  //     [
  //       5600000000000000000n,
  //       2000000000000000000n,
  //       1600000000000000000n,
  //       1000000000000000000n,
  //       700000000000000000n,
  //       700000000000000000n,
  //       1000000000000000000n,
  //       1600000000000000000n,
  //       2000000000000000000n,
  //       5600000000000000000n,
  //     ],
  //     [
  //       8900000000000000000n,
  //       3000000000000000000n,
  //       1400000000000000000n,
  //       1100000000000000000n,
  //       1000000000000000000n,
  //       500000000000000000n,
  //       1000000000000000000n,
  //       1100000000000000000n,
  //       1400000000000000000n,
  //       3000000000000000000n,
  //       8900000000000000000n,
  //     ],
  //     [
  //       8400000000000000000n,
  //       3000000000000000000n,
  //       1900000000000000000n,
  //       1300000000000000000n,
  //       1000000000000000000n,
  //       700000000000000000n,
  //       700000000000000000n,
  //       1000000000000000000n,
  //       1300000000000000000n,
  //       1900000000000000000n,
  //       3000000000000000000n,
  //       8400000000000000000n,
  //     ],
  //     [
  //       10000000000000000000n,
  //       3000000000000000000n,
  //       1600000000000000000n,
  //       1400000000000000000n,
  //       1100000000000000000n,
  //       1000000000000000000n,
  //       500000000000000000n,
  //       1000000000000000000n,
  //       1100000000000000000n,
  //       1400000000000000000n,
  //       1600000000000000000n,
  //       3000000000000000000n,
  //       10000000000000000000n,
  //     ],
  //     [
  //       8100000000000000000n,
  //       4000000000000000000n,
  //       3000000000000000000n,
  //       1900000000000000000n,
  //       1200000000000000000n,
  //       900000000000000000n,
  //       700000000000000000n,
  //       700000000000000000n,
  //       900000000000000000n,
  //       1200000000000000000n,
  //       1900000000000000000n,
  //       3000000000000000000n,
  //       4000000000000000000n,
  //       8100000000000000000n,
  //     ],
  //     [
  //       7100000000000000000n,
  //       4000000000000000000n,
  //       1900000000000000000n,
  //       1400000000000000000n,
  //       1300000000000000000n,
  //       1100000000000000000n,
  //       1000000000000000000n,
  //       500000000000000000n,
  //       1000000000000000000n,
  //       1100000000000000000n,
  //       1300000000000000000n,
  //       1400000000000000000n,
  //       1900000000000000000n,
  //       4000000000000000000n,
  //       7100000000000000000n,
  //     ],
  //     [
  //       15000000000000000000n,
  //       8000000000000000000n,
  //       3000000000000000000n,
  //       2000000000000000000n,
  //       1500000000000000000n,
  //       1100000000000000000n,
  //       1000000000000000000n,
  //       700000000000000000n,
  //       700000000000000000n,
  //       1000000000000000000n,
  //       1100000000000000000n,
  //       1500000000000000000n,
  //       2000000000000000000n,
  //       3000000000000000000n,
  //       8000000000000000000n,
  //       15000000000000000000n,
  //     ],
  //     [
  //       16000000000000000000n,
  //       9000000000000000000n,
  //       2000000000000000000n,
  //       1400000000000000000n,
  //       1400000000000000000n,
  //       1200000000000000000n,
  //       1100000000000000000n,
  //       1000000000000000000n,
  //       500000000000000000n,
  //       1000000000000000000n,
  //       1100000000000000000n,
  //       1200000000000000000n,
  //       1400000000000000000n,
  //       1400000000000000000n,
  //       2000000000000000000n,
  //       9000000000000000000n,
  //       16000000000000000000n,
  //     ],
  //   ],
  //   [
  //     [
  //       13000000000000000000n,
  //       3000000000000000000n,
  //       1300000000000000000n,
  //       700000000000000000n,
  //       400000000000000000n,
  //       700000000000000000n,
  //       1300000000000000000n,
  //       3000000000000000000n,
  //       13000000000000000000n,
  //     ],
  //     [
  //       18000000000000000000n,
  //       4000000000000000000n,
  //       1700000000000000000n,
  //       900000000000000000n,
  //       500000000000000000n,
  //       500000000000000000n,
  //       900000000000000000n,
  //       1700000000000000000n,
  //       4000000000000000000n,
  //       18000000000000000000n,
  //     ],
  //     [
  //       22000000000000000000n,
  //       5000000000000000000n,
  //       2000000000000000000n,
  //       1400000000000000000n,
  //       600000000000000000n,
  //       400000000000000000n,
  //       600000000000000000n,
  //       1400000000000000000n,
  //       2000000000000000000n,
  //       5000000000000000000n,
  //       22000000000000000000n,
  //     ],
  //     [
  //       24000000000000000000n,
  //       6000000000000000000n,
  //       3000000000000000000n,
  //       1800000000000000000n,
  //       700000000000000000n,
  //       500000000000000000n,
  //       500000000000000000n,
  //       700000000000000000n,
  //       1800000000000000000n,
  //       3000000000000000000n,
  //       6000000000000000000n,
  //       24000000000000000000n,
  //     ],
  //     [
  //       33000000000000000000n,
  //       11000000000000000000n,
  //       4000000000000000000n,
  //       2000000000000000000n,
  //       1100000000000000000n,
  //       600000000000000000n,
  //       300000000000000000n,
  //       600000000000000000n,
  //       1100000000000000000n,
  //       2000000000000000000n,
  //       4000000000000000000n,
  //       11000000000000000000n,
  //       33000000000000000000n,
  //     ],
  //     [
  //       43000000000000000000n,
  //       13000000000000000000n,
  //       6000000000000000000n,
  //       3000000000000000000n,
  //       1300000000000000000n,
  //       700000000000000000n,
  //       400000000000000000n,
  //       400000000000000000n,
  //       700000000000000000n,
  //       1300000000000000000n,
  //       3000000000000000000n,
  //       6000000000000000000n,
  //       13000000000000000000n,
  //       43000000000000000000n,
  //     ],
  //     [
  //       58000000000000000000n,
  //       15000000000000000000n,
  //       7000000000000000000n,
  //       4000000000000000000n,
  //       1900000000000000000n,
  //       1000000000000000000n,
  //       500000000000000000n,
  //       200000000000000000n,
  //       500000000000000000n,
  //       1000000000000000000n,
  //       1900000000000000000n,
  //       4000000000000000000n,
  //       7000000000000000000n,
  //       15000000000000000000n,
  //       58000000000000000000n,
  //     ],
  //     [
  //       88000000000000000000n,
  //       18000000000000000000n,
  //       11000000000000000000n,
  //       5000000000000000000n,
  //       3000000000000000000n,
  //       1300000000000000000n,
  //       500000000000000000n,
  //       300000000000000000n,
  //       300000000000000000n,
  //       500000000000000000n,
  //       1300000000000000000n,
  //       3000000000000000000n,
  //       5000000000000000000n,
  //       11000000000000000000n,
  //       18000000000000000000n,
  //       88000000000000000000n,
  //     ],
  //     [
  //       110000000000000000000n,
  //       41000000000000000000n,
  //       10000000000000000000n,
  //       5000000000000000000n,
  //       3000000000000000000n,
  //       1500000000000000000n,
  //       1000000000000000000n,
  //       500000000000000000n,
  //       300000000000000000n,
  //       500000000000000000n,
  //       1000000000000000000n,
  //       1500000000000000000n,
  //       3000000000000000000n,
  //       5000000000000000000n,
  //       10000000000000000000n,
  //       41000000000000000000n,
  //       110000000000000000000n,
  //     ],
  //   ],
  //   [
  //     [
  //       29000000000000000000n,
  //       4000000000000000000n,
  //       1500000000000000000n,
  //       300000000000000000n,
  //       200000000000000000n,
  //       300000000000000000n,
  //       1500000000000000000n,
  //       4000000000000000000n,
  //       29000000000000000000n,
  //     ],
  //     [
  //       43000000000000000000n,
  //       7000000000000000000n,
  //       2000000000000000000n,
  //       600000000000000000n,
  //       200000000000000000n,
  //       200000000000000000n,
  //       600000000000000000n,
  //       2000000000000000000n,
  //       7000000000000000000n,
  //       43000000000000000000n,
  //     ],
  //     [
  //       76000000000000000000n,
  //       10000000000000000000n,
  //       3000000000000000000n,
  //       900000000000000000n,
  //       300000000000000000n,
  //       200000000000000000n,
  //       300000000000000000n,
  //       900000000000000000n,
  //       3000000000000000000n,
  //       10000000000000000000n,
  //       76000000000000000000n,
  //     ],
  //     [
  //       120000000000000000000n,
  //       14000000000000000000n,
  //       5200000000000000000n,
  //       1400000000000000000n,
  //       400000000000000000n,
  //       200000000000000000n,
  //       200000000000000000n,
  //       400000000000000000n,
  //       1400000000000000000n,
  //       5200000000000000000n,
  //       14000000000000000000n,
  //       120000000000000000000n,
  //     ],
  //     [
  //       170000000000000000000n,
  //       24000000000000000000n,
  //       8100000000000000000n,
  //       2000000000000000000n,
  //       700000000000000000n,
  //       200000000000000000n,
  //       200000000000000000n,
  //       200000000000000000n,
  //       700000000000000000n,
  //       2000000000000000000n,
  //       8100000000000000000n,
  //       24000000000000000000n,
  //       170000000000000000000n,
  //     ],
  //     [
  //       260000000000000000000n,
  //       37000000000000000000n,
  //       11000000000000000000n,
  //       4000000000000000000n,
  //       1000000000000000000n,
  //       200000000000000000n,
  //       200000000000000000n,
  //       200000000000000000n,
  //       200000000000000000n,
  //       1000000000000000000n,
  //       4000000000000000000n,
  //       11000000000000000000n,
  //       37000000000000000000n,
  //       260000000000000000000n,
  //     ],
  //     [
  //       420000000000000000000n,
  //       56000000000000000000n,
  //       18000000000000000000n,
  //       5000000000000000000n,
  //       1900000000000000000n,
  //       300000000000000000n,
  //       200000000000000000n,
  //       200000000000000000n,
  //       200000000000000000n,
  //       300000000000000000n,
  //       1900000000000000000n,
  //       5000000000000000000n,
  //       18000000000000000000n,
  //       56000000000000000000n,
  //       420000000000000000000n,
  //     ],
  //     [
  //       620000000000000000000n,
  //       83000000000000000000n,
  //       27000000000000000000n,
  //       8000000000000000000n,
  //       3000000000000000000n,
  //       500000000000000000n,
  //       200000000000000000n,
  //       200000000000000000n,
  //       200000000000000000n,
  //       200000000000000000n,
  //       500000000000000000n,
  //       3000000000000000000n,
  //       8000000000000000000n,
  //       27000000000000000000n,
  //       83000000000000000000n,
  //       620000000000000000000n,
  //     ],
  //     [
  //       1000000000000000000000n,
  //       130000000000000000000n,
  //       26000000000000000000n,
  //       9000000000000000000n,
  //       4000000000000000000n,
  //       2000000000000000000n,
  //       200000000000000000n,
  //       200000000000000000n,
  //       200000000000000000n,
  //       200000000000000000n,
  //       200000000000000000n,
  //       2000000000000000000n,
  //       4000000000000000000n,
  //       9000000000000000000n,
  //       26000000000000000000n,
  //       130000000000000000000n,
  //       1000000000000000000000n,
  //     ],
  //   ],
  // ]
  static multipliers = [
    [
      [
        5600000000000000000n,
        2100000000000000000n,
        1100000000000000000n,
        1000000000000000000n,
        500000000000000000n,
        1000000000000000000n,
        1100000000000000000n,
        2100000000000000000n,
        5600000000000000000n,
      ],
      [
        5600000000000000000n,
        2000000000000000000n,
        1600000000000000000n,
        1000000000000000000n,
        700000000000000000n,
        700000000000000000n,
        1000000000000000000n,
        1600000000000000000n,
        2000000000000000000n,
        5600000000000000000n,
      ],
      [
        8800000000000000000n,
        3000000000000000000n,
        1400000000000000000n,
        1100000000000000000n,
        1000000000000000000n,
        500000000000000000n,
        1000000000000000000n,
        1100000000000000000n,
        1400000000000000000n,
        3000000000000000000n,
        8800000000000000000n,
      ],
      [
        8300000000000000000n,
        3000000000000000000n,
        1900000000000000000n,
        1300000000000000000n,
        1000000000000000000n,
        700000000000000000n,
        700000000000000000n,
        1000000000000000000n,
        1300000000000000000n,
        1900000000000000000n,
        3000000000000000000n,
        8300000000000000000n,
      ],
      [
        10000000000000000000n,
        3000000000000000000n,
        1600000000000000000n,
        1400000000000000000n,
        1100000000000000000n,
        1000000000000000000n,
        500000000000000000n,
        1000000000000000000n,
        1100000000000000000n,
        1400000000000000000n,
        1600000000000000000n,
        3000000000000000000n,
        10000000000000000000n,
      ],
      [
        8100000000000000000n,
        4000000000000000000n,
        3000000000000000000n,
        1900000000000000000n,
        1200000000000000000n,
        900000000000000000n,
        700000000000000000n,
        700000000000000000n,
        900000000000000000n,
        1200000000000000000n,
        1900000000000000000n,
        3000000000000000000n,
        4000000000000000000n,
        8100000000000000000n,
      ],
      [
        7000000000000000000n,
        4000000000000000000n,
        1900000000000000000n,
        1400000000000000000n,
        1300000000000000000n,
        1100000000000000000n,
        1000000000000000000n,
        500000000000000000n,
        1000000000000000000n,
        1100000000000000000n,
        1300000000000000000n,
        1400000000000000000n,
        1900000000000000000n,
        4000000000000000000n,
        7000000000000000000n,
      ],
      [
        14000000000000000000n,
        8000000000000000000n,
        3000000000000000000n,
        2000000000000000000n,
        1500000000000000000n,
        1100000000000000000n,
        1000000000000000000n,
        700000000000000000n,
        700000000000000000n,
        1000000000000000000n,
        1100000000000000000n,
        1500000000000000000n,
        2000000000000000000n,
        3000000000000000000n,
        8000000000000000000n,
        14000000000000000000n,
      ],
      [
        16000000000000000000n,
        9000000000000000000n,
        2000000000000000000n,
        1400000000000000000n,
        1400000000000000000n,
        1200000000000000000n,
        1100000000000000000n,
        1000000000000000000n,
        500000000000000000n,
        1000000000000000000n,
        1100000000000000000n,
        1200000000000000000n,
        1400000000000000000n,
        1400000000000000000n,
        2000000000000000000n,
        9000000000000000000n,
        16000000000000000000n,
      ],
    ],
    [
      [
        13000000000000000000n,
        3000000000000000000n,
        1300000000000000000n,
        700000000000000000n,
        400000000000000000n,
        700000000000000000n,
        1300000000000000000n,
        3000000000000000000n,
        13000000000000000000n,
      ],
      [
        18000000000000000000n,
        3950000000000000000n,
        1700000000000000000n,
        900000000000000000n,
        500000000000000000n,
        500000000000000000n,
        900000000000000000n,
        1700000000000000000n,
        3950000000000000000n,
        18000000000000000000n,
      ],
      [
        22000000000000000000n,
        5000000000000000000n,
        2000000000000000000n,
        1400000000000000000n,
        600000000000000000n,
        400000000000000000n,
        600000000000000000n,
        1400000000000000000n,
        2000000000000000000n,
        5000000000000000000n,
        22000000000000000000n,
      ],
      [
        23000000000000000000n,
        6000000000000000000n,
        3000000000000000000n,
        1800000000000000000n,
        700000000000000000n,
        500000000000000000n,
        500000000000000000n,
        700000000000000000n,
        1800000000000000000n,
        3000000000000000000n,
        6000000000000000000n,
        23000000000000000000n,
      ],
      [
        33000000000000000000n,
        11000000000000000000n,
        4000000000000000000n,
        2000000000000000000n,
        1100000000000000000n,
        600000000000000000n,
        300000000000000000n,
        600000000000000000n,
        1100000000000000000n,
        2000000000000000000n,
        4000000000000000000n,
        11000000000000000000n,
        33000000000000000000n,
      ],
      [
        43000000000000000000n,
        13000000000000000000n,
        6000000000000000000n,
        3000000000000000000n,
        1300000000000000000n,
        700000000000000000n,
        400000000000000000n,
        400000000000000000n,
        700000000000000000n,
        1300000000000000000n,
        3000000000000000000n,
        6000000000000000000n,
        13000000000000000000n,
        43000000000000000000n,
      ],
      [
        58000000000000000000n,
        15000000000000000000n,
        7000000000000000000n,
        4000000000000000000n,
        1900000000000000000n,
        1000000000000000000n,
        500000000000000000n,
        200000000000000000n,
        500000000000000000n,
        1000000000000000000n,
        1900000000000000000n,
        4000000000000000000n,
        7000000000000000000n,
        15000000000000000000n,
        58000000000000000000n,
      ],
      [
        88000000000000000000n,
        18000000000000000000n,
        11000000000000000000n,
        5000000000000000000n,
        3000000000000000000n,
        1300000000000000000n,
        500000000000000000n,
        300000000000000000n,
        300000000000000000n,
        500000000000000000n,
        1300000000000000000n,
        3000000000000000000n,
        5000000000000000000n,
        11000000000000000000n,
        18000000000000000000n,
        88000000000000000000n,
      ],
      [
        110000000000000000000n,
        41000000000000000000n,
        10000000000000000000n,
        5000000000000000000n,
        3000000000000000000n,
        1500000000000000000n,
        1000000000000000000n,
        500000000000000000n,
        300000000000000000n,
        500000000000000000n,
        1000000000000000000n,
        1500000000000000000n,
        3000000000000000000n,
        5000000000000000000n,
        10000000000000000000n,
        41000000000000000000n,
        110000000000000000000n,
      ],
    ],
    [
      [
        29000000000000000000n,
        4000000000000000000n,
        1500000000000000000n,
        300000000000000000n,
        197000000000000000n,
        300000000000000000n,
        1500000000000000000n,
        4000000000000000000n,
        29000000000000000000n,
      ],
      [
        43000000000000000000n,
        7000000000000000000n,
        2000000000000000000n,
        600000000000000000n,
        198000000000000000n,
        198000000000000000n,
        600000000000000000n,
        2000000000000000000n,
        7000000000000000000n,
        43000000000000000000n,
      ],
      [
        76000000000000000000n,
        10000000000000000000n,
        3000000000000000000n,
        900000000000000000n,
        300000000000000000n,
        197000000000000000n,
        300000000000000000n,
        900000000000000000n,
        3000000000000000000n,
        10000000000000000000n,
        76000000000000000000n,
      ],
      [
        120000000000000000000n,
        14000000000000000000n,
        5200000000000000000n,
        1400000000000000000n,
        400000000000000000n,
        196000000000000000n,
        196000000000000000n,
        400000000000000000n,
        1400000000000000000n,
        5200000000000000000n,
        14000000000000000000n,
        120000000000000000000n,
      ],
      [
        170000000000000000000n,
        24000000000000000000n,
        8100000000000000000n,
        2000000000000000000n,
        700000000000000000n,
        200000000000000000n,
        196000000000000000n,
        196000000000000000n,
        700000000000000000n,
        2000000000000000000n,
        8100000000000000000n,
        24000000000000000000n,
        170000000000000000000n,
      ],
      [
        260000000000000000000n,
        37000000000000000000n,
        11000000000000000000n,
        4000000000000000000n,
        1000000000000000000n,
        198000000000000000n,
        199000000000000000n,
        199000000000000000n,
        198000000000000000n,
        1000000000000000000n,
        4000000000000000000n,
        11000000000000000000n,
        37000000000000000000n,
        260000000000000000000n,
      ],
      [
        420000000000000000000n,
        56000000000000000000n,
        18000000000000000000n,
        5000000000000000000n,
        1900000000000000000n,
        300000000000000000n,
        200000000000000000n,
        200000000000000000n,
        200000000000000000n,
        300000000000000000n,
        1900000000000000000n,
        5000000000000000000n,
        18000000000000000000n,
        56000000000000000000n,
        420000000000000000000n,
      ],
      [
        620000000000000000000n,
        83000000000000000000n,
        27000000000000000000n,
        8000000000000000000n,
        3000000000000000000n,
        500000000000000000n,
        199000000000000000n,
        199000000000000000n,
        199000000000000000n,
        199000000000000000n,
        500000000000000000n,
        3000000000000000000n,
        8000000000000000000n,
        27000000000000000000n,
        83000000000000000000n,
        620000000000000000000n,
      ],
      [
        1000000000000000000000n,
        130000000000000000000n,
        26000000000000000000n,
        9000000000000000000n,
        4000000000000000000n,
        2000000000000000000n,
        200000000000000000n,
        200000000000000000n,
        200000000000000000n,
        200000000000000000n,
        200000000000000000n,
        2000000000000000000n,
        4000000000000000000n,
        9000000000000000000n,
        26000000000000000000n,
        130000000000000000000n,
        1000000000000000000000n,
      ],
    ],
  ]
}
