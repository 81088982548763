export const fontFacesCSS = css`
  @font-face {
    font-family: 'Gohu';
    src: url('/fonts/Gohu/gohu-small-uni-14.ttf') format('truetype');
  }
  @font-face {
    font-family: 'GohuSmall';
    src: url('/fonts/Gohu/gohu-small-uni-11.ttf') format('truetype');
  }
  @font-face {
    font-family: 'GohuUppercase';
    src: url('/fonts/Gohu/gohu-font-uppercase-14.woff') format('woff');
  }
  @font-face {
    font-family: 'PixeBoy';
    src: url('/fonts/PixeBoy.ttf') format('ttf');
  }
`

export const FONT_STYLES = {
  xs: css`
    /* font-family: 'GohuSmall' !important; */
    font-size: 11px;
    line-height: 12px;
  `,
  // In the root style, everything within body uses FONT_STYLES.sm by default
  sm: css`
    font-size: 14px;
    line-height: 16px;
  `,
  md: css`
    font-size: 14px;
    line-height: 16px;
  `,
  lg: css`
    font-size: 18px;
    line-height: 16px;
  `,
  xl: css`
    font-size: 24px;
    line-height: 16px;
  `,
  xxl: css`
    font-size: 48px;
    line-height: 36px;
  `,
}
