import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react'
import { Text as TextDrei } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { useBombsGameStore } from '@/store/useBombsGameStore'
import { useGameOutcomeStore } from '@/store/useGameOutcomeStore'

const OddsDisplay: React.FC = () => {
  const { bombCount, selectedTiles, gameState, calculateOdds } = useBombsGameStore(state => ({
    bombCount: state.bombCount,
    selectedTiles: state.selectedTiles,
    gameState: state.gameState,
    calculateOdds: state.calculateOdds,
  }))

  const { didPlayerWin, isShowingOutcome } = useGameOutcomeStore(state => ({
    didPlayerWin: state.didPlayerWin,
    isShowingOutcome: state.isShowingOutcome,
  }))

  const textRef = useRef<THREE.Mesh>(null)
  const [scale, setScale] = useState<number>(1)
  const [color, setColor] = useState<string>('#ffffff')
  const animationRef = useRef<number | null>(null)

  const odds = useMemo(() => {
    const multiplier = calculateOdds()
    return multiplier.toFixed(2)
  }, [calculateOdds])

  const animateProperty = useCallback(
    (start: number, end: number, duration: number, updateFn: (value: number) => void): void => {
      const startTime = Date.now()
      const animate = (): void => {
        const now = Date.now()
        const progress = Math.min((now - startTime) / duration, 1)
        const value = start + (end - start) * progress
        updateFn(value)
        if (progress < 1) {
          animationRef.current = requestAnimationFrame(animate)
        }
      }
      if (animationRef.current !== null) {
        cancelAnimationFrame(animationRef.current)
      }
      animationRef.current = requestAnimationFrame(animate)
    },
    []
  )

  useEffect(() => {
    if (gameState === 'RESOLVE') {
      animateProperty(1, 1.1, 300, setScale)
      setColor('#ffffff')
    } else if (gameState === 'IDLE') {
      animateProperty(1.2, 1, 300, setScale)
      setColor('#ffffff')
    }
  }, [gameState, animateProperty])

  useEffect(() => {
    if (isShowingOutcome) {
      animateProperty(1.1, 1.2, 300, setScale)
      setColor(didPlayerWin ? '#1aff1a' : '#ff1a1a')
    }
  }, [didPlayerWin, animateProperty, isShowingOutcome])

  useEffect(() => {
    return () => {
      if (animationRef.current !== null) {
        cancelAnimationFrame(animationRef.current)
      }
    }
  }, [])

  useFrame(state => {
    if (textRef.current) {
      textRef.current.position.y = Math.sin(state.clock.elapsedTime * 2) * 0.025 + 2.5
      textRef.current.scale.setScalar(scale)
    }
  })

  return (
    <TextDrei
      ref={textRef}
      position={[0, 2.5, -1.5]}
      rotation-x={-Math.PI / 5}
      fontSize={0.5}
      color={color}
      font='/fonts/Gohu/gohu-small-uni-14.ttf'
    >
      <meshStandardMaterial
        attach='material'
        color={color}
        emissive={color}
        emissiveIntensity={1.25}
      />
      {odds}x
    </TextDrei>
  )
}

export default OddsDisplay
