import { fsocket } from '@/lib/fsocket'
import { useActiveWallet } from '@/lib/privy/hooks'
import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'
import { useToken } from '@privy-io/react-auth'
import { useDebounceCallback } from 'usehooks-ts'

const isLocal = import.meta.env.VITE_DEPLOYMENT_ENV === 'local'

export const MountFSocket = () => {
  const { activeWallet } = useActiveWallet()
  const { websocketUrl } = useAppChainConfigStore.use.appChainConfig()
  const { getAccessToken } = useToken()

  const connectToSocket = useDebounceCallback(
    async (wsUrl: string) => {
      if (isLocal) {
        const authToken = (await getAccessToken()) as string
        fsocket.reconnectAll({ wsUrl, authToken })
      } else {
        fsocket.reconnectAll({ wsUrl })
      }
    },
    500,
    { trailing: true }
  )

  useEffect(() => {
    connectToSocket(websocketUrl)
  }, [activeWallet, websocketUrl])

  return null
}
