/* eslint-disable react/prop-types */
import { useMemo, useRef } from 'react'
import { motion } from 'framer-motion-3d'
import { ShaderMaterial, Color, Vector2, CustomBlending, AddEquation, ZeroFactor, SrcAlphaFactor } from 'three'
import { useFrame } from '@react-three/fiber'
import gridFrag from './shaders/grid.frag'
import gridVert from './shaders/grid.vert'

interface GameGridProps {
  position?: [number, number, number]
  rotation?: [number, number, number]
  size?: [number, number]
  color?: string
  emissive?: string
  emissiveIntensity?: number
  opacity?: number
  gridSpacing?: number
}

export const GameGrid: React.FC<GameGridProps> = ({
  position = [0, -2, 0],
  rotation = [-Math.PI / 2, 0, 0],
  size = [100, 100],
  color = '#000000',
  emissive = '#000000',
  emissiveIntensity = 1,
  opacity = 0.5,
  gridSpacing = 0.22,
}) => {
  const shaderRef = useRef<ShaderMaterial>(null)

  const uniforms = useMemo(
    () => ({
      uTime: { value: 0 },
      uGridSpacing: { value: gridSpacing },
      uColor: { value: new Color(color) },
      uEmissive: { value: new Color(emissive) },
      uEmissiveIntensity: { value: emissiveIntensity },
      uOpacity: { value: opacity },
    }),
    [color, emissive, emissiveIntensity, opacity, gridSpacing]
  )

  useFrame(({ clock }) => {
    if (shaderRef.current) {
      shaderRef.current.uniforms.uTime.value = clock.getElapsedTime()
    }
  })

  return (
    <motion.group position={position}>
      <mesh rotation={rotation}>
        <planeGeometry args={size} />
        <shaderMaterial
          ref={shaderRef}
          fragmentShader={gridFrag}
          vertexShader={gridVert}
          uniforms={uniforms}
          transparent
          depthWrite={false}
          blending={CustomBlending}
          blendEquation={AddEquation}
          blendSrcAlpha={ZeroFactor}
          blendSrc={SrcAlphaFactor}
        />
      </mesh>
    </motion.group>
  )
}
