import { Text as TextDrei } from '@react-three/drei'
import { type ReactThreeFiber } from '@react-three/fiber'
import { Suspense, forwardRef } from 'react'

export type DiceNumberProps = JSX.IntrinsicElements['mesh'] & {
  visible?: boolean
  text?: string
  characters?: string
  color?: ReactThreeFiber.Color
  fontSize?: number
  maxWidth?: number
  lineHeight?: number
  letterSpacing?: number
  textAlign?: 'left' | 'right' | 'center' | 'justify'
  font?: string
  anchorX?: number | 'left' | 'center' | 'right'
  anchorY?: number | 'top' | 'top-baseline' | 'middle' | 'bottom-baseline' | 'bottom'
  clipRect?: [number, number, number, number]
  depthOffset?: number
  direction?: 'auto' | 'ltr' | 'rtl'
  overflowWrap?: 'normal' | 'break-word'
  whiteSpace?: 'normal' | 'overflowWrap' | 'nowrap'
  outlineWidth?: number | string
  outlineOffsetX?: number | string
  outlineOffsetY?: number | string
  outlineBlur?: number | string
  outlineColor?: ReactThreeFiber.Color
  outlineOpacity?: number
  strokeWidth?: number | string
  strokeColor?: ReactThreeFiber.Color
  strokeOpacity?: number
  fillOpacity?: number
  sdfGlyphSize?: number
  debugSDF?: boolean
  textRend?: string
  onSync?: (troika: any) => void
}

export const DiceNumber = forwardRef<DiceNumberProps, any>(
  (props, ref: React.ForwardedRef<DiceNumberProps>) => {
    return (
      <TextDrei
        ref={ref}
        characters='.0123456789'
        font='/fonts/Gohu/gohu-small-uni-14.ttf'
        {...props}
      >
        {props.textRend || ''}
        <meshStandardMaterial attach={'material'} />
      </TextDrei>
    )
  }
)

DiceNumber.displayName = 'DiceNumber'
