import { type MouseEventHandler, type UIEventHandler } from 'react'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { AnimatePresence } from 'framer-motion'
import { useDebouncedCallback } from 'use-debounce'

import { fsocket } from '@/lib/fsocket'
import { ensureString } from '@/utils/text'
import { PNGS, SVGS } from '@/assets'
import { TrollMessage } from './TrollMessage'
import { emojiPickerVariant, fadeInOutVariant } from './variants'
import {
  SBottomScroller,
  SChatInputActions,
  SChatList,
  SEmojiButton,
  SInput,
  SPickerWrapper,
  SSendBtn,
  STrollBox,
} from './styles'
import { TrollBoxLoadingOverlay } from './TrollBoxLoadingOverlay'
import { MAX_CHAT_MSG_LENGTH } from '@/lib/fsocket/space'
import { addAppNoti } from '@/store/useNotiStore'
import { useActiveWallet } from '@/lib/privy/hooks'

export const TrollBox = () => {
  const chatListRef = useRef<HTMLDivElement>(null)
  const msgRef = useRef<any>(null)
  const [msg, setMsg] = useState('')
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [autoScroll, setAutoScroll] = useState(true)
  const { chatMsgs } = fsocket.chat.useState()
  const { walletAddress } = useActiveWallet()

  /* Memos */
  const characterCount = useMemo(() => msg.length, [msg])

  const chatMsgElems = useMemo(() => {
    return chatMsgs.map(msg => {
      // @TODO: This may not be needed since we created the new username hook in TrollMessage
      const displayName = msg.username ? msg.username : msg.createdBy.substring(0, 10)
      const displayMsg = { ...msg, username: displayName }

      return <TrollMessage msg={displayMsg} key={msg.id} />
    })
  }, [chatMsgs])

  /* Callbacks */
  const handleClickEmojiBtn: MouseEventHandler<HTMLButtonElement> = useCallback(
    ev => {
      ev.stopPropagation()
      setShowEmojiPicker(val => !val)
    },
    [setShowEmojiPicker]
  )

  const debouncedScrollToBottom = useDebouncedCallback(
    () => {
      const attemptScroll = (retryCount: number = 0) => {
        if (!chatListRef.current) return

        chatListRef.current.scrollTo({
          top: chatListRef.current.scrollHeight,
          behavior: 'smooth',
        })

        // Wait a bit longer to allow for smooth scrolling to complete
        setTimeout(
          () => {
            if (!chatListRef.current) return
            // Calculate current bottom position
            const isAtBottom =
              chatListRef.current.scrollTop + chatListRef.current.clientHeight >=
              chatListRef.current.scrollHeight - 1

            if (!isAtBottom && retryCount < 3) {
              // Check if it's still not at the bottom and under retry limit
              attemptScroll(retryCount + 1)
            }
          },
          retryCount === 0 ? 500 : 200
        ) // Longer initial delay to accommodate smooth scroll
      }

      attemptScroll()
    },
    100,
    { leading: false, trailing: true }
  )

  const immediateScrollToBottom = useCallback(() => {
    if (!chatListRef.current) return
    chatListRef.current.scrollTo({
      top: chatListRef.current.scrollHeight,
      behavior: 'instant',
    })
  }, [])

  const checkScrollPosition: UIEventHandler<HTMLDivElement> = useDebouncedCallback(_ev => {
    if (!chatListRef.current) return
    const { scrollTop, clientHeight, scrollHeight } = chatListRef.current as HTMLDivElement
    const atBottom = scrollTop + clientHeight >= scrollHeight - 10
    setAutoScroll(atBottom)
  }, 20)

  const onClickScrollToBottom = useCallback(() => {
    debouncedScrollToBottom()
    setAutoScroll(true)
  }, [])

  const onMsgSubmission = useCallback(async () => {
    try {
      if (!walletAddress) {
        return addAppNoti({
          type: 'error',
          msg: 'Please connect wallet to send messages',
        })
      }

      if (ensureString(msg).trim().length === 0) return

      fsocket.chat.sendChatMsg(msg)
      immediateScrollToBottom()
      setAutoScroll(true)
      setShowEmojiPicker(false)
      setMsg('')
    } catch (err) {
      console.error(err)
    }
  }, [msg, walletAddress])

  const onMsgChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newMsg = ensureString(e.currentTarget.value)
      if (newMsg.length > MAX_CHAT_MSG_LENGTH) return

      setMsg(newMsg.trimStart())
    },
    [setMsg]
  )

  const handleEmojiSelect = useCallback(
    (emoji: any) => {
      const { selectionStart, selectionEnd } = msgRef.current
      const newMsg = msg.slice(0, selectionStart) + emoji.native + msg.slice(selectionEnd)
      setMsg(newMsg)
      // msgRef.current.focus()
    },
    [msg]
  )

  const handleClickOutside: any = useCallback(
    (_props: any) => {
      setShowEmojiPicker(false)
    },
    [setShowEmojiPicker]
  )

  /* Effects */
  useEffect(() => {
    if (autoScroll && chatMsgElems.length > 0) {
      onClickScrollToBottom()
    }
  }, [chatMsgElems, autoScroll])

  return (
    <STrollBox>
      <TrollBoxLoadingOverlay />
      <AnimatePresence>
        {showEmojiPicker && (
          <SPickerWrapper
            variants={emojiPickerVariant}
            initial='initial'
            animate='animate'
            exit='exit'
          >
            <Picker
              previewPosition='none'
              navPosition='none'
              perLine={8}
              emojiSize={14}
              emojiButtonSize={26}
              emojiButtonRadius={'6px'}
              data={data}
              onEmojiSelect={handleEmojiSelect}
              theme={'dark'}
              autoFocus
              onClickOutside={handleClickOutside}
            />
          </SPickerWrapper>
        )}
      </AnimatePresence>
      <AnimatePresence mode='wait'>
        <SChatList ref={chatListRef} onScroll={checkScrollPosition} layoutScroll>
          {!autoScroll && (
            <SBottomScroller
              layoutId={'scroller'}
              layout
              key='bottom-scroller'
              onClick={onClickScrollToBottom}
              variants={fadeInOutVariant}
              initial='initial'
              animate='animate'
              exit='exit'
            >
              <img src={SVGS.scrollIcon} alt='Scroll' />
            </SBottomScroller>
          )}
          {chatMsgElems}
        </SChatList>
      </AnimatePresence>
      <SChatInputActions>
        <SInput
          customRef={msgRef}
          className='chat-box-input'
          onChange={onMsgChange}
          value={msg}
          type='text'
          maxLength={MAX_CHAT_MSG_LENGTH}
          onEnter={_e => onMsgSubmission()}
          placeholder={'Type a message...'}
        />
        <SEmojiButton className='emoji-btn' onClick={handleClickEmojiBtn}>
          <img src={PNGS.emojiIcon} alt='emoji' />
        </SEmojiButton>
        <SSendBtn
          $hasMessageText={characterCount > 0}
          className='chat-send-btn'
          disabled={characterCount === 0}
          type='button'
          onClick={() => onMsgSubmission()}
        >
          SEND
        </SSendBtn>
      </SChatInputActions>
    </STrollBox>
  )
}
