import { useCoinFlipGameState } from '@/store/useGameStateStore'
import { useShallow } from 'zustand/react/shallow'
import { clamp } from '@/utils'
import { CoinCamera } from './CoinCamera'
import { CoinGround } from './CoinGround'
import { RefactorCoinModel } from './RefactorCoinModel'
import { type CoinState } from './Coin'
import { cumulativeBinomialProbability } from '../shared/probabilityUtils'

const CoinModelMemo = memo(RefactorCoinModel)
interface CoinSceneProps {
  playGameSound: (soundName: string, volume?: number, pitch?: number) => void
}

// eslint-disable-next-line react/prop-types
export const CoinScene: React.FC<CoinSceneProps> = ({ playGameSound }) => {
  // console.log('DEBUG Rendering CoinScene')

  /* Memos */
  const {
    side,
    setSide,
    coinCount,
    type: gameState,
    results,
    submittedAmount,
  } = useCoinFlipGameState(
    useShallow(state => ({
      side:
        state.submittedEntry && state.type !== 'IDLE' ?
          state.submittedEntry.side
        : state.entry.side,
      setSide: (side: 0 | 1) => state.setEntry({ side }),
      coinCount:
        state.submittedEntry && state.type !== 'IDLE' ?
          state.submittedEntry.entryCount
        : state.entry.entryCount,
      type: state.type,
      results: state.results,
      submittedAmount: state.submittedEntry?.entryAmount,
    }))
  )
  const { gap, cols, zoomFactor } = useMemo(
    () => ({
      gap: {
        x: 4.25,
        y: 4.25,
      },
      cols: 5,
      zoomFactor: 0.7,
    }),
    []
  )
  const count = useMemo(() => clamp(coinCount, 1, 20), [coinCount])

  useEffect(() => {
    if (gameState === 'START') {
      const soundName = count === 1 ? 'startSingle' : 'startMulti'
      playGameSound(soundName, 0.3) // Adjust volume as needed
    }
  }, [gameState, count, playGameSound])

  const actualWins = useMemo(() => {
    return results?.resultSides.map(result => (result === side ? 1 : 0)) || []
  }, [results, side])

  const probabilities = useMemo(() => {
    const p = 0.5
    const cumulativeProbabilities = []
    for (let i = 0; i < actualWins.length; i++) {
      const wins = actualWins.slice(0, i + 1).reduce<number>((acc, win) => acc + win, 0)
      cumulativeProbabilities.push(cumulativeBinomialProbability(count, wins, p))
    }
    // console.log('probabilities', cumulativeProbabilities)
    return cumulativeProbabilities
  }, [actualWins])

  // Calculate winsToBreakeven
  const winsToBreakeven = useMemo(() => count / 1.98, [count])

  // Calculate intensity array
  const intensities = useMemo(() => {
    return probabilities.map((odds, index) => {
      if (index < winsToBreakeven) return 0

      if (odds <= 0.13) return 5
      else if (odds <= 0.3) return 4
      else if (odds <= 0.5) return 3
      else if (odds <= 0.65) return 2
      else return 1
    })
  }, [probabilities])

  const coins = useMemo(() => {
    const coinNumbers = Array.from({ length: count }, (_, index) => index + 1)

    return coinNumbers.map((key, idx) => {
      const resultSide = results?.resultSides[idx]
      const resultDeltaAmount = results?.deltaAmounts[idx]
      const playedCount = results?.playedCount
      const hasHitLimit = results ? idx + 1 > results.playedCount : false
      let coinState: CoinState = 'idle'

      if (hasHitLimit) {
        coinState = 'isOverLimit'
      }

      return (
        <CoinModelMemo
          key={key}
          coinIdx={idx}
          gap={gap}
          cols={cols}
          side={side}
          setSide={setSide}
          gameState={gameState}
          resultSide={resultSide}
          // @NOTE: Allows the delta amount text to use submitted amount
          resultDeltaAmount={resultDeltaAmount || 0}
          hasHitLimit={hasHitLimit}
          playedCount={playedCount || 1}
          isWinningOverall={
            count === 1 ? true : (
              actualWins.slice(0, idx + 1).reduce<number>((acc, win) => acc + win, 0) >=
              winsToBreakeven
            )
          }
          intensity={intensities[idx]}
          playGameSound={playGameSound}
          totalCoins={count}
        />
      )
    })
  }, [count, gap, cols, side, gameState, setSide, playGameSound])

  return (
    <>
      <CoinCamera coinCount={count} gap={gap} cols={cols} zoomFactor={zoomFactor} />
      <ambientLight intensity={1} />
      <directionalLight intensity={5} color={'white'} position={[-2, 3.5, -3]} />

      {coins}

      <CoinGround coinCount={count} gap={gap} cols={cols} zoomFactor={zoomFactor} />
      {/* <Preload all /> */}
    </>
  )
}
