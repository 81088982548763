import React, { useMemo, useEffect, useCallback } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useForm } from 'react-hook-form'

import useCurrencyStore from '@/store/useCurrencyStore'
import { FormWrapper, FormFooter } from '../style'
import { AmountInputField } from '../FormComponents/shared/AmountInputField'
import { NumberOfEntriesField } from '../FormComponents/shared/NumberOfEntriesField'
import { SliderInput } from '../FormComponents/shared/SliderInput'
import { BombTileGrid } from './BombTileGrid'
import { useBombsGameStore } from '@/store/useBombsGameStore'
import { useSound } from '@/components/shared/SoundSystem/SoundContext'
import { GameButton } from '@/components/shared/Button/GameButton'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { FormLayout } from '@/components/Forms/FormComponents/shared/FormLayout'
import { SDoubleInputRow } from '../FormComponents/shared/FormLayout/styles'
import { getMaxCount } from '@/lib/crypto'
import { routeGameTypeMap } from '@/chains/lib'

export interface BombsFormData {
  entryAmount: number
  bombCount: number
  numberOfEntries: number
}

export const BombsForm: React.FC = () => {
  const { pathname } = useLocation()
  const isMobileScreen = useIsBreakpoint('sm')

  const { selectedBalance } = useCurrencyStore(
    useShallow(state => ({
      selectedBalance: state.balances.currency,
    }))
  )

  // click sfx
  const soundContext = useSound()

  const {
    bombCount,
    selectedTiles,
    entryAmount,
    selectTile,
    setBombCount,
    setEntryAmount,
    isReselectingTiles,
    toggleIsReselectingTiles,
    gameState,
    setNumberOfEntries,
  } = useBombsGameStore()

  const balanceNumber = useMemo(() => Number(selectedBalance) || 0, [selectedBalance])

  const { control, watch, setValue } = useForm<BombsFormData>({
    defaultValues: {
      entryAmount: 0,
      bombCount: bombCount,
      numberOfEntries: 1,
    },
  })

  const formData = watch()

  useEffect(() => {
    setBombCount(formData.bombCount)
  }, [formData.bombCount, setBombCount])

  useEffect(() => {
    setEntryAmount(formData.entryAmount)
  }, [formData.entryAmount, setEntryAmount])

  useEffect(() => {
    setValue('bombCount', bombCount)
  }, [bombCount, setValue])

  useEffect(() => {
    setValue('entryAmount', entryAmount)
  }, [entryAmount, setValue])

  useEffect(() => {
    setNumberOfEntries(formData.numberOfEntries)
  }, [formData.numberOfEntries, setNumberOfEntries])

  const handleTileClick = (index: number) => {
    selectTile(index)
    if (gameState === 'IDLE') {
      soundContext.playSound('TileSelect', 0.5, 1)
    }
  }

  const calculateMaxBombCount = useCallback(() => {
    const selectedTileCount = selectedTiles.filter(Boolean).length
    return Math.min(24, 25 - selectedTileCount)
  }, [selectedTiles])

  const maxBombCount = useMemo(calculateMaxBombCount, [calculateMaxBombCount])

  const convertSelectedTilesToSelectedTileIndexes = useCallback(() => {
    return selectedTiles.reduce((indexesOfTrue: number[], currentValue: boolean, index: number) => {
      if (currentValue) {
        indexesOfTrue.push(index)
      }
      return indexesOfTrue
    }, [])
  }, [selectedTiles])

  const selectedTileIndexes = useMemo(convertSelectedTilesToSelectedTileIndexes, [
    convertSelectedTilesToSelectedTileIndexes,
  ])

  useEffect(() => {
    if (bombCount > maxBombCount) {
      setBombCount(maxBombCount)
    }
  }, [maxBombCount, bombCount, setBombCount])

  const [maxCountForSide, setMaxCountForSide] = useState(20)

  useEffect(() => {
    const maxCount = getMaxCount(routeGameTypeMap[pathname], {
      bombCount: formData.bombCount,
      revealCount: selectedTileIndexes.length,
    } as any)
    // @NOTE: I could have done what I have done below with a single if but using if and else if makes it easier to understand
    if (formData.numberOfEntries > maxCount) {
      // @NOTE: If their current count is above the new max count, I am setting their count to be the new max count
      setValue('numberOfEntries', maxCount)
    } else if (maxCountForSide === formData.numberOfEntries && maxCount > maxCountForSide) {
      // @NOTE: If someone had fulled the slider to have max count, and if they move the slider for win chance that causes the max count to increase, I autyo increase their selected count to be new max
      setValue('numberOfEntries', maxCount)
    }
    setMaxCountForSide(maxCount)
  }, [
    formData.bombCount,
    selectedTileIndexes.length,
    formData.numberOfEntries,
    setValue,
    maxCountForSide,
    pathname,
  ])

  return (
    <FormWrapper>
      <FormLayout>
        <SliderInput
          label='Bomb Count'
          label2={`Max: ${maxBombCount}`}
          name='bombCount'
          control={control}
          min={1}
          max={maxBombCount}
          step={1}
        />
        <SDoubleInputRow style={{ paddingBottom: '20px' }}>
          <AmountInputField
            control={control}
            name='entryAmount'
            label='Amount'
            balance={balanceNumber}
            maxAmount={1_000_000_000}
          />
          <NumberOfEntriesField
            control={control}
            name='numberOfEntries'
            entryAmount={formData.entryAmount / (formData.numberOfEntries || 1)}
            maxEntries={maxCountForSide}
          />
        </SDoubleInputRow>
        {!isMobileScreen && (
          <BombTileGrid
            selectedTiles={selectedTiles}
            onTileClick={handleTileClick}
            maxSelections={25 - bombCount}
            isReselectingTiles={isReselectingTiles}
            toggleIsReselectingTiles={toggleIsReselectingTiles}
          />
        )}
      </FormLayout>
      <FormFooter>
        <GameButton
          formData={{
            ...formData,
            side: {
              bombCount,
              revealCount: selectedTileIndexes.length,
              revealIndexes: selectedTileIndexes,
            },
          }}
          entryAmountNum={entryAmount}
        />
      </FormFooter>
    </FormWrapper>
  )
}
