import { HandleLocalStorageWithAccountChange } from '@/components/shared/Panels/RightPanel/HandleLocalStorageWithAccountChange'
import { MountFSocket } from './MountFSocket'
import { AllowanceListener } from './AllowanceListener'
import { BalanceListener } from './BalanceListener'
import { UserDataListener } from './UserDataListener'
import { AASessionListener } from './AASessionListener'
import { RouteParamsListener } from './RouteParamsListener'
import { AppChainConfigListener } from './AppChainConfigListener'

export function SingletonListeners() {
  const [showListeners, setShowListeners] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowListeners(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (!showListeners) {
    return null;
  }

  return (
    <>
      <AppChainConfigListener />
      <BalanceListener />
      <AllowanceListener />
      <HandleLocalStorageWithAccountChange />
      <UserDataListener />
      <MountFSocket />
      <AASessionListener />
      <RouteParamsListener />
    </>
  )
}
