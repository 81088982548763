import { SVGS } from '@/assets'
import { deviceBP } from '@/design/breakpoints'
import { DeathmatchTabOption, changeDeathmatchTab } from '@/store/proxy/deathmatchTabs'

const STabOption = styled.div<{ $isActive?: boolean }>`
  position: relative;
  min-width: 120px;
  transition: all ease-in-out 0.12s;
  border-bottom: 1px solid ${props => (props.$isActive ? 'white' : 'transparent')};
  color: ${props => (props.$isActive ? 'white' : '#aaa')};
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  .active-tab-line {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 120px;
    height: 2px;
  }

  @media ${deviceBP.sm} {
    min-width: unset;
    flex: 1;

    .active-tab-line {
      width: auto;
    }
  }
`

const SLeaderboardTabs = styled.div`
  position: relative;
  display: flex;
  gap: 24px;
  padding-bottom: 18px;
`

const SLearnMoreBtn = styled.a`
  all: unset;
  position: absolute;
  right: 0px;
  top: 6px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  img {
    margin-right: 8px;
  }

  @media ${deviceBP.sm} {
    top: -108px;
  }
`

interface ILeaderboardTabs {
  selectedTab: DeathmatchTabOption
}

const LeaderboardTabs = ({ selectedTab }: ILeaderboardTabs) => {
  return (
    <SLeaderboardTabs>
      <STabOption
        $isActive={selectedTab === 'quests'}
        onClick={() => changeDeathmatchTab('quests')}
      >
        <span>QUESTS</span>
        <div className='active-tab-line'></div>
      </STabOption>
      {/* <STabOption */}
      {/*   $isActive={selectedTab === 'invite codes'} */}
      {/*   onClick={() => changeDeathmatchTab('invite codes')} */}
      {/* > */}
      {/*   INVITE CODES */}
      {/* </STabOption> */}
      <STabOption
        $isActive={selectedTab === 'leaderboard'}
        onClick={() => changeDeathmatchTab('leaderboard')}
      >
        Leaderboard
      </STabOption>

      <SLearnMoreBtn
        href='https://x.com/fareplayio/status/1785005624763306143?s=46'
        target='_blank'
      >
        <img src={SVGS.info} alt='Sharing' /> LEARN MORE
      </SLearnMoreBtn>
    </SLeaderboardTabs>
  )
}

export default LeaderboardTabs
