import { Chain } from 'viem'

// NOTE: This enum determines the chains that are active
export enum SupportedAppChainId {
  ARBITRUM_MAINNET = 42161,
  // ARBITRUM_SEPOLIA = 421614,
  // BASE_SEPOLIA = 84532,
  // BASE_MAINNET = 8453,
  // BERACHAIN_BARTIO = 80084,
}

// NOTE: Single source of truth for anything related to chains
export type ChainName = 'BERA' | 'ARBITRUM' | 'ARBITRUM_SEPOLIA' | 'BASE' | 'BASE_SEPOLIA'
export type CurrencyName = 'PHONEY' | 'USDC'
export type NativeTokenName = 'ETH' | 'tBera'
type NetworkType = 'MAINNET' | 'TESTNET'
type CurrencyDecimals = 6 | 18
export type AppContractName = 'bankroll' | 'vault' | 'currency' | 'aa'
export enum AppGameName {
  Dice = 'dice',
  RPS = 'rps',
  CoinFlip = 'coinFlip',
  Bombs = 'bombs',
  Plinko = 'plinko',
  Crash = 'crash',
}

// Define nested types separately
export interface AppFeatures {
  enableMobile: boolean
  enablePosthog: boolean
  enableFaucet: boolean
}

export interface AppServices {
  alchemy: {
    apiKey: string
  }
  posthog: {
    host: string
    key: string
  }
  walletConnect: {
    projectId: string
  }
}

export interface AppChainConfig {
  websocketUrl: string
  httpUrl: string
  addresses: Record<AppContractName, string>
  rpc: {
    ws: string
    http: string
  }
  features: AppFeatures
  services: AppServices
  chainDefinition: Chain
  networkStyle: AppChainNetworkStyle
  biconomyConfig: AppBiconomyConfig
}

export type CurrencyColors = {
  one: string
  two: string
  three: string
  four: string
}

export type AppChainNetworkStyle = {
  networkName: ChainName
  currencyIcon: string
  networkLogo: string
  networkType: NetworkType
  currencyName: CurrencyName
  nativeToken: NativeTokenName
  menuColor: CurrencyColors
  particleColors: string | string[]
  decimals: CurrencyDecimals
}

export type AppBiconomyConfig = {
  bundlerUrl: string
  paymasterUrl: string
  payMasterAPIKey: string
  paymasterId: string
}
