import type { Properties } from 'posthog-js'
import { usePostHog } from 'posthog-js/react'

export const usePostLog = () => {
  const posthog = usePostHog()

  const log = (...args: any[]) => ({
    warning: () => {
      console.log(`%c ${args.join(' ')}`, 'background: #FFFF00; color: black;')
    },
    error: (err?: Error) => {
      const message = args.join(' ')
      const errorStack = err?.stack ? `\nStack Trace:\n${err.stack}` : ''
      console.log(`%c ${message}${errorStack}`, 'background: #FF0000; color: white;')
    },
    success: () => {
      console.log(`%c ${args.join(' ')}`, 'background: #008000; color: black;')
    },
    info: () => {
      console.log(`%c ${args.join(' ')}`, 'background: #0000FF; color: white;')
    },
  })

  // Universal log function that handle warning, error, success, and info logs
  // as well as posthog capture events with optional metadata
  function postlog(
    logPayload: string | [string, ...any[]],
    metadata?:
      | (Properties & {
          loglevel?: keyof ReturnType<typeof log>
          eventName?: string
          errorTrace?: Error
        })
      | keyof ReturnType<typeof log>
  ): void {
    if (typeof metadata === 'string') {
      if (typeof logPayload === 'string') {
        log(logPayload)[metadata]()
      } else {
        log(...logPayload)[metadata]()
      }
    } else if (metadata) {
      const { eventName, loglevel, ...properties } = metadata
      if (loglevel) {
        if (typeof logPayload === 'string') {
          log(logPayload)[loglevel]()
        } else {
          console.log(logPayload)
          if (loglevel === 'error') {
            log(...logPayload)[loglevel](metadata.errorTrace)
          } else {
            log(...logPayload)[loglevel]()
          }
        }
      } else {
        if (typeof logPayload === 'string') {
          log(logPayload).info()
        } else {
          log(...logPayload).info()
        }
      }

      if (eventName) {
        posthog.capture(eventName, { ...properties, loglevel, logPayload })
      }
    } else {
      log(logPayload).info()
    }
  }

  return { log, posthog, postlog }
}
