import { TrollBox } from '@/components/shared/TrollBox'
import { fadeInLeftVariant } from '@/design/animations'
import { RightPanelWrapper } from '../style'
import { OverviewPanel } from './OverviewPanel'

export const RightPanel = () => {
  return (
    <RightPanelWrapper variants={fadeInLeftVariant} initial='initial' animate='animate'>
      <OverviewPanel />
      <TrollBox />
    </RightPanelWrapper>
  )
}
