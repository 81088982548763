import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export interface ILeaderboardArrayData {
  usdcBalance: string
  fundOwnerAddress: string
  winningPoints: number
  losingPoints: number
  referralPoints: number
  totalPoints: number
  killPoints: number
  deathPoints: number
  assistPoints: number
  level: number
  username: string
  avatarSeedAddress: string
  isUsingTwitterToDisplay: boolean
  twitterUsername: string
  twitterDisplayName: string
  twitterImage: string
}

export type LeaderboardState = {
  leaderboard?: ILeaderboardArrayData[]
}

export type LeaderboardActions = {
  setLeaderboard: (leaderboard: ILeaderboardArrayData[]) => void
}

export const initialLeaderboardState: LeaderboardState = {
  leaderboard: undefined,
}

export type LeaderboardStore = LeaderboardState & LeaderboardActions

const useLeaderboardStore = create<LeaderboardStore, [['zustand/immer', LeaderboardStore]]>(
  immer(set => ({
    ...initialLeaderboardState,
    setLeaderboard: leaderboard =>
      set(() => ({
        leaderboard,
      })),
  }))
)

export default useLeaderboardStore
