import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'
import axios from 'axios'

export const useUserApi = () => {
  const { httpUrl } = useAppChainConfigStore.use.appChainConfig()

  const userRoute = (route: string) => `${httpUrl}/user/${route}`

  const getUserEmail = async () => {
    const { data } = await axios({
      url: userRoute('email'),
      method: 'GET',
      withCredentials: true,
    })

    return data as { email: string }
  }

  const updateUserEmail = async (email: string) => {
    if (!email) throw new Error('Email is required')

    const { data } = await axios({
      url: userRoute('email'),
      method: 'POST',
      data: { email },
      withCredentials: true,
    })

    return data as { message: string }
  }

  const updateUserUsername = async (username: string) => {
    if (!username) throw new Error('Email is required')

    const { data } = await axios({
      url: userRoute('username'),
      method: 'POST',
      data: { username },
      withCredentials: true,
    })

    return data as { message: string }
  }

  const updateAvatarSeedAddress = async () => {
    const { data } = await axios({
      url: userRoute('reroll-avatar-seed'),
      method: 'POST',
      withCredentials: true,
    })

    return data as { newAvatarSeedAddress: string }
  }

  const updateIsUsingTwitterToDisplay = async (isUsingTwitterToDisplay: boolean) => {
    const { data } = await axios({
      url: userRoute('is-using-twitter-to-display'),
      method: 'POST',
      data: {
        isUsingTwitterToDisplay,
      },
      withCredentials: true,
    })

    return data as { message: string }
  }

  const unlinkUserTwitterAccount = async () => {
    const { data } = await axios({
      url: userRoute('unlink-twitter'),
      method: 'POST',
      withCredentials: true,
    })

    return data as { message: string }
  }

  return useMemo(
    () => ({
      getUserEmail,
      updateUserEmail,
      updateUserUsername,
      updateAvatarSeedAddress,
      updateIsUsingTwitterToDisplay,
      unlinkUserTwitterAccount,
    }),
    [httpUrl]
  )
}
