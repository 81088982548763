import { useForm, Controller } from 'react-hook-form'
import CountUp from 'react-countup'
import numeral from 'numeral'

import {
  FormAmountLabel,
  FormFooter,
  FormIndicator,
  FormLabel,
  FormLabelRow,
  FormTab,
  FormWrapper,
  SFormSection,
} from '../style'
import { ModeButton } from '@/components/shared/Button/style'
import { type ISUContractForm } from '@/store/useSUContractStore'
import { CoinFlipSelection } from '@/lib/crypto/coinFlip'
import { clamp, ensureNumber } from '@/utils/math'
import { FareNumberInput } from '@/components/shared/Input/FareNumberInput'
import { useCoinFlipGameState } from '@/store/useGameStateStore'
import { useShallow } from 'zustand/react/shallow'
import { GameButton } from '@/components/shared/Button/GameButton'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { useCurrency } from '@/hooks/useCurrency'
import { FormLayout } from '../FormComponents/shared/FormLayout'
import { SDoubleInputRow } from '../FormComponents/shared/FormLayout/styles'
import { DEFAULT_MAX_COUNT } from '@/lib/crypto/constants'
import { useBufferZone } from '@/hooks/useBufferZone'

export const CoinFlipForm = () => {
  const { balances, networkStyle } = useCurrency()

  const balanceNumber = Number(balances.currency)

  const { entry, setEntry } = useCoinFlipGameState(
    useShallow(state => ({
      entry: state.entry,
      setEntry: state.setEntry,
    }))
  )
  const isMobileScreen = useIsBreakpoint('sm')

  const {
    control,
    // handleSubmit,
    // register,
    watch,
    // formState,
    // reset
  } = useForm<ISUContractForm>({
    defaultValues: {
      entryAmount: 0,
      numberOfEntries: 1,
      stopLoss: 0,
      stopGain: 0,
    },
    mode: 'onChange',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
  })
  const formData = watch()
  const { entryAmount, numberOfEntries } = formData

  const entryAmountNum = numeral(entryAmount).value() || 0

  const { bufferedZone: bufferedMaxEntryAmount, stepZone } = useBufferZone(formData.side)

  // @NOTE: This is a workaround with react-hook-form so that numberOfEntries updates the global state
  // Whenever it is change. We need to move away from react-hook-form
  useEffect(() => {
    const value = clamp(ensureNumber(numberOfEntries), 1, 20) // Clamp so entryCount is between 1 and 20
    // setEntryCount(value)
    setEntry({ entryCount: value })
  }, [numberOfEntries, setEntry])

  return (
    <FormWrapper>
      <FormLayout>
        <FormTab $tabs={2} className='mobile-tabs'>
          <ModeButton
            $isActive={entry.side === CoinFlipSelection.Heads}
            onClick={() => setEntry({ side: CoinFlipSelection.Heads })}
          >
            Heads
          </ModeButton>
          <ModeButton
            $isActive={entry.side === CoinFlipSelection.Tails}
            onClick={() => setEntry({ side: CoinFlipSelection.Tails })}
          >
            Tails
          </ModeButton>
        </FormTab>
        <form onSubmit={e => e.preventDefault()}>
          <SDoubleInputRow className='mobile-form'>
            <SFormSection>
              <FormLabelRow>
                <FormLabel>
                  <FormIndicator $isActive={entryAmountNum > 0} />
                  <FormAmountLabel>Amount</FormAmountLabel>
                </FormLabel>
                <FormLabel>
                  {balanceNumber > 0 && (
                    <FormAmountLabel>
                      <CountUp
                        end={balanceNumber}
                        decimals={2}
                        duration={2}
                        separator={','}
                        preserveValue
                      />
                    </FormAmountLabel>
                  )}
                </FormLabel>
              </FormLabelRow>
              <Controller
                name='entryAmount'
                control={control}
                rules={{ required: true, max: bufferedMaxEntryAmount, min: 0 }}
                defaultValue={0}
                render={({ field }) => {
                  const {
                    // ref,
                    ...props
                  } = field
                  const value = numeral(field.value).value() || 0
                  const isDisabled = (numeral(balances.currency).value() || 0) === 0
                  return (
                    <FareNumberInput
                      {...props}
                      // getInputRef={ref}
                      onChange={event => {
                        field.onChange(numeral(event.target.value).value() as any)
                      }}
                      allowLeadingZeros={false}
                      allowNegative={false}
                      thousandSeparator=','
                      decimalScale={2}
                      hasInputSlider
                      disabled={isDisabled}
                      inputSuffix={
                        <img
                          src={networkStyle.currencyIcon}
                          alt={networkStyle.currencyName}
                          width={20}
                        />
                      }
                      inputSliderProps={{
                        value,
                        onChange: sliderValue => {
                          field.onChange(sliderValue)
                        },
                        min: 0,
                        max: bufferedMaxEntryAmount,
                        step: stepZone,
                      }}
                    />
                  )
                }}
              />
            </SFormSection>
            <SFormSection>
              <FormLabelRow>
                <FormLabel>
                  <FormIndicator $isActive={numberOfEntries > 0} />
                  {isMobileScreen ?
                    <FormAmountLabel> Rounds</FormAmountLabel>
                  : <FormAmountLabel> Number of Entries</FormAmountLabel>}
                </FormLabel>
                {!isMobileScreen && (
                  <FormLabel>
                    {Number(entryAmount / (numberOfEntries || 1)) > 0 && (
                      <FormAmountLabel>
                        <CountUp
                          end={Number(entryAmount / (numberOfEntries || 1))}
                          decimals={2}
                          duration={0.18}
                          separator={','}
                          preserveValue
                        />
                        <span>&nbsp;/ entry</span>
                      </FormAmountLabel>
                    )}
                  </FormLabel>
                )}
              </FormLabelRow>
              <Controller
                name='numberOfEntries'
                control={control}
                rules={{
                  required: 'Must be 20 or less.',
                  max: { message: 'Must be less than 20', value: 20 },
                  min: 1,
                }}
                render={({ field }) => {
                  const MIN = 1
                  const MAX = 20
                  const value = numeral(field.value).value()
                  const clampedValue = clamp(ensureNumber(value), MIN, MAX)
                  const {
                    // ref,
                    ...props
                  } = field
                  return (
                    <FareNumberInput
                      {...props}
                      // getInputRef={ref}
                      onChange={event => field.onChange(ensureNumber(event.currentTarget.value))}
                      onBlur={event => {
                        const val = clamp(ensureNumber(event.currentTarget.value), MIN, MAX)
                        field.onChange(val)
                      }}
                      allowLeadingZeros={false}
                      allowNegative={false}
                      hasInputSlider
                      inputSliderProps={{
                        value: clampedValue,
                        onChange: sliderValue => field.onChange(sliderValue),
                        min: 1,
                        max: DEFAULT_MAX_COUNT,
                        step: 1,
                      }}
                    />
                  )
                }}
              />
            </SFormSection>
          </SDoubleInputRow>
        </form>
      </FormLayout>
      <FormFooter>
        <GameButton formData={{ ...formData, side: entry.side }} entryAmountNum={entryAmountNum} />
      </FormFooter>
    </FormWrapper>
  )
}
