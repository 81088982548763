import { PrivyClientConfig, WalletListEntry } from '@privy-io/react-auth'

import { FARE_COLORS } from '@/design'
import { defaultSupportedChain, supportedChains } from '@/chains'

export type PrivyLoginMethod = PrivyClientConfig['loginMethods']

export const PRIVY_APP_ID = import.meta.env.VITE_PRIVY_APP_ID || 'cm2mgmv1g06oz12h3qrxie4k7'
export const PRIVY_APP_CLIENT_ID =
  import.meta.env.VITE_PRIVY_APP_CLIENT_ID || 'client-WY5cjJTuri7cwFC8eCUk8LjmcibTWmkMJj8nefphRTLBs'

export const privyLoginMethods = [
  'wallet',
  'email',
  'sms',
  'google',
  'twitter',
  // 'discord',
  // 'telegram',
  // 'apple',
] as PrivyLoginMethod

export const privyWalletList = [
  'metamask',
  'coinbase_wallet',
  'rainbow',
  'wallet_connect',
  'safe',
] as WalletListEntry[]

export const privyConfig: PrivyClientConfig = {
  defaultChain: defaultSupportedChain,
  supportedChains,
  // Display email and wallet as login methods
  loginMethods: privyLoginMethods,
  // Customize Privy's appearance in your app
  appearance: {
    walletList: privyWalletList,
    theme: 'dark',
    accentColor: FARE_COLORS.aqua,
    logo: 'https://app.fareplay.io/images/metaimage.png',
  },
  // Create embedded wallets for users who don't have a wallet
  embeddedWallets: {
    createOnLogin: 'users-without-wallets',
    showWalletUIs: false,
  },
  externalWallets: {
    coinbaseWallet: {
      connectionOptions: 'eoaOnly',
    },
  },
}
