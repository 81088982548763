// import { usePrivyService } from '@/lib/privy/usePrivyService'
// import useUserDataStore from '@/store/useUserDataStore'
// import { usePrivy } from '@privy-io/react-auth'
// import { usePostHog } from 'posthog-js/react'

export const UserDataListener = () => {
  // const { user, ready, authenticated } = usePrivy()
  // const setUserData = useUserDataStore(state => state.setUserData)
  // const { fetchInitialUserData } = usePrivyService()
  // const posthog = usePostHog()
  // const privyAddress = useMemo(() => user?.wallet?.address, [user?.wallet])

  // useEffect(() => {
  //   if (!ready || !authenticated) return
  //   console.log('NEW PRIVY ADDRESS', privyAddress)
  //   ;(async () => {
  //     const { userData } = await fetchInitialUserData()
  //     setUserData(userData)
  //   })()
  // }, [privyAddress, ready, authenticated])

  // useEffect(() => {
  //   if (!authPublicAddress) {
  //     posthog?.identify(authPublicAddress, {
  //       username,
  //       authPublicAddress,
  //     })
  //     return
  //   }
  //   console.log('Posthog Identify for address:', authPublicAddress)
  //   posthog?.identify(authPublicAddress, {
  //     username,
  //     authPublicAddress,
  //   })
  //   posthog?.group('bera-deathmatch', authPublicAddress)
  // }, [authPublicAddress])
  return null
}
