import { useConnectWallet, useLinkAccount, useLogin, useLogout } from '@privy-io/react-auth'
import { usePrivyService } from '../usePrivyService'
import { useActiveWallet } from './useActiveWallet'

export const useAuthWallet = () => {
  const { activeWallet, isWalletAuthed, user } = useActiveWallet()
  const { privyFareLogout } = usePrivyService()
  const shouldPromptLoginRef = useRef(false)
  const [shouldPromptLogin, setShouldPromptLogin] = useState(false)
  const { login: privyAppLogin } = useLogin({
    onComplete(...privyLoginParams) {
      console.log('privyLoginParams', privyLoginParams)
    },
    onError(privyError) {
      console.log('privyLoginError', privyError)
    },
    onOAuthLoginComplete() {
      console.log('OAuth login complete')
    },
  })
  const { connectWallet } = useConnectWallet({
    onSuccess: wallet => {
      console.log('Wallet connected', wallet)
      // Any logic you'd like to execute after a user successfully connects their wallet
    },
    onError: error => {
      console.log('connectWallet Error', error)
      // Any logic you'd like to execute after a user exits the connection flow or there is an error
    },
  })
  const { logout: privyAppLogout } = useLogout({
    onSuccess() {
      console.log('Logged out of privy')
      if (shouldPromptLoginRef.current) {
        shouldPromptLoginRef.current = false
        setShouldPromptLogin(true)
      }
    },
  })

  useEffect(() => {
    if (shouldPromptLogin) {
      setShouldPromptLogin(false)
      privyAppLogin()
    }
  }, [shouldPromptLogin])

  const { linkGoogle, linkEmail, linkWallet } = useLinkAccount({
    onSuccess: (user, linkMethod, linkedAccount) => {
      console.log(user, linkMethod, linkedAccount)
      // Any logic you'd like to execute if the user successfully links an account while this
      // component is mounted
    },
    onError: (error, details) => {
      console.log(error, details)
      if (details.linkMethod === 'siwe' && error === 'linked_to_another_user') {
        shouldPromptLoginRef.current = true
        privyAppLogout()
      }
      // Any logic you'd like to execute after a user exits the link flow or there is an error
    },
  })

  const isConnectedUserALinkedAccount = useMemo(() => {
    if (!user || !activeWallet) return false
    return Boolean(
      user.linkedAccounts.find(
        linkedAccount =>
          ((linkedAccount as any).address || '').toLowerCase() ===
          activeWallet.address.toLowerCase()
      )
    )
  }, [user, activeWallet])

  const logout = useCallback(async () => {
    await privyAppLogout()
    await privyFareLogout()
  }, [privyAppLogout])

  const linkWalletToUser = useCallback(async () => {
    try {
      if (activeWallet) {
        try {
          linkWallet()
        } catch (err) {
          console.log('RECEIVED ERROR', err)
        }
      }
    } catch (err) {
      console.log('ERRRRR', err)
    }
  }, [activeWallet, privyAppLogin, connectWallet, logout, isConnectedUserALinkedAccount])

  const linkOrLoginWallet = useCallback(async () => {
    try {
      if (activeWallet && isConnectedUserALinkedAccount) {
        try {
          await activeWallet.loginOrLink()
        } catch (err) {
          console.log('RECEIVED ERROR', err)
        }
      } else if (activeWallet && !isConnectedUserALinkedAccount) {
        shouldPromptLoginRef.current = true
        privyAppLogout()
      } else if (!activeWallet && !isConnectedUserALinkedAccount && user) {
        shouldPromptLoginRef.current = true
        privyAppLogout()
      } else {
        privyAppLogin()
      }
    } catch (err) {
      console.log('ERRRRR', err)
    }
  }, [activeWallet, privyAppLogin, connectWallet, logout, isConnectedUserALinkedAccount])

  return {
    logout,
    linkOrLoginWallet,
    linkWalletToUser,
    isWalletAuthed,
  }
}
