import { create } from 'zustand'

export type WalletState = {
  isShowingWalletModal: boolean
}

export type WalletStoreActions = {
  setIsShowingWalletModal: (isShowingWalletModal: boolean) => void
}

export const initialWalletState: WalletState = {
  isShowingWalletModal: false,
}

export type WalletStore = WalletState & WalletStoreActions

const useWalletStore = create<WalletStore>((set) => ({
  ...initialWalletState,
  setIsShowingWalletModal: (isShowingWalletModal) =>
    set(() => ({
      isShowingWalletModal,
    })),
}))

export default useWalletStore
