uniform float uTime;
uniform float uGridSpacing;
uniform vec3 uColor;
uniform vec3 uEmissive;
uniform float uEmissiveIntensity;
uniform float uOpacity;

varying vec2 vUv;
varying vec3 vPosition;

void main(){
    vec2 coord=vPosition.xy;
    vec2 grid=abs(fract(coord/uGridSpacing-.5)-.5)/fwidth(coord/uGridSpacing);
    float line=min(grid.x,grid.y);
    
    float color=1.-min(line,1.);
    color=pow(color,1./2.2);
    
    vec3 finalColor=mix(uColor,uEmissive,color*uEmissiveIntensity);
    float finalOpacity=color*uOpacity;
    
    gl_FragColor=vec4(finalColor,finalOpacity);
}