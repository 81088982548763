import React from 'react'
import { AnimatePresence } from 'framer-motion'
import { ContentWrapper } from './style'

interface AnimatedWrapperProps {
  children: React.ReactNode
  isVisible: boolean
  animateProps?: object
  exitProps?: object
  transitionDuration?: number
}

export const AnimatedWrapper: React.FC<AnimatedWrapperProps> = ({ children, isVisible }) => {
  const animationVariants = {
    initial: {
      opacity: 0,
      scaleY: 0,
    },
    animate: {
      opacity: 1,
      scaleY: 1,
      transition: {
        duration: 0.1,
        ease: [0.12, 0, 0.39, 0],
      },
    },
    exit: {
      opacity: 0,
      scaleY: 0.8,
      transition: {
        duration: 0.3,
        ease: [0.42, 0, 0.58, 1],
      },
    },
  }

  return (
    <AnimatePresence mode='wait'>
      {isVisible && (
        <ContentWrapper
          key='content-wrapper'
          variants={animationVariants}
          initial='initial'
          animate='animate'
          exit='exit'
        >
          {children}
        </ContentWrapper>
      )}
    </AnimatePresence>
  )
}
