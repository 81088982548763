import { proxy } from 'valtio'

export const initialBaseSpaceState = {
  isConnecting: true,
  isConnected: false,
}

export const createBaseSpaceState = () => proxy(initialBaseSpaceState)

// Export SpaceState types
export * from './chat.state'
export * from './liveEntry.state'
export * from './suEntry.state'
export * from './user.state'
