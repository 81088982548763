import { SelectWalletModal } from '@/components/Privy/SelectWalletModal'
import { MobileNoticeBlocker } from '@/components/shared/MobileNoticeBlocker'
import { FaucetModal } from '@/components/Testnet/FaucetModal'
import GeofenceModal from '../GeofenceModal'

export const SingletonModals = () => {
  return (
    <>
      <SelectWalletModal />
      <MobileNoticeBlocker />
      <FaucetModal />
      <GeofenceModal />
    </>
  )
}
