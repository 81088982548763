import { useState, useEffect, useRef } from 'react'

interface CanvasSize {
  width: number
  height: number
}

export const useCanvasResize = (
  containerRef: React.RefObject<HTMLDivElement>,
  canvasRef: React.RefObject<HTMLCanvasElement>
) => {
  const [canvasSize, setCanvasSize] = useState<CanvasSize>({ width: 0, height: 0 })
  const [isResizing, setIsResizing] = useState(false)
  const resizeTimeoutRef = useRef<number | null>(null)

  useEffect(() => {
    const container = containerRef.current
    const canvas = canvasRef.current

    if (container && canvas) {
      const updateCanvasSize = () => {
        setIsResizing(true)
        const newWidth = container.clientWidth
        const newHeight = container.clientHeight
        setCanvasSize({ width: newWidth, height: newHeight })
        canvas.style.width = `${newWidth}px`
        canvas.style.height = `${newHeight}px`

        if (resizeTimeoutRef.current !== null) {
          clearTimeout(resizeTimeoutRef.current)
        }
        resizeTimeoutRef.current = window.setTimeout(() => {
          setIsResizing(false)
        }, 100)
      }

      updateCanvasSize()

      const resizeObserver = new ResizeObserver(updateCanvasSize)
      resizeObserver.observe(container)

      return () => {
        resizeObserver.disconnect()
        if (resizeTimeoutRef.current !== null) {
          clearTimeout(resizeTimeoutRef.current)
        }
      }
    }
  }, [containerRef, canvasRef])

  return { canvasSize, isResizing }
}
