import React from 'react'
import { Controller, type Control } from 'react-hook-form'
import numeral from 'numeral'
import CountUp from 'react-countup'
import { FareNumberInput } from '@/components/shared/Input/FareNumberInput'
import { FormAmountLabel, FormIndicator, FormLabel, FormLabelRow, SFormSection } from '../../style'
import { clamp, ensureNumber } from '@/utils/math'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { DEFAULT_MAX_COUNT } from '@/lib/crypto/constants'

interface NumberOfEntriesFieldProps {
  control: Control<any>
  name: string
  entryAmount: number
  maxEntries?: number
}

export const NumberOfEntriesField: React.FC<NumberOfEntriesFieldProps> = ({
  control,
  name,
  entryAmount,
  maxEntries = DEFAULT_MAX_COUNT,
}) => {
  const isMobileScreen = useIsBreakpoint('sm')
  return (
    <SFormSection>
      <FormLabelRow>
        <FormLabel>
          <FormIndicator $isActive={true} />
          {isMobileScreen ?
            <FormAmountLabel> Rounds</FormAmountLabel>
          : <FormAmountLabel> Number of Entries</FormAmountLabel>}
        </FormLabel>
        {!isMobileScreen && (
          <FormLabel>
            {Number(entryAmount) > 0 && (
              <FormAmountLabel>
                <CountUp
                  end={Number(entryAmount)}
                  decimals={2}
                  duration={0.18}
                  separator=','
                  preserveValue
                />
                <span>&nbsp;/ entry</span>
              </FormAmountLabel>
            )}
          </FormLabel>
        )}
      </FormLabelRow>
      <Controller
        name={name}
        control={control}
        rules={{
          required: 'Must be 20 or less.',
          max: { message: `Must be less than ${maxEntries}`, value: maxEntries },
          min: 1,
        }}
        render={({ field }) => {
          const MIN = 1
          const MAX = maxEntries
          const value = numeral(field.value).value()
          const clampedValue = clamp(ensureNumber(value), MIN, MAX)

          return (
            <FareNumberInput
              {...field}
              onChange={event => field.onChange(ensureNumber(event.currentTarget.value))}
              onBlur={event => {
                const val = clamp(ensureNumber(event.currentTarget.value), MIN, MAX)
                field.onChange(val)
              }}
              allowLeadingZeros={false}
              allowNegative={false}
              hasInputSlider
              inputSliderProps={{
                value: clampedValue,
                onChange: sliderValue => field.onChange(sliderValue),
                min: MIN,
                max: MAX,
                step: 1,
              }}
            />
          )
        }}
      />
    </SFormSection>
  )
}
