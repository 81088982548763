import { useThree, type PerspectiveCameraProps } from '@react-three/fiber'
import { LayoutCamera } from 'framer-motion-3d'
import { useAnimationControls } from 'framer-motion'
import { useDebouncedCallback } from 'use-debounce'

interface ICoinCamera {
  coinCount: number
  gap?: {
    x: number
    y: number
  }
  cols?: number
  zoomFactor?: number
}

export const CoinCamera = ({
  coinCount = 1,
  gap = { x: 4.5, y: 4.25 },
  cols = 5,
  zoomFactor = 0.7,
  ...props
}: PerspectiveCameraProps & ICoinCamera) => {
  const controls = useAnimationControls()
  const { size } = useThree()

  // Memos
  const aspect = useMemo(() => size.width / size.height, [size])

  // Callbacks
  const adjustCameraPosition = useDebouncedCallback(
    async (count = 1, gap: { x: number; y: number }, cols: number, zoom: number) => {
      let posX = 0
      let posY = 0
      let posZ = 10

      if (count > 1) {
        // Calculate the current row
        const currentRow = Math.floor((count - 1) / cols)
        const coinsInFirstRow = Math.min(count, cols)

        // Center the camera based on the number of coins in the first row
        posX = ((coinsInFirstRow - 1) / 2) * gap.x

        // Calculate the Y position based on the current row
        posY = (-currentRow * gap.y) / 2

        // Calculate the Z position based on the spread of X and Y
        const spreadX = (coinsInFirstRow - 1) * gap.x
        const spreadY = currentRow * gap.y
        const additionalDistance = Math.max(spreadX, spreadY)

        // Add additionalDistance and apply zoom to Z position
        posZ += additionalDistance * zoom
      }

      controls.stop()
      await controls.start({
        x: posX,
        y: posY,
        z: posZ,
        transition: {
          x: {
            type: 'tween',
            ease: 'easeInOut',
            duration: 0.2,
          },
          y: {
            type: 'tween',
            ease: 'easeInOut',
            duration: 0.2,
          },
          z: {
            type: 'tween',
            ease: 'linear',
            duration: 0.35,
          },
        },
      })
    },
    15
  )

  // Effects
  useEffect(() => {
    adjustCameraPosition(coinCount, gap, cols, zoomFactor)
  }, [adjustCameraPosition, coinCount, gap, cols, zoomFactor])

  return (
    <LayoutCamera
      near={0.1}
      far={100}
      position={[0, 0, 10]}
      aspect={aspect}
      animate={controls}
      {...(props as any)}
    />
  )
}
