import skull1Complete from './deathmatch-skulls/lvl-1-complete-skull.svg'
import skull2Complete from './deathmatch-skulls/lvl-2-complete-skull.svg'
import skull3Complete from './deathmatch-skulls/lvl-3-complete-skull.svg'
import skull4Complete from './deathmatch-skulls/lvl-4-complete-skull.svg'
import skull5Complete from './deathmatch-skulls/lvl-5-complete-skull.svg'
import skull6Complete from './deathmatch-skulls/lvl-6-complete-skull.svg'

import skull1Incomplete from './deathmatch-skulls/lvl-1-incomplete-skull.svg'
import skull2Incomplete from './deathmatch-skulls/lvl-2-incomplete-skull.svg'
import skull3Incomplete from './deathmatch-skulls/lvl-3-incomplete-skull.svg'
import skull4Incomplete from './deathmatch-skulls/lvl-4-incomplete-skull.svg'
import skull5Incomplete from './deathmatch-skulls/lvl-5-incomplete-skull.svg'
import skull6Incomplete from './deathmatch-skulls/lvl-6-incomplete-skull.svg'

export const skulls = {
  skull1Complete,
  skull2Complete,
  skull3Complete,
  skull4Complete,
  skull5Complete,
  skull6Complete,
  skull1Incomplete,
  skull2Incomplete,
  skull3Incomplete,
  skull4Incomplete,
  skull5Incomplete,
  skull6Incomplete,
}
