import React from 'react'
import {
  CheckboxContainer,
  CheckboxLabel,
  GridContainer,
  GridWrapper,
  SFormGridSection,
  StyledCheckbox,
  Tile,
} from './styles'
import { motion } from 'framer-motion'
import { FARE_COLORS } from '@/design'

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.02,
    },
  },
}

const tileVariants = {
  hidden: {
    scale: 0,
    y: -50,
  },
  visible: {
    scale: 1,
    y: 0,
    transition: { duration: 0.1, ease: 'easeOut' },
  },
  hover: {
    scale: 0.95,
  },
  tap: {
    scale: 0.85,
  },
}

const draw = {
  hidden: { strokeDasharray: '0 100', opacity: 0 },
  visible: {
    strokeDasharray: '100 0',
    opacity: 1,
    transition: {
      strokeDasharray: { type: 'spring', duration: 1, bounce: 0 },
      opacity: { duration: 0.01 },
    },
  },
}

export interface BombTileGridProps {
  selectedTiles: boolean[]
  onTileClick: (index: number) => void
  maxSelections: number
  isReselectingTiles: boolean
  toggleIsReselectingTiles: () => void
}

export const BombTileGrid: React.FC<BombTileGridProps> = ({
  selectedTiles,
  onTileClick,
  maxSelections,
  isReselectingTiles,
  toggleIsReselectingTiles,
}) => {
  const currentSelections = selectedTiles.filter(Boolean).length

  const handleTileClick = (index: number) => {
    if (selectedTiles[index] || currentSelections < maxSelections) {
      onTileClick(index)
    }
  }

  return (
    <SFormGridSection>
      <GridWrapper>
        <GridContainer
          as={motion.div}
          variants={containerVariants}
          initial='hidden'
          animate='visible'
        >
          {selectedTiles.map((isSelected, index) => (
            <Tile
              key={index}
              $isSelected={isSelected}
              onClick={() => handleTileClick(index)}
              $isDisabled={!isSelected && currentSelections >= maxSelections}
              variants={tileVariants}
              whileHover='hover'
              whileTap='tap'
            >
              <motion.svg className='border-draw' viewBox='0 0 100 100'>
                <motion.rect
                  x='0'
                  y='0'
                  width='100'
                  height='100'
                  rx='4'
                  ry='4'
                  stroke={FARE_COLORS.aqua}
                  strokeWidth='1'
                  fill='none'
                  variants={draw}
                  initial='hidden'
                  animate={isSelected ? 'visible' : 'hidden'}
                />
              </motion.svg>
            </Tile>
          ))}
        </GridContainer>
        <CheckboxContainer>
          <CheckboxLabel htmlFor='reselectCheckbox'>KEEP SELECTION</CheckboxLabel>
          <StyledCheckbox
            type='checkbox'
            id='reselectCheckbox'
            checked={isReselectingTiles}
            onChange={toggleIsReselectingTiles}
          />
        </CheckboxContainer>
      </GridWrapper>
    </SFormGridSection>
  )
}
