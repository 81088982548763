import type { LiveEntrySocket, ILiveEntry } from '../types'
import { FSpaceBase, IFSpaceBaseOpts } from './FSpace'
import { LiveEntryFState, initialLiveEntryFState } from '../state'
import { useDelayedLiveEntry } from '@/store/useGameStateStore'
import useUserDataStore from '@/store/useUserDataStore'
import { MAX_LIVE_ENTRIES } from '@/lib/fare/state/gameLiveEntrySlice'
import { nanoid } from 'nanoid'

type LiveEntrySpaceOpts = Omit<IFSpaceBaseOpts<LiveEntryFState>, 'name' | 'status' | 'state'>

export class LiveEntrySpace extends FSpaceBase<LiveEntrySocket, LiveEntryFState> {
  constructor({ fsocketOpts, authToken }: LiveEntrySpaceOpts) {
    super({
      name: 'live-entry',
      state: initialLiveEntryFState,
      fsocketOpts,
      authToken,
    })

    this.mountListeners()
  }

  onReconnectSocket(): void {
    this.state.liveEntries = []
  }

  mountListeners() {
    if (this.hasMountedListeners) return
    this.hasMountedListeners = true
    this.io.on('initial_state', initialLiveEntries => {
      this.state.liveEntries = initialLiveEntries.map(liveEntry => {
        return {
          ...liveEntry,
          reactKey: nanoid(),
        }
      })
    })

    // @TODO: Ensure that the same chat message isn't added more than once
    this.io.on('new_live_entry', _liveEntry => {
      const liveEntry: ILiveEntry = {
        ..._liveEntry,
        reactKey: nanoid(),
      }
      const isMyEntry = this.#checkIsMyEntry(liveEntry)
      if (isMyEntry) return

      if (this.state.liveEntries.length >= MAX_LIVE_ENTRIES) {
        this.state.liveEntries.splice(this.state.liveEntries.length - 1, 1)
      }

      this.state.liveEntries.unshift(liveEntry)
    })
  }

  #checkIsMyEntry(liveEntry: ILiveEntry) {
    const delayedLiveEntry = useDelayedLiveEntry.getState()
    const currTxHash = delayedLiveEntry.liveEntry.submittedTxHash
    // @NOTE: remove in progress entry if this one was an in progress one
    useUserDataStore.getState().removeInProgressEntry(liveEntry.txHash)

    if (liveEntry.txHash && liveEntry.txHash.toLowerCase() === currTxHash?.toLowerCase()) {
      delayedLiveEntry.setInProgressLiveEntry(liveEntry, liveEntry.resolvementTxHash)
      return true
    }
  }
}
