import { useAA } from '@/lib/aa/hooks/useAA'
import { useDebounceCallback } from 'usehooks-ts'
import { AAFullLoading } from '@/components/shared/AAFullLoading'
import { useActiveWallet } from '@/lib/privy/hooks'

export const AASessionListener = () => {
  const { walletProvider, walletAddress } = useActiveWallet()
  const { populateAASession } = useAA()

  const debouncePopulateAASession = useDebounceCallback(populateAASession, 1_000)

  useEffect(() => {
    if (!walletProvider || !walletAddress) return
    debouncePopulateAASession(walletAddress)
  }, [walletProvider, walletAddress])

  return <AAFullLoading />
}
