import { formatEther } from 'viem'
import { type GameHelperFunctions } from './types'
import { DEFAULT_MAX_COUNT } from './constants'
import { unit } from '../vault'

export interface IBombsSide {
  bombCount: number
  revealCount: number
  revealIndexes: number[]
}

export const isValidBombsSide = (side: IBombsSide) => {
  // return false
  const { bombCount, revealCount, revealIndexes } = side
  if (!(bombCount > 0 && revealCount > 0 && revealCount + bombCount <= 25)) {
    return false
  }
  const invalidRevealindex = revealIndexes.filter(
    revealIndex => revealIndex > 24 || revealIndex < 0
  )
  if (invalidRevealindex.length !== 0) {
    return false
  }
  return true
}

export const getMaxCountForBombs = (side: IBombsSide) => {
  let i = BigInt(DEFAULT_MAX_COUNT)
  for (; i >= 1n; i--) {
    // Check limit related to representing with q and k
    const q = bombCountToRevealCountToProbability[side.bombCount][side.revealCount]
    console.log('MAX ENTRY: q: ', q)
    if (q === undefined) return DEFAULT_MAX_COUNT // Since revealCount can be 0 as of data but there is no q to represent that probably of win for that condition, it would return undefined, in that case, return the default value
    if (q ** i / unit ** (i - 1n) > 0n) {
      console.log('MAX ENTRY: breaking from q with maxEntry: ', i)
      break
    }
  }
  // when loop ends or breaks out, the i should be the max valid count for given prob
  return Number(i)
}

// @TODO: Fix
export const getBombsMultiplierWithoutPPV = (side: IBombsSide) => {
  // return 10000 / (10000 - side)
  return 1
}

export const getBombsMultiplierWithPPV = (side: IBombsSide) => {
  const multiplierInBigInt = bombCountToRevealCountToMultiplier[side.bombCount][side.revealCount]
  return Number(formatEther(multiplierInBigInt))
}

// @TODO: Fix
// @NOTE: https://en.wikipedia.org/wiki/Kelly_criterion
export const getBombsKellyFraction = (side: IBombsSide) => {
  // const pWin = bombCountToRevealCountToProbability[side.bombCount][side.revealCount]
  // const aWin = bombCountToRevealCountToMultiplier[side.bombCount][side.revealCount] - unit
  // const pLose = unit - pWin
  // const aLose = unit
  // const kellyFraction = pWin / aLose - pLose / aWin
  // return kellyFraction
  return 1
}

export const getBombsPotentialProfitCoefficient = (side: IBombsSide) => {
  return getBombsMultiplierWithPPV(side) - 1
}

export const bombsHelperFunctions: GameHelperFunctions<IBombsSide> = {
  isValidSide: isValidBombsSide, // @NOTE: Actually being used
  getMaxCount: getMaxCountForBombs,
  getMultiplierWithoutPPV: getBombsMultiplierWithoutPPV, // @NOTE: THESE ARE NOT USED RN
  getMultiplierWithPPV: getBombsMultiplierWithPPV, // @NOTE: Actually being used
  getKellyFraction: getBombsKellyFraction, // @NOTE: THESE ARE NOT USED RN
  getPotentialProfitCoefficient: getBombsPotentialProfitCoefficient, // @NOTE: Actually being used
}

// const PRECISION = BigInt(1e18)

// const bombCountToRevealCountToMultiplier: { [key: number]: { [key: number]: bigint } } = {}

// for (let bombCount = 1n; bombCount < 25n; bombCount++) {
//   bombCountToRevealCountToMultiplier[Number(bombCount)] = {}

//   for (let revealCount = 1n; revealCount < 25n; revealCount++) {
//     if (bombCount + revealCount > 25n) continue

//     let aggregateMultiplier = PRECISION

//     for (let i = 0n; i < revealCount; i++) {
//       aggregateMultiplier = mulDiv(
//         aggregateMultiplier,
//         mulDiv((25n - i) * PRECISION, PRECISION, (25n - i - bombCount) * PRECISION),
//         PRECISION
//       )
//     }

//     bombCountToRevealCountToMultiplier[Number(bombCount)][Number(revealCount)] =
//       (aggregateMultiplier * 99n) / 100n
//   }
// }
// console.log('JUST DID THE CALCULATION')

// function mulDiv(a: bigint, b: bigint, c: bigint): bigint {
//   return (a * b) / c
// }

// console.log(bombCountToRevealCountToMultiplier)
// @NOTE: Above code is to way to calculate this object
export const bombCountToRevealCountToMultiplier: { [key: number]: { [key: number]: bigint } } = {
  '1': {
    // @NOTE: Normally 0 revealCount related thing do not make sense, but bombs' initial state does have 0 revealCount
    // @NOTE: We know that it is an invalid condition, so GameButton is disabled but we technically need the multiplier value to show the user
    '0': 0n,
    '1': 1031249999999999999n,
    '2': 1076086956521739128n,
    '3': 1124999999999999997n,
    '4': 1178571428571428568n,
    '5': 1237499999999999996n,
    '6': 1302631578947368416n,
    '7': 1374999999999999994n,
    '8': 1455882352941176462n,
    '9': 1546874999999999991n,
    '10': 1649999999999999989n,
    '11': 1767857142857142843n,
    '12': 1903846153846153831n,
    '13': 2062499999999999982n,
    '14': 2249999999999999980n,
    '15': 2474999999999999978n,
    '16': 2749999999999999975n,
    '17': 3093749999999999972n,
    '18': 3535714285714285682n,
    '19': 4124999999999999959n,
    '20': 4949999999999999951n,
    '21': 6187499999999999938n,
    '22': 8249999999999999915n,
    '23': 12374999999999999873n,
    '24': 24749999999999999746n,
  },
  '2': {
    '0': 0n,
    '1': 1076086956521739129n,
    '2': 1173913043478260868n,
    '3': 1285714285714285712n,
    '4': 1414285714285714282n,
    '5': 1563157894736842100n,
    '6': 1736842105263157889n,
    '7': 1941176470588235286n,
    '8': 2183823529411764696n,
    '9': 2474999999999999988n,
    '10': 2828571428571428556n,
    '11': 3263736263736263718n,
    '12': 3807692307692307668n,
    '13': 4499999999999999970n,
    '14': 5399999999999999964n,
    '15': 6599999999999999955n,
    '16': 8249999999999999944n,
    '17': 10607142857142857067n,
    '18': 14142857142857142752n,
    '19': 19799999999999999853n,
    '20': 29699999999999999780n,
    '21': 49499999999999999613n,
    '22': 98999999999999999227n,
    '23': 296999999999999997683n,
  },
  '3': {
    '0': 0n,
    '1': 1124999999999999999n,
    '2': 1285714285714285713n,
    '3': 1478571428571428569n,
    '4': 1712030075187969922n,
    '5': 1997368421052631573n,
    '6': 2349845201238390085n,
    '7': 2790441176470588225n,
    '8': 3348529411764705870n,
    '9': 4066071428571428553n,
    '10': 5004395604395604372n,
    '11': 6255494505494505465n,
    '12': 7961538461538461499n,
    '13': 10349999999999999949n,
    '14': 13799999999999999927n,
    '15': 18974999999999999900n,
    '16': 27107142857142856992n,
    '17': 40660714285714285488n,
    '18': 65057142857142856780n,
    '19': 113849999999999999366n,
    '20': 227699999999999998732n,
    '21': 569249999999999996832n,
    '22': 2276999999999999987328n,
  },
  '4': {
    '0': 0n,
    '1': 1178571428571428571n,
    '2': 1414285714285714285n,
    '3': 1712030075187969923n,
    '4': 2092481203007518793n,
    '5': 2584829721362229097n,
    '6': 3231037151702786370n,
    '7': 4092647058823529400n,
    '8': 5261974789915966370n,
    '9': 6881043956043956020n,
    '10': 9174725274725274691n,
    '11': 12510989010989010938n,
    '12': 17515384615384615313n,
    '13': 25299999999999999889n,
    '14': 37949999999999999834n,
    '15': 59635714285714285431n,
    '16': 99392857142857142345n,
    '17': 178907142857142856222n,
    '18': 357814285714285712444n,
    '19': 834899999999999995585n,
    '20': 2504699999999999986755n,
    '21': 12523499999999999933778n,
  },
  '5': {
    '0': 0n,
    '1': 1237500000000000000n,
    '2': 1563157894736842104n,
    '3': 1997368421052631576n,
    '4': 2584829721362229098n,
    '5': 3392589009287925690n,
    '6': 4523452012383900918n,
    '7': 6138970588235294098n,
    '8': 8500113122171945671n,
    '9': 12041826923076923028n,
    '10': 17515384615384615308n,
    '11': 26273076923076922962n,
    '12': 40869230769230769037n,
    '13': 66412499999999999685n,
    '14': 113849999999999999413n,
    '15': 208724999999999998886n,
    '16': 417449999999999997773n,
    '17': 939262499999999994990n,
    '18': 2504699999999999986015n,
    '19': 8766449999999999951053n,
    '20': 52598699999999999706320n,
  },
  '6': {
    '0': 0n,
    '1': 1302631578947368420n,
    '2': 1736842105263157893n,
    '3': 2349845201238390089n,
    '4': 3231037151702786371n,
    '5': 4523452012383900920n,
    '6': 6462074303405572740n,
    '7': 9444570135746606309n,
    '8': 14166855203619909464n,
    '9': 21894230769230769163n,
    '10': 35030769230769230661n,
    '11': 58384615384615384412n,
    '12': 102173076923076922721n,
    '13': 189749999999999999251n,
    '14': 379499999999999998503n,
    '15': 834899999999999996707n,
    '16': 2087249999999999991769n,
    '17': 6261749999999999975309n,
    '18': 25046999999999999901237n,
    '19': 175328999999999999308663n,
  },
  '7': {
    '0': 0n,
    '1': 1374999999999999999n,
    '2': 1941176470588235292n,
    '3': 2790441176470588231n,
    '4': 4092647058823529403n,
    '5': 6138970588235294104n,
    '6': 9444570135746606310n,
    '7': 14953902714932126654n,
    '8': 24470022624434389060n,
    '9': 41599038461538461403n,
    '10': 73953846153846153572n,
    '11': 138663461538461537947n,
    '12': 277326923076923075894n,
    '13': 600874999999999997585n,
    '14': 1442099999999999994205n,
    '15': 3965774999999999984065n,
    '16': 13219249999999999945563n,
    '17': 59486624999999999755033n,
    '18': 475892999999999998040267n,
  },
  '8': {
    '0': 0n,
    '1': 1455882352941176470n,
    '2': 2183823529411764705n,
    '3': 3348529411764705880n,
    '4': 5261974789915966380n,
    '5': 8500113122171945687n,
    '6': 14166855203619909473n,
    '7': 24470022624434389079n,
    '8': 44046040723981900343n,
    '9': 83198076923076922830n,
    '10': 166396153846153845661n,
    '11': 356563186813186812107n,
    '12': 831980769230769228131n,
    '13': 2163149999999999993141n,
    '14': 6489449999999999979423n,
    '15': 23794649999999999920227n,
    '16': 118973249999999999601138n,
    '17': 1070759249999999996410250n,
  },
  '9': {
    '0': 0n,
    '1': 1546875000000000000n,
    '2': 2475000000000000000n,
    '3': 4066071428571428570n,
    '4': 6881043956043956039n,
    '5': 12041826923076923068n,
    '6': 21894230769230769205n,
    '7': 41599038461538461490n,
    '8': 83198076923076922980n,
    '9': 176795913461538461332n,
    '10': 404104945054945054424n,
    '11': 1010262362637362636059n,
    '12': 2828734615384615380966n,
    '13': 9193387499999999988141n,
    '14': 36773549999999999952567n,
    '15': 202254524999999999739119n,
    '16': 2022545249999999997391191n,
  },
  '10': {
    '0': 0n,
    '1': 1649999999999999999n,
    '2': 2828571428571428568n,
    '3': 5004395604395604387n,
    '4': 9174725274725274708n,
    '5': 17515384615384615343n,
    '6': 35030769230769230686n,
    '7': 73953846153846153667n,
    '8': 166396153846153845750n,
    '9': 404104945054945053894n,
    '10': 1077613186813186810115n,
    '11': 3232839560439560430346n,
    '12': 11314938461538461506211n,
    '13': 49031399999999999856476n,
    '14': 294188399999999999138856n,
    '15': 3236072399999999990527420n,
  },
  '11': {
    '0': 0n,
    '1': 1767857142857142856n,
    '2': 3263736263736263733n,
    '3': 6255494505494505487n,
    '4': 12510989010989010974n,
    '5': 26273076923076923045n,
    '6': 58384615384615384539n,
    '7': 138663461538461538279n,
    '8': 356563186813186812640n,
    '9': 1010262362637362635694n,
    '10': 3232839560439560434221n,
    '11': 12123148351648351628331n,
    '12': 56574692307692307590796n,
    '13': 367735499999999999340179n,
    '14': 4412825999999999992082158n,
  },
  '12': {
    '0': 0n,
    '1': 1903846153846153845n,
    '2': 3807692307692307690n,
    '3': 7961538461538461533n,
    '4': 17515384615384615373n,
    '5': 40869230769230769197n,
    '6': 102173076923076922994n,
    '7': 277326923076923076625n,
    '8': 831980769230769229877n,
    '9': 2828734615384615381584n,
    '10': 11314938461538461526338n,
    '11': 56574692307692307631693n,
    '12': 396022846153846153421856n,
    '13': 5148296999999999994484135n,
  },
  '13': {
    '0': 0n,
    '1': 2062499999999999999n,
    '2': 4499999999999999998n,
    '3': 10349999999999999995n,
    '4': 25299999999999999984n,
    '5': 66412499999999999959n,
    '6': 189749999999999999826n,
    '7': 600874999999999999322n,
    '8': 2163149999999999997560n,
    '9': 9193387499999999989632n,
    '10': 49031399999999999941642n,
    '11': 367735499999999999562321n,
    '12': 5148296999999999993872494n,
  },
  '14': {
    '0': 0n,
    '1': 2249999999999999999n,
    '2': 5399999999999999998n,
    '3': 13799999999999999993n,
    '4': 37949999999999999980n,
    '5': 113849999999999999942n,
    '6': 379499999999999999769n,
    '7': 1442099999999999999125n,
    '8': 6489449999999999996062n,
    '9': 36773549999999999973362n,
    '10': 294188399999999999786896n,
    '11': 4412825999999999996803448n,
  },
  '15': {
    '0': 0n,
    '1': 2475000000000000000n,
    '2': 6599999999999999998n,
    '3': 18974999999999999994n,
    '4': 59635714285714285693n,
    '5': 208724999999999999926n,
    '6': 834899999999999999705n,
    '7': 3965774999999999998601n,
    '8': 23794649999999999991610n,
    '9': 202254524999999999928691n,
    '10': 3236072399999999998859060n,
  },
  '16': {
    '0': 0n,
    '1': 2749999999999999999n,
    '2': 8249999999999999997n,
    '3': 27107142857142857132n,
    '4': 99392857142857142801n,
    '5': 417449999999999999764n,
    '6': 2087249999999999998823n,
    '7': 13219249999999999991852n,
    '8': 118973249999999999926672n,
    '9': 2022545249999999998753435n,
  },
  '17': {
    '0': 0n,
    '1': 3093750000000000000n,
    '2': 10607142857142857141n,
    '3': 40660714285714285703n,
    '4': 178907142857142857096n,
    '5': 939262499999999999756n,
    '6': 6261749999999999997749n,
    '7': 59486624999999999978621n,
    '8': 1070759249999999999615194n,
  },
  '18': {
    '0': 0n,
    '1': 3535714285714285713n,
    '2': 14142857142857142854n,
    '3': 65057142857142857132n,
    '4': 357814285714285714226n,
    '5': 2504699999999999999588n,
    '6': 25046999999999999995881n,
    '7': 475892999999999999921750n,
  },
  '19': {
    '0': 0n,
    '1': 4124999999999999999n,
    '2': 19799999999999999996n,
    '3': 113849999999999999977n,
    '4': 834899999999999999794n,
    '5': 8766449999999999997844n,
    '6': 175328999999999999956895n,
  },
  '20': {
    '0': 0n,
    '1': 4950000000000000000n,
    '2': 29700000000000000000n,
    '3': 227699999999999999980n,
    '4': 2504699999999999999782n,
    '5': 52598699999999999995426n,
    // '5': 5259869999999999999542600n,
  },
  '21': {
    '0': 0n,
    '1': 6187500000000000000n,
    '2': 49500000000000000000n,
    '3': 569250000000000000000n,
    '4': 12523500000000000000000n,
  },
  '22': {
    '0': 0n,
    '1': 8249999999999999999n,
    '2': 98999999999999999996n,
    '3': 2276999999999999999908n,
  },
  '23': {
    '0': 0n,
    '1': 12375000000000000000n,
    '2': 297000000000000000000n,
  },
  '24': {
    '0': 0n,
    '1': 24750000000000000000n,
  },
}

// for (let bombCount = 1n; bombCount < 25n; bombCount++) {
//   bombCountToRevealCountToProbability[Number(bombCount)] = {}

//   for (let revealCount = 1n; revealCount < 25n; revealCount++) {
//     if (bombCount + revealCount > 25n) continue

//     let aggregateProbability = 100n * PRECISION

//     for (let i = 0n; i < revealCount; i++) {
//       aggregateProbability = mulDiv(
//         aggregateProbability,
//         (25n - i - bombCount) * PRECISION,
//         (25n - i) * PRECISION
//       )
//     }

//     bombCountToRevealCountToProbability[Number(bombCount)][Number(revealCount)] =
//       aggregateProbability
//   }
// }

// @NOTE: Above code is to way to calculate this object
export const bombCountToRevealCountToProbability: { [key: number]: { [key: number]: bigint } } = {
  '1': {
    '1': 960000000000000000n,
    '2': 920000000000000000n,
    '3': 880000000000000000n,
    '4': 840000000000000000n,
    '5': 800000000000000000n,
    '6': 760000000000000000n,
    '7': 720000000000000000n,
    '8': 680000000000000000n,
    '9': 640000000000000000n,
    '10': 600000000000000000n,
    '11': 560000000000000000n,
    '12': 520000000000000000n,
    '13': 480000000000000000n,
    '14': 440000000000000000n,
    '15': 400000000000000000n,
    '16': 360000000000000000n,
    '17': 320000000000000000n,
    '18': 280000000000000000n,
    '19': 240000000000000000n,
    '20': 200000000000000000n,
    '21': 160000000000000000n,
    '22': 120000000000000000n,
    '23': 80000000000000000n,
    '24': 40000000000000000n,
  },
  '2': {
    '1': 920000000000000000n,
    '2': 843333333333333333n,
    '3': 769999999999999999n,
    '4': 699999999999999999n,
    '5': 633333333333333333n,
    '6': 569999999999999999n,
    '7': 509999999999999999n,
    '8': 453333333333333333n,
    '9': 399999999999999999n,
    '10': 349999999999999999n,
    '11': 303333333333333333n,
    '12': 259999999999999999n,
    '13': 219999999999999999n,
    '14': 183333333333333333n,
    '15': 149999999999999999n,
    '16': 119999999999999999n,
    '17': 93333333333333333n,
    '18': 69999999999999999n,
    '19': 49999999999999999n,
    '20': 33333333333333333n,
    '21': 19999999999999999n,
    '22': 9999999999999999n,
    '23': 3333333333333333n,
  },
  '3': {
    '1': 880000000000000000n,
    '2': 770000000000000000n,
    '3': 669565217391304347n,
    '4': 578260869565217391n,
    '5': 495652173913043478n,
    '6': 421304347826086956n,
    '7': 354782608695652173n,
    '8': 295652173913043478n,
    '9': 243478260869565217n,
    '10': 197826086956521739n,
    '11': 158260869565217391n,
    '12': 124347826086956521n,
    '13': 95652173913043478n,
    '14': 71739130434782608n,
    '15': 52173913043478260n,
    '16': 36521739130434782n,
    '17': 24347826086956521n,
    '18': 15217391304347826n,
    '19': 8695652173913043n,
    '20': 4347826086956521n,
    '21': 1739130434782608n,
    '22': 434782608695652n,
  },
  '4': {
    '1': 840000000000000000n,
    '2': 700000000000000000n,
    '3': 578260869565217391n,
    '4': 473122529644268774n,
    '5': 383003952569169960n,
    '6': 306403162055335968n,
    '7': 241897233201581027n,
    '8': 188142292490118577n,
    '9': 143873517786561264n,
    '10': 107905138339920948n,
    '11': 79130434782608695n,
    '12': 56521739130434782n,
    '13': 39130434782608695n,
    '14': 26086956521739130n,
    '15': 16600790513833992n,
    '16': 9960474308300395n,
    '17': 5533596837944664n,
    '18': 2766798418972332n,
    '19': 1185770750988142n,
    '20': 395256916996047n,
    '21': 79051383399209n,
  },
  '5': {
    '1': 800000000000000000n,
    '2': 633333333333333333n,
    '3': 495652173913043478n,
    '4': 383003952569169960n,
    '5': 291812535290796160n,
    '6': 218859401468097120n,
    '7': 161264822134387351n,
    '8': 116469038208168642n,
    '9': 82213438735177865n,
    '10': 56521739130434782n,
    '11': 37681159420289855n,
    '12': 24223602484472049n,
    '13': 14906832298136645n,
    '14': 8695652173913043n,
    '15': 4743083003952569n,
    '16': 2371541501976284n,
    '17': 1054018445322793n,
    '18': 395256916996047n,
    '19': 112930547713156n,
    '20': 18821757952192n,
  },
  '6': {
    '1': 760000000000000000n,
    '2': 570000000000000000n,
    '3': 421304347826086956n,
    '4': 306403162055335968n,
    '5': 218859401468097120n,
    '6': 153201581027667984n,
    '7': 104822134387351778n,
    '8': 69881422924901185n,
    '9': 45217391304347826n,
    '10': 28260869565217391n,
    '11': 16956521739130434n,
    '12': 9689440993788819n,
    '13': 5217391304347826n,
    '14': 2608695652173913n,
    '15': 1185770750988142n,
    '16': 474308300395256n,
    '17': 158102766798418n,
    '18': 39525691699604n,
    '19': 5646527385657n,
  },
  '7': {
    '1': 720000000000000000n,
    '2': 510000000000000000n,
    '3': 354782608695652173n,
    '4': 241897233201581027n,
    '5': 161264822134387351n,
    '6': 104822134387351778n,
    '7': 66203453297274807n,
    '8': 40457665903890160n,
    '9': 23798627002288329n,
    '10': 13386727688787185n,
    '11': 7139588100686498n,
    '12': 3569794050343249n,
    '13': 1647597254004576n,
    '14': 686498855835240n,
    '15': 249635947576451n,
    '16': 74890784272935n,
    '17': 16642396505096n,
    '18': 2080299563137n,
  },
  '8': {
    '1': 680000000000000000n,
    '2': 453333333333333333n,
    '3': 295652173913043478n,
    '4': 188142292490118577n,
    '5': 116469038208168642n,
    '6': 69881422924901185n,
    '7': 40457665903890160n,
    '8': 22476481057716755n,
    '9': 11899313501144164n,
    '10': 5949656750572082n,
    '11': 2776506483600305n,
    '12': 1189931350114416n,
    '13': 457665903890160n,
    '14': 152555301296720n,
    '15': 41605991262741n,
    '16': 8321198252548n,
    '17': 924577583616n,
  },
  '9': {
    '1': 640000000000000000n,
    '2': 400000000000000000n,
    '3': 243478260869565217n,
    '4': 143873517786561264n,
    '5': 82213438735177865n,
    '6': 45217391304347826n,
    '7': 23798627002288329n,
    '8': 11899313501144164n,
    '9': 5599676941714901n,
    '10': 2449858662000269n,
    '11': 979943464800107n,
    '12': 349979808857181n,
    '13': 107686095032978n,
    '14': 26921523758244n,
    '15': 4894822501499n,
    '16': 489482250149n,
  },
  '10': {
    '1': 600000000000000000n,
    '2': 350000000000000000n,
    '3': 197826086956521739n,
    '4': 107905138339920948n,
    '5': 56521739130434782n,
    '6': 28260869565217391n,
    '7': 13386727688787185n,
    '8': 5949656750572082n,
    '9': 2449858662000269n,
    '10': 918696998250100n,
    '11': 306232332750033n,
    '12': 87494952214295n,
    '13': 20191142818683n,
    '14': 3365190469780n,
    '15': 305926406343n,
  },
  '11': {
    '1': 560000000000000000n,
    '2': 303333333333333333n,
    '3': 158260869565217391n,
    '4': 79130434782608695n,
    '5': 37681159420289855n,
    '6': 16956521739130434n,
    '7': 7139588100686498n,
    '8': 2776506483600305n,
    '9': 979943464800107n,
    '10': 306232332750033n,
    '11': 81661955400008n,
    '12': 17498990442859n,
    '13': 2692152375824n,
    '14': 224346031318n,
  },
  '12': {
    '1': 520000000000000000n,
    '2': 260000000000000000n,
    '3': 124347826086956521n,
    '4': 56521739130434782n,
    '5': 24223602484472049n,
    '6': 9689440993788819n,
    '7': 3569794050343249n,
    '8': 1189931350114416n,
    '9': 349979808857181n,
    '10': 87494952214295n,
    '11': 17498990442859n,
    '12': 2499855777551n,
    '13': 192296598273n,
  },
  '13': {
    '1': 480000000000000000n,
    '2': 220000000000000000n,
    '3': 95652173913043478n,
    '4': 39130434782608695n,
    '5': 14906832298136645n,
    '6': 5217391304347826n,
    '7': 1647597254004576n,
    '8': 457665903890160n,
    '9': 107686095032978n,
    '10': 20191142818683n,
    '11': 2692152375824n,
    '12': 192296598273n,
  },
  '14': {
    '1': 440000000000000000n,
    '2': 183333333333333333n,
    '3': 71739130434782608n,
    '4': 26086956521739130n,
    '5': 8695652173913043n,
    '6': 2608695652173913n,
    '7': 686498855835240n,
    '8': 152555301296720n,
    '9': 26921523758244n,
    '10': 3365190469780n,
    '11': 224346031318n,
  },
  '15': {
    '1': 400000000000000000n,
    '2': 150000000000000000n,
    '3': 52173913043478260n,
    '4': 16600790513833992n,
    '5': 4743083003952569n,
    '6': 1185770750988142n,
    '7': 249635947576451n,
    '8': 41605991262741n,
    '9': 4894822501499n,
    '10': 305926406343n,
  },
  '16': {
    '1': 360000000000000000n,
    '2': 120000000000000000n,
    '3': 36521739130434782n,
    '4': 9960474308300395n,
    '5': 2371541501976284n,
    '6': 474308300395256n,
    '7': 74890784272935n,
    '8': 8321198252548n,
    '9': 489482250149n,
  },
  '17': {
    '1': 320000000000000000n,
    '2': 93333333333333333n,
    '3': 24347826086956521n,
    '4': 5533596837944664n,
    '5': 1054018445322793n,
    '6': 158102766798418n,
    '7': 16642396505096n,
    '8': 924577583616n,
  },
  '18': {
    '1': 280000000000000000n,
    '2': 70000000000000000n,
    '3': 15217391304347826n,
    '4': 2766798418972332n,
    '5': 395256916996047n,
    '6': 39525691699604n,
    '7': 2080299563137n,
  },
  '19': {
    '1': 240000000000000000n,
    '2': 50000000000000000n,
    '3': 8695652173913043n,
    '4': 1185770750988142n,
    '5': 112930547713156n,
    '6': 5646527385657n,
  },
  '20': {
    '1': 200000000000000000n,
    '2': 33333333333333333n,
    '3': 4347826086956521n,
    '4': 395256916996047n,
    '5': 18821757952192n,
  },
  '21': {
    '1': 160000000000000000n,
    '2': 20000000000000000n,
    '3': 1739130434782608n,
    '4': 79051383399209n,
  },
  '22': {
    '1': 120000000000000000n,
    '2': 10000000000000000n,
    '3': 434782608695652n,
  },
  '23': { '1': 80000000000000000n, '2': 3333333333333333n },
  '24': { '1': 40000000000000000n },
}
