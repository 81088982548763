// SVG
import FGray from './svg/FGray.svg'
import betIcon from './svg/bet.svg'
import bombIcon from './svg/bomb.svg'
import burnIcon from './svg/burn.svg'
import checkIcon from './svg/check.svg'
import crashIcon from './svg/crash.svg'
import crossIcon from './svg/cross.svg'
import diceIcon from './svg/dice.svg'
import discordIcon from './svg/discord.svg'
import docsIcon from './svg/docs.svg'
import ethIcon from './svg/eth-logo.svg'
import fareTextIcon from './svg/fare-protocol-color-logo.svg'
import logoIcon from './svg/logo-white.svg'
import metamaskIcon from './svg/metamask.svg'
import mintIcon from './svg/mint.svg'
import mintPng from './png/mint.png'
import burnPng from './png/burn.png'
import plinkoIcon from './svg/plinko.svg'
import rewardIcon from './svg/reward.svg'
import paperIcon from './svg/rps-paper-icon-alt.svg'
import rockIcon from './svg/rps-rock-icon-alt.svg'
import scissorIcon from './svg/rps-scissors-icon-alt.svg'
import scrollIcon from './svg/scroll-down-arrow.svg'
import slotsIcon from './svg/slots.svg'
import spinIcon from './svg/spin.svg'
import statsIcon from './svg/stats.svg'
import telegramIcon from './svg/telegram.svg'
import trashIcon from './svg/trash.svg'
import chatIcon from './svg/chat.svg'
import usdcIcon from './svg/usdc.svg'
import usdcWhiteIcon from './svg/usdc-white.svg'
import chainlinkIcon from './svg/chainlink.svg'
import quickplayIcon from './svg/quickplay.svg'
import lockIcon from './svg/lock.svg'
import externalPlayIcon from './svg/external-play.svg'
import fareIcon from './svg/fare.svg'
import walletIcon from './svg/wallet.svg'
import walletConnectIcon from './svg/wallet-connect.svg'
import settingsIcon from './svg/settings.svg'
import fareETHIcon from './svg/fare-eth.svg'
import xIcon from './svg/x.svg'
import deathmatchIcon from './svg/deathmatch.svg'
import xMarkIcon from './svg/xmark.svg'
import deathMatchColored from './svg/deathmatch-colored.svg'
import deathMatchTextColored from './svg/deathmatch-text-colored.svg'
import oneLineFpLogo from './svg/one-line-fp-logo.svg'
import questionMarkIcon from './svg/question-mark.svg'
import plusIcon from './svg/plus.svg'
import multiplierIcon from './svg/multiplier.svg'
import infoIcon from './svg/info.svg'
import shareIcon from './svg/shareicon.svg'
import info from './svg/infoicon.svg'
import speaker from './svg/speaker.svg'
import loadingChip from './svg/level_0.svg'
import galxeIcon from './svg/gaxle-icon.svg'
import caretLeft from './svg/caret-left.svg'
import caretRight from './svg/caret-right.svg'
import carotDown from './svg/carot-down.svg'
import carotUp from './svg/carot-up.svg'
import dragBar from './svg/drag-bar.svg'
import privyIcon from './svg/privy-icon.svg'
import baseLogo from './svg/base-logo.svg'
import baseLogoWhite from './svg/base-logo-white.svg'
import visaLogo from './svg/visa-logo.svg'
import mastercardLogo from './svg/mastercard-logo.svg'
import paypalLogo from './svg/paypal-logo.svg'
import applePayLogo from './svg/apple-pay-logo.svg'
import googlePayLogo from './svg/google-pay-logo.svg'
import depositWallet from './svg/deposit-wallet.svg'
import refresh from './svg/refresh.svg'
import tripleDot from './svg/triple-dot.svg'
import smoothUsdclogo from './svg/usdc-logo-smooth.svg'
import binanceLogo from './svg/binance-logo.svg'
import coinbaseCoinLogo from './svg/coinbase-coin-logo.svg'

// PNG
import brainIcon from './png/badges/brain.png'
import layersIcon from './png/badges/layers.png'
import pantoneIcon from './png/badges/pantone.png'
import prototypeIcon from './png/badges/prototype.png'
import visionIcon from './png/badges/vision.png'
import wandIcon from './png/badges/wand.png'
import rouletteTable from './png/roulette_table_rage_transparent.png'
import honeyBeraIcon from './png/honey.png'
import beraIcon from './png/beratoken.png'
import arbitrumLogo from './png/arbitrum-logo.png'
import chevronIcon from './png/chevron.png'
import emojiIcon from './svg/fp-smiley-icon.svg'
import linkIcon from './png/link.png'
import simpleWalletIcon1 from './png/simple-wallet-1-64x64.png'
import simpleWalletIcon2 from './png/simple-wallet-2-64x64.png'

import level0Icon from './svg/badges/level_0.svg'
import level1Icon from './svg/badges/level_1.svg'
import level2Icon from './svg/badges/level_2.svg'
import level3Icon from './svg/badges/level_3.svg'
import level4Icon from './svg/badges/level_4.svg'
import level5Icon from './svg/badges/level_5.svg'
import level6Icon from './svg/badges/level_6.svg'

import deathmatchBannerPng from './png/deathmatch-banner.png'
import rightArrowIcon from './png/right-arrow.png'
import skullBlue from './svg/skull-blue.svg'
import skullEmpty from './svg/skull-empty.svg'

import whiteFareIcon from './svg/F.svg'

export const SVGS = {
  oneLineFpLogo,
  betIcon,
  bombIcon,
  burnIcon,
  checkIcon,
  crashIcon,
  crossIcon,
  diceIcon,
  discordIcon,
  docsIcon,
  ethIcon,
  fareTextIcon,
  fareIcon,
  galxeIcon,
  logoIcon,
  metamaskIcon,
  mintIcon,
  paperIcon,
  plinkoIcon,
  rewardIcon,
  rockIcon,
  scissorIcon,
  scrollIcon,
  slotsIcon,
  spinIcon,
  statsIcon,
  telegramIcon,
  trashIcon,
  usdcIcon,
  walletConnectIcon,
  walletIcon,
  questionMarkIcon,
  chatIcon,
  settingsIcon,
  chainlinkIcon,
  lockIcon,
  externalPlayIcon,
  quickplayIcon,
  fareETHIcon,
  xIcon,
  deathmatchIcon,
  level0Icon,
  level1Icon,
  level2Icon,
  level3Icon,
  level4Icon,
  level5Icon,
  level6Icon,
  xMarkIcon,
  usdcWhiteIcon,
  deathMatchColored,
  deathMatchTextColored,
  plusIcon,
  multiplierIcon,
  infoIcon,
  shareIcon,
  info,
  skullBlue,
  skullEmpty,
  whiteFareIcon,
  speaker,
  FGray,
  loadingChip,
  caretLeft,
  caretRight,
  carotDown,
  carotUp,
  dragBar,
  privyIcon,
  baseLogo,
  baseLogoWhite,
  visaLogo,
  mastercardLogo,
  paypalLogo,
  applePayLogo,
  googlePayLogo,
  depositWallet,
  refresh,
  tripleDot,
  smoothUsdclogo,
  binanceLogo,
  coinbaseCoinLogo,
} as const

export const TASK_ICONS: Record<number, string> = {
  0: SVGS.walletIcon,
  1: SVGS.bombIcon,
  2: SVGS.quickplayIcon,
  3: SVGS.walletIcon,
  4: SVGS.diceIcon,
  5: SVGS.scissorIcon,
  6: SVGS.usdcIcon,
}

export const PNGS = {
  brainIcon,
  chevronIcon,
  emojiIcon,
  layersIcon,
  linkIcon,
  pantoneIcon,
  prototypeIcon,
  visionIcon,
  wandIcon,
  burnPng,
  mintPng,
  deathmatchBannerPng,
  rightArrowIcon,
  rouletteTable,
  honeyBeraIcon,
  beraIcon,
  arbitrumLogo,
  simpleWalletIcon1,
  simpleWalletIcon2,
} as const
