import { useQuery } from '@/hooks/useQuery'
import useAuthStore from '@/store/useAuthStore'

export function RouteParamsListener() {
  const query = useQuery()

  // TODO: This needs to change to referral code
  // This value so be saved in localStorage and if the user signs up the referral is created
  useEffect(() => {
    const inviteCode = query.get('inviteCode')
    if (inviteCode) {
      useAuthStore.setState({ isShowingInviteCodeModal: true })
    }
  }, [query])

  return null
}
