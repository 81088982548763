import React, { useMemo, useRef, useEffect } from 'react'
import { useFrame } from '@react-three/fiber'
import * as THREE from 'three'

interface ExplosionEffectProps {
  position: [number, number, number]
  color?: string
  count?: number
  size?: number
  spread?: number
  duration?: number
  gravity?: number
}

export const ExplosionEffect: React.FC<ExplosionEffectProps> = ({
  position,
  color = '#ff3d00',
  count = 250,
  size = 1.5,
  spread = 2,
  duration = 1500,
  gravity = 0,
}) => {
  const mesh = useRef<THREE.Points>(null!)
  const clock = useRef(new THREE.Clock())

  const particleGeometry = useMemo(() => {
    const geometry = new THREE.BufferGeometry()
    const positions = new Float32Array(count * 3)
    const velocities = new Float32Array(count * 3)

    for (let i = 0; i < count; i++) {
      const theta = Math.random() * Math.PI * 2
      const phi = Math.acos(Math.random() * 2 - 1)
      const r = Math.cbrt(Math.random()) * spread

      positions[i * 3] = r * Math.sin(phi) * Math.cos(theta)
      positions[i * 3 + 1] = r * Math.sin(phi) * Math.sin(theta)
      positions[i * 3 + 2] = r * Math.cos(phi)

      velocities[i * 3] = (Math.random() - 0.5) * 0.2
      velocities[i * 3 + 1] = (Math.random() - 0.5) * 0.2
      velocities[i * 3 + 2] = (Math.random() - 0.5) * 0.2
    }

    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
    geometry.setAttribute('velocity', new THREE.BufferAttribute(velocities, 3))

    return geometry
  }, [count, spread])

  const particleMaterial = useMemo(() => {
    return new THREE.ShaderMaterial({
      uniforms: {
        color: { value: new THREE.Color(color) },
        time: { value: 0 },
        size: { value: size },
        duration: { value: duration / 1000 },
        gravity: { value: gravity },
      },
      vertexShader: `
        attribute vec3 velocity;
        uniform float time;
        uniform float size;
        uniform float duration;
        uniform float gravity;
        varying float vAlpha;

        void main() {
          // Add gravity to Y component
          vec3 pos = position + velocity * time;
          pos.y += gravity * time * time * -0.5; // Quadratic gravity effect
          
          float progress = clamp(time / duration, 0.0, 1.0);
          vAlpha = 1.0 - progress;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
          gl_PointSize = size * (1.0 - progress * 0.5);
        }
      `,
      fragmentShader: `
        uniform vec3 color;
        varying float vAlpha;

        void main() {
          vec3 glowColor = color * 1.5;  // Increased brightness
          gl_FragColor = vec4(glowColor, vAlpha);
        }
      `,
      transparent: true,
      depthWrite: false,
      blending: THREE.AdditiveBlending, // Added for brighter effect
    })
  }, [color, size, duration, gravity])

  useFrame(() => {
    if (mesh.current && mesh.current.material instanceof THREE.ShaderMaterial) {
      const elapsedTime = clock.current.getElapsedTime()
      mesh.current.material.uniforms.time.value = elapsedTime
    }
  })

  useEffect(() => {
    const currentClock = clock.current
    currentClock.start()
    return () => {
      currentClock.stop()
    }
  }, [])

  return (
    <points
      ref={mesh}
      position={position}
      geometry={particleGeometry}
      material={particleMaterial}
    />
  )
}
