import { TEXT_COLORS, FONT_STYLES } from '@/design'
import { deviceBP } from '@/design/breakpoints'

export const LeaderboardHeadingContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: right;
  justify-content: space-between;
  position: relative;
`

export const MobileLeaderboardHeadingContainer = styled(LeaderboardHeadingContainer)`
  flex-direction: column;
  align-items: flex-end;
`

export const DeathMatchImageContainer = styled.div`
  display: flex;
  gap: 15px;
  position: absolute;
  left: 0;
  top: 0;
`

export const MobileDeathMatchImageContainer = styled(DeathMatchImageContainer)`
  width: 100%;
  justify-content: flex-end;
`

export const LeaderboardHeadingText = styled.h1`
  color: rgb(255, 255, 255, 0.8);
  opacity: 0.8;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  margin-right: 45px;
  margin-left: 124px;
  margin-top: 82px;
  margin-bottom: 18px;
  text-align: right;

  @media ${deviceBP.sm} {
    margin-top: 64px;
    margin-bottom: 10px;
    margin-left: 16px;
    margin-right: 0px;
    width: 100%;
    text-align: left;
    font-size: 36px;
  }
`

export const MobileLeaderboardHeadingText = styled(LeaderboardHeadingText)`
  ${FONT_STYLES.xl};
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
`

export const LeaderboardDeathMatchColoredSkull = styled.img`
  height: 104px;
  opacity: 0.8;
  /* height: 64px; */
  /* transform: scaleX(-1); */
  @media ${deviceBP.sm} {
    height: 48px;
  }
`

export const MobileLeaderboardDeathMatchColoredSkull = styled(LeaderboardDeathMatchColoredSkull)`
  position: absolute;
  top: 0px;
  left: 0px;
`

export const DeathMatchColoredText = styled.img`
  margin-top: 0px;
  height: 72px;
  opacity: 0.6;
  @media ${deviceBP.sm} {
    height: 48px;
  }
  /* height: 56px; */
`
