import { Vector3 } from 'three'
import { DiceController } from './DiceController'

export const DiceManager = ({
  playGameSound,
}: {
  playGameSound: (name: string, volume?: number, pitch?: number) => void
}) => {
  const startPos = useMemo(() => new Vector3(0, 1.5, 0), [])

  return <DiceController startPos={startPos} playGameSound={playGameSound} />
}
