import { PropsWithChildren } from 'react'
import { SFarePopover, farePopoverVariants } from './styled'
import { AnimatePresence } from 'framer-motion'

interface IFarePopover
  extends PropsWithChildren<{
    isShowing: boolean
    textColor?: string
  }> {}

export const FarePopover = ({ isShowing, textColor = 'white', children }: IFarePopover) => {
  return (
    <AnimatePresence>
      {isShowing && (
        <SFarePopover
          $textColor={textColor}
          variants={farePopoverVariants}
          initial='initial'
          animate='animate'
          exit='exit'
        >
          {children}
        </SFarePopover>
      )}
    </AnimatePresence>
  )
}
