/**
 * Applies a smoothstep interpolation between two edges.
 *
 * @param edge0 - The lower edge of the interpolation range.
 * @param edge1 - The upper edge of the interpolation range.
 * @param x - The input value to interpolate.
 * @returns The interpolated value, smoothly transitioning between the edges.
 */
export function smoothStep(edge0: number, edge1: number, x: number) {
  // Clamp x to the range [0, 1]
  x = Math.max(0, Math.min(1, (x - edge0) / (edge1 - edge0)))

  // Evaluate the smoothstep function
  return x * x * (3 - 2 * x)
}

export function easeOutSine(t: number) {
  return Math.sin((t * Math.PI) / 2)
}

export function easeInElastic(t: number) {
  const c4 = (2 * Math.PI) / 3

  return t === 0 ? 0 : t === 1 ? 1 : -Math.pow(2, 10 * t - 10) * Math.sin((t * 10 - 10.75) * c4)
}

export function easeOutElastic(t: number) {
  const p = 0.3
  return Math.pow(2, -10 * t) * Math.sin(((t - p / 4) * (2 * Math.PI)) / p) + 1
}


export function quadratic(t: number, peakY: number): number {
  return 4 * peakY * t * (1 - t) // Quadratic function for smooth rise and fall
}

export function easeInOutCubic(t: number) {
  return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2
}

export function easeOutCubic(t: number) {
  return 1 - Math.pow(1 - t, 3)
}

export function easeInCubic(t: number) {
  return t * t * t
}
