import { changeDeathmatchTab } from '@/store/proxy/deathmatchTabs'
import './noti-pill.css'
import { useActiveWallet } from '@/lib/privy/hooks'
import { AnimatePresence, motion } from 'framer-motion'
import { useSessionStorage } from 'usehooks-ts'
import useInviteCodeStore from '@/store/useInviteCodeStore'
import { inviteCodesApi } from '@/lib/http/inviteCodes'
import { SESSION_STORAGE } from '@/constants/storage'
import { useDebouncedCallback } from 'use-debounce'

const SNotiPill = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  height: 32px;
  padding-inline: 18px;
  cursor: pointer;
  text-wrap: nowrap;
`

export const NotiPill = () => {
  const navigate = useNavigate()
  const [hasViewed, setHasViewed] = useSessionStorage(
    SESSION_STORAGE.HAS_CLICKED_NOTI_PILL_KEY,
    'false'
  )
  const { walletAddress } = useActiveWallet()
  const inviteCodeCount = useInviteCodeStore(state => (state.activeInviteCodes || []).length)
  const fetchInviteCodes = useDebouncedCallback(
    async () => {
      try {
        const inviteCodes = await inviteCodesApi.getInviteCodes()
        useInviteCodeStore.getState().setInviteCodes({
          usedInviteCodes: inviteCodes.usedInviteCodes,
          activeInviteCodes: inviteCodes.activeInviteCodes,
        })
      } catch (err) {
        // @TODO: Add better error handling
        console.error(err)
      }
    },
    250,
    { leading: true }
  )

  const onClick = useCallback(() => {
    if (hasViewed === 'true') return
    changeDeathmatchTab('invite codes')
    navigate('deathmatch')
    setHasViewed('true')
  }, [navigate, hasViewed])

  useEffect(() => {
    if (!walletAddress) return
    fetchInviteCodes()
  }, [walletAddress])

  return (
    <AnimatePresence>
      {hasViewed !== 'true' && inviteCodeCount > 0 && (
        <SNotiPill
          className='noti-pill'
          initial={{
            opacity: 0,
            y: -60,
          }}
          animate={{
            opacity: 1,
            y: 0,
            transition: {
              type: 'tween',
              ease: 'linear',
              duration: 0.5,
              delay: 1.5,
            },
          }}
          exit={{
            opacity: 0,
          }}
          transition={{
            type: 'tween',
            ease: 'linear',
            duration: 0.18,
          }}
          onClick={onClick}
        >
          YOU HAVE {inviteCodeCount} INVITE CODE(S)!
        </SNotiPill>
      )}
    </AnimatePresence>
  )
}
