import { BORDER_COLORS, FARE_COLORS } from '@/design'
import { deviceBP } from '@/design/breakpoints'

export const SQuestDetails = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(3, 1fr);
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
  flex: 1;
  overflow-y: auto;
  min-height: 0;
  flex-grow: 1;

  @media ${deviceBP.sm} {
    grid-template-columns: repeat(1, 1fr);
    min-height: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
`

export const SQuestBox = styled.div<{ $isCompleted: boolean; $isInProgress: boolean }>`
  border: 1px solid ${BORDER_COLORS.one};
  background: black;
  display: flex;
  flex-direction: column;
  min-height: 160px;
  border-radius: 6px;
  min-width: 0;
  position: relative;
  ${props =>
    !props.$isCompleted &&
    !props.$isInProgress &&
    css`
      opacity: 0.6;
    `};
`

export const SQuestTasks = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 12px;
  gap: 8px;
`

export const SQuestTask = styled.div<{ $isCompleted: boolean }>`
  display: flex;
  align-items: flex-start;
  > span {
    color: ${props => (!props.$isCompleted ? 'white' : 'rgba(255,255,255,0.6)')};
    font-size: 11px;
    margin-left: 8px;
    margin-top: -2px;
  }
  .quest-task-img-skull {
    height: 12px;
    filter: ${props => (!props.$isCompleted ? 'none' : 'brightness(0.6)')};
  }
  .quest-task-img {
    border: 2px solid ${props => (props.$isCompleted ? FARE_COLORS.aqua : `${FARE_COLORS.aqua}60`)};
    min-height: 20px;
    min-width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    img {
      height: 12px;
    }
  }
`

export const STaskProgress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  right: 12px;
  bottom: 12px;
  > .completed-status-text {
    font-size: 11px;
    text-transform: uppercase;
    color: rgb(170, 170, 170);
  }

  @media ${deviceBP.sm} {
    top: 12px;
    bottom: 0;
  }
`

export const SQuestDetailHeader = styled.div<{
  $borderColor: string
  $isCompleted: boolean
  $isInProgress: boolean
}>`
  display: flex;
  border-bottom: 2px solid
    ${props =>
      props.$isCompleted ? props.$borderColor
      : props.$isInProgress ? `${props.$borderColor}50`
      : BORDER_COLORS.one};
  background: ${({ $borderColor, $isCompleted }) =>
    $isCompleted ?
      `linear-gradient(
          135deg,
          ${$borderColor}25,
          ${$borderColor}05,
          ${$borderColor}25
  )`
    : 'transparent'};
  padding: 12px;

  .quest-level-hidden {
    color: rgba(255, 255, 255, 0.33);
  }

  .level-img-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    ${props =>
      props.$isInProgress &&
      css`
        img.quest-border {
          opacity: 1;
        }
        img.quest-jollyroger {
          opacity: 1;
        }
      `}
    img.quest-border {
      height: 48px;
    }
    img.quest-jollyroger {
      height: 24px;
      position: absolute;
    }
  }
  .level-title-wrapper {
    padding-left: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .quest-level-title {
      text-transform: uppercase;
      font-size: 16px;
      padding-bottom: 2px;
    }
    ${props =>
      props.$isInProgress &&
      css`
        text-shadow:
          1px 1px 8.3px rgba(255, 255, 255, 0.5),
          1px 1px 8.3px rgba(255, 255, 255, 0.5);
        span {
          text-shadow:
            1px 1px 8.3px rgba(255, 255, 255, 0.5),
            1px 1px 8.3px rgba(255, 255, 255, 0.5);
        }
      `}
    span {
      text-transform: uppercase;
      color: ${props => (props.$isInProgress ? 'white' : 'rgb(170, 170, 170)')};

      &:last-child {
        /* margin-top: 4px; */
      }
    }
  }
`

export const SHiddenQuestContentTask = styled.div`
  display: flex;
  align-items: center;
  .hidden-circle {
    height: 24px;
    width: 24px;
    border-radius: 100%;
    background: rgba(255, 255, 255, 0.13);
    margin-right: 8px;
  }
  .hidden-line-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
    margin-top: 18px;
    .hidden-line {
      height: 8px;
      width: 100%;
      border-radius: 6px;
      background: rgba(255, 255, 255, 0.13);
      &:last-child {
        width: 60%;
      }
    }
  }

  &.level-1 {
    .hidden-line {
      &:last-child {
        width: 45%;
      }
    }
  }

  &.level-2 {
    .hidden-line {
      &:last-child {
        width: 50%;
      }
    }
  }

  &.level-3 {
    .hidden-line {
      &:last-child {
        width: 90%;
      }
    }
  }

  &.level-4 {
    .hidden-line {
      &:last-child {
        width: 26%;
      }
    }
  }

  &.level-5 {
    .hidden-line {
      &:last-child {
        width: 64%;
      }
    }
  }

  &.level-6 {
    .hidden-line {
      &:last-child {
        width: 84%;
      }
    }
  }
`
