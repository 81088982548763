/**
 * Generates a random floating-point number within the specified range.
 *
 * @param min - The minimum value (inclusive) of the range.
 * @param max - The maximum value (inclusive) of the range.
 * @param [decimals=3] - The number of decimal places in the result.
 * @returns A random floating-point number within the specified range,
 *                   rounded to the specified number of decimal places.
 */
export function getRandomFloat(min: number, max: number, decimals = 3) {
  // Use Math.random to generate a random number with three decimal points
  // within the range [min, max] (inclusive).
  const randomValue = Math.random() * (max - min) + min

  // Round the result to three decimal points.
  return parseFloat(randomValue.toFixed(decimals))
}

/**
 * Finds the edge with the highest absolute value.
 *
 * @param edges - A list of edge values.
 * @returns The edge that has the highest absolute value among the given edges.
 */
export function getAbsHighestEdge(...edges: number[]) {
  let currHighest = 0
  let absEdge = 0
  edges.forEach(edge => {
    if (Math.abs(edge) > currHighest) {
      currHighest = Math.abs(edge)
      absEdge = edge
    }
  })

  return absEdge
}

// Shaping functions
export * from './shapingFn'
