import { SVGS } from '@/assets'
import { Button, ButtonEnum } from '@/components/shared/Button'
import { TEXT_COLORS } from '@/design'
import CountUp from 'react-countup'
import {
  SettingsSubtitle,
  QuickplayWrapper,
  DepositsTitle,
  TitleWrapper,
  BalanceRow,
  Gradient,
  ButtonWrapper,
  EOAWrapper,
  BalanceWrapper,
} from './style'
import { Toggler } from '@/components/shared/Toggle'
import useBankroll from '@/hooks/useBankroll'
import { usePrevious } from 'react-use'
import numeral from 'numeral'
import useCurrencyStore from '@/store/useCurrencyStore'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { useDebouncedCallback } from 'use-debounce'
import { addAppNoti } from '@/store/useNotiStore'
import { useAA } from '@/lib/aa/hooks'
import { useNetworkStyle } from '@/hooks/useCurrency'
import { useActiveWallet } from '@/lib/privy/hooks'

export const TransactionsTab = () => {
  const balances = useCurrencyStore(state => state.balances)
  const networkStyle = useNetworkStyle()
  const selectedCurrencyAmount = useCurrencyStore.use.balances().currency
  const ethAmount = balances.native
  const prevEthAmount = usePrevious(ethAmount)
  const { activateAASession, deactivateAASession, isUsingAA, isActivatingAA, hasSetupBefore } =
    useAA()
  const { walletAddress } = useActiveWallet()
  const { getIsSubmitterAllowed, setAllowedSubmitter } = useBankroll()
  const [isSubmitterAllowed, setIsSubmitterAllowed] = useState(false)
  const ethDecimalCount = useMemo(() => {
    const _ethBalance = numeral(ethAmount).format('0,0.[0000]')
    const decimalCount = _ethBalance.toString().split('.')[1]?.length || 0
    return decimalCount
  }, [ethAmount])
  const isMobileScreen = useIsBreakpoint('sm')

  const handleQuickplayCheck = useDebouncedCallback(
    async () => {
      return
    },
    250,
    { leading: true }
  )

  const handleExternalCheck = async () => {
    if (!isUsingAA) return
    try {
      deactivateAASession(walletAddress)
      addAppNoti({
        msg: `Standard enabled`,
        type: 'success',
      })
    } catch (err) {
      addAppNoti({
        msg: `Failed: enabling standard`,
        type: 'error',
      })
      throw new Error('Error switching to use standard')
    }
  }

  return (
    <>
      <div>
        <SettingsSubtitle>
          Select your preferred transaction method for submitting wagers:
        </SettingsSubtitle>
        <QuickplayWrapper>
          <DepositsTitle
            $walletType='quickplay'
            // $depositType={isUsingAA ? 'aa' : undefined}
            $depositType={isUsingAA ? 'aa' : undefined}
            $disableCheck
            onClick={handleQuickplayCheck}
          >
            <TitleWrapper>
              <img src={SVGS.quickplayIcon} alt='quickplay' />
              <span>QUICKPLAY</span>
            </TitleWrapper>

            {/* <Toggler */}
            {/*   disabled */}
            {/*   isChecked={false} */}
            {/*   // disabled={isActivatingAA} */}
            {/*   // isChecked={isUsingAA} */}
            {/*   onChecked={handleQuickplayCheck} */}
            {/*   toggleType='quickplay' */}
            {/* /> */}

            <Gradient />
          </DepositsTitle>

          <div style={{ userSelect: 'none' }}>
            <p style={{ textAlign: 'justify' }}>
              Submit bets with one click. This means when you click{' '}
              <span style={{ color: TEXT_COLORS.one }}>SUBMIT BET</span> in a game, the funds will
              automatically be deployed from your wallet. You won&apos;t have to manually sign a
              transaction for every wager. Just click{' '}
              <span style={{ color: TEXT_COLORS.one }}>QUICKPLAY</span> to set a PHONEY spend limit
              and create a session key. That&apos;s it!
            </p>
            <ButtonWrapper isMobileScreen={isMobileScreen} depositType='aa'>
              <BalanceWrapper>
                <BalanceRow>
                  <img src={networkStyle.currencyIcon} alt={networkStyle.currencyName} width={16} />
                  <CountUp
                    end={Number(selectedCurrencyAmount)}
                    decimals={2}
                    duration={2}
                    separator={','}
                    preserveValue
                    suffix={` ${networkStyle.currencyName}`}
                  />
                </BalanceRow>
              </BalanceWrapper>
              <div className='qp-btn-wrapper'>
                {/* {zeroDevAddress && hasSetupBefore && ( */}
                {/*   <Button */}
                {/*     className='revoke-btn' */}
                {/*     isMinified={isMobileScreen} */}
                {/*     buttonType={ButtonEnum.BASE} */}
                {/*     disabled={false} */}
                {/*     isLoading={false} */}
                {/*     onClick={refreshSessionKey} */}
                {/*     style={{ width: 132, marginRight: 12 }} */}
                {/*   > */}
                {/*     Refresh */}
                {/*   </Button> */}
                {/* )} */}
                {isSubmitterAllowed && hasSetupBefore && (
                  <Button
                    className='revoke-btn'
                    isMinified={isMobileScreen}
                    buttonType={ButtonEnum.BASE}
                    disabled={false}
                    isLoading={false}
                    style={{ width: 132 }}
                    onClick={async () => {
                      deactivateAASession(walletAddress)
                    }}
                  >
                    Revoke
                  </Button>
                )}
                {!isSubmitterAllowed && hasSetupBefore && (
                  <Button
                    className='approve-btn'
                    isMinified={isMobileScreen}
                    buttonType={ButtonEnum.BASE}
                    disabled={false}
                    isLoading={false}
                    style={{ width: 132 }}
                    onClick={async () => {
                      activateAASession(walletAddress as `0x${string}`)
                    }}
                  >
                    Approve
                  </Button>
                )}
              </div>
            </ButtonWrapper>
          </div>
        </QuickplayWrapper>
        <EOAWrapper>
          <DepositsTitle
            $disableCheck={false}
            $walletType='external'
            $depositType={isUsingAA ? undefined : 'eoa'}
            onClick={handleExternalCheck}
          >
            <TitleWrapper>
              <img src={SVGS.externalPlayIcon} alt='external-play' />
              <span>EXTERNAL WALLET</span>
            </TitleWrapper>

            <Toggler isChecked={!isUsingAA} onChecked={handleExternalCheck} />

            <Gradient />
          </DepositsTitle>
          <div style={{ userSelect: 'none' }}>
            <p style={{ textAlign: 'justify' }}>
              Submit bets with two clicks. This means when you click{' '}
              <span style={{ color: TEXT_COLORS.one }}>SUBMIT BET</span> in a game, you will be
              prompted by your wallet to sign the transaction. You will have to manually sign this
              transaction for every wager. This is the slower option, but will feel more familiar to
              your experience with other dApps.
            </p>

            <ButtonWrapper isMobileScreen={isMobileScreen} depositType='eoa'>
              <BalanceWrapper>
                <BalanceRow>
                  <img src={networkStyle.currencyIcon} alt={networkStyle.currencyName} width={16} />
                  <CountUp
                    end={Number(selectedCurrencyAmount)}
                    decimals={2}
                    duration={2}
                    separator={','}
                    preserveValue
                    suffix={` ${networkStyle.currencyName}`}
                  />
                </BalanceRow>
                <BalanceRow>
                  <img src={networkStyle.networkLogo} alt={networkStyle.currencyName} width={16} />
                  <CountUp
                    start={Number(prevEthAmount)}
                    end={Number(ethAmount)}
                    decimals={ethDecimalCount}
                    duration={2}
                    separator={','}
                    preserveValue
                    suffix={` ${networkStyle.nativeToken}`}
                  />
                </BalanceRow>
              </BalanceWrapper>
            </ButtonWrapper>
          </div>
        </EOAWrapper>
      </div>
    </>
  )
}
