import { type ShipConfig } from './GameConfig'
import intensityData from './gameIntensity.json'

/**
 * Calculates the ship's vertical position based on the sum of two sine waves with exponential intensity scaling.
 * @param globalTime - The total time since the game was loaded.
 * @param roundTime - The time since the current round started.
 * @param config - Configuration object for the sine waves, including intensity scaling.
 * @returns The vertical position (y-coordinate) of the ship.
 */
export const getShipPosition = (
  globalTime: number,
  roundTime: number,
  config: ShipConfig
): number => {
  const { sine1, sine2, intensityScaling } = config
  const amplitudeScale = intensityScaling?.amplitudeScale ?? 0
  const frequencyScale = intensityScaling?.frequencyScale ?? 0
  const roundStartTime = globalTime - roundTime

  // Time since round started
  const timeSinceRoundStart = roundTime

  // First sine wave component
  const amplitude1 = sine1.amplitude * Math.exp(amplitudeScale * timeSinceRoundStart)

  const startingPhase1 = sine1.frequency * roundStartTime
  let phi1: number
  if (frequencyScale !== 0) {
    const expFreqScaleTime = Math.exp(frequencyScale * timeSinceRoundStart)
    phi1 = startingPhase1 + (sine1.frequency / frequencyScale) * (expFreqScaleTime - 1)
  } else {
    phi1 = startingPhase1 + sine1.frequency * timeSinceRoundStart
  }
  const sineComponent1 = amplitude1 * Math.sin(phi1)

  // Second sine wave component
  const amplitude2 = sine2.amplitude * Math.exp(amplitudeScale * timeSinceRoundStart)

  const startingPhase2 = sine2.frequency * roundStartTime
  let phi2: number
  if (frequencyScale !== 0) {
    const expFreqScaleTime = Math.exp(frequencyScale * timeSinceRoundStart)
    phi2 = startingPhase2 + (sine2.frequency / frequencyScale) * (expFreqScaleTime - 1)
  } else {
    phi2 = startingPhase2 + sine2.frequency * timeSinceRoundStart
  }
  const sineComponent2 = amplitude2 * Math.sin(phi2)

  // Sum of both sine waves
  return sineComponent1 + sineComponent2
}

/**
 * Calculates the ship's vertical velocity based on the derivative of the position function.
 * @param globalTime - The total time since the game was loaded.
 * @param roundTime - The time since the current round started.
 * @param config - Configuration object for the sine waves, including intensity scaling.
 * @returns The vertical velocity (y-component) of the ship.
 */
export const getShipVelocity = (
  globalTime: number,
  roundTime: number,
  config: ShipConfig
): number => {
  const { sine1, sine2, intensityScaling } = config
  const amplitudeScale = intensityScaling?.amplitudeScale ?? 0
  const frequencyScale = intensityScaling?.frequencyScale ?? 0
  const roundStartTime = globalTime - roundTime

  // Time since round started
  const timeSinceRoundStart = roundTime

  // First sine wave component
  const amplitude1 = sine1.amplitude * Math.exp(amplitudeScale * timeSinceRoundStart)
  const amplitudeDerivative1 = amplitude1 * amplitudeScale // Derivative of the amplitude

  const startingPhase1 = sine1.frequency * roundStartTime
  let phi1: number
  let phiDerivative1: number
  if (frequencyScale !== 0) {
    const expFreqScaleTime = Math.exp(frequencyScale * timeSinceRoundStart)
    phi1 = startingPhase1 + (sine1.frequency / frequencyScale) * (expFreqScaleTime - 1)
    phiDerivative1 = sine1.frequency * expFreqScaleTime // Derivative of the phase
  } else {
    phi1 = startingPhase1 + sine1.frequency * timeSinceRoundStart
    phiDerivative1 = sine1.frequency // Derivative of the phase
  }
  const velocityComponent1 =
    amplitudeDerivative1 * Math.sin(phi1) + amplitude1 * phiDerivative1 * Math.cos(phi1)

  // Second sine wave component
  const amplitude2 = sine2.amplitude * Math.exp(amplitudeScale * timeSinceRoundStart)
  const amplitudeDerivative2 = amplitude2 * amplitudeScale // Derivative of the amplitude

  const startingPhase2 = sine2.frequency * roundStartTime
  let phi2: number
  let phiDerivative2: number
  if (frequencyScale !== 0) {
    const expFreqScaleTime = Math.exp(frequencyScale * timeSinceRoundStart)
    phi2 = startingPhase2 + (sine2.frequency / frequencyScale) * (expFreqScaleTime - 1)
    phiDerivative2 = sine2.frequency * expFreqScaleTime // Derivative of the phase
  } else {
    phi2 = startingPhase2 + sine2.frequency * timeSinceRoundStart
    phiDerivative2 = sine2.frequency // Derivative of the phase
  }
  const velocityComponent2 =
    amplitudeDerivative2 * Math.sin(phi2) + amplitude2 * phiDerivative2 * Math.cos(phi2)

  // Sum of both velocity components
  return velocityComponent1 + velocityComponent2
}

/**
 * Calculates the time it takes to reach a given crash point based on the intensity levels.
 * @param crashPoint - The multiplier value at which the crash occurs.
 * @returns The time in seconds it takes to reach the crash point.
 */
export const getCrashRoundTime = (crashPoint: number): number => {
  const intensities = intensityData.intensities

  let accumulatedTime = 0 // Time in milliseconds
  let currentMultiplier = 0 // Starting multiplier

  for (let i = 0; i < intensities.length; i++) {
    const intensity = intensities[i]
    const nextIntensity = intensities[i + 1]
    const { multiplierStepSize, multiplierStepRate } = intensity.gamePacingConfig

    // Define the start and end times for the current intensity level
    const startTime = intensity.time * 1000 // Convert seconds to milliseconds if needed
    const endTime = nextIntensity ? nextIntensity.time * 1000 : Infinity // Convert to ms

    // Determine the duration available for the current intensity
    const availableTime = endTime - accumulatedTime

    if (availableTime <= 0) {
      continue // Proceed to the next intensity level
    }

    // Calculate the number of steps possible within the available time
    const stepInterval = multiplierStepRate // milliseconds per step
    const stepsPossible = Math.floor(availableTime / stepInterval)

    // Calculate the steps needed to reach the crashPoint
    const stepsNeeded = Math.ceil((crashPoint - currentMultiplier) / multiplierStepSize)

    if (stepsNeeded <= stepsPossible) {
      // Crash occurs within the current intensity level
      accumulatedTime += stepsNeeded * stepInterval
      return accumulatedTime / 1000 // Convert ms to seconds
    } else {
      // Crash not reached within this intensity level
      accumulatedTime += stepsPossible * stepInterval
      currentMultiplier += stepsPossible * multiplierStepSize
    }
  }

  // If crashPoint not reached within all intensity levels, return the accumulated time
  return accumulatedTime / 1000 // Convert ms to seconds
}
