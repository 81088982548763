import { LoadingChip } from '../LoadingChip'

const SFullScreenLoadingChip = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: black;
`

const FullScreenLoadingChip = () => {
  return (
    <SFullScreenLoadingChip>
      <LoadingChip width={48} height={48} />
    </SFullScreenLoadingChip>
  )
}

export default FullScreenLoadingChip
