import React from 'react'
import { TrollBox } from '../../TrollBox'

interface IMobileTrollBoxProps {
  isShowChat: boolean
}

export const MobileTrollBox = ({ isShowChat }: IMobileTrollBoxProps) => {
  return isShowChat ? <TrollBox /> : null
}
