import { Unity } from 'react-unity-webgl'

const SMetaverseContainer = styled.div`
  height: 100%;
  width: 100%;
`

// eslint-disable-next-line react/prop-types
export const PlinkoScene = ({ unityContext }: { unityContext: any }) => {
  // eslint-disable-next-line react/prop-types
  const { unityProvider, isLoaded } = unityContext

  return (
    <SMetaverseContainer>
      <Unity
        style={{ height: '100%', width: '100%', visibility: isLoaded ? 'visible' : 'hidden' }}
        unityProvider={unityProvider}
        // devicePixelRatio={window.devicePixelRatio}
        devicePixelRatio={2}
        id='unity-canvas'
      />
    </SMetaverseContainer>
  )
}
