import { type AppGameName } from '@/chains/types'
import useCurrencyStore from '@/store/useCurrencyStore'
import { useDebouncedCallback } from 'use-debounce'
import { gameStoreMapByGameName } from '@/store/useGameStateStore'
import { entryEvent } from '@/events/entryEvent'
import { useAppChainConfig } from '@/hooks/useAppChainConfig'
import { useCurrency } from '@/hooks/useCurrency'
import { useActiveWallet } from '@/lib/privy/hooks'

export const useBalanceListener = (gameName: AppGameName) => {
  const { appAddresses, appContracts, appProvider } = useAppChainConfig()
  const { walletChainId, isWalletLinked, activeWallet } = useActiveWallet()
  const isListeningToUSDC = useRef(true)
  const queuedUSDCBalance = useRef('')
  const useGameStore = useMemo(
    () =>
      gameStoreMapByGameName[gameName] ?
        gameStoreMapByGameName[gameName]
      : gameStoreMapByGameName.coinFlip,
    [gameName]
  )
  const gameState = useGameStore(state => state.type)
  const setBalance = useCurrencyStore(state => state.setBalance)
  const { fetchBalance } = useCurrency()
  const uiBalanceUpdateTimeoutRef = useRef<NodeJS.Timeout>()

  const walletAddress = useMemo(() => activeWallet?.address || '', [activeWallet])

  // NOTE: Need to refactor so there is a selectedCurrency rather than just usdc
  const fetchAndUpdateBalances = useDebouncedCallback(async (addr: string) => {
    setBalance('currency', (await fetchBalance(addr, 'currency')) ?? '0')
    setBalance('native', (await fetchBalance(addr, 'native')) ?? '0')
    setBalance('bankroll', (await fetchBalance(appAddresses.bankroll, 'currency')) ?? '0')
  }, 250)

  // TODO: Fetching a users nativeBalance amount is required whenever user is using privy wallet
  const debounceFetchUpdateNativeBalance = useDebouncedCallback(
    async (_account: string | undefined) => {
      setTimeout(async () => {
        if (_account) setBalance('native', (await fetchBalance(_account, 'native')) ?? '0')
      }, 1_000)
    },
    250
  )

  const debounceFetchUpdateUsdcBalance = useDebouncedCallback(
    async (_account: string | undefined) => {
      setTimeout(async () => {
        if (_account) setBalance('currency', (await fetchBalance(_account, 'currency')) ?? '0')
      }, 1_000)
    },
    250,
    { leading: true }
  )

  useEffect(() => {
    if (!appContracts || !appProvider || !activeWallet) return

    const transferFromFilter = appContracts.ws.currency.filters.Transfer(
      activeWallet.address,
      null,
      null
    )
    const transferToFilter = appContracts.ws.currency.filters.Transfer(
      null,
      activeWallet.address,
      null
    )

    const transferFromListener = (..._args: any[]) => {
      if (isListeningToUSDC.current) {
        console.log('can run from?', isListeningToUSDC.current)
        debounceFetchUpdateUsdcBalance(activeWallet.address)
      }
    }

    const transferToListener = (..._args: any[]) => {
      console.log('can run to?', isListeningToUSDC.current)
      if (isListeningToUSDC.current) {
        debounceFetchUpdateUsdcBalance(activeWallet.address)
      }
    }

    // TODO: Need to ensure this isn't running more than once per 50 blocks
    // const blockListener = (...args: any[]) => {
    // console.log(args)
    // debounceFetchUpdateNativeBalance(account)
    // }

    // Mount listeners
    // wsProvider.on('block', blockListener)
    appContracts.ws.currency.on(transferFromFilter, transferFromListener)
    appContracts.ws.currency.on(transferToFilter, transferToListener)

    // Remove listeners
    return () => {
      // wsProvider.removeListener('block', blockListener)
      appContracts.ws.currency.removeListener(transferFromFilter, transferFromListener)
      appContracts.ws.currency.removeListener(transferToFilter, transferToListener)
    }
  }, [walletChainId, isWalletLinked, activeWallet, appContracts])

  useEffect(() => {
    if (activeWallet) fetchAndUpdateBalances(activeWallet.address)
  }, [activeWallet, walletChainId, appProvider, fetchAndUpdateBalances, isWalletLinked])

  // TODO: This code will be a problem now that we are multichain. We need to refactor this
  useEffect(() => {
    if (gameState === 'IDLE') {
      isListeningToUSDC.current = true
    }

    if (gameState === 'START') {
      isListeningToUSDC.current = false
    }

    if (gameState === 'RESOLVE') {
      fetchBalance(walletAddress || '', 'currency')
        .then(balance => {
          queuedUSDCBalance.current = String(balance)
        })
        .catch(console.error)
    }
  }, [gameState, walletChainId, walletAddress])

  entryEvent.useSub('updateBalance', data => {
    const delay = data.detail?.balanceUpdateDelay || 0

    uiBalanceUpdateTimeoutRef.current = setTimeout(() => {
      if (queuedUSDCBalance.current) {
        setBalance('currency', queuedUSDCBalance.current)
      } else {
        debounceFetchUpdateUsdcBalance(walletAddress)
      }

      isListeningToUSDC.current = true
      queuedUSDCBalance.current = ''
    }, delay)
  })

  // Clear out updateBalance timeout when switching games
  useEffect(() => {
    if (uiBalanceUpdateTimeoutRef.current) {
      clearTimeout(uiBalanceUpdateTimeoutRef.current)
      uiBalanceUpdateTimeoutRef.current = undefined
    }
    // NOTE: We need to account for mulit chain here
  }, [gameName])
}
