import { GameContractListener } from '@/components/Singletons/GameContractListener'
import { type PropsWithChildren, useState } from 'react'
import { GamePage } from '@/pages'
import { GameLoadingChip } from './styled'
import { type GameModes } from '../Spinner'
import { LeftContainer, PageWrapper, RightContainer } from '@/pages/style'
import { RefactorLiveEntries } from '../LiveEntries/RefactorLiveEntries'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import FullScreenLoadingChip from '../FullScreenLoadingChip'

interface IGamePageWrapperProps extends PropsWithChildren {
  mode: GameModes
  CanvasComponent: React.FC<{
    isMounted: boolean
    setIsMounted: React.Dispatch<React.SetStateAction<boolean>>
  }>
  FormComponent: React.FC
}

const GamePageComponent: React.FC<IGamePageWrapperProps> = ({
  mode,
  CanvasComponent,
  FormComponent,
}) => {
  const [isMounted, setIsMounted] = useState(true)
  const isMobileScreen = useIsBreakpoint('sm')

  return (
    <PageWrapper>
      {!isMounted &&
        (isMobileScreen ? <GameLoadingChip width={48} height={48} /> : <FullScreenLoadingChip />)}
      <GamePage mode={mode}>
        <LeftContainer>
          <CanvasComponent isMounted={isMounted} setIsMounted={setIsMounted} />
          {!isMobileScreen && isMounted && <RefactorLiveEntries />}
        </LeftContainer>
        <RightContainer>{isMounted && <FormComponent />}</RightContainer>
      </GamePage>
      <GameContractListener />
    </PageWrapper>
  )
}

export default GamePageComponent
