import { useEffect, useState, useRef } from 'react'

const useMountDuration = () => {
  const [isMounted, setIsMounted] = useState(false)
  const [mountDuration, setMountDuration] = useState(0)
  const startTimeRef = useRef<number | null>(null)

  useEffect(() => {
    startTimeRef.current = Date.now()
    setIsMounted(true)
    return () => setIsMounted(false)
  }, [])

  useEffect(() => {
    if (isMounted && startTimeRef.current !== null) {
      const duration = (Date.now() - startTimeRef.current) / 1000
      setMountDuration(duration)
    }
  }, [isMounted])

  return { isMounted, mountDuration, setIsMounted }
}

export default useMountDuration
