import { SVGS } from '@/assets'
import { Button, ButtonEnum } from '@/components/shared/Button'
import { BORDER_COLORS, FARE_COLORS, SPACING } from '@/design'
import { useActiveWallet } from '@/lib/privy/hooks'

const ContainerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: fit-content;
  grid-gap: ${SPACING.sm}px;
  margin: 0 auto;

  @media (min-width: 1200px) {
    grid-template-columns: 2.5fr 1fr;
    margin: ${SPACING.xs}px;
  }
`

const DepositBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${FARE_COLORS.black};
  border-radius: 6px;
  padding: 18px;
  border: 1px solid ${BORDER_COLORS.one};
`

const DepositHeader = styled.div`
  margin-bottom: 16px;
  font-size: 12px;

  .aqua-bubble {
    border: 1px solid ${FARE_COLORS.aqua};
    border-radius: 4px;
    padding: 4px 8px;
    width: fit-content;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.1em;
    color: white;
  }
`

const DepositTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  color: white;
  margin-block: 6px;
  position: relative;

  img {
    margin-left: 8px;
  }

  .badge {
    position: absolute;
    top: 14px;
    left: 276px;
    background-color: ${BORDER_COLORS.one};
    border-radius: 50%;
    padding: 2px;
  }
`

const DepositContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-block: 16px;
`

const DepositContentItem = styled.div`
  display: flex;
  margin-block: 16px;
  align-items: center;

  .number {
    display: flex;
    flex-shrink: 0;
    border: 1px solid ${FARE_COLORS.aqua};
    background-color: ${FARE_COLORS.aqua}50;
    border-radius: 50%;
    padding: 6px;
    margin-right: 12px;
    color: white;
    justify-content: center;
    align-items: center;
    width: 10px;
    height: 10px;
    font-size: 10px;
  }

  .list-item {
    font-size: 12px;
    align-items: center;
    line-height: 1.5rem;
  }

  .logo-wrapper {
    display: inline-flex;
    align-items: center;
    position: relative;
    width: 40px;
    height: 24px;
  }

  .coinbase-logo,
  .binance-logo {
    position: absolute;
    width: 24px;
  }

  .coinbase-logo {
    top: -5px;
    left: 5px;
    z-index: 1;
  }

  .binance-logo {
    top: -5px;
    left: 25px;
    z-index: 0;
  }

  .base-bubble {
    display: inline-flex;
    border: 2px solid ${BORDER_COLORS.one};
    border-radius: 12px;
    padding: 1px 6px;
    width: fit-content;
    font-size: 12px;
    letter-spacing: 0.1em;
    color: white;
    align-items: center;

    img {
      margin-right: 4px;
    }
  }
`

const TextBold = styled.span`
  font-weight: 600;
  letter-spacing: 0.1em;
  color: white;
  font-size: 12px;
`

const CopyInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
  align-items: center;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
`

const InputAddress = styled.input`
  background-color: #121212;
  border: 1px solid ${BORDER_COLORS.one};
  border-radius: 6px;
  padding-inline: 8px;
  font-size: 13px;
  flex: 1;
  height: 40px;

  @media (min-width: 768px) {
    width: auto;
  }
`

const BalanceWrapper = styled.div`
  background-color: ${FARE_COLORS.black};
  height: 60%;
  border-radius: 6px;
  padding: 12px;
  border: 1px solid ${BORDER_COLORS.one};

  .balance-amount {
    font-size: 24px;
    color: white;
  }

  img {
    width: 14px;
  }
`

const BalanceContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-block: 16px;
`

const BalanceButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${BORDER_COLORS.one};
    border-radius: 50%;
    width: 26px;
    height: 26px;
    border: none;
  }
`

const BuyCryptoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${FARE_COLORS.black};
  border-radius: 6px;
  padding: 12px;
  border: 1px solid ${BORDER_COLORS.one};
`
const InlineContent = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  .divider {
    border: 1px solid ${BORDER_COLORS.one};
    flex-grow: 1;
  }

  .text {
    margin: 0 10px;
    color: ${BORDER_COLORS.one};
    font-weight: 700;
    font-size: 12px;
  }
`

const CryptoWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
`

const PaymentMethods = styled.div`
  display: flex;
  gap: 8px;

  img {
    width: 24px;
  }
`

export const DepositTab = () => {
  const { walletAddress, networkStyle } = useActiveWallet()

  return (
    <ContainerWrapper>
      <DepositBoxWrapper>
        <DepositHeader>
          <span className='aqua-bubble'>EASIEST METHOD</span> 1 MINUTE • FREE
        </DepositHeader>
        <DepositTitle>
          Deposit {networkStyle.currencyName} (Arbitrum)
          <img src={networkStyle.currencyIcon} width={24} alt={networkStyle.currencyName} />
          <img
            src={networkStyle.networkLogo}
            width={10}
            className='badge'
            alt={networkStyle.networkName}
          />
        </DepositTitle>

        <DepositContent>
          <DepositContentItem>
            <div className='number'>1</div>
            <div className='list-item'>
              <TextBold>BUY USDC</TextBold> on Coinbase, Binance or another exchange.
              <div className='logo-wrapper'>
                <div>
                  <img src={SVGS.coinbaseCoinLogo} className='coinbase-logo' alt='coinbase logo' />
                  <img src={SVGS.binanceLogo} className='binance-logo' alt='binance logo' />
                </div>
              </div>
            </div>
          </DepositContentItem>

          <DepositContentItem>
            <div className='number'>2</div>
            <div className='list-item'>
              <TextBold>SEND/WITHDRAW</TextBold> USDC to the address below and select{' '}
              <span className='base-bubble'>
                <img src={SVGS.baseLogoWhite} width={10} alt='base logo' />
                BASE
              </span>{' '}
              as the network
            </div>
          </DepositContentItem>
        </DepositContent>

        <CopyInputWrapper>
          <InputAddress placeholder={walletAddress} />
          <Button
            type='button'
            buttonType={ButtonEnum.CONNECT_WALLET}
            disabled={false}
            style={{
              width: '100px',
              backgroundColor: `${FARE_COLORS.aqua}50`,
              color: 'white',
              fontWeight: 'bold',
              height: '40px',
              paddingTop: '10px',
            }}
          >
            COPY
          </Button>
        </CopyInputWrapper>
      </DepositBoxWrapper>
      <BalanceWrapper>
        <div>BALANCE</div>
        <BalanceContent>
          <div className='balance-amount'>$0.00</div>
          <BalanceButtons>
            <button>
              <img
                src={SVGS.depositWallet}
                alt='deposit wallet'
                style={{ marginBottom: '3px', width: '12px' }}
              />
            </button>
            <button>
              <img src={SVGS.refresh} alt='refresh' />
            </button>
            <button>
              <img src={SVGS.tripleDot} alt='triple dot' />
            </button>
          </BalanceButtons>
        </BalanceContent>
      </BalanceWrapper>
      <InlineContent>
        <div className='divider'></div>
        <div className='text'>OTHER METHODS</div>
        <div className='divider'></div>
      </InlineContent>
      <div></div>
      <BuyCryptoWrapper>
        <CryptoWrapper>
          <TextBold>NO CRYPTO?</TextBold>
          <Button
            type='button'
            buttonType={ButtonEnum.CONNECT_WALLET}
            disabled={false}
            style={{
              width: '100px',
              backgroundColor: `${FARE_COLORS.aqua}50`,
              color: 'white',
              fontWeight: 'bold',
              paddingTop: '3px',
              height: '28px',
            }}
          >
            BUY USDC
          </Button>
        </CryptoWrapper>
        <PaymentMethods>
          <img src={SVGS.visaLogo} />
          <img src={SVGS.mastercardLogo} />
          <img src={SVGS.paypalLogo} />
          <img src={SVGS.applePayLogo} />
          <img src={SVGS.googlePayLogo} />
        </PaymentMethods>
      </BuyCryptoWrapper>
    </ContainerWrapper>
  )
}
