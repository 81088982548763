import numeral from 'numeral'

import { SGameHistoryWrapper, SGameHistory, SGameResult, SGameResultWrapper } from './styled'
import useUserDataStore, { type IGameHistory } from '@/store/useUserDataStore'
import { AppGameName } from '@/chains/types'
import { getAppCurrencyDecimals, gameNameToSVGMap } from '@/chains/lib'
import { FarePopover } from '../shared/FarePopover'
import { COLORS } from '@/design'
import { SVGS } from '@/assets'
import { utils } from 'ethers'
import { useBlockExplorerUrl } from '@/lib/privy/hooks/useBlockExplorerUrl'

export const GameHistory = () => {
  const { latestSUEntries } = useUserDataStore()
  const [hoveredGameTx, setHoveredGameTx] = useState('')
  const blockExplorerUrl = useBlockExplorerUrl()

  const handleMouseEnter = useCallback((txHash: string) => setHoveredGameTx(txHash), [])
  const handleMouseLeave = useCallback(() => setHoveredGameTx(''), [])

  const gameHistoryElems = useMemo(
    () =>
      latestSUEntries.map((game: IGameHistory) => {
        const decimalizedDeltaAmount = utils.formatUnits(
          game.deltaAmount || 0,
          getAppCurrencyDecimals()
        )
        const popoverTextColor =
          Number(decimalizedDeltaAmount) >= 0 ? COLORS.softSuccess : COLORS.softError
        const formattedDecimalizedDeltaAmount = numeral(decimalizedDeltaAmount)
          .format('0,0')
          .replace('-', '')
        const gameImageToRender = () => {
          if (!game.gameName) return <img src={SVGS.questionMarkIcon} alt={'game icon'} />

          return (
            <img
              src={gameNameToSVGMap[game.gameName as AppGameName] || SVGS.questionMarkIcon}
              alt={'game icon'}
            />
          )
        }

        return (
          <SGameHistory
            key={game.resolvementTxHash}
            href={`${blockExplorerUrl}/tx/${game.resolvementTxHash}`}
            target='_blank'
            onMouseEnter={() => handleMouseEnter(game.resolvementTxHash)}
            onMouseLeave={handleMouseLeave}
          >
            <FarePopover
              isShowing={hoveredGameTx === game.resolvementTxHash}
              textColor={popoverTextColor}
            >
              {formattedDecimalizedDeltaAmount}
            </FarePopover>
            {gameImageToRender()}

            <SGameResultWrapper>
              <SGameResult $isWin={Number(game?.deltaAmount) > 0} />
            </SGameResultWrapper>
          </SGameHistory>
        )
      }),
    [latestSUEntries, hoveredGameTx, blockExplorerUrl]
  )

  return <SGameHistoryWrapper>{gameHistoryElems}</SGameHistoryWrapper>
}
