import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { useAppFeatures } from '@/hooks/useAppFeatures'
import { useMediaQuery } from 'usehooks-ts'

const SMobileNoticeBlocker = styled.div`
  position: absolute;
  display: flex;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: black;
  color: #aaa;
  top: 0;
  left: 0;
  > span {
    text-align: justify;
    /* width: 33%; */
    width: 300px;
    /* max-width: 700px; */

    @media (max-width: 340px) {
      width: 76%;
    }

    @media (max-width: 200px) {
      display: none;
    }
  }

  .super-small-span {
    @media (min-width: 200px) {
      display: none;
    }
    text-align: center;
    width: 50px;
    display: block;
  }
`

export const MobileNoticeBlocker = () => {
  const isBreakpoint = useIsBreakpoint('hide')
  const isBreakpointHeight = useMediaQuery(`(max-height: 672px)`)
  const isMobileOverlayEnabled = useAppFeatures().enableMobile

  if ((!isBreakpoint && !isBreakpointHeight) || isMobileOverlayEnabled) {
    return null
  }

  return (
    <SMobileNoticeBlocker>
      <span>
        please increase your window size and refresh. if you’re on mobile or a tablet, try us out on
        desktop. resolution responsiveness is hard. mobile coming soon - we’re working on it 😊
      </span>
      <span className='super-small-span'>plz use on phone</span>
    </SMobileNoticeBlocker>
  )
}
