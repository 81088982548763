import { DEFAULT_MAX_COUNT } from './constants'
import { unit } from '../vault'
import { type GameHelperFunctions } from './types'

export const isValidDiceSide = (side: number = 0) => {
  return side >= 500 && side <= 9990
}

export const getMaxCountForDice = (side: number = 0) => {
  const maxCountLimitingSide = Math.min(side, 10000 - side)
  let i = BigInt(DEFAULT_MAX_COUNT)
  for (; i >= 1n; i--) {
    const q = ((10000n - BigInt(maxCountLimitingSide)) * unit) / 10000n
    const minQ = q > unit - q ? unit - q : q
    if (minQ ** i / unit ** (i - 1n) > 0n) {
      break
    }
  }
  // when loop ends or breaks out, the i should be the max valid count for given prob
  return Number(i)
}

export const getDiceMultiplierWithoutPPVFromSide = (side: number = 0) => {
  return 10000 / (10000 - side)
}

export const getDiceMultiplierWithPPVFromSide = (side: number = 0) => {
  return getDiceMultiplierWithoutPPVFromSide(side) * 0.99
}

// @NOTE: https://en.wikipedia.org/wiki/Kelly_criterion
export const getDiceKellyFractionFromSide = (side: number = 0) => {
  const pWin = (10000 - side) / 10000
  const pLose = 1 - pWin
  const aLose = 1
  const aWin = getDiceMultiplierWithPPVFromSide(side) - 1
  const kellyFraction = pWin / aLose - pLose / aWin
  return kellyFraction
}

export const getDicePotentialProfitCoefficientFromSide = (side: number = 0) => {
  return getDiceMultiplierWithPPVFromSide(side) - 1
}

export const diceHelperFunctions: GameHelperFunctions<number> = {
  isValidSide: isValidDiceSide,
  getMaxCount: getMaxCountForDice,
  getMultiplierWithoutPPV: getDiceMultiplierWithoutPPVFromSide,
  getMultiplierWithPPV: getDiceMultiplierWithPPVFromSide,
  getKellyFraction: getDiceKellyFractionFromSide,
  getPotentialProfitCoefficient: getDicePotentialProfitCoefficientFromSide,
}
