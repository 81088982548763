import React, { useEffect, useMemo, useState } from 'react'
import * as THREE from 'three'
import { BombTubeModel } from './BombTubeModel'
import { useBombsGameStore } from '@/store/useBombsGameStore'
import { useGameOutcomeStore } from '@/store/useGameOutcomeStore'
import { useSound } from '@/components/shared/SoundSystem/SoundContext'

// SFX imports
import TileSelectAudio from '@/assets/audio/ClickyButton4.wav'
import CoinOpenedAudio from '@/assets/audio/coin-flip.wav'
import BombOpenedAudio from '@/assets/audio/Spark 20.wav'
import { entryEvent } from '@/events/entryEvent'
import { useBombsGameState } from '@/store/useGameStateStore'

// Game parameters
const GRID_SIZE = 5
const TILE_SPACING = 1.03
const REVEAL_DELAY = 200 // ms between each tile reveal
const BOMB_REVEAL_DELAY = 600 // ms before revealing all bombs (after last selected tile reveal)

export const BombsGame: React.FC = () => {
  const {
    selectedTiles,
    // gameState,
    revealedTiles,
    bombCount,
    selectTile,
    revealNextTile,
    revealAllBombs,
    checkGameOutcome,
    setGameState,
    resetGame,
    ripples,
    updateRipples,
    addRipple,
    isReselectingTiles,
    reselectPreviousTiles,
    calculateOdds,
    numberOfEntries,
    allResults,
    setNumberOfEntries,
    setRevealedTiles,
    speedMultiplier,
    backendResult,
  } = useBombsGameStore()
  const gameState = useBombsGameState(state => state.type)

  const setBorderActive = useGameOutcomeStore(state => state.setIsShowingOutcome)
  const setPlayerWon = useGameOutcomeStore(state => state.setDidPlayerWin)
  const setWinIntensity = useGameOutcomeStore(state => state.setIntensity)

  const [openTiles, setOpenTiles] = useState<boolean[]>(Array(25).fill(false))

  // SFX
  const soundContext = useSound()

  useEffect(() => {
    soundContext.loadSound('TileSelect', TileSelectAudio)
    soundContext.loadSound('CoinOpened', CoinOpenedAudio)
    soundContext.loadSound('BombOpened', BombOpenedAudio)
  }, [soundContext])

  // Selection enabling/disabling
  const maxSelections = useMemo(() => 25 - bombCount, [bombCount])
  const currentSelections = useMemo(() => selectedTiles.filter(Boolean).length, [selectedTiles])

  const revealTile = useCallback((index: number) => {
    setOpenTiles(prev => {
      const newOpen = [...prev]
      newOpen[index] = true
      return newOpen
    })
  }, [])

  const revealAllTiles = useCallback(() => {
    setOpenTiles(Array(25).fill(true))
  }, [])

  // Game state change events
  useEffect(() => {
    switch (gameState) {
      case 'RESET':
        handleReset()
        break
      case 'START':
        console.log('START')
        break
      case 'RESOLVE':
        let timeoutId: NodeJS.Timeout

        // Reveal selected tiles one by one
        const revealLoop = async () => {
          for (let entryIndex = 0; entryIndex < numberOfEntries; entryIndex++) {
            const currentResult = allResults[entryIndex]
            setRevealedTiles(currentResult)

            const selectedIndices = selectedTiles
              .map((selected, index) => (selected ? index : -1))
              .filter(index => index !== -1)

            for (let i = 0; i < selectedIndices.length; i++) {
              await new Promise(resolve => {
                timeoutId = setTimeout(() => {
                  revealTile(selectedIndices[i])
                  const tileType = currentResult[selectedIndices[i]]
                  if (tileType === 'safe') {
                    soundContext.playSound('CoinOpened', 0.5, 1)
                  } else if (tileType === 'bomb') {
                    soundContext.playSound('BombOpened', 0.5, 1)
                    setPlayerWon(false)
                    setBorderActive(true)
                    setWinIntensity(0)
                  }
                  resolve(null)
                }, REVEAL_DELAY / speedMultiplier)
              })
            }

            await new Promise(resolve => {
              timeoutId = setTimeout(() => {
                revealAllBombs()
                revealAllTiles()
                const didPlayerWin: boolean = checkGameOutcome()
                setBorderActive(true)
                setPlayerWon(didPlayerWin)

                if (didPlayerWin) {
                  const winMultiplier = calculateOdds()
                  const winIntensity = calculateWinIntensity(winMultiplier)
                  setWinIntensity(winIntensity)
                } else {
                  setWinIntensity(0)
                }

                entryEvent.pub('updateBalance')
                entryEvent.pub('entryFinished', {
                  deltaAmount: backendResult.deltaAmounts[entryIndex],
                })
                resolve(null)
              }, BOMB_REVEAL_DELAY / speedMultiplier)
            })

            // Add a short delay only if we're in multi-game mode
            if (numberOfEntries > 1 && entryIndex < numberOfEntries - 1) {
              await new Promise(resolve => {
                timeoutId = setTimeout(() => {
                  resolve(null)
                }, 300 / speedMultiplier)
              })
            }

            if (entryIndex < numberOfEntries - 1) {
              await new Promise(resolve => {
                timeoutId = setTimeout(() => {
                  handleReset()
                  resolve(null)
                }, 1000 / speedMultiplier)
              })
            }
          }

          setTimeout(() => {
            setGameState('RESET')
            entryEvent.pub('gameFinished')
          }, 3000 / speedMultiplier)

          setTimeout(() => {
            setGameState('IDLE')
            if (isReselectingTiles) {
              reselectPreviousTiles()
            }
          }, 3500 / speedMultiplier)
        }

        revealLoop()

        return () => clearTimeout(timeoutId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    gameState,
    selectedTiles,
    revealTile,
    revealAllBombs,
    revealAllTiles,
    checkGameOutcome,
    setGameState,
    setBorderActive,
    setPlayerWon,
    setWinIntensity,
    soundContext,
    isReselectingTiles,
    reselectPreviousTiles,
    numberOfEntries,
    allResults,
    setRevealedTiles,
    calculateOdds,
    speedMultiplier,
    backendResult,
  ])

  useEffect(() => console.log(gameState, 'gameState1'), [gameState])

  const handleTileClick = (index: number) => {
    if (gameState === 'IDLE') {
      if (!selectedTiles[index] && currentSelections >= maxSelections) {
        return
      }
      selectTile(index)
      soundContext.playSound('TileSelect', 0.5, 1)
    }
  }

  function handleReset() {
    setOpenTiles(Array(25).fill(false))
    setBorderActive(false)
    setPlayerWon(false)
    setWinIntensity(1)
    resetGame()
    addRipple([2, 2], 0.75)
  }

  // Ripple animation
  useEffect(() => {
    const timer = setInterval(() => {
      updateRipples()
    }, 16) // ~60 FPS

    return () => clearInterval(timer)
  }, [updateRipples])

  // const [totalRevealTime, setTotalRevealTime] = useState(0)

  // Total time to reveal all tiles - used for bomb animation calculation
  // useEffect(() => {
  // if (gameState === 'RESOLVE') {
  // const selectedIndices = selectedTiles.filter(Boolean).length
  // const calculatedTotalRevealTime = selectedIndices * REVEAL_DELAY + BOMB_REVEAL_DELAY
  // setTotalRevealTime(calculatedTotalRevealTime)
  // }
  // }, [gameState, selectedTiles])

  return (
    <group position={[-2.06, 0, -2.06]}>
      {Array(25)
        .fill(null)
        .map((_, index) => {
          const x = index % GRID_SIZE
          const z = Math.floor(index / GRID_SIZE)
          const position = new THREE.Vector3(x * TILE_SPACING, 0, z * TILE_SPACING)
          return (
            <BombTubeModel
              key={index}
              position={position}
              scale={0.45}
              isSelected={selectedTiles[index]}
              onClick={() => handleTileClick(index)}
              isDisabled={
                gameState !== 'IDLE' ||
                (!selectedTiles[index] && currentSelections >= maxSelections)
              }
              index={index}
              ripples={ripples}
              rippleHeight={0.25}
              isOpen={openTiles[index]}
              revealedContent={revealedTiles[index]}
              speedMultiplier={speedMultiplier}
              numberOfEntries={numberOfEntries}
            />
          )
        })}
    </group>
  )
}

const calculateWinIntensity = (multiplier: number) => {
  if (multiplier >= 20) return 5
  if (multiplier >= 10) return 4
  if (multiplier >= 5) return 3
  if (multiplier >= 2.5) return 2
  return 1
}
