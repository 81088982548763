import React from 'react'
import { Controller, type Control } from 'react-hook-form'
import numeral from 'numeral'
import CountUp from 'react-countup'
import { FareNumberInput } from '@/components/shared/Input/FareNumberInput'
import { useCurrency } from '@/hooks/useCurrency'
import { SVGS } from '@/assets'
import { FormAmountLabel, FormIndicator, FormLabel, FormLabelRow, SFormSection } from '../../style'

interface AmountInputFieldProps {
  control: Control<any>
  name: string
  label: string
  balance: number
  maxAmount: number
}

export const AmountInputField: React.FC<AmountInputFieldProps> = ({
  control,
  name,
  label,
  balance,
  maxAmount,
}) => {
  const { networkStyle } = useCurrency()

  return (
    <SFormSection>
      <FormLabelRow>
        <FormLabel>
          <FormIndicator $isActive={balance > 0} />
          <FormAmountLabel>{label}</FormAmountLabel>
        </FormLabel>
        <FormLabel>
          {balance > 0 && (
            <FormAmountLabel>
              <CountUp end={balance} decimals={2} duration={2} separator=',' preserveValue />
            </FormAmountLabel>
          )}
        </FormLabel>
      </FormLabelRow>
      <Controller
        name={name}
        control={control}
        rules={{ required: true, max: maxAmount, min: 0 }}
        render={({ field }) => {
          const value = numeral(field.value).value() || 0
          const isDisabled = balance === 0

          return (
            <FareNumberInput
              {...field}
              onChange={event => field.onChange(numeral(event.target.value).value())}
              allowLeadingZeros={false}
              allowNegative={false}
              isAllowed={({ floatValue = 0 }) => floatValue <= maxAmount}
              onFocus={event => event.target.select()}
              hasInputSlider
              disabled={isDisabled}
              inputSliderProps={{
                value,
                onChange: sliderValue => field.onChange(sliderValue),
                min: 0,
                max: balance,
                step: 1,
              }}
              thousandSeparator=','
              inputSuffix={
                <img src={networkStyle.currencyIcon} alt={networkStyle.currencyName} width={20} />
              }
            />
          )
        }}
      />
    </SFormSection>
  )
}
