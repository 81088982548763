import { useDebouncedCallback } from 'use-debounce'
import useCurrencyStore from '@/store/useCurrencyStore'
import { TypedListener } from '@/lib/crypto/typechain/common'
import { utils } from 'ethers'
import { ApprovalEvent, ApprovalEventFilter } from '@/lib/crypto/typechain/USDCMock'
import { useAppChainConfig } from '@/hooks/useAppChainConfig'
import { useCurrency } from '@/hooks/useCurrency'
import { useActiveWallet } from '@/lib/privy/hooks'

export const useAllowanceListner = () => {
  const { appAddresses, appContracts, appProvider } = useAppChainConfig()
  const setAllowance = useCurrencyStore(state => state.setAllowance)
  const { fetchAllowance } = useCurrency()
  const { walletAddress, walletChainId, networkStyle } = useActiveWallet()

  const debounceFetchAllowance = useDebouncedCallback(
    async (...args: Parameters<typeof fetchAllowance>) => {
      try {
        const allowance = await fetchAllowance(...args)
        setAllowance('currency', allowance)
      } catch (err) {
        console.warn(err)
      }
    },
    250,
    { leading: true, maxWait: 2_000 }
  )

  useEffect(() => {
    if (!walletAddress || !appContracts) return
    let approvalFromFilter: ApprovalEventFilter
    let approvalFromListener: TypedListener<ApprovalEvent>
    approvalFromFilter = appContracts.ws.currency.filters.Approval(
      walletAddress,
      appAddresses.bankroll,
      null
    )
    approvalFromListener = async (_owner, _spender, value) => {
      console.log(_owner, _spender, value)
      if (walletAddress) setAllowance('currency', utils.formatUnits(value, networkStyle.decimals))
    }
    appContracts.ws.currency.on(approvalFromFilter, approvalFromListener)

    return () => {
      appContracts.ws.currency.removeListener(approvalFromFilter, approvalFromListener)
    }
  }, [walletAddress, appContracts, appProvider, appAddresses, walletChainId])

  useEffect(() => {
    if (walletAddress && appContracts && appAddresses.bankroll) {
      debounceFetchAllowance(walletAddress, appAddresses.bankroll)
    }
  }, [walletAddress, appContracts, appProvider, appAddresses, walletChainId])
}
