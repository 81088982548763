import { motion } from 'framer-motion'
import {
  floatingContainer,
  FONT_STYLES,
  SPACING,
  BORDER_COLORS,
  TEXT_COLORS,
  FARE_COLORS,
  COLORS,
  COMPONENTS,
  BACKGROUND_COLORS,
  BREAKPOINTS,
} from '@/design'
import { ModeButton } from '../shared/Button/style'

export const Form = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  position: relative;
  border-radius: 6px;
  user-select: none;
  overflow: scroll;
  height: fit-content;
  ${floatingContainer}

  input[inputmode="numeric"] {
    ${FONT_STYLES.sm}
  }

  .rc-slider {
    width: calc(100% - 24px);
    margin-left: auto;
    margin-right: auto;
    margin-top: ${SPACING.md}px;
    margin-bottom: 8px;
  }
  .rc-slider-mark-text {
    height: 0px;
    width: 0px;
    display: none;
  }

  .rc-slider-mark-text-active {
    height: 0px;
    width: 0px;
    display: none;
  }

  .rc-slider-rail {
    background: rgba(255, 255, 255, 0.15);
  }

  .rc-slider-track {
    background: #0f6fff;
  }

  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: #27ff83;
    background: #27ff83;
    box-shadow: 0px 0px 16px #53ff0f;
    position: relative;
  }

  .rc-slider-handle-dragging::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;
    background-color: #27ff83;
    opacity: 0.5;
    border-radius: 50%;
  }

  .rc-slider-dot {
    background: transparent;
    border: none;
    height: 6px;
    width: 6px;
    bottom: 0px;
    height: 4px;
    width: 2px;
    border-radius: 0px;
    &:first-of-type {
      height: 0px;
      width: 0px;
      border: none;
    }
    &:last-of-type {
      height: 10px;
      width: 10px;
      bottom: -3px;
      border-radius: 100px;
      background: #0f6fff;
      border: 1px solid #0f6fff;
    }
  }

  .rc-slider-dot-active {
    display: none;
  }

  .rc-slider-handle {
    border-color: #27ff83;
    background: #27ff83;
  }
`
export const FormWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
`

export const FormTab = styled.div<{ $tabs?: number }>`
  margin: 0;
  background: ${BACKGROUND_COLORS.two};
  border-bottom: 1px solid ${BORDER_COLORS.one};
  padding: 12px 16px 12px 24px;
  gap: 18px;
  display: flex;

  &.mobile-tabs {
    @media (max-width: 1000px) {
      padding-top: 25px;
      border: none;
    }
  }

  &.risk-tabs {
    border: none;
    padding: 0px;
    @media (max-width: 1000px) {
      padding: 0px;
      gap: 4px;
    }
  }

  ${({ $tabs }) => css`
    @for $i from 1 through ${$tabs ?? 1} {
      &:nth-child(#{$i}) {
        grid-column: $i / span 1;
      }
    }
  `}

  > ${ModeButton} {
    height: ${COMPONENTS.tabs}px;
    text-transform: uppercase;
    margin: ${SPACING.xxs}px;
    border-bottom: 1px solid ${BORDER_COLORS.one};
    flex: 1;
    font-family: GohuUppercase, monospace;
    line-height: 18px;

    &:first-of-type {
      margin-right: 0px;
    }

    &:last-of-type {
      margin-left: 0px;
    }

    &:not(:last-of-type) {
      border-right: 1px solid ${BORDER_COLORS.one};
    }

    @media (max-width: 1000px) {
      padding: 8px 16px;
      margin: 0;
    }
  }

  > * {
    grid-column: span 1;
  }

  @media (max-width: 1000px) {
    padding-inline: 8px;
    padding-bottom: 10px;
  }
`

export const FormLabel = styled.label.attrs<{ $isRelative?: boolean; name?: string }>(props => ({
  name: props.name,
  $isRelative: props.$isRelative,
}))`
  color: ${TEXT_COLORS.two};
  margin-bottom: ${SPACING.xs}px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${FONT_STYLES.xs}
  ${({ $isRelative }) =>
    $isRelative &&
    css`
      position: relative;
    `}

  > span {
    display: inline-block;
  }

  p {
    color: ${TEXT_COLORS.three};
  }
  .queued-title-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`

export const FormIndicator = styled.span<{ $isActive?: boolean }>`
  display: inline-block;
  height: 4px;
  width: 4px;
  border-radius: 1px;
  margin: auto;
  position: absolute;
  left: ${SPACING.sm}px;
  transition: 0.2s all ease-in-out;
  ${({ $isActive }) =>
    $isActive ?
      css`
        border: 1px solid ${FARE_COLORS.aqua};
        box-shadow: inset 0px 0px 10px ${FARE_COLORS.aqua}99;
      `
    : css`
        border: 1px solid #ffffff1c;
        background: #27292b99;
      `}
`

export const FormFooter = styled.div`
  padding-top: ${SPACING.sm}px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width: ${BREAKPOINTS.sm}px) {
    marin-top: ${SPACING.sm}px;

    button {
      margin-bottom: 0;
    }
  }

  > .form-footer-mt-sm {
    margin-bottom: ${SPACING.sm}px;
  }

  &:hover {
    ${FormIndicator} {
      border: 1px solid rgba(255, 255, 255, 0.5);
    }
  }

  button {
    width: 100%;
  }
`

export const FormLabelRow = styled.div`
  display: flex;
  justify-content: space-between;

  > ${FormLabel} {
    margin-bottom: ${SPACING.xs}px;
  }

  &.center-label {
    justify-content: center;
    padding-right: 12px;
  }

  @media (max-width: 1000px) {
    padding-inline: 16px;

    &.center-label {
      justify-content: start;
      padding-top: 6px;
    }
  }
`

export const FormAmountLabel = styled.div`
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  ${FONT_STYLES.xs};
  &.is-negative {
    color: ${COLORS.error};
  }

  > span {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    ${FONT_STYLES.xs};
    &.is-negative {
      color: ${COLORS.error};
    }

    @media (max-height: 790px) {
      text-wrap: nowrap;
    }
  }
`

export const FormButton = styled.button`
  background: ${BACKGROUND_COLORS.two};
  border: 1px solid ${BORDER_COLORS.one};
  transition: 0.2s ease-in-out;
  color: ${TEXT_COLORS.two};
  ${FONT_STYLES.xs};
  border-radius: 4px;
  min-width: 28px;
  cursor: pointer;
  margin: auto;
  padding: ${SPACING.xxs}px;
  min-height: 18px;

  &:not(:last-of-type) {
    margin-right: 6px;
  }

  &:hover {
    color: ${TEXT_COLORS.one};
    border: 1px solid ${FARE_COLORS.blue};
  }
`

export const MotionFormSection = styled(motion.div)`
  padding: ${SPACING.md}px;
  position: relative;
  transition: 0.2s all ease-in-out;
  box-sizing: border-box;
  border-bottom: 1px solid ${BORDER_COLORS.one};
  padding-left: ${SPACING.lg}px;
  display: flex;
  flex-direction: column;

  &.stretch-content {
    flex: 1;
  }

  &:not(.notActive):hover {
    > * {
      transition: 0.2s all ease-in-out;
      color: ${TEXT_COLORS.one};
    }

    &:first-of-type {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
  }

  &.content-scroll {
    overflow-y: scroll;
    padding: 0px;
  }

  &.sm-pd-bottom {
    padding-bottom: ${SPACING.xs}px;
  }
`

export const SFormSection = styled.div`
  padding: ${SPACING.md}px;
  position: relative;
  transition: 0.2s all ease-in-out;
  box-sizing: border-box;
  border-bottom: 1px solid ${BORDER_COLORS.one};
  padding-left: ${SPACING.lg}px;
  display: flex;
  flex-direction: column;

  &.no-pd {
    padding: 0px;
    border-bottom: none;
  }

  &.stretch-content {
    flex: 1;
  }

  &:not(.notActive):hover {
    > * {
      transition: 0.2s all ease-in-out;
      color: ${TEXT_COLORS.one};
    }

    &:first-of-type {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
  }

  &.content-scroll {
    overflow-y: scroll;
    padding: 0px;
  }

  &.no-border {
    border: none;
    padding-bottom: 4px;
  }

  &.sm-pd-bottom {
    padding-bottom: ${SPACING.xs}px;
  }

  @media only screen and (max-width: ${BREAKPOINTS.sm}px) {
    padding: 10px;
    padding-inline: 8px;
    flex: 1;
    border: none;
  }
`
