import useLeaderboardStore, { type ILeaderboardArrayData } from '@/store/useLeaderboardStore'
import axios from 'axios'
import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'

const useLeaderboard = (shouldRetrieve = false) => {
  const { leaderboard, setLeaderboard } = useLeaderboardStore()
  const { httpUrl } = useAppChainConfigStore.use.appChainConfig()

  // @NOTE: Do not request the data from backend everytime you use this hook. Only do it once, by setting `shouldRetrieve` to true in one of the hook usage
  useEffect(() => {
    if (!shouldRetrieve) return
    ;(async () => {
      const {
        data: { leaderboard: receivedLeaderboard },
      } = await axios({
        method: 'GET',
        withCredentials: true,
        url: `${httpUrl}/deathmatch/leaderboard?stopLimit=500`,
      })
      console.log('received leaderboard: ', receivedLeaderboard)
      setLeaderboard(receivedLeaderboard as ILeaderboardArrayData[])
    })()
  }, [shouldRetrieve, setLeaderboard, httpUrl])

  const requestLeaderboard = async () => {
    const {
      data: { leaderboard },
    } = await axios({
      method: 'GET',
      withCredentials: true,
      url: `${httpUrl}/deathmatch/leaderboard?stopLimit=500`,
    })
    console.log('received leaderboard: ', leaderboard)
    setLeaderboard(leaderboard as ILeaderboardArrayData[])
  }

  return {
    leaderboard,
    requestLeaderboard,
  }
}

export default useLeaderboard
