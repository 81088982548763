import { Environment } from '@react-three/drei'

import { RPSCamera } from './RPSCamera'
import { RPSGrid } from './RPSGround'
// import { HandModelRefactor } from './HandModelRefactor'
import { MultiHandRefactor } from './MultiHandRefactor'

export const RPSScene = ({ loadSound, playSound }: any) => {
  const [isFastMode, setIsFastMode] = useState(false)

  return (
    <>
      <RPSCamera />
      <Environment preset='forest' />

      {/* Lights */}
      <ambientLight intensity={0.2} />
      <directionalLight intensity={2} color={'white'} position={[1, 2, 3]} castShadow />

      <MultiHandRefactor
        isFastMode={isFastMode}
        setIsFastMode={setIsFastMode}
        loadSound={loadSound}
        playSound={playSound}
      />
      <RPSGrid />
    </>
  )
}
