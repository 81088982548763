import { PaymasterMode } from '@biconomy/account'
import { AppBiconomyConfig } from '../types'

// Usdc Prod Mainnet Biconomy
export const BICONOMY_PAYMASTER_API_KEY = 'RltkjGfVe.397a4268-259e-4640-b3cb-dd210018af58'
export const BICONOMY_PAYMASTER_URL =
  'https://paymaster.biconomy.io/api/v1/42161/RltkjGfVe.397a4268-259e-4640-b3cb-dd210018af58'
export const BICONOMY_BUNDLER_URL =
  'https://bundler.biconomy.io/api/v2/42161/plfarye.wh12stac-7E49-85b-af80-z7JnP3xLs'
export const BICONOMY_PAYMASTER_ADDRESS = '0xace659dc614d5fc455d123a1c3e438dd78a05e77'

export const defaultBiconomyConfig: AppBiconomyConfig = {
  bundlerUrl: BICONOMY_BUNDLER_URL,
  paymasterUrl: BICONOMY_PAYMASTER_URL,
  payMasterAPIKey: BICONOMY_PAYMASTER_API_KEY,
  paymasterId: BICONOMY_PAYMASTER_ADDRESS,
} as const

export const withSponsorship = {
  paymasterServiceData: { mode: PaymasterMode.SPONSORED },
}

export const withoutSponsorship = {
  paymasterServiceData: { mode: PaymasterMode.ERC20 },
  skipPatchCallData: true, // Required when mode is set to ERC20
}
