import { SVGS } from '@/assets'
import {
  DeathMatchColoredText,
  DeathMatchImageContainer,
  LeaderboardDeathMatchColoredSkull,
  LeaderboardHeadingContainer,
  LeaderboardHeadingText,
} from './style'

interface ILeaderboardHeaderProps {
  heading: string
}
const LeaderboardHeader = ({ heading }: ILeaderboardHeaderProps) => {
  return (
    <LeaderboardHeadingContainer>
      <DeathMatchImageContainer>
        <LeaderboardDeathMatchColoredSkull src={SVGS.deathMatchColored} />
        <DeathMatchColoredText src={SVGS.deathMatchTextColored} />
      </DeathMatchImageContainer>
      <LeaderboardHeadingText>{heading}</LeaderboardHeadingText>
    </LeaderboardHeadingContainer>
  )
}

export default LeaderboardHeader
