import { COLORS, SPACING } from '@/design'

export const SGameHistoryWrapper = styled.div`
  display: grid;
  width: 100%;
  align-items: center;
  grid-template-columns: repeat(10, 1fr);
  grid-gap: ${SPACING.xs}px;
`
export const SGameHistory = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  > img {
    transition: 0.2s ease-in-out all;
    width: 16px;
    height: 16px;
    margin: auto;
    filter: grayscale(1) brightness(0.5);
  }

  &:hover {
    > img {
      filter: grayscale(0) brightness(1);
    }
  }
`

export const SGameResultWrapper = styled.div`
  margin-top: ${SPACING.xxs}px;
`

export const SGameResult = styled.div<{ $isWin?: boolean }>`
  height: 4px;
  width: 4px;
  border-radius: 100%;

  ${({ $isWin }) =>
    $isWin ?
      css`
        background-color: ${COLORS.success};
      `
    : css`
        background-color: ${COLORS.error};
      `}
`
