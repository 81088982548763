import { DicePage } from './pages/DicePage'
import { CoinFlipPage } from './pages/CoinFlipPage'
import { RPSPage } from './pages/RPSPage'
import { BombsPage } from './pages/BombsPage'
import SettingsPage from './pages/SettingsPage'
import { PointsPage } from './pages/PointsPage'
import { PlinkoPage } from './pages/PlinkoPage'
import { AppDownPage } from './pages/AppDownPage'
import { CrashPage } from './components/Games/Crash/CrashPage'
import { routeLinks } from './constants/links'

const routes = [
  <Route path='/dice' element={<DicePage />} />,
  <Route path='/coin-flip' element={<CoinFlipPage />} />,
  <Route path='/rps' element={<RPSPage />} />,
  <Route path='/bombs' element={<BombsPage />} />,
  <Route path='/plinko' element={<PlinkoPage />} />,
  <Route path='/deathmatch' element={<PointsPage />} />,
  <Route path='/crash' element={<CrashPage />} />,
  <Route path='/settings' element={<SettingsPage />} />,
].filter(routeDef => routeLinks.find(routeLink => routeLink.to === routeDef.props.path)?.isEnabled)

export const PublicRoutes = () => {
  return (
    <Routes>
      <Route path='/'>
        <Route index element={<Navigate to='/coin-flip' replace />} />
        {routes}
        <Route path='*' element={<Navigate to='/deathmatch' replace />} />
      </Route>
      <Route path='/down-for-maintenance' element={<AppDownPage />} />,
    </Routes>
  )
}
