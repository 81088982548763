import { BREAKPOINTS } from '@/design'
import {
  mobileModalContentVariants,
  modalContentVariants,
  SGradientWalletModalContent,
} from '../Privy/SelectWalletModal'
import { useIsBreakpoint } from '@/hooks/common/useIsBreakpoint'
import { blockedGeoMessage } from '@/constants/geofence'
import './geofence-modal.css'

// const Overlay = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.6);
//   z-index: 999;

//   &:not(.display-modal) {
//     display: none;
//   }
// `

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  height: 300px;
  width: 600px;
  border-radius: 6px;

  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 330px;
    padding: 0 20px;
    text-align: center;
  }
`

export default function GeofenceModal() {
  const originDomainName = location.origin
  const isMobileScreen = useIsBreakpoint('xs')

  if (!originDomainName.includes('blocked-geo.')) {
    return null
  }

  return (
    <div id='blocked-geo-modal-overlay' className='modal-showing'>
      <ModalWrapper>
        <SGradientWalletModalContent
          variants={isMobileScreen ? mobileModalContentVariants : modalContentVariants}
          initial='initial'
          animate='animate'
          exit='exit'
        >
          <ModalContent>{blockedGeoMessage}</ModalContent>
        </SGradientWalletModalContent>
      </ModalWrapper>
    </div>
  )
}
