import { BREAKPOINTS, floatingContainer } from '@/design'
import { motion } from 'framer-motion'

export const SLiveEntriesWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  flex-grow: 1;
  box-sizing: border-box;
  grid-column: 1 / span 5;
  user-select: none;
  ${floatingContainer};
  font-family: GohuUppercase, monospace;
  overflow: scroll;

  @media only screen and (max-width: ${BREAKPOINTS.lg}px) {
    grid-column: 1 / span 5;
  }
`

export const SBetLink = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-top: 7px;
  padding-bottom: 7px;
  width: 100%;

  img {
    filter: invert();
    width: 10px;
    height: 10px;
  }
`

export const SLEHeaderRow = styled(motion.div)`
  user-select: none;
  display: flex;
  align-items: center;
  height: 32px;
  border-bottom: 1px solid #222;
  background: transparent;
  position: sticky;
  z-index: 9;
  width: 100%;
  background: black;
  top: 0;
  box-sizing: border-box;
  padding-inline: 16px;
  > div {
    color: #aaa;
    font-size: 12px;
    line-height: 9px;
    padding-top: 1px;
    &.le-center {
      text-align: center;
    }
    &:nth-child(1) {
      width: 29%;
    }
    &:nth-child(2) {
      width: 22%;
    }
    &:nth-child(3) {
      width: 21%;
    }
    &:nth-child(4) {
      width: 21%;
    }
    &:nth-child(5) {
      width: 7%;
    }

    @media only screen and (max-width: ${BREAKPOINTS.sm}px) {
    padding-inline: 2px;
      &:nth-child(1) {
        width: 42%;
      }
      &:nth-child(2) {
        width: 24%;
      }
      &:nth-child(3) {
        width: 16%;
      }
      &:nth-child(4) {
        width: 9%;
      }
      &:nth-child(5) {
        width: 9%;
      }
  }
`

export const SLEData = styled.div`
  color: #aaa;
  display: flex;
  align-items: center;
  height: 24px;
  background: transparent;
  .mr-left {
    margin-left: 8px;
  }
  &.le-center {
    justify-content: center;
  }
  > span {
    &.le-win {
      color: #01ff00;
    }
    font-size: 12px;
    line-height: 9px;
    padding-top: 1px;
  }
`

export const SLEDataRow = styled(motion.div)`
  display: flex;
  padding: 6px 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  width: 100%;
  box-sizing: border-box;
  ${SLEData} {
    display: flex;
    align-items: center;
    font-family: GohuUppercase, monospace;
    line-height: 9px;
    padding-top: 1px;
    &:nth-child(1) {
      width: 29%;
    }
    &:nth-child(2) {
      width: 22%;
    }
    &:nth-child(3) {
      width: 21%;
    }
    &:nth-child(4) {
      width: 21%;
    }
    &:nth-child(5) {
      width: 7%;
    }

    @media only screen and (max-width: ${BREAKPOINTS.sm}px) {
      &:nth-child(1) {
        width: 42%;
      }
      &:nth-child(2) {
        width: 24%;
      }
      &:nth-child(3) {
        width: 16%;
      }
      &:nth-child(4) {
        width: 9%;
      }
      &:nth-child(5) {
        width: 9%;
      }
    }
  }
`
