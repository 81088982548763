// Asteroid.tsx
import React, {
  useRef,
  useEffect,
  useMemo,
  useState,
  memo,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react'
import { useFrame } from '@react-three/fiber'
import * as THREE from 'three'
import EdgeShaderMaterial from './EdgeShaderMaterial'
import { useGLTF } from '@react-three/drei'
import { type GLTF } from 'three-stdlib'
import { ExplosionEffect } from '@/components/shared/ParticleFX/GenericExplosion'
import { useCrashGameStore } from '@/store/useCrashGameStore'

type GLTFResult = GLTF & {
  nodes: { [key: string]: THREE.Mesh }
  materials: { [key: string]: THREE.Material }
}

const asteroidModels = [
  '/glb/asteroids/Asteroid1.glb',
  '/glb/asteroids/Asteroid2.glb',
  '/glb/asteroids/Asteroid3.glb',
  '/glb/asteroids/Asteroid4.glb',
]

interface AsteroidProps {
  config: {
    speed: number // Units per second
    initialPosition: THREE.Vector3
    size: number
    isKiller: boolean
    color?: string
    emissiveColor: string
    edgeColor: string
    edgeThickness?: number
    roughness?: number
    metalness?: number
    edgeGlow?: number
    onRespawn: () => void
    rotationIntensity: number
    onPositionUpdate?: (position: THREE.Vector3) => void
    onDestroyComplete?: () => void
    id?: string
  }
}

export interface AsteroidRef {
  destroy: () => void
  freeze: () => void
}

const Asteroid = forwardRef<AsteroidRef, AsteroidProps>(({ config }, ref) => {
  const {
    speed,
    initialPosition,
    size,
    emissiveColor,
    edgeColor,
    edgeThickness = 2,
    edgeGlow = 2.0,
    rotationIntensity,
  } = config

  const meshRef = useRef<THREE.Mesh>(null!)
  const positionRef = useRef<THREE.Vector3>(new THREE.Vector3())
  const modelIndex = useMemo(() => Math.floor(Math.random() * asteroidModels.length), [])
  const { nodes } = useGLTF(asteroidModels[modelIndex]) as GLTFResult
  const [isLoading, setIsLoading] = useState(true)
  const [isExploding, setIsExploding] = useState(false)
  const [isVisible, setIsVisible] = useState(true)
  const { gameState } = useCrashGameStore()
  const speedMultiplier = useRef(1)

  const lastKnownPosition = useRef(new THREE.Vector3())

  useEffect(() => {
    if (nodes) {
      const asteroidNode = Object.values(nodes).find(node => node.geometry)
      if (asteroidNode) {
        setIsLoading(false)
      } else {
        console.error('No asteroid geometry found in the loaded model')
      }
    }
  }, [nodes])

  useEffect(() => {
    if (meshRef.current) {
      meshRef.current.position.copy(initialPosition)
      meshRef.current.scale.setScalar(size)
      meshRef.current.rotation.set(
        Math.random() * Math.PI,
        Math.random() * Math.PI,
        Math.random() * Math.PI
      )
    }
  }, [initialPosition, size])

  const asteroidGeometry = useMemo(() => {
    if (!isLoading && nodes) {
      const asteroidNode = Object.values(nodes).find(node => node.geometry)
      const geometry = asteroidNode?.geometry || new THREE.SphereGeometry(1, 16, 16)

      // Add barycentric coordinates
      const positions = geometry.attributes.position
      const indices = geometry.index ? geometry.index.array : null
      const barycentricCoords = new Float32Array(positions.count * 3)

      if (indices) {
        // For indexed geometry
        for (let i = 0; i < indices.length; i += 3) {
          const idx1 = indices[i]
          const idx2 = indices[i + 1]
          const idx3 = indices[i + 2]

          barycentricCoords[idx1 * 3] = 1
          barycentricCoords[idx1 * 3 + 1] = 0
          barycentricCoords[idx1 * 3 + 2] = 0

          barycentricCoords[idx2 * 3] = 0
          barycentricCoords[idx2 * 3 + 1] = 1
          barycentricCoords[idx2 * 3 + 2] = 0

          barycentricCoords[idx3 * 3] = 0
          barycentricCoords[idx3 * 3 + 1] = 0
          barycentricCoords[idx3 * 3 + 2] = 1
        }
      } else {
        // For non-indexed geometry
        for (let i = 0; i < positions.count; i += 3) {
          barycentricCoords[i * 3] = 1
          barycentricCoords[i * 3 + 1] = 0
          barycentricCoords[i * 3 + 2] = 0

          barycentricCoords[(i + 1) * 3] = 0
          barycentricCoords[(i + 1) * 3 + 1] = 1
          barycentricCoords[(i + 1) * 3 + 2] = 0

          barycentricCoords[(i + 2) * 3] = 0
          barycentricCoords[(i + 2) * 3 + 1] = 0
          barycentricCoords[(i + 2) * 3 + 2] = 1
        }
      }

      geometry.setAttribute('aBarycentric', new THREE.BufferAttribute(barycentricCoords, 3))
      return geometry
    }
    return new THREE.SphereGeometry(1, 16, 16)
  }, [isLoading, nodes])

  const startDestruction = useCallback(() => {
    speedMultiplier.current = 0 // Stop movement
    setIsVisible(false) // Hide asteroid
    setIsExploding(true) // Start explosion
  }, [])

  useImperativeHandle(ref, () => ({
    destroy: startDestruction,
    freeze: () => {
      speedMultiplier.current = 0
    },
  }))

  useFrame((state, delta) => {
    if (!meshRef.current) return

    meshRef.current.position.x -= speedMultiplier.current * speed * delta

    lastKnownPosition.current.copy(meshRef.current.position)

    if (speedMultiplier.current > 0) {
      meshRef.current.rotation.x += 0.005 * rotationIntensity
      meshRef.current.rotation.y += 0.005 * rotationIntensity
      meshRef.current.rotation.z += 0.005 * rotationIntensity
    }

    positionRef.current.copy(meshRef.current.position)

    if (
      meshRef.current.position.x < -12 &&
      speedMultiplier.current > 0 &&
      gameState !== 'CRASHING' &&
      gameState !== 'EXPLODING'
    ) {
      config.onRespawn()
    }

    config.onPositionUpdate?.(positionRef.current)
  })

  // memoize explosion
  const explosion = useMemo(() => {
    return (
      isExploding && (
        <ExplosionEffect
          key={config.id}
          position={[
            lastKnownPosition.current.x,
            lastKnownPosition.current.y,
            lastKnownPosition.current.z,
          ]}
          colors={[emissiveColor]}
          count={3}
          size={4}
          spread={1}
          duration={750}
          gravity={Math.random() * 2 - 1}
          force={3}
          shape='square'
          intensity={1.25}
          onComplete={() => {
            setIsExploding(false)
            config.onDestroyComplete?.()
          }}
        />
      )
    )
  }, [isExploding])

  return (
    <>
      {isVisible && (
        <mesh ref={meshRef} position={initialPosition} geometry={asteroidGeometry}>
          <EdgeShaderMaterial
            emissiveColor={new THREE.Color(emissiveColor)}
            edgeColor={new THREE.Color(edgeColor)}
            edgeThickness={edgeThickness}
            glowIntensity={edgeGlow}
          />
        </mesh>
      )}
      {explosion}
    </>
  )
})

Asteroid.displayName = 'Asteroid'

export default memo(Asteroid)
