import React, { useRef, useEffect } from 'react'
import { useFrame } from '@react-three/fiber'
import * as THREE from 'three'
import { useGameOutcomeStore } from '@/store/useGameOutcomeStore'

interface BombsLightingProps {
  initialAmbientIntensity?: number
  initialDirectionalIntensity?: number
}

export const BombsLighting: React.FC<BombsLightingProps> = ({
  initialAmbientIntensity = 1,
  initialDirectionalIntensity = 2,
}) => {
  const ambientLightRef = useRef<THREE.AmbientLight>(null)
  const directionalLightRef = useRef<THREE.DirectionalLight>(null)
  const isShowingOutcome = useGameOutcomeStore(state => state.isShowingOutcome)
  const didPlayerWin = useGameOutcomeStore(state => state.didPlayerWin)

  useEffect(() => {
    if (ambientLightRef.current && directionalLightRef.current) {
      ambientLightRef.current.intensity = initialAmbientIntensity
      directionalLightRef.current.intensity = initialDirectionalIntensity
    }
  }, [initialAmbientIntensity, initialDirectionalIntensity])

  useFrame(() => {
    if (ambientLightRef.current && directionalLightRef.current) {
      if (isShowingOutcome) {
        if (didPlayerWin) {
          // Win: Increase ambient light intensity
          ambientLightRef.current.intensity = THREE.MathUtils.lerp(
            ambientLightRef.current.intensity,
            0,
            0.05
          )
        } else {
          // Lose: Decrease directional light intensity
          directionalLightRef.current.intensity = THREE.MathUtils.lerp(
            directionalLightRef.current.intensity,
            0.75,
            0.05
          )
        }
      } else {
        // Reset
        ambientLightRef.current.intensity = THREE.MathUtils.lerp(
          ambientLightRef.current.intensity,
          initialAmbientIntensity,
          0.05
        )
        directionalLightRef.current.intensity = THREE.MathUtils.lerp(
          directionalLightRef.current.intensity,
          initialDirectionalIntensity,
          0.05
        )
      }
    }
  })

  return (
    <>
      <ambientLight ref={ambientLightRef} intensity={initialAmbientIntensity} color={'#e90c0c'} />
      <directionalLight
        ref={directionalLightRef}
        intensity={initialDirectionalIntensity}
        color={'#7398c9'}
        position={[10, 5, 10]}
      />
    </>
  )
}
