import styled from 'styled-components'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import { useSnapshot } from 'valtio'

import { faucetState } from '@/lib/privy/hooks/faucetState'
import { FARE_GRADIENT } from '../shared/Header/style'
import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'
import { Input } from '../shared/Input'
import { Button, ButtonEnum } from '../shared/Button'
import { inviteCodesApi } from '@/lib/http/inviteCodes'
import { useToken } from '@privy-io/react-auth'

const SSelectWalletModal = styled(motion.div)`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.48);
  z-index: 999;
  backdrop-filter: blur(4px);
`

const modalVariants: Variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
}

const modalContentVariants: Variants = {
  initial: {
    y: -60,
  },
  animate: {
    y: 0,
  },
  exit: {
    y: -60,
  },
}

const SGradientModalContent = styled(motion.div)`
  padding: 1px;
  border-radius: 6px;
  ${FARE_GRADIENT}
`

const SModalContent = styled.div`
  background: #101010;
  min-width: 300px;
  max-width: 300px;
  border-radius: 6px;
  overflow: hidden;
  user-select: none;
  padding: 18px 24px;
`

const SModalTitle = styled.h2`
  color: rgba(255, 255, 255, 0.82);
  font-size: 1.2rem;
  margin-bottom: 12px;
  text-align: center;
`

const SErrorMessage = styled.div`
  color: #ff4d4f;
  margin-top: 4px;
  margin-bottom: 16px;
  font-size: 12px;
  text-align: center;
`

const FaucetTokenModalContent = ({ closeModal }: { closeModal: () => void }) => {
  const { faucetCode } = useSnapshot(faucetState)
  const [errorMessage, setErrorMessage] = useState('')
  const [isValidating, setIsValidating] = useState(false)
  const [outOfRequests, setOutOfRequests] = useState(false)
  const { getAccessToken } = useToken()

  const validateFaucetCode = async () => {
    try {
      setIsValidating(true)
      setErrorMessage('')

      const resp = await inviteCodesApi.tryInviteCode(faucetCode, getAccessToken)
      if (!resp.isSuccessful) {
        if (resp.hoursLeft) {
          setOutOfRequests(true)
          setErrorMessage(resp.reason || 'Error with request')
        } else {
          setErrorMessage(resp.reason || '')
        }
      } else {
        setTimeout(inviteCodesApi.getInviteCodes, 3_000)
        closeModal()
      }
    } catch (err: any) {
      setErrorMessage(err.toString())
    } finally {
      setIsValidating(false)
    }
  }

  const setFaucetCode = (value: string) => (faucetState.faucetCode = value)

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    validateFaucetCode()
  }

  return (
    <SGradientModalContent
      variants={modalContentVariants}
      initial='initial'
      animate='animate'
      exit='exit'
    >
      <SModalContent>
        <SModalTitle>TESTNET FAUCET</SModalTitle>
        {errorMessage && <SErrorMessage>{errorMessage}</SErrorMessage>}
        <form onSubmit={handleSubmit}>
          <Input
            autoFocus
            placeholder={'Faucet Code...'}
            value={faucetCode}
            onChange={ev => setFaucetCode(ev.currentTarget.value.trim())}
          />
          <Button
            style={{ marginTop: 16, width: '100%', background: 'rgba(0,0,0,.40)' }}
            buttonType={ButtonEnum.CONNECT_WALLET}
            disabled={isValidating || !faucetCode || outOfRequests}
            isLoading={isValidating}
            // onClick={tryInviteCode}
            type='submit'
          >
            Submit
          </Button>
        </form>
      </SModalContent>
    </SGradientModalContent>
  )
}

// Fake validation function for demonstration purposes
const fakeValidateTokenCode = async (code: string) => {
  // Simulate an API call delay
  await new Promise(resolve => setTimeout(resolve, 500))
  // For demonstration, accept '123456' as a valid code
  return code === '123456'
}

export const FaucetModal = () => {
  const state = useSnapshot(faucetState)
  const appChainConfig = useAppChainConfigStore.use.appChainConfig()

  const closeModal = () => {
    faucetState.isFaucetModalOpen = false
  }

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeModal()
    }
  }

  if (!appChainConfig.features.enableFaucet) return

  return (
    <AnimatePresence>
      {state.isFaucetModalOpen ?
        <SSelectWalletModal
          variants={modalVariants}
          initial='initial'
          animate='animate'
          exit='exit'
          onClick={handleOverlayClick}
        >
          <FaucetTokenModalContent closeModal={closeModal} />
        </SSelectWalletModal>
      : null}
    </AnimatePresence>
  )
}
