import { routeLinks, externalLinks } from '@/constants/links'
import {
  BottomDirectory,
  DirectoryWrapper,
  ExternalLinkWrapper,
  FloatingDirectory,
  LinkWrapper,
  TopDirectory,
} from '../style'
import { fadeInRightVariant } from '@/design/animations'
import { useGetGameStoreType } from '@/store/useGameStateStore'

export const LeftPanel = () => {
  const { pathname } = useLocation()
  const gameStoreType = useGetGameStoreType(pathname)
  const activePage = useMemo(() => {
    return pathname
  }, [pathname])

  const shouldDisable = useMemo(() => gameStoreType !== 'IDLE', [gameStoreType])

  return (
    <DirectoryWrapper>
      <FloatingDirectory variants={fadeInRightVariant} initial='initial' animate='animate'>
        <TopDirectory $isDisabled={shouldDisable}>
          {routeLinks.map(route => (
            <LinkWrapper
              key={route.title}
              to={route.to}
              $isActive={route.to === activePage}
              $bgColor={route.bgColor}
              $borderColor={route.borderColor}
              target={route.to.startsWith('https') ? '_blank' : undefined}
            >
              <img id={`route-${route.title}`} src={route.img} alt={`${route.img}-icon`} />
            </LinkWrapper>
          ))}
        </TopDirectory>
        <BottomDirectory>
          {externalLinks.map((link, i) => (
            <ExternalLinkWrapper
              key={link.title}
              href={link.to}
              target='_blank'
              rel='noreferrer'
              $delay={(routeLinks.length + i + 1) / 10}
            >
              <img src={link.img} alt={`${link.alt}-icon`} />
            </ExternalLinkWrapper>
          ))}
        </BottomDirectory>
      </FloatingDirectory>
    </DirectoryWrapper>
  )
}
