import { StateCreator } from 'zustand'
import { GameEntryContext, GameEntrySlice } from './index.types'

export const initialGameEntryState: GameEntryContext = {
  side: 0,
  entryAmount: 0,
  entryCount: 1,
  stopLoss: 0,
  stopGain: 0,
} as const

export const createGameEntrySlice: StateCreator<GameEntrySlice, [], [], GameEntrySlice> = set => ({
  ...initialGameEntryState,
  entry: initialGameEntryState,
  setEntry: gameEntry => set(state => ({ entry: { ...state.entry, ...gameEntry } })),
})
