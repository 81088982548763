import { SVGS } from '@/assets'
import useAAStore from '@/lib/aa/hooks/useAAStore'
import { AnimatePresence, motion, Variants } from 'framer-motion'

const SAAFullLoading = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.6);
`

export const SLoadingContent = styled(motion.div)`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    margin-bottom: 16px;
    height: 64px;
  }
`

export const loadingVariants: Variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
}

export const loadingContentVariants: Variants = {
  initial: {
    opacity: 0,
    y: -200,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: 200,
  },
}

export const imgLoadingVariant: Variants = {
  initial: {
    rotateY: 0,
    scale: 1,
  },
  animate: {
    scale: [0.9, 1.25],
    rotateY: [0, 180, 360], // Simulates the flipping effect
    transition: {
      scale: {
        type: 'tween',
        ease: 'easeOut',
        repeat: Infinity,
        repeatType: 'mirror',
        duration: 3,
      },
      rotateY: {
        type: 'tween',
        ease: 'linear',
        repeat: Infinity,
        repeatType: 'loop',
        duration: 1, // Adjust to control the speed of the flip
      },
    },
  },
}

export const AAFullLoading = () => {
  const isActivatingAA = useAAStore(state => state.isActivatingAA)

  return (
    <AnimatePresence>
      {isActivatingAA ?
        <SAAFullLoading variants={loadingVariants} initial='initial' animate='animate' exit='exit'>
          <SLoadingContent
            variants={loadingContentVariants}
            initial='initial'
            animate='animate'
            exit='exit'
          >
            <motion.img
              src={SVGS.fareIcon}
              variants={imgLoadingVariant}
              initial='initial'
              animate='animate'
              exit='exit'
            />
            <span>setting up quickplay</span>
          </SLoadingContent>
        </SAAFullLoading>
      : null}
    </AnimatePresence>
  )
}
