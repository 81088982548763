import { Rule, Policy } from '@biconomy/account'

export const createRulesAndPolicy = (
  account: `0x${string}`,
  sessionKeyAddress: `0x${string}`,
  vaultAddress: string
) => {
  const vaultRules: Rule[] = [
    {
      offset: 0,
      condition: 0,
      referenceValue: account,
    },
  ]

  const vaultPolicy: Policy = {
    interval: {
      validUntil: 0,
      validAfter: 0,
    },
    sessionKeyAddress,
    contractAddress: vaultAddress as `0x${string}`,
    functionSelector: 'trialRegister(address,uint256,uint256[],uint256[],uint256)',
    rules: vaultRules,
    valueLimit: 0n,
  }

  const policies: Policy[] = [vaultPolicy]

  return {
    vaultRules,
    vaultPolicy,
    policies,
  }
}
