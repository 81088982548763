import React, { createContext, useContext, useState, useEffect, useRef } from 'react'

interface GameTimeContextProps {
  roundTime: number
  gameTime: number
  startRoundTime: () => void
  resetRoundTime: () => void
}

const GameTimeContext = createContext<GameTimeContextProps>({
  roundTime: 0,
  gameTime: 0,
  startRoundTime: () => {},
  resetRoundTime: () => {},
})

export const GameTimeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [roundTime, setRoundTime] = useState<number | null>(null)
  const [gameTime, setGameTime] = useState(0)
  const loadTimeRef = useRef(Date.now())
  const roundStartTimeRef = useRef<number | null>(null)

  useEffect(() => {
    const interval = setInterval(() => {
      // GameTime always counts up from component mount
      setGameTime((Date.now() - loadTimeRef.current) / 1000)

      // RoundTime only counts when game has started
      if (roundStartTimeRef.current !== null) {
        setRoundTime((Date.now() - roundStartTimeRef.current) / 1000)
      }
    }, 16) // Approximately 60 FPS

    return () => clearInterval(interval)
  }, [gameTime])

  const startRoundTime = () => {
    roundStartTimeRef.current = Date.now()
    setRoundTime(0)
  }

  const resetRoundTime = () => {
    roundStartTimeRef.current = null
    setRoundTime(0)
  }

  return (
    <GameTimeContext.Provider
      value={{
        roundTime: roundTime ?? 0, // Convert null to 0 for the interface
        gameTime,
        startRoundTime,
        resetRoundTime,
      }}
    >
      {children}
    </GameTimeContext.Provider>
  )
}

export const useGameTime = () => useContext(GameTimeContext)
