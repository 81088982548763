import { create } from 'zustand'
import { v4 as uuid } from 'uuid'

export type NotiType = 'success' | 'error'

export interface INoti {
  id?: string
  type: NotiType
  msg: string
  image?: string
}

export type NotiState = {
  appNotis: INoti[]
  gameNotis: INoti[]
}

export type NotiStoreActions = {
  addAppNoti: (appNoti: INoti) => void
  addGameNoti: (gameNoti: INoti) => void
  removeAppNoti: (id?: string) => void
  removeGameNoti: (id?: string) => void
}

export const initialNotiState: NotiState = {
  appNotis: [],
  gameNotis: [],
}

export type NotiStore = NotiState & NotiStoreActions

const useNotiStore = create<NotiStore>(set => ({
  ...initialNotiState,
  addAppNoti: appNoti =>
    set(state => {
      appNoti.id = uuid()
      return {
        appNotis: [...state.appNotis, appNoti],
      }
    }),
  addGameNoti: gameNoti =>
    set(state => {
      gameNoti.id = uuid()
      return {
        gameNotis: [...state.gameNotis, gameNoti],
      }
    }),
  removeAppNoti: id =>
    set(state => ({
      appNotis: id ? state.appNotis.filter(noti => noti.id !== id) : state.appNotis.slice(1),
    })),
  removeGameNoti: id =>
    set(state => ({
      gameNotis: id ? state.gameNotis.filter(noti => noti.id !== id) : state.gameNotis.slice(1),
    })),
}))

export const addAppNoti = useNotiStore.getState().addAppNoti
export const addGameNoti = useNotiStore.getState().addGameNoti

export default useNotiStore
