import { FARE_COLORS } from '@/design'
import { SSliderThumb } from '../shared/Slider/AmountSlider'

export const SliderThumb = styled(SSliderThumb)`
  height: 18px;
  width: 18px;
  /* border-radius: 2px; */
  /* border: 1px solid black; */
  top: 7px;
`

export const SliderContainer = styled.div`
  height: 100%;
  width: 98%;
  margin: auto;
  margin-top: 12px;
  /* position: absolute; */
  /* z-index: 2; */
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */

  h1 {
    font-size: 36px;
    margin-bottom: 64px;
  }

  form {
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .slider-input-wrapper {
    width: 100%;
    /* height: 100%; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export const MarkContainer = styled.div<{ left: number }>`
  position: absolute;
  top: -10px;
  left: ${({ left }) => left}%;
  transform: translateX(-${({ left }) => left}%);
  font-size: 12px;
  margin: 0;
  color: #aaa;
`

export const DiceContainer = styled.div`
  /* margin-top: 40px; */
`
