import { OrbitControls, PerspectiveCamera } from '@react-three/drei'
import { PerspectiveCameraProps } from '@react-three/fiber'
import { PerspectiveCamera as PerspectiveCameraImpl, Vector3 } from 'three'

export const RPSCamera = (props: PerspectiveCameraProps) => {
  const cameraPos = useMemo(() => new Vector3(0, 0.0, 1.5), [])
  const cameraRef = useRef<PerspectiveCameraImpl>(null)

  useEffect(() => {
    if (cameraRef.current) {
      cameraRef.current.lookAt(new Vector3(0, 0, 0))
    }
  }, [])

  return (
    <>
      <PerspectiveCamera ref={cameraRef} position={cameraPos} fov={60} makeDefault {...props} />
      {/* <OrbitControls /> */}
    </>
  )
}
