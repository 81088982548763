export function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max)
}

export function ensureNumber(value: any) {
  const num = Number(value)
  return isNaN(num) ? 0 : num
}

export function roundToDecimalPlaces(num: number, decimalPlaces: number) {
  const factor = Math.pow(10, decimalPlaces)

  // return Math.round(num * factor)
  return Math.ceil(num * factor) / factor
}
