import { useActiveWallet } from '@/lib/privy/hooks'
import { usePrivyService } from '@/lib/privy/usePrivyService'
import { useAppChainConfigStore } from '@/store/useAppChainConfigStore'
import useUserDataStore from '@/store/useUserDataStore'
import posthog from 'posthog-js'
import { useDebounceCallback } from 'usehooks-ts'

export const AppChainConfigListener = () => {
  const { pathname } = useLocation()
  const { activeWallet, isWalletAuthed, twitterData } = useActiveWallet()
  const { verifyIdToken } = usePrivyService()
  const setUserData = useUserDataStore(state => state.setUserData)
  const { setAppProvider, appWalletClientType } = useAppChainConfigStore(state => ({
    setAppProvider: state.setAppProvider,
    appWalletClientType: state.appWalletClientType,
  }))

  const walletChainId = useMemo(
    () => (activeWallet ? Number(activeWallet.chainId.split(':')[1]) : 0),
    [activeWallet]
  )

  useEffect(() => {
    if (!activeWallet?.address || !isWalletAuthed) return
    ;(async () => {
      // const appProvider = await activeWallet.getEthersProvider()
      setAppProvider(walletChainId, activeWallet)
    })()
  }, [activeWallet?.address, walletChainId, isWalletAuthed])

  const debouncedVerifyIdSetUserData = useDebounceCallback(
    async (publicAddress: string) => {
      try {
        const shouldSyncSocials = pathname === '/settings' || Boolean(twitterData)
        const { userData } = await verifyIdToken(publicAddress, shouldSyncSocials)
        setUserData(userData)

        posthog?.identify(activeWallet?.address, {
          username: userData.username,
          twitterData: userData.twitterAccount,
        })
        posthog?.group('arbitrum-mainnet-deathmatch', activeWallet?.address || '')
      } catch (err) {
        console.error('ISSUE VERIFYING ID/SETTING USER DATA')
        console.error(err)
      }
    },
    100,
    { leading: false }
  )

  useEffect(() => {
    if (!activeWallet || !isWalletAuthed) return
    debouncedVerifyIdSetUserData(activeWallet.address)
  }, [activeWallet, walletChainId])

  return null
}
