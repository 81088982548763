import { FARE_COLORS } from '@/design'

const fareAvatarColors = Object.entries(FARE_COLORS)
  .filter(([key]) => key !== 'gray' && key !== 'black')
  .map(([_key, value]) => value.substring(1))

export const generateUserColor = (address?: string) => {
  if (!address) {
    return fareAvatarColors[0]
  }

  const lastTwoChars = address.substring(address.length - 2)
  const colorId = parseInt(lastTwoChars, 16) % fareAvatarColors.length

  return fareAvatarColors[colorId]
}
