import { BORDER_COLORS, FARE_COLORS } from '@/design'
import { type WheelEventHandler } from 'react'
import ReactSlider, { type ReactSliderProps } from 'react-slider'
import { useSound } from '../../shared/SoundSystem/SoundContext'
import ClickSound2Audio from '@/assets/audio/bombs-mouse-over.wav'
import MouseDownSoundAudio from '@/assets/audio/Click 11.wav'
import MinMaxSoundAudio from '@/assets/audio/mouse-over-slider.wav'

const SReactSliderWrapper = styled.div<{ $disabled?: boolean }>`
  height: 18px;
  width: 100%;
  position: absolute;
  bottom: -6px;
  ${props =>
    props.$disabled &&
    css`
      pointer-events: none;
    `}
  .fare-input-slider {
    cursor: pointer;
    width: calc(100% - 1.5px);
    height: 18px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .track-0 {
      transition:
        background ease-out 0.16s,
        border-top ease-out 0.16s;
      height: 4px;
      background: ${FARE_COLORS.aqua}50;
      border-top: 1px solid transparent;
      border-bottom-left-radius: 6px;
      border-bottom: 1px solid transparent;
      overflow: hidden;
    }

    .track-1 {
      transition:
        background ease-out 0.16s,
        border-top ease-out 0.16s;
      height: 4px;
      background: #1b1d26;
      border-bottom-right-radius: 6px;
      border-top: 1px solid ${BORDER_COLORS.one};
      border-bottom: 1px solid transparent;
    }

    &:active {
      cursor: grabbing !important;
      .track-0 {
        border-top: 1px solid ${FARE_COLORS.aqua};
        border-bottom: 1px solid ${FARE_COLORS.aqua};
      }
      .track-1 {
        border-bottom: 1px solid ${FARE_COLORS.aqua};
      }
      .thumb-0 {
        background: #428375;
      }
    }
  }
`

const SSliderThumb = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 2px;
  border: 1px solid ${FARE_COLORS.aqua};
  background: ${FARE_COLORS.aqua}50;
  /* cursor: grab; */
  transition: background ease-out 0.16s;
  backdrop-filter: blur(1px);
  &:active {
    background: #428375;
    /* cursor: grabbing; */
  }
  &:focus {
    outline: none;
  }
`

const Thumb = (props: any, _state: any) => {
  const { key, ...restProps } = props
  return <SSliderThumb key={key} {...restProps} />
}

export const InputSlider = (props: ReactSliderProps) => {
  const { loadSound, playSound } = useSound()
  const lastPlayTime = useRef(0)
  const lastValue = useRef(props.defaultValue || 0)
  const reachedMinRef = useRef(false)
  const reachedMaxRef = useRef(false)

  useEffect(() => {
    // loadSound('clickSound2', '/src/assets/audio/Click 11.wav')
    loadSound('clickSound2', ClickSound2Audio)
    loadSound('mouseDownSound', MouseDownSoundAudio)
    loadSound('maxSound', MinMaxSoundAudio)
    loadSound('minSound', MinMaxSoundAudio)
  }, [loadSound])

  const handleChange = useCallback(
    (value: number, index: number) => {
      const now = Date.now()
      const timeSinceLastPlay = now - lastPlayTime.current

      if (timeSinceLastPlay < 50) return // Throttle to max 20 plays per second

      const min = props.min ?? 0
      const max = props.max ?? 1000

      // Check if we've reached the min or max
      if (value === min && !reachedMinRef.current) {
        console.log('min sound')
        playSound('minSound', 0.1, 0.1) // Adjust volume as needed
        reachedMinRef.current = true
        reachedMaxRef.current = false
      } else if (value === max && !reachedMaxRef.current) {
        console.log('max sound')
        playSound('maxSound', 0.1, 0.1) // Adjust volume as needed
        reachedMaxRef.current = true
        reachedMinRef.current = false
      } else if (value !== min && value !== max) {
        // Calculate speed based on value change and time elapsed
        const valueChange = Math.abs(value - lastValue.current)
        const speed = valueChange / timeSinceLastPlay

        // Adjust these values to fine-tune the behavior
        const minSpeed = 0.001
        const maxSpeed = 5
        const minPitch = 1
        const maxPitch = 1.05
        // const minPitch = 1.5
        // const maxPitch = 1.6
        const minVolume = 0.2
        const maxVolume = 0.3

        // Calculate pitch based on speed
        const normalizedSpeed = Math.min(Math.max((speed - minSpeed) / (maxSpeed - minSpeed), 0), 1)
        const pitch = minPitch + normalizedSpeed * (maxPitch - minPitch)

        // Calculate volume based on speed
        const volume = minVolume + normalizedSpeed * (maxVolume - minVolume)

        playSound('clickSound2', volume, pitch)

        reachedMinRef.current = false
        reachedMaxRef.current = false
      }

      lastPlayTime.current = now
      lastValue.current = value
      props.onChange?.(value, index)
    },
    [playSound, props.onChange]
  )

  const handleWheel: WheelEventHandler<HTMLDivElement> = useCallback(
    ev => {
      if (
        typeof props.value !== 'number' ||
        typeof props.step !== 'number' ||
        typeof props.max !== 'number' ||
        typeof props.min !== 'number'
      )
        return

      const { deltaX, deltaY, shiftKey } = ev
      const incrementAmount = shiftKey ? 5 : props.step || 1
      const halfValue = (props.max - props.min) / 2
      let newVal = props.value

      if (deltaX < 0 || deltaY < 0) {
        // Increase the value for the first half of the slider
        if (props.value < halfValue) {
          newVal = Math.min(props.value + incrementAmount, halfValue)
        } else {
          newVal = Math.min(props.value + incrementAmount, props.max)
        }
      }

      if (deltaX > 0 || deltaY > 0) {
        // Decrease the value for the second half of the slider
        if (props.value > halfValue) {
          newVal = Math.max(props.value - incrementAmount, halfValue)
        } else {
          newVal = Math.max(props.value - incrementAmount, props.min)
        }
      }

      handleChange(newVal, 0)
    },
    [props, handleChange]
  )

  return (
    <SReactSliderWrapper
      id='fare-input-slider-wrapper'
      onWheel={handleWheel}
      $disabled={props.disabled}
    >
      <ReactSlider
        className='fare-input-slider'
        min={0}
        // max={100}
        defaultValue={50}
        renderThumb={Thumb}
        {...props}
        max={props.max}
        step={props.step}
        marks
        onChange={handleChange}
      />
    </SReactSliderWrapper>
  )
}
