import { addAppNoti } from '@/store/useNotiStore'

interface ClickToCopyProps {
  children: React.ReactNode
  className?: string
  copyText: string
}

const SClickToCopy = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  color: #aaa;
  font-size: 12px;
  font-family: GohuUppercase, monospace;
  cursor: pointer;

  > span {
    transition: all ease-in-out 0.16;
    color: #aaa;
    font-size: 12px;
    line-height: 9px;
    padding-top: 1px;
  }

  &:hover {
    > span {
      color: white;
    }
  }
`

export const ClickToCopy = ({ children, copyText, ...props }: ClickToCopyProps) => {
  const copyToClipboard = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(copyText)
      addAppNoti({
        type: 'success',
        msg: 'Copied to clipboard',
      })
    } catch (err) {
      console.error(err)
      addAppNoti({
        type: 'error',
        msg: 'Failed to copy',
      })
      throw new Error(`Error copying to clipboard inside ClickToCopy`)
    }
  }, [copyText])

  const elem = useMemo(
    () => (typeof children === 'string' ? <span>{children}</span> : children),
    [children]
  )

  return (
    <SClickToCopy {...props} onClick={copyToClipboard}>
      {elem}
    </SClickToCopy>
  )
}
