import { COMPONENTS } from '@/design/components'

export const ParticlesWrapper = styled.div`
  position: absolute;

  canvas {
    height: calc(100vh - ${COMPONENTS.header}px);
    width: 100%;
    bottom: 0;
  }
`

export const MenuParticlesWrapper = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  #menuParticles {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  canvas {
    position: absolute !important;
    overflow: hidden !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
  }
`
