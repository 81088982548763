import {
  BACKGROUND_COLORS,
  BORDER_COLORS,
  COMPONENTS,
  FARE_COLORS,
  FONT_STYLES,
  SPACING,
  TEXT_COLORS,
  floatingContainer,
} from '@/design'
import {
  PointsAssistBorder,
  PointsDeathsBorder,
  PointsKillsBorder,
  PointsMultiplierBorder,
} from './PointsBorders'
import { deviceBP } from '@/design/breakpoints'
import { motion } from 'framer-motion'
import { noUserSelect } from '@/style'

export const PointsPageWrapper = styled.div`
  max-height: calc(100vh - ${COMPONENTS.header + SPACING.md * 2 + 0}px);
  margin-top: ${SPACING.md}px;
  display: grid;
  height: 100%;
  border: 1px solid ${BORDER_COLORS.one};
  border-radius: 6px;
  background: ${BACKGROUND_COLORS.two};
  grid-template-rows: auto 1fr;
  z-index: 2;
  width: 100%;
  box-sizing: border-box;
  ${floatingContainer}
  background: transparent;
  backdrop-filter: blur(1px);
  ${noUserSelect}

  @media ${deviceBP.md} {
    width: 100vw;
    border: none;
    margin-top: 16px;
  }

  @media ${deviceBP.sm} {
    max-height: calc(100vh - ${COMPONENTS.header}px);
  }
`
export const PointsBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: ${SPACING.xxl}px 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  gap: 0px;
  height: 100%;
  box-sizing: border-box;
  min-height: 0;
  flex-grow: 1;
  background: ${BACKGROUND_COLORS.two};

  @media ${deviceBP.sm} {
    padding-top: 8px;
    padding-bottom: 8px;
    height: 100%;
    min-height: 0;
    flex-grow: 1;
  }
`

export const PointsUpperContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${deviceBP.sm} {
    align-items: center;
    flex-direction: column;
  }
`

export const PointsLeftContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${deviceBP.sm} {
    width: 100%;
  }
`

export const PointsUserAvatarInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18px;

  @media ${deviceBP.sm} {
    align-self: start;
    margin-bottom: 12px;
    gap: 24px;
  }
`

export const TwitterAvatarPFP = styled.div`
  width: 92px;
  height: 92px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .twitter-pfp {
    width: 58px;
    height: 58px;
    border-radius: 100%;
  }

  @media ${deviceBP.sm} {
    width: 48px;
    height: 48px;

    .twitter-pfp {
      width: 32px;
      height: 32px;
    }
  }
`

export const PointsAvatarContainer = styled.div`
  position: relative;

  .avatar-wrapper {
    width: 92px;
    height: 92px;
  }

  .avatar-img {
    width: 56px;
    height: 56px;
  }

  .badge-img {
    width: 92px;
    height: 92px;
  }
`

export const PointsBadge = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;

   img {
    width: 104px;
    height: 104px;
  }

  @media ${deviceBP.sm} {
    img {
      width: 66px;
      height: 66px;
    }
`

export const PointsNameContainer = styled.div`
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  margin-left: 2px;

  .address {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 4px;
  }

  @media ${deviceBP.sm} {
      justify-content: flex-start;
      .address {
        font-size: 14px;
        margin-bottom: 7px;
  }
`

export const RankContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  ${noUserSelect}

  > span {
    color: rgb(170, 170, 170);
  }
`

export const TotalPoints = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;

  @media ${deviceBP.sm} {
    margin-top: 8px;
    margin-bottom: 8px;
  }
`
export const TotalPointsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;

  @media ${deviceBP.sm} {
    flex: 1;
  }
`
export const PointsCount = styled.div`
  margin-top: -10px;
  span {
    color: #fff;
    text-shadow:
      1px 1px 8.3px rgba(255, 255, 255, 0.5),
      1px 1px 8.3px rgba(255, 255, 255, 0.5);
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  @media ${deviceBP.sm} {
    margin-top: -6px;
    span {
      font-size: 28px;
    }
  }
`

export const PointsRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 15px;
  column-gap: 25px;

  @media ${deviceBP.lg} {
    button {
      margin-top: 20px;
    }
  }

  @media ${deviceBP.sm} {
    width: 100%;
  }
`

export const PointsStatsContainer = styled.div`
  grid-area: stats;
  display: flex;
  justify-content: flex-end;
  column-gap: ${SPACING.lg}px;
`

const PointsStats = styled.div`
  height: 100%;
  height: 63px;
  width: 137px;
  position: relative;
  gap: 8px;

  .container {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-top: 12px;
    gap: 8px;
  }
  .heading,
  .count {
    color: ${FARE_COLORS.aqua};
    ${FONT_STYLES.md};
    font-style: normal;
    font-weight: 500;
  }
  .count {
    ${FONT_STYLES.xl};
  }
`

export const PointsPlus = styled.div`
  position: absolute;
  right: 8px;
  top: 32px;
`

export const PointsKillsStatsContainer = styled(PointsStats)`
  ${PointsKillsBorder};

  @media ${deviceBP.sm} {
    display: none;
  }
`

export const PointsAssistsStatsContainer = styled(PointsStats)`
  ${PointsAssistBorder};
  .heading,
  .count {
    color: ${FARE_COLORS.peach};
  }

  @media ${deviceBP.sm} {
    display: none;
  }
`

export const PointsDeathsStatsContainer = styled(PointsStats)`
  ${PointsDeathsBorder};
  .heading,
  .count {
    color: ${FARE_COLORS.salmon};
  }

  @media ${deviceBP.sm} {
    display: none;
  }
`

export const PointsMultiplierContainer = styled(PointsStats)`
  grid-area: multiplier;
  ${PointsMultiplierBorder};
  .heading,
  .count {
    color: #bbcbd3;
  }
  .multiplierContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }

  @media ${deviceBP.sm} {
    display: none;
  }
`
export const PointsInfoContainer = styled.div`
  grid-area: info;
  display: flex;
  grid-row: 4;
  padding: 8px 11px;
  flex-direction: row;
  border: 1px solid ${BORDER_COLORS.one};
  border-radius: 6px;
  gap: 24px;
  margin-top: 0px;

  @media ${deviceBP.lg} {
    grid-row: 4/7;
  }
`

export const PointsInfoColumnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 24px;

  @media ${deviceBP.sm} {
    width: 100%;
  }
`

export const PointsInfoCalculatedContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
`

export const PointsBotContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 12px;
  min-height: 0;
  overflow: hidden;

  @media ${deviceBP.sm} {
    min-height: 0;
  }
`

export const PointsDownContainer = styled.div`
  gap: 20px;
  display: inline-flex;
  overflow-x: auto;
  align-items: center;
  min-height: 352px;
  @media (max-width: 375px) {
    overflow-x: auto;
  }
  @media (max-width: 992px) {
    min-height: 352px;
  }
  overflow-y: hidden;
  scrollbar-width: auto !important;
  -ms-overflow-style: auto !important;

  &::-webkit-scrollbar {
    display: block;
    width: 1px;
    height: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(45deg, rgba(255, 255, 255, 1), rgba(0, 0, 0, 0));
    width: 0px;
    height: 2px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${TEXT_COLORS.two};
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`

export const SShareBtn = styled.button`
  all: unset;
  position: absolute;
  right: 18px;
  top: 18px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  img {
    margin-left: 8px;
  }

  @media ${deviceBP.sm} {
    top: 12px;
    right: 10px;
  }
`

export const SPointsExplainerContainer = styled.div`
  flex: 1;
  width: 300px;

  @media ${deviceBP.sm} {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
`
export const SConnectWalletInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.md}px;
  margin: auto;
  padding: ${SPACING.lg}px;
  max-width: 400px;
  border: 1px solid ${BORDER_COLORS.one};
  border-radius: 6px;
  backdrop-filter: blur(2px);
  background: ${BACKGROUND_COLORS.one};
  z-index: 10;

  @media ${deviceBP.sm} {
    margin: ${SPACING.lg}px auto;
    max-width: 300px;
    height: 85%;
    text-wrap: balance;
    margin-block: auto;
    justify-content: space-between;
    text-align: center;
  }
`

export const SConnectWalletContent = styled(motion.p)`
  line-height: 1.5rem;
  font-size: 16px;
  ${noUserSelect}
`

export const SColoredText = styled(motion.span)`
  font-size: 18px;
  font-weight: bold;
`
