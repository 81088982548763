import { parseEther } from 'viem'
import { type AppGameName } from '@/chains/types'
import {
  bombCountToRevealCountToMultiplier,
  bombCountToRevealCountToProbability,
  type IBombsSide,
} from '../crypto/bombs'
import { type IPlinkoSide, PlinkoProbabilitiesAndMultipliers } from '../crypto/plinko'

export const unit = parseEther('1')
export const GameNameToQK: { [key in AppGameName]: { getQK: (_side: any) => any } } = {
  coinFlip: {
    getQK: (_side: number) => ({ q: [parseEther('0.5')], k: [parseEther('1.98')] }),
  },
  rps: {
    getQK: (_side: number) => ({
      q: [unit / 3n, unit / 3n],
      k: [parseEther('1.98'), parseEther('0.99')],
    }),
  },
  dice: {
    getQK: (side: number) => ({
      q: [((10000n - BigInt(side)) * unit) / 10000n],
      k: [(((10000n * unit) / (10000n - BigInt(side))) * 99n * unit) / 100n / unit],
    }),
  },
  bombs: {
    getQK: (side: IBombsSide) => ({
      q: [bombCountToRevealCountToProbability[side.bombCount][side.revealCount]],
      k: [bombCountToRevealCountToMultiplier[side.bombCount][side.revealCount]],
    }),
  },
  plinko: {
    getQK: (side: IPlinkoSide) => ({
      q: PlinkoProbabilitiesAndMultipliers.getProbabilitesForRowCount(side.rowCount),
      k: PlinkoProbabilitiesAndMultipliers.getMultipliersForRiskLevelAndRowCount(
        side.riskLevel,
        side.rowCount
      ),
    }),
  },
  crash: {
    getQK: (side: number) => ({
      q: [(((99n * unit) / 100n) * unit) / ((BigInt(side) * unit) / 100n)], // since q * k = ev (q * k = ev * 1e18, because of the units) and we want to have ev = 0.99 * 1e18, we just do 0.99 * 1e18 / k to find q
      k: [(BigInt(side) * unit) / 100n], // for x2.0, side will be 200 => 200 * unit / 100
    }),
  },
}

export function productRange(a: bigint, b: bigint) {
  let prd = a
  let i = a

  while (i < b) {
    i += unit
    prd *= i
    prd = prd / unit
  }
  return prd
}

export function combinations(n: bigint, r: bigint) {
  if (n === r || r === 0n) {
    return unit
  } else {
    r = r < n - r ? n - r : r
    return (productRange(r + unit, n) * unit) / productRange(unit, n - r)
  }
}

export function factorial(num: bigint): bigint {
  if (num === 0n) return 1n
  else return num * factorial(num - 1n)
}

export function nestedForLoops(n: bigint, maxValue: bigint, callback: (indices: bigint[]) => void) {
  const indices = new Array(n).fill(0n)

  function loop(index: bigint) {
    if (index === n) {
      callback(indices)
    } else {
      for (let i = 0n; i <= maxValue; i++) {
        indices[Number(index)] = i
        loop(index + 1n)
      }
    }
  }

  loop(0n)
}

export function generatePermutations(length: number, values: number[]): number[][] {
  const start = Date.now()
  const permutations: number[][] = []
  const totalPermutations = Math.pow(values.length, length) // values.length ^ length
  for (let i = 0; i < totalPermutations; i++) {
    const permutation: number[] = []
    let index = i
    for (let j = 0; j < length; j++) {
      permutation.push(values[index % values.length])
      index = Math.floor(index / values.length)
    }
    permutations.push(permutation.reverse())
  }
  const timeTaken = Date.now() - start
  console.log('qk: Total time taken to create perm: ' + timeTaken + ' milliseconds')
  return permutations
}

export const fillProvidedQK = (providedQ: bigint[], providedK: bigint[]) => {
  // Check if q's sum up to 1, if not, place another entry to the end to complete it to 1 with k = 0
  const qSum = providedQ.reduce((a, b) => a + b)
  if (qSum < unit) {
    // If there is an entry with k = 0, increase it's q value rather than adding a new entry
    // @TODO: Test this out
    if (providedK.includes(0n)) {
      const indexOf0 = providedK.indexOf(0n)
      providedQ[indexOf0] += unit - qSum
    } else {
      providedQ.push(unit - qSum)
      providedK.push(0n)
    }
  }
  return { q: providedQ, k: providedK }
}

export const minimizeQK = (q: bigint[], k: bigint[]) => {
  if (q.length !== k.length) {
    throw new Error('Cannot minimize q and k arrays, if they are not the same length')
  }
  const minimizedQ: bigint[] = []
  const minimizedK = k.reduce((acc: bigint[], current, currentIndex) => {
    if (!acc.includes(current)) {
      // Do not consider the entry if k is 0
      if (current !== 0n) {
        acc.push(current)
        minimizedQ.push(q[currentIndex])
      }
    } else {
      const firstIndexOfSameK = acc.indexOf(current)
      minimizedQ[firstIndexOfSameK] += q[currentIndex]
    }
    return acc
  }, [])
  return { q: minimizedQ, k: minimizedK }
}

// @NOTE: Without kToMs or mToOrderingCount (those values will be calculated at backend), this will only buildQK to get q and k values to submit to contract
export const buildQK = (q: bigint[], k: bigint[], count: bigint) => {
  return updateQKForMultipleFlips(q, k, count)
}

export const updateQKForMultipleFlips = (
  providedQ: bigint[],
  providedK: bigint[],
  count: bigint
) => {
  if (providedK.length !== providedQ.length) {
    throw Error('provided q and k lengths do not match')
  }
  const { q: filledQ, k: filledK } = fillProvidedQK(providedQ, providedK)
  const filledQKLen = BigInt(filledK.length)
  const k: bigint[] = []
  const q: bigint[] = []
  let currentIndex = 0
  nestedForLoops(filledQKLen, count, indices => {
    const mSum = indices.reduce((sum, newValue) => sum + newValue)
    if (mSum === count) {
      const kVal = filledK.map((ki, i) => ki * indices[i]).reduce((a, b) => a + b) / count // here is what left hand is doing for providedK with length 3: (originalK[0] * m1 + originalK[1] * m2 + originalK[2] * m3) / count
      k[currentIndex] = kVal
      const possibleOrderingCount =
        factorial(count) / indices.map(indice => factorial(indice)).reduce((a, b) => a * b, 1n)
      const qVal =
        (filledQ.map((qi, i) => qi ** indices[i]).reduce((a, b) => a * b, 1n) *
          possibleOrderingCount) /
        unit ** (count - 1n) // here is what left hand is doing for provided Q with length 3: (originalQ[0] ** m1 * originalQ[1] ** m2 * originalQ[2] ** m3 * (factorial(count) / (factorial(m1) * factorial(m2) * factorial(m3)))) / unit ** (count - 1n)
      if (qVal === 0n && kVal !== 0n) {
        throw new Error('Try decreasing count. Building Q requires more precision than we support.')
      }
      q[currentIndex] = qVal
      currentIndex++
    }
  })
  const { q: minimizedQ, k: minimizedK } = minimizeQK(q, k)
  let evSum = 0n
  for (let i = 0; i < minimizedQ.length; i++) {
    evSum += (minimizedQ[i] * minimizedK[i]) / unit
    if ((minimizedQ[i] * minimizedK[i]) / unit > (unit * 99n) / 100n) {
      throw new Error('EV for a slice is above 0.99, this slice will be discarded.')
    }
  }
  if (evSum > (unit * 99n) / 100n) {
    throw new Error('EV sum is above 0.99, this trial will be discarded.')
  }
  console.log('QK::: evSum: ', evSum)
  // @TODO: probably require evSum to be below 0.99 (or the value received from the smart contract)
  // @TODO: probably check the qSum as well
  // console.log('qk: not readable: ', minimizedQ, minimizedK)
  // const readableQ = minimizedQ.map(qi => formatEther(String(qi)))
  // const readableK = minimizedK.map(ki => formatEther(String(ki)))
  // console.log('qk: ', readableQ, readableK)
  // console.log('qk: evSum: ', formatEther(evSum))
  return { q: minimizedQ, k: minimizedK }
}

export const packMaxValues = (
  maxEthUsdcPrice: bigint,
  maxAverageCallbackGas: bigint,
  maxAACostMultiplier: bigint
) => {
  return (maxEthUsdcPrice << 40n) + (maxAverageCallbackGas << 8n) + maxAACostMultiplier
}

// @NOTE: Will work for bigint type qk arrays, not sure about if type is decimal
export const sortBigIntQK = ({
  q,
  k,
}: {
  q: bigint[]
  k: bigint[]
}): { sortedQ: bigint[]; sortedK: bigint[] } => {
  if (k.length !== q.length) {
    throw new Error('Arrays must have the same length')
  }

  // Create an array of indices
  const indices = k.map((_, i) => i)

  // Sort the indices based on the values in k
  indices.sort((a, b) => {
    if (k[a] < k[b]) return -1
    if (k[a] > k[b]) return 1
    return 0
  })

  // Create new sorted arrays
  const sortedK = indices.map(i => k[i])
  const sortedQ = indices.map(i => q[i])

  return { sortedQ, sortedK }
}
