import React from 'react'
import { CrashThree } from './CrashThree'
import { CrashForm } from '@/components/Games/Crash/CrashForm'
import GamePageComponent from '@/components/shared/GamePage'
import { AppGameName } from '@/chains/types'
import { useDidIRender } from '@/hooks/common/useDidIRender'

export const CrashPage: React.FC = () => {
  useDidIRender('CrashPage')
  return (
    <GamePageComponent
      mode={AppGameName.Crash}
      CanvasComponent={CrashThree}
      FormComponent={CrashForm}
    />
  )
}
