import React, { useRef, useEffect, useMemo } from 'react'
import { Text } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import * as THREE from 'three'
import { type ShipConfig } from './GameConfig'
import { useCrashGameStore } from '@/store/useCrashGameStore'

interface HUDProps {
  multiplier: number
  gameOutcome: 'WIN' | 'LOSS' | null
  config: ShipConfig
}

const HUD: React.FC<HUDProps> = ({ multiplier, gameOutcome, config }) => {
  const textRef = useRef<THREE.Mesh>(null!)
  const targetScale = useRef(1)
  const targetColor = useRef(new THREE.Color('yellow'))
  const currentScale = useMemo(() => new THREE.Vector3(1, 1, 1), [])
  const targetVector = useMemo(() => new THREE.Vector3(), [])
  const materialRef = useRef<THREE.MeshStandardMaterial>(null!)
  const displayMultiplierRef = useRef(multiplier)
  const emissiveIntensity = useRef(0.5)

  const { gameState, crashPoint } = useCrashGameStore()

  useEffect(() => {
    if (gameOutcome === 'WIN') {
      targetColor.current.setHex(0x808080) // Gray
      emissiveIntensity.current = 0.1
    } else {
      targetScale.current = 1
      targetColor.current.setHex(0xffff00) // Yellow
      emissiveIntensity.current = 0.5
    }
  }, [gameOutcome])

  useEffect(() => {
    if (gameState === 'CRASHING') {
      displayMultiplierRef.current = crashPoint
    } else if (gameState === 'PLAYING') {
      displayMultiplierRef.current = multiplier
    }
  }, [gameState, multiplier])

  useFrame(() => {
    if (textRef.current) {
      targetVector.set(targetScale.current, targetScale.current, targetScale.current)
      currentScale.lerp(targetVector, 0.1)
      textRef.current.scale.copy(currentScale)

      if (materialRef.current) {
        materialRef.current.color.lerp(targetColor.current, 0.1)
        materialRef.current.emissiveIntensity = emissiveIntensity.current
      }
    }
  })

  // If game is won, gray out the HUD
  if (gameOutcome === 'WIN') {
    targetColor.current.setHex(0x808080) // Gray
    // console.log('DEBUG: finalHudPosition2', textRef.current?.position.y)
  }

  return (
    <group position={[0, config.hud.verticalOffset, 0]}>
      <Text
        ref={textRef}
        fontSize={config.hud.fontSize}
        anchorX='center'
        anchorY='middle'
        outlineWidth={0.05}
        outlineColor='black'
        font='/fonts/Gohu/gohu-small-uni-14.ttf'
      >
        {`${displayMultiplierRef.current.toFixed(2)}x`}
        <meshStandardMaterial
          ref={materialRef}
          attach='material'
          emissive={targetColor.current}
          emissiveIntensity={emissiveIntensity.current}
        />
      </Text>
    </group>
  )
}

export default HUD
