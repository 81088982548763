import { Web3Provider } from '@ethersproject/providers'
import {
  useDisconnect,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
  useWeb3ModalState,
} from '@web3modal/ethers5/react'
import { EthersStoreUtil } from '@web3modal/scaffold-utils/ethers'

export const useWeb3 = () => {
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider, walletProviderType } = useWeb3ModalProvider()
  const { open, selectedNetworkId } = useWeb3ModalState()
  const { disconnect } = useDisconnect()
  const [provider, setProvider] = useState<Web3Provider>()

  useEffect(() => {
    if (walletProvider) {
      setProvider(new Web3Provider(walletProvider))
    } else {
      setProvider(undefined)
    }
  }, [walletProvider, chainId])

  useEffect(() => {
    EthersStoreUtil.state.address = address
  }, [address])

  return useMemo(
    () => ({
      account: address,
      chainId,
      isActive: isConnected,
      isActivating: open,
      selectedNetworkId,
      provider,
      walletProvider,
      walletProviderType,
      disconnect,
    }),
    [
      address,
      chainId,
      isConnected,
      open,
      selectedNetworkId,
      provider,
      walletProvider,
      walletProviderType,
      disconnect,
    ]
  )
}
